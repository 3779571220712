import { Button, Flex, Input, message, Popconfirm,Card, Space } from "antd";
import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";
import { Icon } from "@iconify/react/dist/iconify.js";
import Table2 from "../../../../../Components/tables/table2";
import { ConvertParams, MainURL } from "../../../../../config";
import { MakeApiCall } from "../../../../../apis";
import moment from "moment";
import SpeciesForm from "./add";
import MarketplaceSelect from "../../../../../Components/marketplace";
import ImportFIle from "../../../../../Components/import-file";
// import BrandModalForm from "./add";

const { Search } = Input;


const Species = ({ userData, marketplace_id, selectedMarketplace, setSelectedMarketplace}) => {

    

//   const [selectedMarketplace, setSelectedMarketplace] = useState(null);

  const [visible, setVisible] = useState(false);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    page: 1,
    "per-page": 20,
    totalPage: 0,
    name: null
  });
  const [selectedRow, setSelectedRow] = useState({});

  const columns = [
    {
      title: "#",
      align: "left",
      render: (_, __, i) => {
        return (
          <span>{(filters?.page - 1) * filters?.["per-page"] + 1 + i}</span>
        );
      },
    },
    {
      title: "Species Name",
      dataIndex: "name",
      key: "name",
      render: (e) => {
        return <b>{e}</b>;
      },
    },
    {
      title: "Marketplace",
      dataIndex: "marketplace",
      key: "marketplace",
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      render: (_, item) => 
        !item?.updated_at || isNaN(parseInt(item.updated_at, 10)) || parseInt(item.updated_at, 10) <= 0 
          ? <>N/A</> 
          : <>{moment(new Date(item.updated_at * 1000)).format("MMM DD, YYYY hh:mm A")}</>

    },

    {
      title: "Action",
      key: "action",
      width: 150,
      render: (text, record) => (
        <Space size="middle">
          <Button
            color="primary"
            variant="solid"
            onClick={() => handleEdit(record)}
            icon={<Icon width={22} icon="iconamoon:edit-fill" />}
          ></Button>
          <Popconfirm
            title="Are you sure to delete this brand?"
            onConfirm={() => handleDelete(record)}
            okText="Yes"
            placement="left"
            cancelText="No"
          >
            <Button
              color="danger"
              variant="filled"
              icon={<Icon width={22} icon="ic:round-delete" />}
            ></Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const ListSpecies = async (data) => {
    const { totalPage, ...rest } = data;
    setLoading(true);
    try {
      const response = await MakeApiCall(
        `species${ConvertParams(rest)}`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        const { totalCount, page, page_size } = response?.data?.pagination;

        setFilters({
          ...data,
          page: page,
          "per-page": page_size,
          totalPage: totalCount,
        });

        setList(response?.data?.records || []);

        setLoading(false);
      } else {
        message.destroy();
        setLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setLoading(false);
      message.destroy();
      message.error(error?.message);
    }
  };
  const DeleteSpecies = async (id) => {
    try {
      const response = await MakeApiCall(
        `species/${id}`,
        "delete",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        ListSpecies({
          ...filters,
        });
        setLoading(true);
        message.destroy();
        message.success(response?.message);
      } else {
        message.destroy();

        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      message.error(error?.message);
    }
  };

  useEffect(() => {
    ListSpecies({
      ...filters,
    });
    setLoading(true);
    return () => {};
  }, []);

  // Edit handler
  const handleEdit = (record) => {
    console.log(record, '-----')
    setSelectedRow(record);
    setVisible(true);
  };

  // Delete handler
  const handleDelete = (record) => {
    DeleteSpecies(record.id);

    // Add your delete logic here
  };

  const handleSearch = (value) => {
    // setLoading(true);
    ListSpecies(filters);
  };
  return (
    <Wrapper>
    <Card className="mb-5" title={
       <>

        <Search
            placeholder="Search by Species name"
            allowClear
            size="large"
            className=""
            onSearch={handleSearch}
            value={filters?.name || ""}
            style={{
              width: 250,
            }}
            onChange={(e) => {
              setFilters({
                ...filters,
                name: e.target.value,
              });
            }}
            onClear={() => {
              setFilters({
                ...filters,
                name: null, // Ensure it's null or empty string
              });
              ListSpecies({ ...filters, name: null }); // Fetch updated data when cleared
            }}
          />
       </>
     } 
     extra={
     <Flex wrap>
     <Button
         type="primary"
         className="me-3"
         onClick={() => setVisible(true)}
         icon={<Icon width={20} icon="material-symbols:add" />}
       >
         Add Species
       </Button>
       <ImportFIle
                   fileType=".xlsx"
                   multiple={false}
                   documentType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                   downloadSamplePath={`${MainURL}/SpeciesTemplates.xlsx`}
                   trigger={["click"]}
                   endpoint="import-species"
                   methodKey="POST"
                   bodyKey={{
                     marketplace_id: selectedMarketplace,
                   }}
                   paramKey="UploadedDataFile[import_file]"
                   onResponse={(e) => {
                     if (e?.status === true) {
                       message.destroy();
                       message.success(e?.message);
                     } else {
                       message.destroy();
                       message.error(e?.message);
                     }
                   }}
                   tokenKey={userData?.user?.auth_token}
                 >
                   <Button
                     type="primary"
                     style={{ marginRight: "0px", padding: "5px 10px" }}
                   >
                     <Icon icon="bi:filetype-xlsx" width={18} /> Import
                   </Button>
                 </ImportFIle>
       </Flex>
     }
     >
         <Table2
        dataSource={list}
        columns={columns}
        loading={loading}
        pagination={{
          pageSize: filters?.["per-page"],
          current: filters?.page,
          total: filters?.totalPage,
          onChange: (e, f) => {
            const obj = {
              ...filters,
              page: e,
              "per-page": f,
            };
            setFilters(obj);
            ListSpecies({
              ...obj,
            });
          },
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50, 100],
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
        scroll={{ x: "max-content", y: 500 }}
      />
    </Card>
      <SpeciesForm 
        selectedRow={selectedRow}
        onSubmit={() => {
          setSelectedRow({});
          setVisible(false);
          ListSpecies({
            ...filters,
          });
          setLoading(true);
        }}
        userData={userData}
        visible={visible}
        setVisible={() => {
          setVisible(false);
          setSelectedRow({});
        }}
        selectedMarketplace={selectedMarketplace} // Pass the selected marketplace
      />
    </Wrapper>
  );
};

export default Species;
