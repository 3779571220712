import styled from "styled-components";

export const Wrapper = styled.div`
.ant-input-search .ant-input{
    font-size: 14px;
}
.ant-input-search .ant-input-group-addon .ant-btn{
border-left:0  !important;
    }
.ant-input-outlined {
    background: #ffffff;
    border-width: 1px;
    border-style: solid;
    border-color: #d1d1d1;
}
// .react-tel-input .form-control{
//     background: #eef4f8;
//     border-width: 1px;
//     border-style: solid;
//     border-color: #0179b0;
// }
// .react-tel-input .flag-dropdown{
//      border-color: #0179b0;
//     }
.cus-input input{
    border: navajowhite;
}
    .cus-input{border-color: #d1d1d1;}
    .cus-input input:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
}
    `;
