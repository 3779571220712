import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, Upload, InputNumber, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { URL } from "../../../../../config";

const TemplateModal = ({ visible, onClose, onSubmit, editData }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (!visible) {
      return form.resetFields();
    } else {
      form.setFieldsValue({
        ...editData,
        logo: editData?.logo
          ? [
              {
                uid: "-1",
                name: "logo.jpeg",
                status: "done",
                url: URL + "/images/" + editData?.logo,
              },
            ]
          : null,
      });
    }
  }, [visible]);

  // Convert image file to Base64
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleFinish = async (values) => {
    if (editData) {
      if (values.logo?.file) {
        const base64 = await getBase64(values.logo?.file);

        onSubmit({ ...values, logo: base64, status: 1 }, "edit");
      } else {
        delete values?.logo;
        onSubmit({ ...values, status: 1 }, "edit");
      }
    } else {
      const base64 = await getBase64(values.logo?.file);
      onSubmit({ ...values, logo: base64, status: 1 }, "add");
    }
  };

  if (editData?.logo) {
    form.setFieldsValue({
      ...editData,
      logo: [
        {
          uid: "-1",
          name: "logo.jpeg",
          status: "done",
          url: URL + "/images/" + editData?.logo,
        },
      ],
    });
  }

  return (
    <Modal
      title={editData ? "Edit Template" : "Create Template"}
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button onClick={onClose} style={{ marginLeft: 8 }}>
          Cancel
        </Button>,
        <Button
          type="primary"
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                handleFinish(values);

                // Submit form or perform further actions
                // setVisible(false); // Close modal after submit
                // form.resetFields(); // Reset form fields
              })
              .catch((info) => {});
          }}
        >
          {editData ? "Update" : "Create"}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
        // initialValues={editData || {}}
      >
        {/* Template Name */}
        <Form.Item
          label="Template Name"
          name="name"
          rules={[
            { required: true, message: "Please input the template name!" },
          ]}
        >
          <Input placeholder="Enter template name" />
        </Form.Item>

        {/* Template Logo */}
        <Form.Item
          label="Template Logo"
          name="logo"
          rules={[
            { required: true, message: "Please upload a template logo!" },
          ]}
          // valuePropName="fileList"
        >
          <Upload
            beforeUpload={(file) => {
              const isPNGorJPEG =
                file.type === "image/jpeg" || file.type === "image/png";

              if (!isPNGorJPEG) {
                message.error("Only PNG and JPEG are supported!");
                return Upload.LIST_IGNORE; // Prevent adding unsupported files
              }

              return false; // Prevent automatic upload
            }}
            listType="picture"
            accept=".png,.jpeg"
            maxCount={1}
            // action={""}
            defaultFileList={
              editData?.logo
                ? [
                    {
                      uid: "-1",
                      name: "yyy.png",
                      status: "done",
                      url: URL + "/images/" + editData?.logo,
                    },
                  ]
                : null
            }
            onChange={(e) => {
              if (e?.fileList?.length === 0) {
                form.setFieldsValue({ logo: null });
                form.validateFields();
              }
            }}
          >
            <Button icon={<UploadOutlined />}>Upload Logo</Button>
          </Upload>
        </Form.Item>

        {/* Bill To Address */}
        <Form.Item
          label="Bill To Address"
          name="bill_to_address"
          rules={[
            { required: true, message: "Please input the Bill To address!" },
          ]}
        >
          <Input placeholder="Enter Bill To address" />
        </Form.Item>

        {/* Ship To Address */}
        <Form.Item
          label="Ship To Address"
          name="ship_to_address"
          rules={[
            { required: true, message: "Please input the Ship To address!" },
          ]}
        >
          <Input placeholder="Enter Ship To address" />
        </Form.Item>

        {/* Outbound Lead Time */}
        <Form.Item
          label="Outbound Lead Time (Days)"
          name="outbound_lead_time"
          rules={[
            { required: true, message: "Please input the outbound lead time!" },
            {
              pattern: /^\d+$/,
              message: "Please enter only number!",
            },
          ]}
        >
          <Input
            placeholder="Enter outbound lead time"
            style={{ width: "100%" }}
            type="number"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default TemplateModal;
