import { Modal, Table } from "antd";
import React from "react";

const UnitHistory = ({ unitCostHistory, handleCancel, columnsUnitCost }) => {
  return (
    <Modal
      title="Unit Cost History"
      visible={unitCostHistory?.show}
      onCancel={handleCancel}
      footer={null}
      width={800}
    >
      <Table
        columns={columnsUnitCost}
        dataSource={unitCostHistory?.data}
        pagination={false}
        scroll={{ x: "max-content" }}
      />
    </Modal>
  );
};

export default UnitHistory;
