import { Button, Card, Flex, Input, message, Select, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import Table2 from "../../../../Components/tables/table2";
import { MakeApiCall } from "../../../../apis";
import { ConvertParams } from "../../../../config";
import { Wrapper } from "../style";
import { Icon } from "@iconify/react/dist/iconify.js";
import ImportFIle from "../../../../Components/import-file";

const { Search } = Input;


const MappingView = ({ userData, selectedMarketplace, marketplace_id }) => {

  const [brandList, setBrandList] = useState([]);
  const [brandLoading, setBrandLoading] = useState(true);


  const [productLineList, setProductLineList] = useState([]);
  const [productLineLoading, setproductLineLoading] = useState(true);

  const [vendorList, setVendorList] = useState([]);
  const [vendorLoading, setVendorLoading] = useState(true);

  const [speciesList, setSpeciesList] = useState([]);
  const [speciesLoading, setSpeciesLoading] = useState(true);


  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    page: 1,
    "page-size": 20,
    totalPage: 0,
    mapping_filter: null
  });

  
  console.log(marketplace_id,'marketplace_id')

  const [selectedRow, setSelectedRow] = useState({});



  const ListBrand = async () => {
    setBrandLoading(true);
    try {
      const response = await MakeApiCall(
        `brands?page=all`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setBrandList(response?.data?.records || []);
        setBrandLoading(false);
      } else {
        setBrandLoading(false);
        setBrandList([]);
        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      setBrandLoading(false);
      setBrandList([]);
      message.error(error?.message);
    }
  };

  const ListProductLine = async () => {
    setproductLineLoading(true);
    try {
      const response = await MakeApiCall(
        `product-lines`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setProductLineList(response?.data?.records || []);
        setproductLineLoading(false);
      } else {
        setproductLineLoading(false);
        setProductLineList([]);
        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      setproductLineLoading(false);
      setProductLineList([]);
      message.error(error?.message);
    }
  };

  const ListVendor = async () => {
    setVendorLoading(true);
    try {
      const response = await MakeApiCall(
        `vendors?page=all`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setVendorList(response?.data?.records || []);
        setVendorLoading(false);
      } else {
        setVendorLoading(false);
        setVendorList([]);
        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      setVendorLoading(false);
      setVendorList([]);
      message.error(error?.message);
    }
  };

  const ListSpecies = async () => {
    setSpeciesLoading(true);
    try {
      const response = await MakeApiCall(
        `species-list`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setSpeciesList(response?.data || []);
        setSpeciesLoading(false);
      } else {
        setSpeciesLoading(false);
        setSpeciesList([]);
        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      setSpeciesLoading(false);
      setSpeciesList([]);
      message.error(error?.message);
    }
  };


  const ListMapping = async (data) => {
    const { totalPage, ...rest } = data;
    setLoading(true);
    try {
      const response = await MakeApiCall(
        `product-mapping${ConvertParams(rest)}&marketplace_id=${marketplace_id}`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        const { totalCount, page, page_size } = response?.data?.pagination;

        setFilters({
          ...data,
          page: page,
          "page-size": page_size,
          totalPage: totalCount,
        });

        setList(response?.data?.records || []);

        setLoading(false);
      } else {
        message.destroy();
        setLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setLoading(false);
      message.destroy();
      message.error(error?.message);
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
  
        await ListBrand({ ...filters });
        await ListProductLine({ ...filters });
        await ListVendor({ ...filters });
        await ListSpecies({ ...filters });
        await ListMapping({ ...filters });
  
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);


  // const data = Array.from({ length: 10 }).map((_, i) => ({
  //   key: i,
  //   ASIN: `ASIN-${i}`,
  //   MSKU: `MSKU-${i}`,
  //   Title: `Title-${i}`,
  //   Image: `https://picsum.photos/id/${i}/50/50`,
  //   "Brand Name": `Brand Name-${i}`,
  //   "Product Line": `Product Line-${i}`,
  // }));

  const UpdateBrandAction = async (id,field, value) => {
    try {
      const response = await MakeApiCall(
        `product-mapping/${id}`,
        "PATCH",
        { [field]: value },
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setList((prev) =>
          prev?.map((d) =>
            d?.id === id ? { ...d, [field]: value } : d
          )
        );
        message.destroy();
        message.success(response?.message);
        ListMapping({
          ...filters,
        });
      } else {
        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      message.error(error?.message);
    }
  };


  const columns = [
    {
      title: "Image",
      dataIndex: "image_url",
      key: "image_url",
      render: (text) => text ? <img src={text} width={50} height={50} alt="Image" /> : <img src="../assets/media/dummy.jpg" width={50} height={50} alt="Image"/>,
    },
    {
      title: "Title",
      dataIndex: "item_name",
      key: "item_name",
      render:(text)=> <Tooltip title={text}><div className="text-two mw-350px">{text}</div></Tooltip>
    },
    {
      title: "ASIN",
      dataIndex: "asin1",
      key: "asin1",
    },
    {
      title: "SKU",
      dataIndex: "seller_sku",
      key: "seller_sku",
    },
    // {
    //   title: "MSKU",
    //   dataIndex: "MSKU",
    //   key: "MSKU",
    // },
    {
      title: "Brand",
      dataIndex: "brand_name",
      key: "brand_name",
      render: (_ , row) => {
        return (
          <div>
             <Select
              className="w-150px"
              variant="filled"
              onChange={(e) => {
                // UpdateBrandAction(t?.fald_id, e);
                UpdateBrandAction(row?.id, "brand_id", e);
              }}
              loading={brandLoading}
              placeholder="Select Brand"
              // value={brandLoading ? undefined : row?.brand_id} 
              value={brandLoading ? null : row?.brand_id == 0 ? null : row?.brand_id}
              options={brandList?.map((d) => ({
                label: d?.b_name,
                value: d?.b_id,
              }))}
            />
          </div>
        );
      },
    },
    {
      title: "Product Line",
      dataIndex: "product_line_name",
      key: "product_line_name",
      // render: (_, records) => records?.product_line_name || "-",
      render: (_ , row) => {
        return (
          <div>
             <Select
              className="w-150px"
              variant="filled"
              onChange={(e) => {
                // UpdateBrandAction(t?.fald_id, e);
                UpdateBrandAction(row?.id, "product_line_id", e);
              }}
              loading={productLineLoading}
              placeholder="Select Product Line"
              value={productLineLoading ? null : row?.product_line_id == 0 ? null : row?.product_line_id}
              options={productLineList?.map((d) => ({
                label: d?.name,
                value: d?.id,
              }))}
            />
          </div>
        );
      },
    },
    {
      title: "Vendor",
      dataIndex: "vendor_name",
      key: "vendor_name",
      // render: (_, records) => records?.vendor_name || "-",
      render: (_ , row) => {
        return (
          <div>
             <Select
              className="w-150px"
              variant="filled"
              onChange={(e) => {
                // UpdateBrandAction(t?.fald_id, e);
                UpdateBrandAction(row?.id, "vendor_id", e);
              }}
              loading={vendorLoading}
              placeholder="Select Vendor"
              value={vendorLoading ? null : row?.vendor_id == 0 ? null : row?.vendor_id}
              options={vendorList?.map((d) => ({
                label: d?.name,
                value: d?.id,
              }))}
            />
          </div>
        );
      },
    },
    // {
    //   title: "Supply",
    //   dataIndex: "",
    //   key: "",
    // },
    {
      title: "Species",
      dataIndex: "species_name",
      key: "species_name",
      // render: (_, records) => records?.species_name || "-",
      render: (_ , row) => {
        return (
          <div>
             <Select
              className="w-150px"
              variant="filled"
              onChange={(e) => {
                // UpdateBrandAction(t?.fald_id, e);
                UpdateBrandAction(row?.id, "species_id", e);
              }}
              loading={productLineLoading}
              placeholder="Select Species Name"
              value={speciesLoading ? null : row?.species_id == 0 ? null : row?.species_id}
              options={speciesList?.map((d) => ({
                label: d?.name,
                value: d?.id,
              }))}
            />
          </div>
        );
      },
    },
  ];



  const handleSearch = (value) => {
    // setLoading(true);
    ListMapping(filters);
  };


  return (
    <Wrapper>
      {/* <Table2
        scroll={{ x: "max-content" }}
        columns={columns}
        dataSource={list}
      /> */}
      <Card className="mb-5" title={
        <>

        <Search
          placeholder="Search by title or ASIN"
          allowClear
          size="large"
          className=""
          onSearch={handleSearch}
          value={filters?.mapping_filter || ""}
          style={{
            width: 250,
          }}
          onChange={(e) => {
            setFilters({
              ...filters,
              mapping_filter: e.target.value,
            });
          }}
          onClear={() => {
            setFilters({
              ...filters,
              mapping_filter: null, // Ensure it's null or empty string
            });
            ListMapping({ ...filters, mapping_filter: null }); // Fetch updated data when cleared
          }}
        />
        </>
      } 
      extra={
      <Flex wrap>
      {/* <Button
          type="primary"
          className="me-3"
          onClick={() => setVisible(true)}
          icon={<Icon width={20} icon="material-symbols:add" />}
        >
          Add Vendor
        </Button> */}
       
        </Flex>
      }
      >
            <Table2
        dataSource={list}
        columns={columns}
        scroll={{ x: "max-content", y: 500 }}
        loading={loading}
        pagination={{
          pageSize: filters?.["page-size"],
          current: filters?.page,
          total: filters?.totalPage,
          onChange: (page, pageSize) => {
            const updatedFilters = {
              ...filters,
              page: page,
              "page-size": pageSize, // Ensure page-size updates in state
            };
            setFilters(updatedFilters);
            ListMapping(updatedFilters);
          },
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100"],
          onShowSizeChange: (current, size) => {
            const updatedFilters = {
              ...filters,
              page: 1, // Reset to first page when page size changes
              "page-size": size, // Update page size in state
            };
            setFilters(updatedFilters);
            ListMapping(updatedFilters);
          },
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        }}
      />
      </Card>
    </Wrapper>
  );
};

export default MappingView;
