import React, { useState, useEffect } from "react";

import styled from "styled-components";
import { MakeApiCall } from "../../../apis";
import { Button, Modal, Spin, Typography, Tag } from "antd";

const LoadingWrapper = styled.div`
  height: 118px !important;
  .ant-spin {
    position: absolute;
    width: fit-content;
    left: 0;
    right: 0;
    bottom: 0;
    top: 42px;
    margin: auto;
    height: fit-content;
  }
`;

export const PrepLogModal = (props) => {
  const { view, userData } = props;
  const [alertShow, setAlertShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataUpdate, setDataUpdate] = useState([]);

  const Replenishment_qty = (row) => {
    if (row.replenishment_qty === "0") {
      if (row.salesSum && row.salesSum.replenishment_QTY) {
        return parseInt(row.salesSum.replenishment_QTY);
      }
    } else {
      return row.replenishment_qty;
    }
    return 0;
  };

  useEffect(() => {
    if (props.isOpen) {
      if (view.filter((d) => d.isLinked === true).length !== 0) {
        setLoading(true);
        getComponentData(view.filter((d) => d.isLinked));
      } else {
        setDataUpdate(view);
        setLoading(false);
      }
    }
    return () => {
      setAlertShow(false);
    };
  }, [props.isOpen]);

  const getComponentData = async (ids) => {
    try {
      const res = await MakeApiCall(
        `get-components-list`,
        "post",
        {
          selectedIds: ids.map((d) => d.fald_id),
        },
        true,
        {},
        userData?.user?.auth_token
      );

      if (res.status === true) {
        const dataUpdateSet = [];
        view.forEach((ee) => {
          const index = Object.keys(res.data.linkedItemsList).findIndex(
            (d) => parseInt(d) === parseInt(ee.fald_id)
          );
          if (index !== -1) {
            dataUpdateSet.push({
              ...ee,
              quantityUpdate: Replenishment_qty(ee),
              componentData: Object.values(res.data.linkedItemsList)[index],
            });
          } else {
            dataUpdateSet.push({
              ...ee,
              quantityUpdate: ee.replenishment_qty,
              componentData: [],
            });
          }
        });
        setDataUpdate(dataUpdateSet);
        setLoading(false);
      }
    } catch (error) {}
  };
  const [loadingAction, setLoadingAction] = useState(false);
  const submitPrep = async () => {
    const index = dataUpdate.findIndex((d) => d.isLinked === false);
    if (index !== -1) {
      setAlertShow(true);
    } else {
      const data = [];
      dataUpdate.forEach((e) => {
        if (
          e.quantityUpdate &&
          e.quantityUpdate !== undefined &&
          e.quantityUpdate !== null &&
          parseInt(parseFloat(e.quantityUpdate).toFixed(0)) !== 0
        ) {
          data.push({ [e.fald_id]: parseInt(e.quantityUpdate) });
        }
      });

      if (dataUpdate.length === data.length) {
        var result = {};
        for (var i = 0; i < data.length; i++) {
          result[Object.entries(data[i])[0][0]] = Object.entries(data[i])[0][1];
        }
        setLoadingAction(true);

        const res = await MakeApiCall(
          `create-prep-log`,
          "post",
          { quantity: result },
          true,
          {},
          userData?.user?.auth_token
        );

        if (res.status === true) {
          const link = document.createElement("a");
          link.href = res.data.file;
          link.click();
          props.closeModal();
          setAlertShow(false);
        }
        setLoadingAction(false);
      } else {
        alert("Please provide a quantity");
      }
    }
  };

  const updateQuantity = (e, row) => {
    const dataUpdateSet = dataUpdate;
    const index = dataUpdateSet.findIndex((d) => d.fald_id === row.fald_id);
    if (index !== -1) {
      dataUpdateSet[index].quantityUpdate = e;
      setDataUpdate([...dataUpdateSet]);
    }
  };

  console.log(dataUpdate, "dataUpdate");

  return (
    <Modal
      onCancel={() => {
        props.closeModal();
        setAlertShow(false);
      }}
      open={props.isOpen}
      width={750}
      title="Create Prep Log"
      footer={[
        <Button
          type="primary"
          size="small"
          onClick={() => {
            submitPrep();
          }}
          loading={loadingAction}
        >
          Export
        </Button>,
      ]}
    >
      <>
        {alertShow && (
          <div
            class="alert alert-warning alert-dismissible fade show"
            role="alert"
            style={{
              height: "50px",
              display: "flex",
              alignItems: "center",
              padding: "20px",
            }}
          >
            All Asin Should Be Linked up With Component.
            <button
              type="button"
              class="close btn-sm"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        )}
        <table class="table table-bordered">
          <tbody>
            <tr class="">
              <th style={{ padding: "0.4rem 0.5rem" }}>ASIN</th>
              <th style={{ padding: "0.4rem 0.5rem" }}>Description</th>
              <th style={{ padding: "0.4rem 0.5rem" }}>Link</th>
              <th style={{ padding: "0.4rem 0.5rem" }}>Quantity</th>
            </tr>
            {loading && (
              <LoadingWrapper>
                <Spin />
              </LoadingWrapper>
            )}
            {dataUpdate !== undefined &&
              loading === false &&
              dataUpdate.length !== 0 &&
              dataUpdate.map((d, i) => (
                <tr key={i}>
                  <th class="tbody_1">
                    <strong>{d.asin1 || d.fbaAllListing.asin1 || null}</strong>
                  </th>
                  <td class="tbody_2">
                    <div
                      class="item_description"
                      style={{
                        borderBottom: "1px solid #808080b0",
                        margin: "10px 0px",
                      }}
                    >
                      {d.item_name ||
                        d.fbaAllListing.item_name ||
                        "no item description"}
                    </div>
                    {d.componentData && d.componentData.length > 0
                      ? d.componentData.map((d, i) => (
                          <div class="label-gap" key={i}>
                            <span class="">
                              {i + 1} {d.componentData.item_description}
                            </span>
                          </div>
                        ))
                      : "Not Defined"}
                  </td>
                  <td class="tbody_2">
                    {
                      <Typography.Text className="shipping">
                        <Tag
                          color={d.isLinked ? "blue" : "geekblue"}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (!d.isLinked) {
                              window.location.replace(
                                `/link-items?searchKeyword=${
                                  d.asin1 || d.fbaAllListing.asin1 || null
                                }`
                              );
                            }
                          }}
                        >
                          {d.isLinked ? "Linked" : "Link"}
                        </Tag>
                      </Typography.Text>
                    }
                  </td>
                  <td class="tbody_3">
                    <input
                      type="number"
                      class="form-control component_qty"
                      value={d.quantityUpdate || ""}
                      name="quantity"
                      onChange={(e) => {
                        updateQuantity(e.target.value, d);
                      }}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </>
    </Modal>
  );
};
