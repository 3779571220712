import React from "react";
import moment from "moment";
import styled from "styled-components";
// import { Button, ModalFooter, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Button, Col, Divider, Modal, Row, Tag } from "antd";

// import "@styles/base/pages/page-auth.scss";

const ModalWrapper = styled.div`
  th,
  td {
    border: none !important;
    font-size: 12px !important;
  }
  .table td {
    padding: 0.5rem !important;
  }
  th {
    font-weight: 300 !important;
  }
  td {
    // font-weight: bold !important;
  }
  table.table tr td,
  table.table tr th {
    border-bottom: 1px solid #82868b2b !important;
  }

  .table-info {
    th,
    td {
      background-color: #f9f9f9 !important;
      // background-color: #d7f7fc !important;
    }
  }
  .border-add {
    // border: 1px solid red;
  }
  [dir] .btn-secondary {
    color: #333 !important;
    background-color: #e4e4e4 !important;
    border-color: #fff !important;
  }
  table.table tr td,
  table.table tr th {
    padding: 0.4rem !important;
  }
  .table {
    margin-bottom: 0rem !important;
  }

  .forecast-table {
    // background-color: #f9f9f9; /* Light background color */
    // border: 1px solid #d9d9d9; /* Border for the entire table */
    // border-radius: 8px; /* Rounded corners */
    padding: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }

  .forecast-table .ant-row {
    border-bottom: 1px solid #e8e8e8; /* Row separators */
    padding: 8px 0;
  }

  .forecast-table .ant-row:last-child {
    border-bottom: none; /* Remove border for the last row */
  }

  .forecast-table strong {
    color: #595959; /* Text color for labels */
  }

  .forecast-table .ant-col {
    display: flex;
    align-items: center; /* Vertically align content */
  }

  .forecast-table .ant-col:nth-child(2) {
    color: #1890ff; /* Highlighted color for the first value column */
    font-weight: bold;
  }

  .forecast-table .ant-col:nth-child(3) {
    color: #32780f; /* Highlighted color for the second value column */
    font-weight: bold;
  }
`;

const ReplenishmentModal = (props) => {
  const { isOpen, close, dataList } = props;
  const data = dataList;
  return (
    <Modal
      open={isOpen}
      onCancel={close}
      title={
        <div className="d-flex align-items-center">
          Replenishment&nbsp;&nbsp;
          <Tag bordered={false} color="blue">{`#${data.asin}`}</Tag>
        </div>
      }
      footer={[
        <Button type="dashed" size="small" onClick={() => close()}>
          Close
        </Button>,
      ]}
    >
      {data && data !== -1 && (
        <>
          <ModalWrapper>
            <div className="forecast-table">
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <strong>Sales Period for Forecast:</strong>
                </Col>
                <Col span={6}>
                  {moment(data.start_date).format("MMM DD YYYY")}
                </Col>
                <Col span={6}>
                  {moment(data.end_date).format("MMM DD YYYY")}
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <strong>Sales for Period:</strong>
                </Col>
                <Col span={6}>{data.total_sales || ""}</Col>
                <Col span={6}>
                  {`${data.sales_period_for_forecast} days` || ""}
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <strong>Stockouts:</strong>
                </Col>
                <Col span={6}>{data.stockoutdayscount || ""}</Col>
                <Col span={6}>days</Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <strong>Sales for Forecast:</strong>
                </Col>
                <Col span={6}>{data?.["total_sales"] || ""}</Col>
                <Col span={6}>
                  {`${data?.["sales_for_forcast"]} days` || ""}
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <strong>Adjusted Sales Velocity:</strong>
                </Col>
                <Col span={6}>{`${data?.salesVelocityPerDay || 0} / day`}</Col>
                <Col span={6}>{`${
                  data?.salesVelocityPerMonth || 0
                } / month`}</Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <strong>Current Stock:</strong>
                </Col>
                <Col span={6}>{data?.currentStock || 0}</Col>
                <Col span={6}>
                  {moment(data?.["currentStockDate"]).format("MMM DD YYYY")}
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <strong>In Transit:</strong>
                </Col>
                <Col span={6}>{data?.InTransit}</Col>
                <Col span={6}></Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <strong>Sells Out In:</strong>
                </Col>
                <Col span={6}>{data?.sells_out_in_main}</Col>
                <Col span={6}>
                  {moment(data?.sells_out_in_date_main).format("MMM DD YYYY")}
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <strong>Days of Stock Forecast:</strong>
                </Col>
                <Col span={6}>{Math.ceil(data?.days_of_stock_forecast)}</Col>
                <Col span={6}>{`${data?.days_of_stock} days`}</Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <strong>Lead Time Forecast:</strong>
                </Col>
                <Col span={6}>{Math.ceil(data?.lead_time_forcast)}</Col>
                <Col span={6}>{`${data?.lead_time || 0} days`}</Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <strong>Lead Time Closing Stock:</strong>
                </Col>
                <Col span={6}>
                  {Array.isArray(data?.lead_time_closing_stock)
                    ? data?.lead_time_closing_stock[
                        data?.lead_time_closing_stock?.length - 1
                      ]
                    : data?.lead_time_closing_stock}
                </Col>
                <Col span={6}>
                  {Array.isArray(data?.lead_time_closing_stock_date)
                    ? data?.lead_time_closing_stock_date[
                        data?.lead_time_closing_stock_date?.length - 1
                      ]
                    : data?.lead_time_closing_stock_date}{" "}
                  days
                </Col>
              </Row>
              {data?.poCount !== 0 && (
                <>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <strong>On Order:</strong>
                    </Col>
                    <Col span={6}>{data?.on_order}</Col>
                    <Col span={6}></Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <strong>Stocked Coverage for Period Forecast:</strong>
                    </Col>
                    <Col span={6}>
                      {Array.isArray(data?.stock_period_forecast)
                        ? data?.stock_period_forecast[
                            data?.stock_period_forecast?.length - 1
                          ]
                        : data?.stock_period_forecast}
                    </Col>
                    <Col span={6}>
                      {`${
                        Array.isArray(data?.days_of_stock_period_forecast)
                          ? data?.days_of_stock_period_forecast[
                              data?.days_of_stock_period_forecast?.length - 1
                            ]
                          : data?.days_of_stock_period_forecast
                      } days`}
                    </Col>
                  </Row>
                </>
              )}
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <strong>Days of Stock Cover Needed:</strong>
                </Col>
                <Col span={6}>
                  {Array.isArray(data?.days_of_stock_closing_stock)
                    ? data?.days_of_stock_closing_stock[
                        data?.days_of_stock_closing_stock?.length - 1
                      ]
                    : data?.days_of_stock_closing_stock}
                </Col>
                <Col span={6}></Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <strong>Replenishment Quantity:</strong>
                </Col>
                <Col span={6}>
                  {data?.rep_QTY
                    ? data?.rep_QTY?.[data?.rep_QTY?.length - 1]
                    : data?.replenishment_QTY_ori}
                </Col>
                <Col span={6}></Col>
              </Row>
            </div>
          </ModalWrapper>
        </>
      )}
    </Modal>
  );
};

export default ReplenishmentModal;
