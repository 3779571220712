import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";
import {
  Button,
  Card,
  Flex,
  Input,
  message,
  Popconfirm,
  Space,
  Switch,
  Tag,
} from "antd";

import { Icon } from "@iconify/react/dist/iconify.js";
import VendorModalForm from "./add";
import Table2 from "../../../../../Components/tables/table2";

import { ConvertParams, MainURL } from "../../../../../config";
import { MakeApiCall } from "../../../../../apis";
import ImportFIle from "../../../../../Components/import-file";

const { Search } = Input;

const VendorsSetup = ({ userData, selectedMarketplace, marketplace_id }) => {
  const [visible, setVisible] = useState(false);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    page: 1,
    "per-page": 20,
    totalPage: 0,
    name:null
  });
  const [selectedRow, setSelectedRow] = useState({});

  const columns = [
    {
      title: "#",
      render: (_, __, i) => {
        return (
          <span>{(filters?.page - 1) * filters?.["per-page"] + 1 + i}</span>
        );
      },
    },
    {
      title: "Vendor Name",
      dataIndex: "name",
      key: "name",
      render: (e) => {
        return <b>{e}</b>;
      },
    },
    {
      title: "Lead Time",
      dataIndex: "lead_time",
      key: "lead_time",
      render: (e) => (
        <Tag bordered={false} color="blue">
          {e}
        </Tag>
      ),
    },
    {
      title: "Outbound Lead Time",
      dataIndex: "outbound_lead_time",
      key: "outbound_lead_time",
      render: (e) => (
        <div className="min-w-100px">{e}</div>
      ),
    },
    {
      title: "Days of Stock",
      dataIndex: "days_of_stock",
      key: "days_of_stock",
      render: (e) => (
        <div className="min-w-100px">{e}</div>
      ),
    },
    {
      title: "Phone No",
      dataIndex: "phone_no",
      key: "phone_no",
      render: (e) => (
        <div className="min-w-100px">{e}</div>
      ),
    },
    {
      title: "Status",
      dataIndex: "is_enabled",
      key: "is_enabled",
      render: (text, record) => (
        <Switch
          // defaultChecked={record.is_enabled}
          checked={record.is_enabled} // Bind the switch state
          // disabled
          onChange={(checked) => handleStatusChange(record?.id, checked)}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      width: 150,
      render: (text, record) => (
        <Space size="middle">
          <Button
            color="primary"
            variant="solid"
            onClick={() => handleEdit(record)}
            icon={<Icon width={22} icon="iconamoon:edit-fill" />}
          ></Button>
          <Popconfirm
            title="Are you sure to delete this vendor?"
            onConfirm={() => handleDelete(record)}
            okText="Yes"
            placement="left"
            cancelText="No"
          >
            <Button
              color="danger"
              variant="filled"
              icon={<Icon width={22} icon="ic:round-delete" />}
            ></Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const ListVendor = async (data) => {
    const { totalPage, ...rest } = data;
    setLoading(true);
    try {
      const response = await MakeApiCall(
        `vendors${ConvertParams(rest)}`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        const { totalCount, page, page_size } = response?.data?.pagination;

        setFilters({
          ...data,
          page: page,
          "per-page": page_size,
          totalPage: totalCount,
        });

        setList(response?.data?.records || []);

        setLoading(false);
      } else {
        message.destroy();
        setLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setLoading(false);
      message.destroy();
      message.error(error?.message);
    }
  };
  const DeleteVenodr = async (id) => {
    try {
      const response = await MakeApiCall(
        `vendor/${id}`,
        "delete",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        ListVendor({
          ...filters,
        });
        setLoading(true);
        message.destroy();
        message.success(response?.message);
      } else {
        message.destroy();

        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      message.error(error?.message);
    }
  };

  useEffect(() => {
    ListVendor({
      ...filters,
    });
    setLoading(true);
    return () => {};
  }, []);

  // Edit handler
  const handleEdit = (record) => {
    setSelectedRow(record);
    setVisible(true);
  };

  // Delete handler
  const handleDelete = (record) => {
    DeleteVenodr(record.id);

    // Add your delete logic here
  };
  const handleSearch = (value) => {
    // setLoading(true);
    ListVendor(filters);
  };

  const handleStatusChange = async (id, status) => {
    console.log(id, status, '----');
    const statusInt = status ? 1 : 0;
    try {
      const response = await MakeApiCall(
        `vendor/${id}`, // Adjust API endpoint if needed
        "patch",
        { is_enabled: statusInt }, // Send updated status
        true,
        {},
        userData?.user?.auth_token
      );
  
      if (response?.status) {
        message.success("Status updated successfully");
        ListVendor({ ...filters }); // Fetch the updated list of vendors
      } else {
        message.error(response?.message);
      }
    } catch (error) {
      message.error("Failed to update status");
    }
  }

  return (
    <Wrapper>
     <Card className="mb-5" title={
        <>
        <Search
                placeholder="Search by vendor name"
                allowClear
                size="large"
                className=""
                onSearch={handleSearch}
                value={filters?.name || ""}
                style={{
                  width: 250,
                }}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    name: e.target.value,
                  });
                }}
                onClear={() => {
                  setFilters({
                    ...filters,
                    name: null, // Ensure it's null or empty string
                  });
                  ListVendor({ ...filters, name: null }); // Fetch updated data when cleared
                }}
              />
        </>
      } 
      extra={
      <Flex wrap>
      <Button
          type="primary"
          className="me-3"
          onClick={() => setVisible(true)}
          icon={<Icon width={20} icon="material-symbols:add" />}
        >
          Add Vendor
        </Button>
        <ImportFIle
                    fileType=".xlsx"
                    multiple={false}
                    documentType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    downloadSamplePath={`${MainURL}/VendorTemplate.xlsx`}
                    trigger={["click"]}
                    endpoint="import-vendors"
                    methodKey="POST"
                    bodyKey={{
                      marketplace_id: selectedMarketplace,
                    }}
                    paramKey="UploadedDataFile[import_file]"
                    onResponse={(e) => {
                      if (e?.status === true) {
                        message.destroy();
                        message.success(e?.message);
                      } else {
                        message.destroy();
                        message.error(e?.message);
                      }
                    }}
                    tokenKey={userData?.user?.auth_token}
                  >
                    <Button
                      type="primary"
                      style={{ marginRight: "0px", padding: "5px 10px" }}
                    >
                      <Icon icon="bi:filetype-xlsx" width={18} /> Import
                    </Button>
                  </ImportFIle>
        </Flex>
      }
      >
            <Table2
        dataSource={list}
        columns={columns}
        scroll={{ x: "max-content", y: 500 }}
        loading={loading}
        pagination={{
          pageSize: filters?.["per-page"],
          current: filters?.page,
          total: filters?.totalPage,
          onChange: (e, f) => {
            const obj = {
              ...filters,
              page: e,
              "per-page": f,
            };
            setFilters(obj);
            ListVendor({
              ...obj,
            });
          },
          showSizeChanger: true, // To show page size options
          pageSizeOptions: [10, 20, 50, 100], // Page size options
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
      />
      </Card>
      {/* <Flex justify="space-between" style={{ marginBottom: "10px" }}>
        <Input className="w-200px" placeholder="Search..." />

        <Button
          type="primary"
          onClick={() => setVisible(true)}
          icon={<Icon width={20} icon="material-symbols:add" />}
        >
          Add Vendor
        </Button>
      </Flex> */}
  
      <VendorModalForm
        selectedRow={selectedRow}
        onSubmit={() => {
          setSelectedRow({});
          setVisible(false);
          ListVendor({
            ...filters,
          });
          setLoading(true);
        }}
        userData={userData}
        visible={visible}
        setVisible={() => {
          setVisible(false);
          setSelectedRow({});
        }}
      />
    </Wrapper>
  );
};

export default VendorsSetup;
