import { Modal, Form, Input, Select, Button, Row, Col, message } from "antd";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../apis";

const { Option } = Select;

const EditModal = ({
  show,
  onClose,
  selectedRow,
  brandList,
  brandLoading,
  userData,
  vendorList,
  vendorLoading,
  getList,
}) => {
  const [form] = Form.useForm();

  const [submitLoading, setSubmitLoading] = useState(false);

  const updateItemsAction = async (data) => {
    try {
      const response = await MakeApiCall(
        `update-fba-item-new/${selectedRow?.id}`,
        "patch",
        data,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        getList();
        setSubmitLoading(false);
        message.destroy();
        message.success(response?.message);
        onClose();
      } else {
        setSubmitLoading(false);
        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      setSubmitLoading(false);
      message.error(error?.message);
    }
  };

  useEffect(() => {
    if (Object.keys(selectedRow || {})?.length > 0) {
      form.setFieldsValue({
        item_name: selectedRow?.item_name,
        asin_cost: selectedRow?.asin_cost,
        brand_id: selectedRow?.brand_id,
        vendor_id: selectedRow?.vendor_id,
        asin1: selectedRow?.asin1,
        msku: selectedRow?.msku,
        moq: selectedRow?.moq,
        item_note: selectedRow?.item_note,
        prep_type: selectedRow?.prep_type,
      });
    }
  }, [selectedRow]);

  return (
    <Modal
      title="Edit Purchase Order"
      visible={show}
      onCancel={onClose}
      width={600}
      okButtonProps={{ loading: submitLoading }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            setSubmitLoading(true);
            updateItemsAction(values);
          })
          .catch((info) => {});
      }}
    >
      <Form form={form} layout="vertical">
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item
              name="item_name"
              label="Item Name"
              rules={[{ required: true, message: "Item Name is required" }]}
            >
              <Input placeholder="Enter Item Name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="asin_cost" label="ASIN Cost">
              <Input placeholder="0" disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="brand_id"
              label="Brand"
              rules={[{ required: true, message: "Brand is required" }]}
            >
              <Select
                allowClear
                loading={brandLoading}
                options={brandList?.map((d) => ({
                  label: d?.b_name,
                  value: d?.b_id,
                }))}
                size="large"
                placeholder="Select Brand"
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="vendor_id"
              label="Vendor"
              rules={[{ required: true, message: "Vendor is required" }]}
            >
              <Select
                allowClear
                loading={vendorLoading}
                options={vendorList?.map((d) => ({
                  label: d?.name,
                  value: d?.id,
                }))}
                size="large"
                placeholder="Select Vendor"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="asin1" label="ASIN">
              <Input placeholder="Enter ASIN" disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="msku" label="MSKU">
              <Input placeholder="Enter MSKU" disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="moq"
              label="MOQ"
              rules={[{ required: true, message: "MOQ is required" }]}
            >
              <Input placeholder="Enter MOQ" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="item_note" label="Item Note">
              <Input placeholder="Enter Item Note" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="prep_type" label="Prep Type">
              <Input placeholder="Enter Prep Type" disabled />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default EditModal;
