import { useEffect, useState } from "react";
import { Button, Form, Input, message, Modal } from "antd";
import { MakeApiCall } from "../../../../apis";

const UpdateProfile = ({ closeModal, data, isOpen, userData, submit }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const response = await MakeApiCall(
        `update-replenishment-qty/${data?.value?.id}`,
        "patch",
        {
          AllListingCalculation: {
            replenishment_QTY: values?.qty || null,
          },
        },
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        message.success(response?.message);
        setTimeout(() => {
          closeModal();
          submit();
        }, 1000);
      } else {
        message.warning(response?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      form.setFieldsValue({ qty: data.value.replenishment_QTY || "" });
    }
  }, [isOpen]);

  return (
    <Modal
      title="Update Replenishment"
      open={data.visible}
      onCancel={closeModal}
      centered
      footer={null}
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item
          label="Replenishment Qty"
          name="qty"
          rules={[
            {
              required: true,
              message: "Please enter the replenishment quantity!",
            },
          ]}
        >
          <Input placeholder="Enter Replenishment Qty" />
        </Form.Item>

        <div style={{ textAlign: "right" }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            size="small"
          >
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default UpdateProfile;
