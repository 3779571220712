import {
  DatePicker,
  Pagination,
  Popover,
  Select,
  Spin,
  Table,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { ConvertParams, DefaultPerPage } from "../../../config";
import { CFTooltip } from "../../../Components/tooltip";
import moment from "moment";
import { MakeApiCall } from "../../../apis";
import Table1 from "../../../Components/tables/table1";

export default function SystemLogs({ userData }) {
  const [tableList, setTableList] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [pageSize, setPageSize] = useState(20);

  const getTableList = async (data) => {
    setTableLoading(true);
    const response = await MakeApiCall(
      `application-logs/user-system-logs${ConvertParams(data)}`,
      "get",
      null,
      true,
      {},
      userData?.user?.auth_token
    );
    if (response?.status) {
      setTableLoading(false);
      setTotalPage(response?.data?.pagination?.totalCount); // Update total pages
      setTableList(response?.data?.records || []);
    } else {
      setTableLoading(false);
      message.warning(response?.message);
      setTableList([]);
    }
  };

  useEffect(() => {
    getTableList({ page: page, perPage: pageSize });

    return () => {};
  }, []);

  const onPageNo = (e) => {
    setTableLoading(true);
    setTableList([]);
    getTableList({
      page: e,
      perPage: pageSize,
    });
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setTableLoading(true);
    getTableList({
      page: 1,
      perPage: e,
    });
  };

  const columns = [
    {
      title: "Sr. No.",
      width: 100,
      align: "left",
      ellipsis: true,
      render: (_, __, i) => {
        return <span>{(page - 1) * pageSize + 1 + i}</span>;
      },
    },
    {
      title: "Date & Time Log Created",
      width: 250,
      align: "left",
      render: (d) => {
        return (
          <span>
            {moment(new Date(d.created_at * 1000)).format(
              "MMM DD, YYYY hh:mm A"
            )}
          </span>
        );
      },
    },
    {
      title: "Event Name",
      width: 220,
      align: "left",
      render: (d) => {
        return <span>{d.event_name || "-"}</span>;
      },
    },
    {
      title: "Event Type",
      width: 150,
      align: "left",
      render: (d) => {
        return <span>{d.event_type || "-"}</span>;
      },
    },
    {
      title: "Event Message",
      width: 180,
      align: "left",
      ellipsis: false,
      render: (d) => {
        return (
          <CFTooltip row={3} rule title={d.error_message}>
            <div className="actionIcon">{d.error_message}</div>
          </CFTooltip>
        );
      },
    },
    {
      title: "Event Data",
      width: 150,
      align: "left",
      ellipsis: false,
      render: (d) => {
        if (!d?.error_data) {
          return "-";
        }

        return (
          <CFTooltip row={3} rule={false}>
            <Popover
              placement="bottom"
              title=""
              content={
                <div
                  style={{
                    width: "400px",
                    wordBreak: "break-word",
                    minHeight: "20px",
                    maxHeight: "100px",
                    overflow: "auto",
                  }}
                >
                  {d?.error_data}
                </div>
              }
              trigger="hover"
            >
              <div className="actionIcon">{d.error_data}</div>
            </Popover>
          </CFTooltip>
        );
      },
    },
  ];

  return (
    <div>
      {" "}
      <div className="d-none flex-wrap gap-4 mb-7">
        <div className="position-relative">
          <Select
            size="large"
            className="w-175px"
            placeholder="Select Marketplace"
          />
        </div>

        <div className="position-relative">
          <DatePicker.RangePicker
            size="large"
            className="w-250px"
            suffixIcon={
              <i className="ki-duotone ki-calendar-8 fs-1 ms-2 me-0">
                <span className="path1" />
                <span className="path2" />
                <span className="path3" />
                <span className="path4" />
                <span className="path5" />
                <span className="path6" />
              </i>
            }
          />
        </div>
      </div>
      <div className="row gx-5 gx-xl-10">
        <div className="col-xl-12 mb-5 mb-xl-5">
          {/*begin::Chart widget 31*/}
          <div className="card card-flush h-xl-100">
            {/*begin::Header*/}
            <div className="card-header  mb-0">
              {/*begin::Title*/}
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">
                  System Error Log List
                </span>
                {/* <span class="text-gray-500 mt-1 fw-semibold fs-6">8k social visitors</span> */}
              </h3>
              {/*end::Title*/}
              {/*begin::Toolbar*/}
              <div className="card-toolbar"></div>
              {/*end::Toolbar*/}
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body pt-0">
              <Spin spinning={tableLoading} tip={"Loading..."}>
                <Table1
                  columns={columns}
                  dataSource={tableList}
                  rowKey="key"
                  scroll={{ x: "max-content" }}
                  pagination={false}
                />
              </Spin>
            </div>
            <div className="card-footer  pt-0 pb-4">
              {/* <Pagination
                loading={tableLoading || tableList?.length === 0}
                pageSize={pageSize}
                page={page}
                totalPage={totalPage}
                onPerPage={onPerPage}
                onPageNo={onPageNo}
              /> */}

                         <Pagination
                          current={page}
                          pageSize={pageSize}
                          total={totalPage}
                          showSizeChanger
                          onChange={onPageNo}
                          onShowSizeChange={(_, size) => onPerPage(size)}
                          showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                        />
            </div>
            {/*end::Body*/}
          </div>
        </div>
      </div>
    </div>
  );
}
