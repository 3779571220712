import { Button, Form, Input, message, Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../apis";

const ASINNote = ({
  asinNote,
  handleCancel,
  onSubmit,
  userData,
  selectedRows,
}) => {
  const [form] = Form.useForm();
  const multiEdit = Object.keys(selectedRows || {}).length !== 0;
  const [submitLoading, setSubmitLoading] = useState(false);

   useEffect(()=>{
      if (asinNote?.data?.item_note) {
        form.setFieldsValue({
          itemNote: asinNote?.data?.item_note,
        });
      } else {
        form.setFieldsValue({
          itemNote: null,
        });
      }
    },[asinNote?.show])
 

  const SaveNoteAction = async (id, data) => {
    setSubmitLoading(true);
    try {
      const response = await MakeApiCall(
        `add-item-note/${id}`,
        "PATCH",
        data,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        onSubmit(data, id);
        handleCancel();
        setSubmitLoading(false);
      } else {
        message.destroy();
        setSubmitLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setSubmitLoading(false);
      message.destroy();
      message.error(error?.message);
    }
  };

  const SaveItemNoteAction = async (id, data) => {
    setSubmitLoading(true);
    try {
      const response = await MakeApiCall(
        `save-item-note`,
        "post",
        data,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        onSubmit(data, id);
        handleCancel();
        setSubmitLoading(false);
      } else {
        message.destroy();
        setSubmitLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setSubmitLoading(false);
      message.destroy();
      message.error(error?.message);
    }
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        if (multiEdit) {
          const obj = {
            selectedPoItems: ["3043"],
            itemNote: "test",
          };
          console.log(values, selectedRows);

          // SaveItemNoteAction(values);
          return;
        }
        SaveNoteAction(asinNote?.data?.fald_id, values);
      })
      .catch((errorInfo) => {});
  };

  return (
    <Modal
      title="ASIN Note"
      visible={asinNote?.show}
      onCancel={handleCancel}
      footer={[
        <Button onClick={handleCancel}>Cancel</Button>,
        <Button loading={submitLoading} onClick={handleSubmit} type="primary">
          Submit
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="itemNote"
          label="Notes"
          rules={[{ required: true, message: "Please enter your notes" }]}
        >
          <Input.TextArea rows={4} placeholder="Enter your notes here" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ASINNote;
