import { AdminMenus } from "./admin";
import { BrandMenus } from "./brand";
import { UserMenus } from "./user";

export const FindMenus = (type) => {
  return {
    admin: AdminMenus,
    user: UserMenus,
    brand: BrandMenus,
  }?.[type];
};
