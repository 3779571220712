// store.js

import { createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import rootReducer from "./reducers"; // Your root reducer
import { encryptTransform } from "redux-persist-transform-encrypt";
import { Title } from "../config";

const encryptor = encryptTransform({
  secretKey: Title, // Use a strong secret key
  onError: function (error) {
    console.error("Encryption error:", error);
  },
});
const persistConfig = {
  key: "root",
  storage,
  transforms: process.env?.NODE_ENV === "development" ? [] : [encryptor],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer);
export const persistor = persistStore(store);
