import React from "react";
import { Navigate } from "react-router-dom";
import { isAuthenticated } from "../utils/auth"; // Adjust the path as necessary

const ProtectedRoute = ({ element, user, setUser }) => {
  return isAuthenticated(user) ? (
    React.cloneElement(element, { userData: user, setUser: setUser })
  ) : (
    <Navigate to="/login" />
  );
};

export default ProtectedRoute;
