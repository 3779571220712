// SimpleForm.js
import React from "react";
import { Form, Input, InputNumber, Modal, Select } from "antd";
import TextArea from "antd/es/input/TextArea";

const BulkModalAction = ({
  form,
  show,
  selectedBulkData,
  onSubmit,
  onClose,
}) => {
  const { label, key, componentType, options, fields } = selectedBulkData;
  return (
    <Modal
      title={label}
      visible={show}
      onOk={() => onSubmit(selectedBulkData)}
      onCancel={onClose}
    >
      {/* <Form form={form} layout="vertical" name="BulkModalAction">
        <Form.Item
          name={key}
          label={label}
          rules={[{ required: true, message: `${label} is required` }]}
        >
          {componentType === "number" ? (
            <InputNumber style={{ width: "100%" }} placeholder={label} />
          ) : componentType === "textarea" ? (
            <TextArea placeholder={label} />
          ) : componentType === "select" ? (
            <Select placeholder={label} options={options} allowClear />
          ) : (
            <Input placeholder="Enter a value" />
          )}
        </Form.Item>
      </Form> */}
      <Form form={form} layout="vertical" name="BulkModalAction">
        {componentType === "group" && fields ? (
          fields.map((field) => (
            <Form.Item
              key={field.key}
              name={field.key}
              label={field.label}
              // rules={[{ required: true, message: `${field.label} is required` }]}
            >
              {field.componentType === "number" ? (
                <InputNumber style={{ width: "100%" }} placeholder={field.label} />
              ) : field.componentType === "textarea" ? (
                <TextArea placeholder={field.label} />
              ) : field.componentType === "select" ? (
                <Select placeholder={field.label} options={field.options} allowClear />
              ) : (
                <Input placeholder={field.label} />
              )}
            </Form.Item>
          ))
        ) : (
          <Form.Item
            name={key}
            label={label}
            rules={[{ required: true, message: `${label} is required` }]}
          >
            {componentType === "number" ? (
              <InputNumber style={{ width: "100%" }} placeholder={label} />
            ) : componentType === "textarea" ? (
              <TextArea placeholder={label} />
            ) : componentType === "select" ? (
              <Select placeholder={label} options={options} allowClear />
            ) : (
              <Input placeholder="Enter a value" />
            )}
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default BulkModalAction;
