import styled from "styled-components";

export const Wrapper = styled.div`

     .cus-input input{
    border: navajowhite;
}
    .cus-input{border-color: #d1d1d1;}
    .cus-input input:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
}
`;
