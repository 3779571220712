import React, { useEffect } from "react";
import Table2 from "../../../../../Components/tables/table2";
import { useState } from "react";
import { Button, Card, Flex, Input, message, Popconfirm, Space } from "antd";
import { MakeApiCall } from "../../../../../apis";
import moment from "moment";
import { Icon } from "@iconify/react/dist/iconify.js";
import ImportFIle from "../../../../../Components/import-file";
import { ConvertParams, MainURL, URL } from "../../../../../config";
import { Wrapper } from "./style";
import AddProductLineForm from "./add";


const { Search } = Input;


const ProductLine = ({ userData, marketplace_id, selectedMarketplace }) => {


  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);

  const [productList, setProductList] = useState([]);
  const [productListLoading, setProductListLoading] = useState(true);
  const [filters, setFilters] = useState({
    name: null,
  });

  const [selectedRow, setSelectedRow] = useState({});


  const columns = [
    {
      title: "Product Line",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Created At",
      render: (_, records) => {
        return records?.created_at 
          ? moment(new Date(parseInt(records.created_at) * 1000)).format("MM/DD/YYYY hh:mm A") 
          : 'N/A';
      },
    },
    {
        title: "Action",
        key: "action",
        width: 150,
        render: (text, record) => (
          <Space size="middle">
            <Button
              color="primary"
              variant="solid"
              onClick={() => handleEdit(record)}
              icon={<Icon width={22} icon="iconamoon:edit-fill" />}
            ></Button>
            <Popconfirm
              title="Are you sure to delete this brand?"
              onConfirm={() => handleDelete(record)}
              okText="Yes"
              placement="left"
              cancelText="No"
            >
              <Button
                color="danger"
                variant="filled"
                icon={<Icon width={22} icon="ic:round-delete" />}
              ></Button>
            </Popconfirm>
          </Space>
        ),
      },
  ];

  

  const GetProductLineData = async (data) => {
    const {...rest } = data;
    try {
      setProductList([]);
      setProductListLoading(true);
      const response = await MakeApiCall(
        `product-lines${ConvertParams(rest)}`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setProductList(response?.data?.records || []);
        setFilters({
          ...data,
        });
        setProductListLoading(false);
      } else {
        setProductList([]);
        setProductListLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setProductList([]);
      setProductListLoading(false);
      message.warning(error?.message);
    }
  };

  useEffect(() => {
    GetProductLineData(filters);
    return () => {};
  }, []);


  const DeleteProductLine = async (id) => {
    try {
      const response = await MakeApiCall(
        `product-lines/${id}`,
        "delete",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        GetProductLineData({
          ...filters,
        });
        setLoading(true);
        message.destroy();
        message.success(response?.message);
      } else {
        message.destroy();

        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      message.error(error?.message);
    }
  };

    // Edit handler
    const handleEdit = (record) => {
        console.log(record, '-----')
        setSelectedRow(record);
        setVisible(true);
      };
    
      // Delete handler
      const handleDelete = (record) => {
        DeleteProductLine(record.id);
    
        // Add your delete logic here
      };

  const handleSearch = (value) => {
    // setLoading(true);
    GetProductLineData(filters);
  };

  return (
    <Wrapper>
       <Card className="mb-5" title={
        <>

     <Search
                placeholder="Search by Product Line"
                allowClear
                size="large"
                className=""
                onSearch={handleSearch}
                value={filters?.name || ""}
                style={{
                  width: 250,
                }}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    name: e.target.value,
                  });
                }}
                onClear={() => {
                  setFilters({
                    ...filters,
                    name: null, // Ensure it's null or empty string
                  });
                  GetProductLineData({ ...filters, name: null }); // Fetch updated data when cleared
                }}
              />
        </>
      } 
      extra={
      <Flex wrap>
      <Button
          type="primary"
          className="me-3"
          // onClick={() => setVisible(true)}
          onClick={() => setVisible(true)}
          icon={<Icon width={20} icon="material-symbols:add" />}
        >
          Add Product Line
        </Button>
        <ImportFIle
                    fileType=".xlsx"
                    multiple={false}
                    documentType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    downloadSamplePath={`${MainURL}/ProductLinesTemplate.xlsx`}
                    trigger={["click"]}
                    endpoint="import-product-lines"
                    methodKey="POST"
                    bodyKey={{
                      marketplace_id: selectedMarketplace,
                    }}
                    paramKey="UploadedDataFile[import_file]"
                    onResponse={(e) => {
                      if (e?.status === true) {
                        message.destroy();
                        message.success(e?.message);
                      } else {
                        message.destroy();
                        message.error(e?.message);
                      }
                    }}
                    tokenKey={userData?.user?.auth_token}
                  >
                    <Button
                      type="primary"
                      style={{ marginRight: "0px", padding: "5px 10px" }}
                    >
                      <Icon icon="bi:filetype-xlsx" width={18} /> Import
                    </Button>
                  </ImportFIle>
        </Flex>
      }
      >
        <Table2
        scroll={{ x: "max-content", y: 500 }}
        columns={columns}
        dataSource={productList?.filter(
          (d) => d?.marketplace_id === marketplace_id
        )}
        loading={productListLoading}
      />
      </Card>
      <AddProductLineForm 
        selectedRow={selectedRow}
        onSubmit={() => {
          setSelectedRow({});
          setVisible(false);
          GetProductLineData({
            ...filters,
          });
          setLoading(true);
        }}
        userData={userData}
        visible={visible}
        setVisible={() => {
          setVisible(false);
          setSelectedRow({});
        }}
        selectedMarketplace={selectedMarketplace} // Pass the selected marketplace
      />
    </Wrapper>
  );
};

export default ProductLine;
