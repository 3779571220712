import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";
import {
  Button,
  Divider,
  Input,
  message,
  Radio,
  Space,
  Spin,
  Table,
} from "antd";
import { useLocation } from "react-router-dom";
import { ConvertParams, FormatNumber } from "../../../config";
import { MakeApiCall } from "../../../apis";
import { Icon } from "@iconify/react/dist/iconify.js";

const LinkItems = ({ userData, asin }) => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location?.search);
  const keyword = queryParams.get("searchKeyword");

  const [filter, setFilter] = useState({
    itemStatus: "3",
    searchKeyword: asin || keyword,
  });
  const [itemList, setItemList] = useState([]);
  const [itemLoading, setItemLoading] = useState(true);
  const [selectedId, setSelectedId] = useState(null);
  const [componentList, setComponentList] = useState([]);
  const [componentLoading, setComponentLoading] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);

  const getItemComponents = async (id) => {
    setComponentLoading(true);
    setComponentList([]);
    try {
      const response = await MakeApiCall(
        `get-item-components`,
        "post",
        {
          item_id: id,
        },
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setComponentLoading(false);
        setComponentList(response?.data?.componentList || []);
        setSelectedRowKeys(
          response?.data?.componentList?.filter((d) =>
            response?.data?.linkedComponentList
              ?.map((d) => d?.components_id)
              ?.includes(d?.id)
          ) || []
        );
      } else {
        setComponentLoading(false);
        message.destroy();
        setComponentList([]);
        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      setComponentList([]);
      setComponentLoading(false);
      message.error(error?.message);
    }
  };

  const ItemSearchAction = async (data) => {
    setItemLoading(true);
    setItemList([]);
    try {
      const response = await MakeApiCall(
        `item-search`,
        "post",
        data,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setItemLoading(false);
        setItemList(response?.data || []);
        if (response?.data?.length > 0) {
          setComponentLoading(true);
          getItemComponents(response?.data?.[0]?.id);
          setSelectedId(response?.data?.[0]?.id);
        }
      } else {
        setItemLoading(false);
        setItemList([]);
        setSelectedId(null);
        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      setItemList([]);
      setItemLoading(false);
      setSelectedId(null);
      message.destroy();
      message.error(error?.message);
    }
  };
  const LinkComponents = async (data) => {
    setSubmitLoading(true);

    try {
      const response = await MakeApiCall(
        `link-components`,
        "post",
        data,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setSubmitLoading(false);
        message.destroy();
        message.success(response?.message);
      } else {
        setSubmitLoading(false);

        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      setSubmitLoading(false);

      message.destroy();
      message.error(error?.message);
    }
  };

  useEffect(() => {
    if (filter?.searchKeyword) {
      ItemSearchAction(filter);
    } else {
      setSubmitLoading(false);
      setItemLoading(false);
      setComponentLoading(false);
    }
    return () => {};
  }, []);

  const rowSelection = {
    selectedRowKeys: selectedRowKeys?.map((d) => d?.id),
    onChange: (e, d) => {
      setSelectedRowKeys(d);
    },
  };

  const columns = [
    {
      title: "Component ID",
      dataIndex: "component_id",
      key: "component_id",
    },
    {
      title: "Item Description",
      dataIndex: "item_description",
      key: "item_description",
    },
    {
      title: "Brand",
      dataIndex: "brand_id",
      key: "brand_id",
    },
    {
      title: "Vendor SKU",
      dataIndex: "vendor_sku",
      key: "vendor_sku",
    },
    {
      title: "Unit Cost",
      dataIndex: "unit_cost",
      key: "unit_cost",
      render: (text) => `$${FormatNumber(parseFloat(text).toFixed(2))}`, // Adding dollar sign formatting for unit cost
    },
    {
      title: "UPC",
      dataIndex: "upc",
      key: "upc",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: 160,
      render: (e, row) => {
        return (
          <Input
            type="number"
            value={e}
            onChange={(e) => {
              const val = parseInt(e.target.value);

              setComponentList((prev) => {
                return prev?.map((d) => {
                  if (d?.id === row?.id) {
                    return {
                      ...d,
                      quantity: val,
                    };
                  } else {
                    return { ...d };
                  }
                });
              });
              setSelectedRowKeys((prev) => {
                return prev?.map((d) => {
                  if (d?.component_id === row?.component_id) {
                    return {
                      ...d,
                      quantity: val,
                    };
                  } else {
                    return { ...d };
                  }
                });
              });
            }}
          />
        );
      },
    },
  ];

  return (
    <Wrapper>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex gap-2 align-items-center">
          <Input
            className="w-300px"
            onChange={(e) => {
              setFilter({
                ...filter,
                searchKeyword: e.target.value,
              });
            }}
            onPressEnter={() => {
              ItemSearchAction({
                ...filter,
              });
            }}
            value={filter?.searchKeyword}
            placeholder="Search by Brand, ASIN, MSKU & Title"
          />
          <Radio.Group
            value={filter?.itemStatus}
            onChange={(e) => {
              setFilter({
                ...filter,
                itemStatus: e.target.value,
              });
              ItemSearchAction({
                ...filter,
                itemStatus: e.target.value,
              });
            }}
            buttonStyle="solid"
            size="large"
          >
            <Radio.Button value="1">Linked</Radio.Button>
            <Radio.Button value="2">Unlinked</Radio.Button>
            <Radio.Button value="3">All</Radio.Button>
          </Radio.Group>
        </div>
      </div>

      <div className="card mt-3 p-5">
        <Spin spinning={itemLoading}>
          <Radio.Group
            value={selectedId}
            onChange={(e) => {
              getItemComponents(e.target.value);
              setSelectedId(e.target.value);
            }}
          >
            <Space direction="vertical">
              {itemList?.map((d) => (
                <Radio className="mb-1" value={d?.id}>
                  <div>
                    <a style={{ color: "#0179af" }}>{d?.fbaitem}</a>
                    {d?.item_name}
                  </div>
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Spin>
      </div>

      <div className="card mt-3 p-5">
        <Table
          columns={columns}
          dataSource={componentList}
          loading={componentLoading}
          pagination={false}
          rowKey={"id"}
          scroll={{ x: "max-content" }}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
        />
        <div
          style={{
            position: "sticky",
            bottom: 0,
            background: "#FFF",
            padding: "15px 0px",
          }}
        >
          <Divider className="mt-4 mb-4" />

          <div>
            <Button
              loading={submitLoading}
              onClick={() => {
                LinkComponents({
                  item_id: selectedId,
                  link_components: selectedRowKeys?.map((d) => ({
                    component_id: d?.id,
                    quantity: d?.quantity,
                  })),
                });
              }}
              className="me-3"
              type="primary"
            >
              Apply
            </Button>
            <Button>Clear</Button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default LinkItems;
