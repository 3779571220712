import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import LogoAnimation from "../Components/logo-animation";
import SignIn from "../modules/auth";
import Page from "../modules/page";
import { setUser } from "../redux/actions";
import ProtectedRoute from "./ProtectedRoute";
import { Admins } from "./admin";
import { Users } from "./user";
import SignUp from "../modules/auth/register";
import ForgotPassword from "../modules/auth/forgot-password";
import { Brands } from "./brand";

export default function Routers({ loading, setLoading }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.data);

  const loginType = user?.loginType;

  const pages = {
    admin: Admins,
    user: Users,
    brand: Brands,
  };

  useEffect(() => {
    if (!loginType && window.location.pathname !== "/login") {
      setLoading(false);
    }
  }, [loginType]);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Navigate to={user ? "/dashboard" : "/login"} />,
    },
    {
      path: "/login",
      element: <SignIn />,
    },
    {
      path: "/register",
      element: <SignUp />,
    },
    {
      path: "/forgot-password",
      element: <ForgotPassword />,
    },
    ...(loginType
      ? [
          {
            path: "/",
            element: (
              <Suspense fallback={<LogoAnimation />}>
                <Page user={user} setUser={(e) => dispatch(setUser(e))} />{" "}
              </Suspense>
            ),
            children: (pages[loginType] || []).map((d, i) => ({
              path: d.path,
              element: (
                <ProtectedRoute
                  user={user}
                  setUser={(e) => dispatch(setUser(e))}
                  element={d.element}
                />
              ),
            })),
          },
          {
            path: "*",
            element: <Navigate to="/" />,
          },
        ]
      : []),
    {
      path: "*",
      element: <Navigate to="/login" />,
    },
  ]);

  // if (loading) {
  //   return <LogoAnimation />;
  // }

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}
