import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  message,
  Popconfirm,
  Checkbox,
  Table,
} from "antd";
import { MakeApiCall } from "../../../../../apis";
import { Icon } from "@iconify/react/dist/iconify.js";
import { FormatNumber } from "../../../../../config";
import TextArea from "antd/es/input/TextArea";

const { Option } = Select;

const AddHistoryModal = ({
  setIsModalVisible,
  isModalVisible,
  form,
  vendorList,
  vendorLoading,
  userData,
  selectedRow,
  historyType,
}) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const GetUnitCostHistoryList = async (values) => {
    setSubmitLoading(true);
    try {
      const response = await MakeApiCall(
        `add-unit-cost-history/${selectedRow?.id}`,
        "post",
        { ...values, date_active: values?.date_active?.format("YYYY-MM-DD") },
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setSubmitLoading(false);
        message.destroy();

        message.success(response?.message);
        setIsModalVisible();
      } else {
        message.destroy();
        setSubmitLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setSubmitLoading(false);
      message.destroy();
      message.error(error?.message);
    }
  };

  const onFinish = (values) => {
    GetUnitCostHistoryList(values);
  };

  const onFinishFailed = (errorInfo) => {
    message.error("Please fill all required fields correctly");
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Modal
        title="Unit Cost History"
        width={600}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={
          historyType === "view"
            ? []
            : [
                <Button type="dashed" onClick={() => setIsModalVisible(false)}>
                  Close
                </Button>,
                <Button
                  loading={submitLoading}
                  key="submit"
                  type="primary"
                  onClick={() => {
                    form
                      .validateFields()
                      .then((values) => {
                        form.submit();
                      })
                      .catch((info) => {
                        console.log("Validate Failed:", info);
                      });
                  }}
                >
                  Save
                </Button>,
              ]
        }
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ maxWidth: 600, margin: "0 auto", padding: "20px" }}
        >
          <Form.Item
            name="unit_cost"
            label="Unit Cost"
            rules={[
              {
                required: true,
                message: "Please input the unit cost",
              },
              {
                pattern: /^\d+(\.\d{1,2})?$/,
                message: "Please enter a valid cost (e.g., 10.50)",
              },
            ]}
          >
            <Input
              prefix="$"
              type="number"
              step="0.01"
              placeholder="Enter unit cost"
            />
          </Form.Item>

          <Form.Item
            name="vendor_id"
            label="Select Vendor"
            rules={[
              {
                required: true,
                message: "Please select a vendor",
              },
            ]}
          >
            <Select loading={vendorLoading} placeholder="Select a vendor">
              {vendorList.map((vendor) => (
                <Option key={vendor.id} value={vendor.id}>
                  {vendor.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="date_active"
            label="Date Active"
            rules={[
              {
                required: true,
                message: "Please select a date",
              },
            ]}
          >
            <DatePicker
              size="large"
              style={{ width: "100%" }}
              placeholder="Select activation date"
            />
          </Form.Item>

          <Form.Item
            name="history_note"
            label="History Note"
            rules={[
              {
                max: 500,
                message: "History note cannot exceed 500 characters",
              },
            ]}
          >
            <TextArea
              rows={3}
              placeholder="Enter additional notes or history"
              maxLength={500}
              showCount
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddHistoryModal;
