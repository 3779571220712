import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";
import { Button, Flex, Input, message, Popconfirm, Space } from "antd";

import Table2 from "../../../../Components/tables/table2";
import { Icon } from "@iconify/react/dist/iconify.js";
import SupplyModalForm from "./add";
import { ConvertParams } from "../../../../config";
import { MakeApiCall } from "../../../../apis";

const SupplySetup = ({ userData }) => {
  const [visible, setVisible] = useState(false);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    page: 1,
    "per-page": 20,
    totalPage: 0,
  });
  const [selectedRow, setSelectedRow] = useState({});

  const columns = [
    {
      title: "#",
      align: "left",
      render: (_, __, i) => {
        return (
          <span>{(filters?.page - 1) * filters?.["per-page"] + 1 + i}</span>
        );
      },
    },
    {
      title: "Supply",
      dataIndex: "supply",
      key: "supply",
    },
    {
      title: "Action",
      key: "action",
      width: 150,
      render: (text, record) => (
        <Space size="middle">
          <Button
            color="primary"
            variant="solid"
            onClick={() => handleEdit(record)}
            icon={<Icon width={22} icon="iconamoon:edit-fill" />}
          ></Button>
          <Popconfirm
            title="Are you sure to delete this supply?"
            onConfirm={() => handleDelete(record)}
            okText="Yes"
            placement="left"
            cancelText="No"
          >
            <Button
              color="danger"
              variant="filled"
              icon={<Icon width={22} icon="ic:round-delete" />}
            ></Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const ListSupplyAction = async (data) => {
    const { totalPage, ...rest } = data;
    setLoading(true);
    try {
      const response = await MakeApiCall(
        `supplys${ConvertParams(rest)}`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        const { totalCount, page, page_size } = response?.data?.pagination;

        setFilters({
          page: page,
          "per-page": page_size,
          totalPage: totalCount,
        });

        setList(response?.data?.records || []);

        setLoading(false);
      } else {
        message.destroy();
        setLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setLoading(false);
      message.destroy();
      message.error(error?.message);
    }
  };

  useEffect(() => {
    ListSupplyAction({ ...filters });
    return () => {};
  }, []);

  // Edit handler
  const handleEdit = (record) => {
    setSelectedRow(record);
    setVisible(true);
  };

  const DeleteSupply = async (id) => {
    try {
      const response = await MakeApiCall(
        `supply/${id}`,
        "delete",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        ListSupplyAction({ ...filters });
        setLoading(true);
        message.destroy();
        message.success(response?.message);
      } else {
        message.destroy();

        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      message.error(error?.message);
    }
  };

  // Delete handler
  const handleDelete = (record) => {
    DeleteSupply(record.id);
  };
  return (
    <Wrapper>
      <Flex justify="space-between">
        <Input className="w-200px" placeholder="Search..." />

        <Button
          type="primary"
          onClick={() => setVisible(true)}
          icon={<Icon width={20} icon="material-symbols:add" />}
        >
          Add Supply
        </Button>
      </Flex>
      <Table2
        dataSource={list}
        loading={loading}
        columns={columns}
        scroll={{ x: "max-content" }}
      />
      <SupplyModalForm
        selectedRow={selectedRow}
        userData={userData}
        onSubmit={() => {
          setSelectedRow({});
          setVisible(false);
          ListSupplyAction({
            ...filters,
          });
          setLoading(true);
        }}
        visible={visible}
        setVisible={setVisible}
      />
    </Wrapper>
  );
};

export default SupplySetup;
