import { Button, DatePicker, message, Select, Spin, Tabs } from "antd";
import React, { useEffect, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import MarketplaceSelect from "../../../Components/marketplace";
import dayjs from "dayjs";
import RangePickerCF from "../../../Components/datepicker";
import { MakeApiCall } from "../../../apis";
import { CFTooltip } from "../../../Components/tooltip";
import { ConvertParams, FormatNumber } from "../../../config";
import Table1 from "../../../Components/tables/table1";
import { TableLoading } from "../../../Components/table-animation";
import { TabWrapper } from "../dashboard/style";

export default function FinancialSummary({ userData }) {
  const sign = "$";
  const [marketplaceList, setMarketplaceList] = useState([]);
  const [filtersList, setFiltersList] = useState({});

  const [tableList, setTableList] = useState({});

  const [loaders, setLoaders] = useState({
    marketplace: true,
    filters: true,
    graph: true,
    tableAPI: true,
  });
  const [graphData, setGraphData] = useState({});
  const [selectedFilters, setSelectedFilters] = useState({
    marketplace_id: null,
    period_type: "month",
    brand_id: userData?.user?.brand_id ?? null,
    // start_date: dayjs().add(-7, "d").subtract(1, "d").format("YYYY-MM-DD"),
    // end_date: dayjs().subtract(1, "d").format("YYYY-MM-DD"),
  });

  const TableListData = async (data) => {
    try {
      setLoaders((prev) => ({
        ...prev,
        tableAPI: true,
      }));

      const response = await MakeApiCall(
        `financial-sales-data${ConvertParams(data)}`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setTableList(response?.data?.table_data);
        setLoaders((prev) => ({
          ...prev,
          tableAPI: false,
        }));
      } else {
        setLoaders((prev) => ({
          ...prev,
          tableAPI: false,
        }));
        message.warning(response?.message);
      }
    } catch (error) {
      message.warning(error?.message);

      setLoaders((prev) => ({
        ...prev,
        tableAPI: false,
      }));
    }
  };

  const FinancialGraphData = async (data) => {
    try {
      setLoaders((prev) => ({
        ...prev,
        graph: true,
      }));
      setGraphData({});
      const response = await MakeApiCall(
        `financial-graph-data${ConvertParams(data)}`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setGraphData(response?.data?.graph_data || {});
        setLoaders((prev) => ({
          ...prev,
          graph: false,
        }));
      } else {
        setGraphData({});
        setLoaders((prev) => ({
          ...prev,
          graph: false,
        }));
        message.warning(response?.message);
      }
    } catch (error) {
      message.warning(error?.message);
      setGraphData({});
      setLoaders((prev) => ({
        ...prev,
        graph: false,
      }));
    }
  };
  const userSettingFilter = async (data) => {
    try {
      setFiltersList({});

      setLoaders((prev) => ({
        ...prev,
        filters: true,
      }));
      const {
        start_date,
        end_date,
        period_type,
        column_type,
        graph_time_period,
        ...rest
      } = data;

      const response = await MakeApiCall(
        `user-setting/filters${ConvertParams(rest)}`, // ${ConvertParams(data)}
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setFiltersList(response?.data);
        setLoaders((prev) => ({
          ...prev,
          filters: false,
        }));
      } else {
        setFiltersList({});
        setLoaders((prev) => ({
          ...prev,
          filters: false,
        }));
        message.warning(response?.message);
      }
    } catch (error) {
      message.warning(error?.message);
      setFiltersList({});
      setLoaders((prev) => ({
        ...prev,
        filters: false,
      }));
    }
  };

  const chartRef = useRef(null);
  useEffect(() => {
    return () => {};
  }, []);

  const formatData = (data) => {
    const formattedData = [];
    let keyCounter = 1;

    for (const brandName in data) {
      if (data.hasOwnProperty(brandName)) {
        const brandData = data[brandName];
        const formattedBrand = {
          key: keyCounter.toString(),
          brand: brandName,
          children: [],
        };

        const years = Object.keys(brandData);

        years.forEach((year) => {
          formattedBrand[`year`] = years;
          formattedBrand[`target_sales${year}`] = `${brandData[
            year
          ].product_sales.target_sales.toFixed(2)}`;
          formattedBrand[`actual_sales${year}`] =
            brandData[year].product_sales.actual_sales;
          formattedBrand[`change${year}`] =
            brandData[year].product_sales.change;
          formattedBrand[`delivery_rate${year}`] =
            brandData[year].product_sales.delivery_rate;
        });

        brandData[years[years.length - 1]].child_data.forEach(
          (child, index) => {
            const formattedChild = {
              key: `${keyCounter}-${index + 1}`,
              brand: child.product_name,
            };

            // Dynamically add year-specific fields for child data
            years.forEach((year) => {
              if (brandData[year].child_data.some((c) => c.sku === child.sku)) {
                formattedChild[`target_sales${year}`] = `${parseFloat(
                  child.product_sales.target_sales
                ).toFixed(2)}`;
                formattedChild[`actual_sales${year}`] = parseInt(
                  child.product_sales.actual_sales
                );
                formattedChild[`change${year}`] = child.product_sales.change;
                formattedChild[`delivery_rate${year}`] =
                  child.product_sales.delivery_rate;
              } else {
                formattedChild[`target_sales${year}`] = `0.00`;
                formattedChild[`actual_sales${year}`] = 0;
                formattedChild[`change${year}`] = 0;
                formattedChild[`delivery_rate${year}`] = 0;
              }
            });

            formattedBrand.children.push(formattedChild);
          }
        );

        formattedData.push(formattedBrand);
        keyCounter++;
      }
    }

    return formattedData;
  };

  useEffect(() => {
    if (Object.keys(graphData || {})?.length === 0) return;
    // Initialize chart when component mounts
    if (!chartRef.current) return;

    // Root setup
    const root = am5.Root.new(chartRef.current);

    // Set themes
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        endAngle: 270,
      })
    );

    // Create series
    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "value",
        categoryField: "category",
        endAngle: 270,
      })
    );

    // series.states.create("hidden", {
    //   endAngle: -90,
    // });

    // Set custom colors
    const colors = am5.ColorSet.new(root, {
      colors: [am5.color(0x0079ae), am5.color(0xf6c000)],
      passOptions: {
        lightness: -0.05,
        hue: 0,
      },
    });
    series.set("colors", colors);

    // Set data
    series.data.setAll([
      {
        category: "Target",
        value: graphData?.target_sales,
      },
      {
        category: "Actual",
        value: graphData?.actual_sales,
      },
    ]);

    series.appear(1000, 100);

    // Cleanup function
    return () => {
      root.dispose();
    };
  }, [graphData]);

  const MassFire = async (data, type) => {
    try {
      await TableListData({ ...data });
      await FinancialGraphData({ ...data });
    } catch (error) {
      console.error("Error in MassFire:", error);
    }
  };
  const FilterUpdate = (data) => {
    userSettingFilter(data);
  };

  const callAfterMarketPlace = async (marketplace_id) => {
    try {
      await userSettingFilter({ ...selectedFilters, marketplace_id });
      await TableListData({ ...selectedFilters, marketplace_id });
      await FinancialGraphData({ ...selectedFilters, marketplace_id });
    } catch (error) {
      message.warning(error?.message);
    }
  };

  const columns = [
    {
      title: "",
      dataIndex: "expand",
      key: "expand",
      width: 60,
      render: (_, record, index) => {
        return {
          props: {
            style: {
              padding: 15,
              opacity: record?.children ? 1 : 0,
              display: "flex",
              alignItems: "center",
            },
          },
        };
      },
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      fixed: "left",
      width: 300,
      render: (e) => {
        return (
          <div className="text-primary fs-6 fw-bold">
            <CFTooltip placement={"right"} row={2} rule>
              {e}
            </CFTooltip>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div className="d-flex flex-wrap gap-4 mb-7">
        <div className="position-relative">
          <MarketplaceSelect
            callAfterMarketPlace={callAfterMarketPlace}
            showSearch
            MarketplaceData={(e) => {
              setMarketplaceList(e);

              setLoaders((prev) => ({
                ...prev,
                marketplace: false,
              }));

              const obj = {
                ...selectedFilters,
                marketplace_id: e?.[0]?.value,
              };

              setSelectedFilters(obj);
            }}
            onChange={(e) => {
              const obj = {
                ...selectedFilters,
                marketplace_id: e,
              };
              MassFire(obj);
              FilterUpdate(obj);
              setSelectedFilters(obj);
            }}
            userData={userData}
          />
        </div>
        {userData?.user?.user_data?.u_type !== 3 && (
        <div className="position-relative">
          <Select
            options={filtersList?.brand}
            allowClear
            loading={loaders?.filters}
            size="large"
            className="w-200px"
            placeholder="Select Brand"
            onChange={(e) => {
              const obj = {
                ...selectedFilters,
                brand_id: e,
              };
              MassFire(obj);
              setSelectedFilters(obj);
            }}
          />
        </div>
        )}
      </div>
      <div className="row gx-5 gx-xl-7 ">
        <div className="col-xl-4 mb-5 mb-xl-5">
          <div className="card card-flush h-xl-100">
            <div className="card-header mb-0">
              <h3 className="card-title pt-0 align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">
                  Sales to Target
                </span>
              </h3>
              <TabWrapper className="card-toolbar">
                <Tabs
                  onChange={(e) => {
                    setSelectedFilters({
                      ...selectedFilters,
                      period_type: e,
                    });
                    FinancialGraphData({
                      ...selectedFilters,
                      period_type: e,
                    });
                  }}
                  activeKey={selectedFilters?.period_type}
                  type="card"
                >
                  <Tabs.TabPane tab="This Month" key="month"></Tabs.TabPane>
                  <Tabs.TabPane tab="Overall" key="year"></Tabs.TabPane>
                </Tabs>
              </TabWrapper>
            </div>
            <div className="card-body pt-0 pb-0">
              {loaders?.graph ? (
                <div className="w-100 h-300px d-flex align-items-center justify-content-center">
                  <Spin />
                </div>
              ) : (
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="kt_chart_widget_19_tab_content_1"
                    role="tabpanel"
                    aria-labelledby="kt_chart_widget_19_tab_1"
                  >
                    <div ref={chartRef} className="h-325px"></div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-xl-8 mb-5 mb-xl-5 ">
          <div className="card card-flush h-xl-100 mb-5">
            <div className="card-header mb-0">
              <h3 className="card-title pt-0 align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">
                  Financial Details
                </span>
              </h3>
              <div className="card-toolbar"></div>
            </div>
            <div className="card-body pt-0 pb-0 h-350px overflow-auto">
              <div className="table-responsive">
                {/*begin::Table*/}
                <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gx-4 gs-4">
                  {/*begin::Table head*/}
                  <thead>
                    <tr className="fw-bolder text-gray-800 bg-white border-top border-dashed fs-6">
                      <th
                        colSpan={2}
                        className="min-w-275px border-end border-dashed border-gray-300 "
                        style={{ verticalAlign: "middle" }}
                      />
                      <th
                        className="min-w-225px text-center border-end border-dashed border-gray-300"
                        colSpan={4}
                      >
                        January 2024
                      </th>
                      <th
                        className="min-w-225px text-center border-end border-dashed border-gray-300"
                        colSpan={4}
                      >
                        Fabruary 2024
                      </th>
                      <th
                        className="min-w-225px text-center border-end border-dashed border-gray-300"
                        colSpan={4}
                      >
                        March 2024
                      </th>
                      <th
                        className="min-w-225px text-center border-gray-300"
                        colSpan={4}
                      >
                        Total
                      </th>
                    </tr>
                    <tr className="fw-bolder text-gray-800 bg-main-light">
                      <th className="w-75px" />
                      <th className="min-w-200px border-end border-dashed border-gray-300">
                        Brand
                      </th>
                      <th className="min-w-75px text-end">Target</th>
                      <th className="min-w-75px text-end">Actual</th>
                      <th className="min-w-75px text-end">Change</th>
                      <th className="min-w-125px text-end border-end border-dashed border-gray-300">
                        Delivery Rate
                      </th>
                      <th className="min-w-75px text-end">Target</th>
                      <th className="min-w-75px text-end">Actual</th>
                      <th className="min-w-75px text-end">Change</th>
                      <th className="min-w-125px text-end border-end border-dashed border-gray-300">
                        Delivery Rate
                      </th>
                      <th className="min-w-75px text-end">Target</th>
                      <th className="min-w-75px text-end">Actual</th>
                      <th className="min-w-75px text-end">Change</th>
                      <th className="min-w-125px text-end border-end border-dashed border-gray-300">
                        Delivery Rate
                      </th>
                      <th className="min-w-75px text-end">Target</th>
                      <th className="min-w-75px text-end">Actual</th>
                      <th className="min-w-75px text-end">Change</th>
                      <th className="min-w-125px text-end">Delivery Rate</th>
                    </tr>
                  </thead>
                  {/*end::Table head*/}
                  {/*begin::Table body*/}
                  <tbody>
                    <tr>
                      <td className="expand">
                        <div
                          className="symbol symbol-30px cursor-pointer"
                          aria-expanded="true"
                          data-bs-toggle="collapse"
                          data-bs-target="#kt_accordion_3a_item_1"
                        >
                          <span className="symbol-label accordion-icon bg-light-primary">
                            <i className="ki-outline ki-right fs-3 text-gray-600" />
                          </span>
                        </div>
                      </td>
                      <td>
                        <a href className="text-primary fs-6 fw-bold">
                          Sales
                        </a>
                      </td>
                      <td className="text-end">$1,279,990.00</td>
                      <td className="text-end">$1,186,753.53</td>
                      <td className="text-end">
                        <span className="text-danger">-7.28%</span>
                      </td>
                      <td className="text-end">
                        <span className="text-danger">92.72%</span>
                      </td>
                      <td className="text-end">$1,177,392.61</td>
                      <td className="text-end">$1,095,414.51</td>
                      <td className="text-end">
                        <span className="text-danger">6.96%</span>
                      </td>
                      <td className="text-end">
                        <span className="text-danger">93.04%</span>
                      </td>
                      <td className="text-end">$1,331,701.60</td>
                      <td className="text-end">$1,142,520.90</td>
                      <td className="text-end">
                        <span className="text-danger">-14.21%</span>
                      </td>
                      <td className="text-end">
                        <span className="text-danger">85.79%</span>
                      </td>
                      <td className="text-end">$3,789,084.20</td>
                      <td className="text-end">$3,424,689.24</td>
                      <td className="text-end">
                        <span className="text-danger">9.62%</span>
                      </td>
                      <td className="text-end">
                        <span className="text-danger">90.38%</span>
                      </td>
                    </tr>
                    <tr className>
                      <td colSpan={18} className="hiddenRow p-0 ">
                        <div
                          className="collapse"
                          id="kt_accordion_3a_item_1"
                          data-bs-parent="#kt_accordion_3a"
                          style={{}}
                        >
                          <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gx-4 gs-4 mb-0">
                            {/*begin::Table head*/}
                            <thead>
                              <tr className="fw-bolder text-gray-800 bg-gray-100">
                                <th className="w-75px p-0" />
                                <th className="min-w-200px p-0 border-end border-dashed border-gray-300" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-125px p-0 text-end border-end border-dashed border-gray-300" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-125px p-0 text-end border-end border-dashed border-gray-300" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-125px p-0 text-end border-end border-dashed border-gray-300" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-125px p-0 text-end" />
                              </tr>
                            </thead>
                            <tbody>
                              <tr style={{ backgroundColor: "#f1f8fa" }}>
                                <td className />
                                <td className>
                                  <a href className="text-gray-800  fw-bold">
                                    Forecast Sales{" "}
                                  </a>
                                </td>
                                <td className="text-end">$1,279,990.00</td>
                                <td className="text-end">$1,186,753.53</td>
                                <td className="text-end">
                                  <span className="text-danger">-7.28%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">92.72%</span>
                                </td>
                                <td className="text-end">$1,177,392.61</td>
                                <td className="text-end">$1,095,414.51</td>
                                <td className="text-end">
                                  <span className="text-danger">6.96%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">93.04%</span>
                                </td>
                                <td className="text-end">$1,331,701.60</td>
                                <td className="text-end">$1,142,520.90</td>
                                <td className="text-end">
                                  <span className="text-danger">-14.21%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">85.79%</span>
                                </td>
                                <td className="text-end">$3,789,084.20</td>
                                <td className="text-end">$3,424,689.24</td>
                                <td className="text-end">
                                  <span className="text-danger">9.62%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">90.38%</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="expand">
                        <div
                          className="symbol symbol-30px cursor-pointer"
                          aria-expanded="false"
                          data-bs-toggle="collapse"
                          data-bs-target="#kt_accordion_3a_item_2"
                        >
                          <span className="symbol-label accordion-icon bg-light-primary">
                            <i className="ki-outline ki-right fs-3 text-gray-600" />
                          </span>
                        </div>
                      </td>
                      <td>
                        <a href className="text-primary fs-6 fw-bold">
                          Purchase
                        </a>
                      </td>
                      <td className="text-end">$1,279,990.00</td>
                      <td className="text-end">$1,186,753.53</td>
                      <td className="text-end">
                        <span className="text-danger">-7.28%</span>
                      </td>
                      <td className="text-end">
                        <span className="text-danger">92.72%</span>
                      </td>
                      <td className="text-end">$1,177,392.61</td>
                      <td className="text-end">$1,095,414.51</td>
                      <td className="text-end">
                        <span className="text-danger">6.96%</span>
                      </td>
                      <td className="text-end">
                        <span className="text-danger">93.04%</span>
                      </td>
                      <td className="text-end">$1,331,701.60</td>
                      <td className="text-end">$1,142,520.90</td>
                      <td className="text-end">
                        <span className="text-danger">-14.21%</span>
                      </td>
                      <td className="text-end">
                        <span className="text-danger">85.79%</span>
                      </td>
                      <td className="text-end">$3,789,084.20</td>
                      <td className="text-end">$3,424,689.24</td>
                      <td className="text-end">
                        <span className="text-danger">9.62%</span>
                      </td>
                      <td className="text-end">
                        <span className="text-danger">90.38%</span>
                      </td>
                    </tr>
                    <tr className>
                      <td colSpan={18} className="hiddenRow p-0 ">
                        <div
                          className=" collapse "
                          id="kt_accordion_3a_item_2"
                          data-bs-parent="#kt_accordion_3a"
                          style={{}}
                        >
                          <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gx-4 gs-4 mb-0">
                            {/*begin::Table head*/}
                            <thead>
                              <tr className="fw-bolder text-gray-800 bg-gray-100">
                                <th className="w-75px p-0" />
                                <th className="min-w-200px p-0 border-end border-dashed border-gray-300" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-125px p-0 text-end border-end border-dashed border-gray-300" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-125px p-0 text-end border-end border-dashed border-gray-300" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-125px p-0 text-end border-end border-dashed border-gray-300" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-125px p-0 text-end" />
                              </tr>
                            </thead>
                            <tbody>
                              <tr style={{ backgroundColor: "#f1f8fa" }}>
                                <td className />
                                <td className>
                                  <a href className="text-gray-800  fw-bold">
                                    Cheddy Purchase
                                  </a>
                                </td>
                                <td className="text-end">$1,279,990.00</td>
                                <td className="text-end">$1,186,753.53</td>
                                <td className="text-end">
                                  <span className="text-danger">-7.28%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">92.72%</span>
                                </td>
                                <td className="text-end">$1,177,392.61</td>
                                <td className="text-end">$1,095,414.51</td>
                                <td className="text-end">
                                  <span className="text-danger">6.96%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">93.04%</span>
                                </td>
                                <td className="text-end">$1,331,701.60</td>
                                <td className="text-end">$1,142,520.90</td>
                                <td className="text-end">
                                  <span className="text-danger">-14.21%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">85.79%</span>
                                </td>
                                <td className="text-end">$3,789,084.20</td>
                                <td className="text-end">$3,424,689.24</td>
                                <td className="text-end">
                                  <span className="text-danger">9.62%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">90.38%</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="expand">
                        <div
                          className="symbol symbol-30px cursor-pointer"
                          aria-expanded="false"
                          data-bs-toggle="collapse"
                          data-bs-target="#kt_accordion_3a_item_3"
                        >
                          <span className="symbol-label accordion-icon bg-light-primary">
                            <i className="ki-outline ki-right fs-3 text-gray-600" />
                          </span>
                        </div>
                      </td>
                      <td>
                        <a href className="text-primary fs-6 fw-bold">
                          Advertising
                        </a>
                      </td>
                      <td className="text-end">$1,279,990.00</td>
                      <td className="text-end">$1,186,753.53</td>
                      <td className="text-end">
                        <span className="text-danger">-7.28%</span>
                      </td>
                      <td className="text-end">
                        <span className="text-danger">92.72%</span>
                      </td>
                      <td className="text-end">$1,177,392.61</td>
                      <td className="text-end">$1,095,414.51</td>
                      <td className="text-end">
                        <span className="text-danger">6.96%</span>
                      </td>
                      <td className="text-end">
                        <span className="text-danger">93.04%</span>
                      </td>
                      <td className="text-end">$1,331,701.60</td>
                      <td className="text-end">$1,142,520.90</td>
                      <td className="text-end">
                        <span className="text-danger">-14.21%</span>
                      </td>
                      <td className="text-end">
                        <span className="text-danger">85.79%</span>
                      </td>
                      <td className="text-end">$3,789,084.20</td>
                      <td className="text-end">$3,424,689.24</td>
                      <td className="text-end">
                        <span className="text-danger">9.62%</span>
                      </td>
                      <td className="text-end">
                        <span className="text-danger">90.38%</span>
                      </td>
                    </tr>
                    <tr className>
                      <td colSpan={18} className="hiddenRow p-0 ">
                        <div
                          className=" collapse "
                          id="kt_accordion_3a_item_3"
                          data-bs-parent="#kt_accordion_3a"
                          style={{}}
                        >
                          <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gx-4 gs-4 mb-0">
                            {/*begin::Table head*/}
                            <thead>
                              <tr className="fw-bolder text-gray-800 bg-gray-100">
                                <th className="w-75px p-0" />
                                <th className="min-w-200px p-0 border-end border-dashed border-gray-300" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-125px p-0 text-end border-end border-dashed border-gray-300" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-125px p-0 text-end border-end border-dashed border-gray-300" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-125px p-0 text-end border-end border-dashed border-gray-300" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-125px p-0 text-end" />
                              </tr>
                            </thead>
                            <tbody>
                              <tr style={{ backgroundColor: "#f1f8fa" }}>
                                <td className />
                                <td className>
                                  <a href className="text-gray-800  fw-bold">
                                    Brand Ad
                                  </a>
                                </td>
                                <td className="text-end">$1,279,990.00</td>
                                <td className="text-end">$1,186,753.53</td>
                                <td className="text-end">
                                  <span className="text-danger">-7.28%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">92.72%</span>
                                </td>
                                <td className="text-end">$1,177,392.61</td>
                                <td className="text-end">$1,095,414.51</td>
                                <td className="text-end">
                                  <span className="text-danger">6.96%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">93.04%</span>
                                </td>
                                <td className="text-end">$1,331,701.60</td>
                                <td className="text-end">$1,142,520.90</td>
                                <td className="text-end">
                                  <span className="text-danger">-14.21%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">85.79%</span>
                                </td>
                                <td className="text-end">$3,789,084.20</td>
                                <td className="text-end">$3,424,689.24</td>
                                <td className="text-end">
                                  <span className="text-danger">9.62%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">90.38%</span>
                                </td>
                              </tr>
                              <tr style={{ backgroundColor: "#f1f8fa" }}>
                                <td className />
                                <td className>
                                  <a href className="text-gray-800  fw-bold">
                                    Brand DSP
                                  </a>
                                </td>
                                <td className="text-end">$1,279,990.00</td>
                                <td className="text-end">$1,186,753.53</td>
                                <td className="text-end">
                                  <span className="text-danger">-7.28%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">92.72%</span>
                                </td>
                                <td className="text-end">$1,177,392.61</td>
                                <td className="text-end">$1,095,414.51</td>
                                <td className="text-end">
                                  <span className="text-danger">6.96%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">93.04%</span>
                                </td>
                                <td className="text-end">$1,331,701.60</td>
                                <td className="text-end">$1,142,520.90</td>
                                <td className="text-end">
                                  <span className="text-danger">-14.21%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">85.79%</span>
                                </td>
                                <td className="text-end">$3,789,084.20</td>
                                <td className="text-end">$3,424,689.24</td>
                                <td className="text-end">
                                  <span className="text-danger">9.62%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">90.38%</span>
                                </td>
                              </tr>
                              <tr style={{ backgroundColor: "#f1f8fa" }}>
                                <td className />
                                <td className>
                                  <a href className="text-gray-800  fw-bold">
                                    Cheddy Ad
                                  </a>
                                </td>
                                <td className="text-end">$1,279,990.00</td>
                                <td className="text-end">$1,186,753.53</td>
                                <td className="text-end">
                                  <span className="text-danger">-7.28%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">92.72%</span>
                                </td>
                                <td className="text-end">$1,177,392.61</td>
                                <td className="text-end">$1,095,414.51</td>
                                <td className="text-end">
                                  <span className="text-danger">6.96%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">93.04%</span>
                                </td>
                                <td className="text-end">$1,331,701.60</td>
                                <td className="text-end">$1,142,520.90</td>
                                <td className="text-end">
                                  <span className="text-danger">-14.21%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">85.79%</span>
                                </td>
                                <td className="text-end">$3,789,084.20</td>
                                <td className="text-end">$3,424,689.24</td>
                                <td className="text-end">
                                  <span className="text-danger">9.62%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">90.38%</span>
                                </td>
                              </tr>
                              <tr style={{ backgroundColor: "#f1f8fa" }}>
                                <td className />
                                <td className>
                                  <a href className="text-gray-800  fw-bold">
                                    Cheddy DSP
                                  </a>
                                </td>
                                <td className="text-end">$1,279,990.00</td>
                                <td className="text-end">$1,186,753.53</td>
                                <td className="text-end">
                                  <span className="text-danger">-7.28%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">92.72%</span>
                                </td>
                                <td className="text-end">$1,177,392.61</td>
                                <td className="text-end">$1,095,414.51</td>
                                <td className="text-end">
                                  <span className="text-danger">6.96%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">93.04%</span>
                                </td>
                                <td className="text-end">$1,331,701.60</td>
                                <td className="text-end">$1,142,520.90</td>
                                <td className="text-end">
                                  <span className="text-danger">-14.21%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">85.79%</span>
                                </td>
                                <td className="text-end">$3,789,084.20</td>
                                <td className="text-end">$3,424,689.24</td>
                                <td className="text-end">
                                  <span className="text-danger">9.62%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">90.38%</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="expand">
                        <div
                          className="symbol symbol-30px cursor-pointer"
                          aria-expanded="false"
                          data-bs-toggle="collapse"
                          data-bs-target="#kt_accordion_3a_item_4"
                        >
                          <span className="symbol-label accordion-icon bg-light-primary">
                            <i className="ki-outline ki-right fs-3 text-gray-600" />
                          </span>
                        </div>
                      </td>
                      <td>
                        <a href className="text-primary fs-6 fw-bold">
                          Coupons
                        </a>
                      </td>
                      <td className="text-end">$1,279,990.00</td>
                      <td className="text-end">$1,186,753.53</td>
                      <td className="text-end">
                        <span className="text-danger">-7.28%</span>
                      </td>
                      <td className="text-end">
                        <span className="text-danger">92.72%</span>
                      </td>
                      <td className="text-end">$1,177,392.61</td>
                      <td className="text-end">$1,095,414.51</td>
                      <td className="text-end">
                        <span className="text-danger">6.96%</span>
                      </td>
                      <td className="text-end">
                        <span className="text-danger">93.04%</span>
                      </td>
                      <td className="text-end">$1,331,701.60</td>
                      <td className="text-end">$1,142,520.90</td>
                      <td className="text-end">
                        <span className="text-danger">-14.21%</span>
                      </td>
                      <td className="text-end">
                        <span className="text-danger">85.79%</span>
                      </td>
                      <td className="text-end">$3,789,084.20</td>
                      <td className="text-end">$3,424,689.24</td>
                      <td className="text-end">
                        <span className="text-danger">9.62%</span>
                      </td>
                      <td className="text-end">
                        <span className="text-danger">90.38%</span>
                      </td>
                    </tr>
                    <tr className>
                      <td colSpan={18} className="hiddenRow p-0 ">
                        <div
                          className=" collapse "
                          id="kt_accordion_3a_item_4"
                          data-bs-parent="#kt_accordion_3a"
                          style={{}}
                        >
                          <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gx-4 gs-4 mb-0">
                            {/*begin::Table head*/}
                            <thead>
                              <tr className="fw-bolder text-gray-800 bg-gray-100">
                                <th className="w-75px p-0" />
                                <th className="min-w-200px p-0 border-end border-dashed border-gray-300" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-125px p-0 text-end border-end border-dashed border-gray-300" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-125px p-0 text-end border-end border-dashed border-gray-300" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-125px p-0 text-end border-end border-dashed border-gray-300" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-125px p-0 text-end" />
                              </tr>
                            </thead>
                            <tbody>
                              <tr style={{ backgroundColor: "#f1f8fa" }}>
                                <td className />
                                <td className>
                                  <a href className="text-gray-800  fw-bold">
                                    Brand Coupons
                                  </a>
                                </td>
                                <td className="text-end">$1,279,990.00</td>
                                <td className="text-end">$1,186,753.53</td>
                                <td className="text-end">
                                  <span className="text-danger">-7.28%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">92.72%</span>
                                </td>
                                <td className="text-end">$1,177,392.61</td>
                                <td className="text-end">$1,095,414.51</td>
                                <td className="text-end">
                                  <span className="text-danger">6.96%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">93.04%</span>
                                </td>
                                <td className="text-end">$1,331,701.60</td>
                                <td className="text-end">$1,142,520.90</td>
                                <td className="text-end">
                                  <span className="text-danger">-14.21%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">85.79%</span>
                                </td>
                                <td className="text-end">$3,789,084.20</td>
                                <td className="text-end">$3,424,689.24</td>
                                <td className="text-end">
                                  <span className="text-danger">9.62%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">90.38%</span>
                                </td>
                              </tr>
                              <tr style={{ backgroundColor: "#f1f8fa" }}>
                                <td className />
                                <td className>
                                  <a href className="text-gray-800  fw-bold">
                                    Brand Promotion
                                  </a>
                                </td>
                                <td className="text-end">$1,279,990.00</td>
                                <td className="text-end">$1,186,753.53</td>
                                <td className="text-end">
                                  <span className="text-danger">-7.28%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">92.72%</span>
                                </td>
                                <td className="text-end">$1,177,392.61</td>
                                <td className="text-end">$1,095,414.51</td>
                                <td className="text-end">
                                  <span className="text-danger">6.96%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">93.04%</span>
                                </td>
                                <td className="text-end">$1,331,701.60</td>
                                <td className="text-end">$1,142,520.90</td>
                                <td className="text-end">
                                  <span className="text-danger">-14.21%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">85.79%</span>
                                </td>
                                <td className="text-end">$3,789,084.20</td>
                                <td className="text-end">$3,424,689.24</td>
                                <td className="text-end">
                                  <span className="text-danger">9.62%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">90.38%</span>
                                </td>
                              </tr>
                              <tr style={{ backgroundColor: "#f1f8fa" }}>
                                <td className />
                                <td className>
                                  <a href className="text-gray-800  fw-bold">
                                    Cheddy Coupons
                                  </a>
                                </td>
                                <td className="text-end">$1,279,990.00</td>
                                <td className="text-end">$1,186,753.53</td>
                                <td className="text-end">
                                  <span className="text-danger">-7.28%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">92.72%</span>
                                </td>
                                <td className="text-end">$1,177,392.61</td>
                                <td className="text-end">$1,095,414.51</td>
                                <td className="text-end">
                                  <span className="text-danger">6.96%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">93.04%</span>
                                </td>
                                <td className="text-end">$1,331,701.60</td>
                                <td className="text-end">$1,142,520.90</td>
                                <td className="text-end">
                                  <span className="text-danger">-14.21%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">85.79%</span>
                                </td>
                                <td className="text-end">$3,789,084.20</td>
                                <td className="text-end">$3,424,689.24</td>
                                <td className="text-end">
                                  <span className="text-danger">9.62%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">90.38%</span>
                                </td>
                              </tr>
                              <tr style={{ backgroundColor: "#f1f8fa" }}>
                                <td className />
                                <td className>
                                  <a href className="text-gray-800  fw-bold">
                                    Cheddy Promotion
                                  </a>
                                </td>
                                <td className="text-end">$1,279,990.00</td>
                                <td className="text-end">$1,186,753.53</td>
                                <td className="text-end">
                                  <span className="text-danger">-7.28%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">92.72%</span>
                                </td>
                                <td className="text-end">$1,177,392.61</td>
                                <td className="text-end">$1,095,414.51</td>
                                <td className="text-end">
                                  <span className="text-danger">6.96%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">93.04%</span>
                                </td>
                                <td className="text-end">$1,331,701.60</td>
                                <td className="text-end">$1,142,520.90</td>
                                <td className="text-end">
                                  <span className="text-danger">-14.21%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">85.79%</span>
                                </td>
                                <td className="text-end">$3,789,084.20</td>
                                <td className="text-end">$3,424,689.24</td>
                                <td className="text-end">
                                  <span className="text-danger">9.62%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">90.38%</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="expand">
                        <div
                          className="symbol symbol-30px cursor-pointer"
                          aria-expanded="false"
                          data-bs-toggle="collapse"
                          data-bs-target="#kt_accordion_3a_item_5"
                        >
                          <span className="symbol-label accordion-icon bg-light-primary">
                            <i className="ki-outline ki-right fs-3 text-gray-600" />
                          </span>
                        </div>
                      </td>
                      <td>
                        <a href className="text-primary fs-6 fw-bold">
                          SnS
                        </a>
                      </td>
                      <td className="text-end">$1,279,990.00</td>
                      <td className="text-end">$1,186,753.53</td>
                      <td className="text-end">
                        <span className="text-danger">-7.28%</span>
                      </td>
                      <td className="text-end">
                        <span className="text-danger">92.72%</span>
                      </td>
                      <td className="text-end">$1,177,392.61</td>
                      <td className="text-end">$1,095,414.51</td>
                      <td className="text-end">
                        <span className="text-danger">6.96%</span>
                      </td>
                      <td className="text-end">
                        <span className="text-danger">93.04%</span>
                      </td>
                      <td className="text-end">$1,331,701.60</td>
                      <td className="text-end">$1,142,520.90</td>
                      <td className="text-end">
                        <span className="text-danger">-14.21%</span>
                      </td>
                      <td className="text-end">
                        <span className="text-danger">85.79%</span>
                      </td>
                      <td className="text-end">$3,789,084.20</td>
                      <td className="text-end">$3,424,689.24</td>
                      <td className="text-end">
                        <span className="text-danger">9.62%</span>
                      </td>
                      <td className="text-end">
                        <span className="text-danger">90.38%</span>
                      </td>
                    </tr>
                    <tr className>
                      <td colSpan={18} className="hiddenRow p-0 ">
                        <div
                          className=" collapse "
                          id="kt_accordion_3a_item_5"
                          data-bs-parent="#kt_accordion_3a"
                          style={{}}
                        >
                          <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gx-4 gs-4 mb-0">
                            {/*begin::Table head*/}
                            <thead>
                              <tr className="fw-bolder text-gray-800 bg-gray-100">
                                <th className="w-75px p-0" />
                                <th className="min-w-200px p-0 border-end border-dashed border-gray-300" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-125px p-0 text-end border-end border-dashed border-gray-300" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-125px p-0 text-end border-end border-dashed border-gray-300" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-125px p-0 text-end border-end border-dashed border-gray-300" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-125px p-0 text-end" />
                              </tr>
                            </thead>
                            <tbody>
                              <tr style={{ backgroundColor: "#f1f8fa" }}>
                                <td className />
                                <td className>
                                  <a href className="text-gray-800  fw-bold">
                                    Brand SnS
                                  </a>
                                </td>
                                <td className="text-end">$1,279,990.00</td>
                                <td className="text-end">$1,186,753.53</td>
                                <td className="text-end">
                                  <span className="text-danger">-7.28%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">92.72%</span>
                                </td>
                                <td className="text-end">$1,177,392.61</td>
                                <td className="text-end">$1,095,414.51</td>
                                <td className="text-end">
                                  <span className="text-danger">6.96%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">93.04%</span>
                                </td>
                                <td className="text-end">$1,331,701.60</td>
                                <td className="text-end">$1,142,520.90</td>
                                <td className="text-end">
                                  <span className="text-danger">-14.21%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">85.79%</span>
                                </td>
                                <td className="text-end">$3,789,084.20</td>
                                <td className="text-end">$3,424,689.24</td>
                                <td className="text-end">
                                  <span className="text-danger">9.62%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">90.38%</span>
                                </td>
                              </tr>
                              <tr style={{ backgroundColor: "#f1f8fa" }}>
                                <td className />
                                <td className>
                                  <a href className="text-gray-800  fw-bold">
                                    Cheddy SnS
                                  </a>
                                </td>
                                <td className="text-end">$1,279,990.00</td>
                                <td className="text-end">$1,186,753.53</td>
                                <td className="text-end">
                                  <span className="text-danger">-7.28%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">92.72%</span>
                                </td>
                                <td className="text-end">$1,177,392.61</td>
                                <td className="text-end">$1,095,414.51</td>
                                <td className="text-end">
                                  <span className="text-danger">6.96%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">93.04%</span>
                                </td>
                                <td className="text-end">$1,331,701.60</td>
                                <td className="text-end">$1,142,520.90</td>
                                <td className="text-end">
                                  <span className="text-danger">-14.21%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">85.79%</span>
                                </td>
                                <td className="text-end">$3,789,084.20</td>
                                <td className="text-end">$3,424,689.24</td>
                                <td className="text-end">
                                  <span className="text-danger">9.62%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">90.38%</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="expand">
                        <div
                          className="symbol symbol-30px cursor-pointer"
                          aria-expanded="false"
                          data-bs-toggle="collapse"
                          data-bs-target="#kt_accordion_3a_item_6"
                        >
                          <span className="symbol-label accordion-icon bg-light-primary">
                            <i className="ki-outline ki-right fs-3 text-gray-600" />
                          </span>
                        </div>
                      </td>
                      <td>
                        <a href className="text-primary fs-6 fw-bold">
                          Graphic Design
                        </a>
                      </td>
                      <td className="text-end">$1,279,990.00</td>
                      <td className="text-end">$1,186,753.53</td>
                      <td className="text-end">
                        <span className="text-danger">-7.28%</span>
                      </td>
                      <td className="text-end">
                        <span className="text-danger">92.72%</span>
                      </td>
                      <td className="text-end">$1,177,392.61</td>
                      <td className="text-end">$1,095,414.51</td>
                      <td className="text-end">
                        <span className="text-danger">6.96%</span>
                      </td>
                      <td className="text-end">
                        <span className="text-danger">93.04%</span>
                      </td>
                      <td className="text-end">$1,331,701.60</td>
                      <td className="text-end">$1,142,520.90</td>
                      <td className="text-end">
                        <span className="text-danger">-14.21%</span>
                      </td>
                      <td className="text-end">
                        <span className="text-danger">85.79%</span>
                      </td>
                      <td className="text-end">$3,789,084.20</td>
                      <td className="text-end">$3,424,689.24</td>
                      <td className="text-end">
                        <span className="text-danger">9.62%</span>
                      </td>
                      <td className="text-end">
                        <span className="text-danger">90.38%</span>
                      </td>
                    </tr>
                    <tr className>
                      <td colSpan={18} className="hiddenRow p-0 ">
                        <div
                          className=" collapse "
                          id="kt_accordion_3a_item_6"
                          data-bs-parent="#kt_accordion_3a"
                          style={{}}
                        >
                          <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gx-4 gs-4 mb-0">
                            {/*begin::Table head*/}
                            <thead>
                              <tr className="fw-bolder text-gray-800 bg-gray-100">
                                <th className="w-75px p-0" />
                                <th className="min-w-200px p-0 border-end border-dashed border-gray-300" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-125px p-0 text-end border-end border-dashed border-gray-300" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-125px p-0 text-end border-end border-dashed border-gray-300" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-125px p-0 text-end border-end border-dashed border-gray-300" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-125px p-0 text-end" />
                              </tr>
                            </thead>
                            <tbody>
                              <tr style={{ backgroundColor: "#f1f8fa" }}>
                                <td className />
                                <td className>
                                  <a href className="text-gray-800  fw-bold">
                                    Brand Graphic Design
                                  </a>
                                </td>
                                <td className="text-end">$1,279,990.00</td>
                                <td className="text-end">$1,186,753.53</td>
                                <td className="text-end">
                                  <span className="text-danger">-7.28%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">92.72%</span>
                                </td>
                                <td className="text-end">$1,177,392.61</td>
                                <td className="text-end">$1,095,414.51</td>
                                <td className="text-end">
                                  <span className="text-danger">6.96%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">93.04%</span>
                                </td>
                                <td className="text-end">$1,331,701.60</td>
                                <td className="text-end">$1,142,520.90</td>
                                <td className="text-end">
                                  <span className="text-danger">-14.21%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">85.79%</span>
                                </td>
                                <td className="text-end">$3,789,084.20</td>
                                <td className="text-end">$3,424,689.24</td>
                                <td className="text-end">
                                  <span className="text-danger">9.62%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">90.38%</span>
                                </td>
                              </tr>
                              <tr style={{ backgroundColor: "#f1f8fa" }}>
                                <td className />
                                <td className>
                                  <a href className="text-gray-800  fw-bold">
                                    Cheddy Graphic Design
                                  </a>
                                </td>
                                <td className="text-end">$1,279,990.00</td>
                                <td className="text-end">$1,186,753.53</td>
                                <td className="text-end">
                                  <span className="text-danger">-7.28%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">92.72%</span>
                                </td>
                                <td className="text-end">$1,177,392.61</td>
                                <td className="text-end">$1,095,414.51</td>
                                <td className="text-end">
                                  <span className="text-danger">6.96%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">93.04%</span>
                                </td>
                                <td className="text-end">$1,331,701.60</td>
                                <td className="text-end">$1,142,520.90</td>
                                <td className="text-end">
                                  <span className="text-danger">-14.21%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">85.79%</span>
                                </td>
                                <td className="text-end">$3,789,084.20</td>
                                <td className="text-end">$3,424,689.24</td>
                                <td className="text-end">
                                  <span className="text-danger">9.62%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">90.38%</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="expand">
                        <div
                          className="symbol symbol-30px cursor-pointer"
                          aria-expanded="false"
                          data-bs-toggle="collapse"
                          data-bs-target="#kt_accordion_3a_item_7"
                        >
                          <span className="symbol-label accordion-icon bg-light-primary">
                            <i className="ki-outline ki-right fs-3 text-gray-600" />
                          </span>
                        </div>
                      </td>
                      <td>
                        <a href className="text-primary fs-6 fw-bold">
                          Miscellaneous
                        </a>
                      </td>
                      <td className="text-end">$1,279,990.00</td>
                      <td className="text-end">$1,186,753.53</td>
                      <td className="text-end">
                        <span className="text-danger">-7.28%</span>
                      </td>
                      <td className="text-end">
                        <span className="text-danger">92.72%</span>
                      </td>
                      <td className="text-end">$1,177,392.61</td>
                      <td className="text-end">$1,095,414.51</td>
                      <td className="text-end">
                        <span className="text-danger">6.96%</span>
                      </td>
                      <td className="text-end">
                        <span className="text-danger">93.04%</span>
                      </td>
                      <td className="text-end">$1,331,701.60</td>
                      <td className="text-end">$1,142,520.90</td>
                      <td className="text-end">
                        <span className="text-danger">-14.21%</span>
                      </td>
                      <td className="text-end">
                        <span className="text-danger">85.79%</span>
                      </td>
                      <td className="text-end">$3,789,084.20</td>
                      <td className="text-end">$3,424,689.24</td>
                      <td className="text-end">
                        <span className="text-danger">9.62%</span>
                      </td>
                      <td className="text-end">
                        <span className="text-danger">90.38%</span>
                      </td>
                    </tr>
                    <tr className>
                      <td colSpan={18} className="hiddenRow p-0 ">
                        <div
                          className=" collapse "
                          id="kt_accordion_3a_item_7"
                          data-bs-parent="#kt_accordion_3a"
                          style={{}}
                        >
                          <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gx-4 gs-4 mb-0">
                            {/*begin::Table head*/}
                            <thead>
                              <tr className="fw-bolder text-gray-800 bg-gray-100">
                                <th className="w-75px p-0" />
                                <th className="min-w-200px p-0 border-end border-dashed border-gray-300" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-125px p-0 text-end border-end border-dashed border-gray-300" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-125px p-0 text-end border-end border-dashed border-gray-300" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-125px p-0 text-end border-end border-dashed border-gray-300" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-125px p-0 text-end" />
                              </tr>
                            </thead>
                            <tbody>
                              <tr style={{ backgroundColor: "#f1f8fa" }}>
                                <td className />
                                <td className>
                                  <a href className="text-gray-800  fw-bold">
                                    Brand Miscellaneous
                                  </a>
                                </td>
                                <td className="text-end">$1,279,990.00</td>
                                <td className="text-end">$1,186,753.53</td>
                                <td className="text-end">
                                  <span className="text-danger">-7.28%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">92.72%</span>
                                </td>
                                <td className="text-end">$1,177,392.61</td>
                                <td className="text-end">$1,095,414.51</td>
                                <td className="text-end">
                                  <span className="text-danger">6.96%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">93.04%</span>
                                </td>
                                <td className="text-end">$1,331,701.60</td>
                                <td className="text-end">$1,142,520.90</td>
                                <td className="text-end">
                                  <span className="text-danger">-14.21%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">85.79%</span>
                                </td>
                                <td className="text-end">$3,789,084.20</td>
                                <td className="text-end">$3,424,689.24</td>
                                <td className="text-end">
                                  <span className="text-danger">9.62%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">90.38%</span>
                                </td>
                              </tr>
                              <tr style={{ backgroundColor: "#f1f8fa" }}>
                                <td className />
                                <td className>
                                  <a href className="text-gray-800  fw-bold">
                                    Cheddy Miscellaneous
                                  </a>
                                </td>
                                <td className="text-end">$1,279,990.00</td>
                                <td className="text-end">$1,186,753.53</td>
                                <td className="text-end">
                                  <span className="text-danger">-7.28%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">92.72%</span>
                                </td>
                                <td className="text-end">$1,177,392.61</td>
                                <td className="text-end">$1,095,414.51</td>
                                <td className="text-end">
                                  <span className="text-danger">6.96%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">93.04%</span>
                                </td>
                                <td className="text-end">$1,331,701.60</td>
                                <td className="text-end">$1,142,520.90</td>
                                <td className="text-end">
                                  <span className="text-danger">-14.21%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">85.79%</span>
                                </td>
                                <td className="text-end">$3,789,084.20</td>
                                <td className="text-end">$3,424,689.24</td>
                                <td className="text-end">
                                  <span className="text-danger">9.62%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">90.38%</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="expand">
                        <div
                          className="symbol symbol-30px cursor-pointer"
                          aria-expanded="false"
                          data-bs-toggle="collapse"
                          data-bs-target="#kt_accordion_3a_item_8"
                        >
                          <span className="symbol-label accordion-icon bg-light-primary">
                            <i className="ki-outline ki-right fs-3 text-gray-600" />
                          </span>
                        </div>
                      </td>
                      <td>
                        <a href className="text-primary fs-6 fw-bold">
                          Commission
                        </a>
                      </td>
                      <td className="text-end">$1,279,990.00</td>
                      <td className="text-end">$1,186,753.53</td>
                      <td className="text-end">
                        <span className="text-danger">-7.28%</span>
                      </td>
                      <td className="text-end">
                        <span className="text-danger">92.72%</span>
                      </td>
                      <td className="text-end">$1,177,392.61</td>
                      <td className="text-end">$1,095,414.51</td>
                      <td className="text-end">
                        <span className="text-danger">6.96%</span>
                      </td>
                      <td className="text-end">
                        <span className="text-danger">93.04%</span>
                      </td>
                      <td className="text-end">$1,331,701.60</td>
                      <td className="text-end">$1,142,520.90</td>
                      <td className="text-end">
                        <span className="text-danger">-14.21%</span>
                      </td>
                      <td className="text-end">
                        <span className="text-danger">85.79%</span>
                      </td>
                      <td className="text-end">$3,789,084.20</td>
                      <td className="text-end">$3,424,689.24</td>
                      <td className="text-end">
                        <span className="text-danger">9.62%</span>
                      </td>
                      <td className="text-end">
                        <span className="text-danger">90.38%</span>
                      </td>
                    </tr>
                    <tr className>
                      <td colSpan={18} className="hiddenRow p-0 ">
                        <div
                          className=" collapse "
                          id="kt_accordion_3a_item_8"
                          data-bs-parent="#kt_accordion_3a"
                          style={{}}
                        >
                          <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gx-4 gs-4 mb-0">
                            {/*begin::Table head*/}
                            <thead>
                              <tr className="fw-bolder text-gray-800 bg-gray-100">
                                <th className="w-75px p-0" />
                                <th className="min-w-200px p-0 border-end border-dashed border-gray-300" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-125px p-0 text-end border-end border-dashed border-gray-300" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-125px p-0 text-end border-end border-dashed border-gray-300" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-125px p-0 text-end border-end border-dashed border-gray-300" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-75px p-0 text-end" />
                                <th className="min-w-125px p-0 text-end" />
                              </tr>
                            </thead>
                            <tbody>
                              <tr style={{ backgroundColor: "#f1f8fa" }}>
                                <td className />
                                <td className>
                                  <a href className="text-gray-800  fw-bold">
                                    Brand Commission
                                  </a>
                                </td>
                                <td className="text-end">$1,279,990.00</td>
                                <td className="text-end">$1,186,753.53</td>
                                <td className="text-end">
                                  <span className="text-danger">-7.28%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">92.72%</span>
                                </td>
                                <td className="text-end">$1,177,392.61</td>
                                <td className="text-end">$1,095,414.51</td>
                                <td className="text-end">
                                  <span className="text-danger">6.96%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">93.04%</span>
                                </td>
                                <td className="text-end">$1,331,701.60</td>
                                <td className="text-end">$1,142,520.90</td>
                                <td className="text-end">
                                  <span className="text-danger">-14.21%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">85.79%</span>
                                </td>
                                <td className="text-end">$3,789,084.20</td>
                                <td className="text-end">$3,424,689.24</td>
                                <td className="text-end">
                                  <span className="text-danger">9.62%</span>
                                </td>
                                <td className="text-end">
                                  <span className="text-danger">90.38%</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  {/*end::Table body*/}
                </table>
                {/*end::Table*/}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row gx-5 gx-xl-10">
        <div className="col-xl-12 mb-5 mb-xl-5">
          {/*begin::Chart widget 31*/}
          <div className="card card-flush h-xl-100">
            {/*begin::Header*/}
            <div className="card-header  mb-0">
              {/*begin::Title*/}
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">
                  Brand Sales Details
                </span>
              </h3>
              {/*end::Title*/}
              {/*begin::Toolbar*/}
              <div className="card-toolbar"></div>
              {/*end::Toolbar*/}
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body  pb-0 pt-0 ">
              {loaders?.tableAPI ? (
                <TableLoading
                  id="test-table"
                  row={10}
                  columns={[1, 2, 3, 4, 5]}
                  checkBoxVal={false}
                  actions={[]}
                  style={{ height: "calc(100vh - 500px)", overflow: "hidden" }}
                />
              ) : (
                <Table1
                  columns={[
                    ...columns,
                    ...formatData(tableList)?.[0]?.year?.map((d) => ({
                      title: d,
                      children: [
                        {
                          title: "Target",
                          dataIndex: `target_sales${d}`,
                          key: `target_sales${d}`,
                          align: "center",
                          render: (e, row) => {
                            return <>${FormatNumber(e || 0)}</>;
                          },
                        },
                        {
                          title: "Actual",
                          dataIndex: `actual_sales${d}`,
                          key: `actual_sales${d}`,
                          align: "center",
                          render: (e) => {
                            return <>${FormatNumber(e || 0)}</>;
                          },
                        },
                        {
                          title: "Change",
                          dataIndex: `change${d}`,
                          key: `change${d}`,
                          align: "center",
                          render: (e) => {
                            if (e === 0) {
                              return (
                                <span style={{ color: "black" }}>
                                  {FormatNumber(e || 0)}%
                                </span>
                              );
                            } else if (e > 0) {
                              return (
                                <span style={{ color: "green" }}>
                                  {FormatNumber(e || 0)}%
                                </span>
                              );
                            } else {
                              return (
                                <span style={{ color: "red" }}>
                                  {FormatNumber(e || 0)}%
                                </span>
                              );
                            }
                          },
                        },
                        {
                          title: "Delivery Rate",
                          dataIndex: `delivery_rate${d}`,
                          key: `delivery_rate${d}`,
                          align: "center",
                          render: (e) => {
                            if (e === 0) {
                              return (
                                <span style={{ color: "black" }}>
                                  {FormatNumber(e || 0)}%
                                </span>
                              );
                            } else if (e > 0) {
                              return (
                                <span style={{ color: "green" }}>
                                  {FormatNumber(e || 0)}%
                                </span>
                              );
                            } else {
                              return (
                                <span style={{ color: "red" }}>
                                  {FormatNumber(e || 0)}%
                                </span>
                              );
                            }
                          },
                        },
                      ],
                    })),
                  ]}
                  dataSource={formatData(tableList)}
                  rowKey={"key"}
                  scroll={{ x: "max-content" }}
                  pagination={false}
                  // virtual
                  expandable={{
                    indentSize: 0,
                    expandIconColumnIndex: 0, // Change this to the index of the column where you want the icon
                    expandIcon: ({ expanded, onExpand, record }) => (
                      <Button
                        icon={
                          <div
                            className="symbol symbol-30px ms-5 cursor-pointer"
                            style={{
                              transform: `rotate(${expanded ? 90 : 0}deg)`,
                            }}
                          >
                            <span className="symbol-label accordion-icon bg-light-primary">
                              <i className="ki-outline ki-right fs-3 text-gray-600" />
                            </span>
                          </div>
                        }
                        onClick={(e) => onExpand(record, e)}
                        size="small"
                        style={{ border: "none", background: "none" }}
                      />
                    ),
                  }}
                />
              )}
            </div>
            {/*end::Body*/}
          </div>
        </div>
      </div>
    </div>
  );
}
