import styled from "styled-components";

export const Wrapper = styled.div`
.ant-input-search .ant-input{
    font-size: 14px;
}
.ant-input-search .ant-input-group-addon .ant-btn{
border-left:0  !important;
    }
.ant-input-outlined {
    background: #ffffff;
    border-width: 1px;
    border-style: solid;
    border-color: #d1d1d1;
}
.ant-select-selection-item {
    font-size: 0.95rem !important;
    font-weight: 600 !important;
}
.ant-tabs-nav-list .ant-tabs-tab:nth-child(1) {
    border: 1px solid #0079ae;
    background-color: #0079ae;
    color: #fff !important;
    border-radius: 10px;
    margin-bottom: 7px;
    padding-left: 15px;
    padding-right: 15px;
}
.ant-tabs-nav-list .ant-tabs-tab:nth-child(1) .ant-tabs-tab-btn{color:#fff}

.ant-tabs-top >.ant-tabs-nav::before, .ant-tabs-bottom >.ant-tabs-nav::before, .ant-tabs-top >div>.ant-tabs-nav::before, .ant-tabs-bottom >div>.ant-tabs-nav::before {
    border-bottom: 1px dashed #e7e7e7;
}
.ant-tabs-tab-btn {
    font-size: 15.5px;
    font-weight: 500;
}
.ant-tabs .ant-tabs-ink-bar {
    background: rgb(0 121 174);
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    text-shadow: none;
}
`;
