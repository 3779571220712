export const DefaultAdminPage = `/manage-user`;
export const DefaultUserPage = `/dashboard`;
export const DefaultBrandPage = `/dashboard`;

export const Title = "CHEDDY-ANALYTICS_C4";
export const MainURL = 'https://analytics.cheddyproducts.com/'
export const URL =
  process.env?.NODE_ENV === "development"
    ? `http://18.233.1.243/cheddy_php_v3`
    : `${window.location?.origin}/cheddy_php_v3`;
export const ENDPOINT = `${URL}/v3/`;
export const APPID = "amzn1.sp.solution.e782eb66-c1f7-49bb-b76b-6c0ae5edcbd2";
export const ClientID =
  "amzn1.application-oa2-client.b519ca7170204281a4c30086710a5a47";
export const Types = {
  1: "admin",
  2: "user",
  3: "brand",
};
export const DefaultPerPage = 10;
export const FormatUserName = (userName) => {
  // Replace hyphens with spaces
  let formattedName = userName?.replace(/-/g, " ")?.split("_")?.join(" ");
  // Capitalize the first letter of each word
  formattedName = formattedName?.replace(/\b\w/g, function (char) {
    return char?.toUpperCase();
  });

  const replacements = [
    ["Roas", "ROAS"],
    ["Sns", "SNS"],
  ];
  return replacements.reduce(
    (name, [key, value]) => name?.replace(key, value),
    formattedName
  );
};
export const ConvertParams = (values) => {
  return Object.entries(
    Object.entries(values)
      .filter(
        ([_, value]) =>
          value &&
          value !== "" &&
          value !== undefined &&
          value !== null &&
          value?.length !== 0
      )
      .reduce((acc, [key, value]) => {
        acc[key] = encodeURIComponent(value);

        return acc;
      }, {})
  )
    ?.map((d, i) => {
      return `${i === 0 ? "?" : "&"}${d[0]}=${d[1] || ""}`;
    })
    ?.join("");
};
export const FormatNumber = (number) => {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(number);
};
export const RenderTable = (props, row, index, d, width) =>
  typeof d?.render === "function" ? (
    <>
      <div
        className="d-flex"
        style={{
          flexDirection: d?.align === "right" ? "row-reverse" : "row",
          minWidth: d?.title?.length * (10 + (width || 0)),
        }}
      >
        {d?.render(props, row, index)}
      </div>
    </>
  ) : (
    <>
      <div
        className="d-flex"
        style={{
          flexDirection: d?.align === "right" ? "row-reverse" : "row",
          minWidth: d?.title?.length * (10 + (width || 0)),
        }}
      >
        <span>{props || "-"}</span>
      </div>
    </>
  );
export const PropsFilter = (type) =>
  !type
    ? {}
    : {
        sorter: (a, b) => {
          return typeof a?.[type] === "string"
            ? (a?.[type] || "")?.localeCompare(b?.[type] || "")
            : parseFloat(a?.[type] || 0) - parseFloat(b?.[type] || 0);
        },
      };
export const urlDecode = (url) => {
  let newObj = {};
  url?.search
    ?.split("?")[1]
    ?.split("&")
    ?.forEach((e) => {
      const obj = e?.split("=");
      newObj = { ...newObj, [obj[0]]: obj[1] };
    });
  return newObj || {};
};
export const PaginationConfig = ({
  pageSize,
  currentPage,
  totalPage,
  onChange = () => {},
}) => {
  return {
    pageSize: pageSize,
    current: currentPage,
    total: totalPage,
    onChange: (e, f) => {
      onChange(e, f);
    },
    showSizeChanger: true, // To show page size options
    pageSizeOptions: [10, 20, 50, 100], // Page size options
    showTotal: (total, range) =>
      `${FormatNumber(range[0] || 0)}-${FormatNumber(
        range[1] || 0
      )} of ${FormatNumber(total || 0)} items`,
  };
};
