import {
  Button,
  Checkbox,
  Col,
  Drawer,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Row,
  Space,
  Typography,
} from "antd";
import React from "react";

import { Icon } from "@iconify/react/dist/iconify.js";
import { useState } from "react";
import { Form, Input, Radio, Select, Card, List } from "antd";

import { MakeApiCall } from "../../../../../apis";
import { AdvancedFilterWrapper } from "../../style";

const { Option } = Select;

const AdvancedFilter = ({
  setMoreFilterView,
  moreFilterView,
  brandList,
  brandLoading,
  vendorList,
  vendorLoading,
  userData,
  advanceFilterLoading,
  advanceFilterList,
  selectedAdvanceFilter,
  setSelectedAdvanceFilter,
  getList,
  applyFilter,
  form,
}) => {
  const [createFilter, setCreateFilter] = useState(false);
  const [createSubmitLoading, setCreateSubmitLoading] = useState(false);

  const AdvanceFiltersAction = async (values) => {
    setCreateSubmitLoading(true);
    try {
      const response = await MakeApiCall(
        `advance-filter-component`,
        "POST",
        { ...values, saved_filter: "1" },
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        form.resetFields();
        getList();
        setCreateFilter(false);
        setCreateSubmitLoading(false);
        setSelectedAdvanceFilter({ ...selectedAdvanceFilter, ...values });
        message.destroy();
        message.success("Filter added successfully");
      } else {
        message.destroy();
        setCreateSubmitLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setCreateSubmitLoading(false);
      message.destroy();
      message.error(error?.message);
    }
  };

  const AdvanceFiltersDeleteAction = async (id) => {
    message.destroy();
    message.loading("Loading...", 0);
    try {
      const response = await MakeApiCall(
        `delete-filter/${id}`,
        "DELETE",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        message.destroy();
        message.success(response?.message);
        getList();
        setSelectedAdvanceFilter(null);
      } else {
        message.destroy();

        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      message.error(error?.message);
    }
  };

  const handleSubmit = (values) => {
    form
      .validateFields()
      .then((values) => {
        AdvanceFiltersAction(
          selectedAdvanceFilter?.usf_id
            ? {
                ...values,
                user_filter_id: selectedAdvanceFilter?.usf_id,
              }
            : values
        );
      })
      .catch((error) => {
        console.log("Error submitting form:", error);
      });
    console.log("Submitted values:", values);
  };

  const handleReset = () => {
    form.resetFields(); // Resets the form fields to their initial values
  };

  const submitFilter = () => {
    const allValues = form.getFieldsValue();

    const obj = {
      action: allValues?.link_type || selectedAdvanceFilter?.component_type,

      filter_value:
        allValues?.user_filter_value ||
        selectedAdvanceFilter?.user_filter_value,
      usf_id: allValues?.usf_id || selectedAdvanceFilter?.usf_id,

      "filters[comp_desc]":
        allValues?.comp_desc || selectedAdvanceFilter?.comp_desc,

      "filters[unit_cost]":
        allValues?.unit_cost || selectedAdvanceFilter?.unit_cost,
      "filters[vendor_id]":
        allValues?.vendor_id || selectedAdvanceFilter?.vendor_id,
      "filters[brand_id]":
        allValues?.brand_id || selectedAdvanceFilter?.brand_id,
      "filters[upc]": allValues?.upc || selectedAdvanceFilter?.upc,
      "filters[case_qty]":
        allValues?.case_qty || selectedAdvanceFilter?.case_qty,
      "filters[moq]": allValues?.moq || selectedAdvanceFilter?.moq,
      "filters[vendor_sku]":
        allValues?.vendor_sku || selectedAdvanceFilter?.vendor_sku,

      search_type: "advance",
    };
    applyFilter(obj);
  };

  return (
    <Modal
      title="Advanced Filter"
      onCancel={() => {
        setMoreFilterView(false);
      }}
      bodyStyle={{
        padding: "5px",
      }}
      open={moreFilterView}
      width={850}
      footer={[
        <Button className="me-3" onClick={submitFilter} type="primary">
          Apply
        </Button>,
        <Space>
          <Button
            color="primary"
            variant="filled"
            loading={createSubmitLoading}
            onClick={handleSubmit}
          >
            {selectedAdvanceFilter?.usf_id ? "Update" : "Create"}
          </Button>
          <Button type="link" onClick={handleReset}>
            Reset
          </Button>
        </Space>,
      ]}
    >
      <AdvancedFilterWrapper>
        <Row gutter={16}>
          <Col span={10} style={{ maxHeight: "500px", overflow: "auto" }}>
            <Row
              style={{
                position: "sticky",
                top: 0,
                zIndex: 9,
                background: "#FFF",
              }}
              justify={"end"}
              gutter={10}
            >
              <Col>
                {/* <Button
                            onClick={() => {
                              form.resetFields();
                              setCreateFilter(true);
                              setSelectedAdvanceFilter(null);
                            }}
                            type="primary"
                            size="small"
                          >
                            <Icon icon="material-symbols:add" width={20} />
                          </Button> */}
                <Button
                  onClick={() => {
                    form.resetFields();
                    setCreateFilter(true);
                    setSelectedAdvanceFilter(null);
                  }}
                  color="danger"
                  variant="filled"
                  size="small"
                >
                  Clear Filters
                </Button>
              </Col>
            </Row>
            <List
              dataSource={advanceFilterList}
              loading={advanceFilterLoading}
              style={{
                padding: "12px 0px",
              }}
              renderItem={(item) => (
                <List.Item
                  className={`${
                    selectedAdvanceFilter?.usf_id === item?.usf_id
                      ? "active-card-item"
                      : ""
                  } hover-list-item`}
                  onClick={() => {
                    if (selectedAdvanceFilter?.usf_id === item?.usf_id) {
                      setSelectedAdvanceFilter(null);
                      form.resetFields();
                      return;
                    }
                    form.setFieldsValue({
                      user_filter_name: item?.user_filter_name,
                      user_filter_value: item?.user_filter_value,
                      brand_id: item?.brand_id,
                      case_qty: item?.case_qty,
                      comp_desc: item?.comp_desc,
                      comp_note: item?.comp_note,
                      moq: item?.moq,
                      unit_cost: item?.unit_cost,
                      upc: item?.upc,
                      vendor_id: item?.vendor_id,
                      vendor_sku: item?.vendor_sku,
                    });
                    setSelectedAdvanceFilter(item);
                  }}
                  actions={[
                    <Space>
                      <Popconfirm
                        title="Are you sure to delete this filter?"
                        onConfirm={() => {
                          AdvanceFiltersDeleteAction(item?.usf_id);
                        }}
                        placement="left"
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          <Icon
                            icon="material-symbols:delete"
                            width={24}
                            style={{ color: "#ff5a5a" }}
                          />
                        </Button>
                      </Popconfirm>
                    </Space>,
                  ]}
                >
                  <List.Item.Meta title={item?.user_filter_name} />
                </List.Item>
              )}
            />
          </Col>
          <Col span={14} style={{ maxHeight: "500px", overflow: "auto" }}>
            <Form
              form={form}
              layout="vertical"
              name="filter_form"
              autoComplete="off"
            >
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    name="user_filter_name"
                    label={
                      <Typography.Text strong>Filter Name</Typography.Text>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please enter the filter name",
                      },
                    ]}
                  >
                    <Input placeholder="Name of filter" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="user_filter_value"
                    label={
                      <Typography.Text strong>Search Term</Typography.Text>
                    }
                  >
                    <Input placeholder="Search by Brand, Prep Type, etc..." />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="comp_desc"
                    label={
                      <Typography.Text strong>
                        Component Description
                      </Typography.Text>
                    }
                  >
                    <Input.TextArea placeholder="Component Description" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="comp_note"
                    label={
                      <Typography.Text strong>Component Note</Typography.Text>
                    }
                  >
                    <Input.TextArea placeholder="Component Note" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="unit_cost"
                    label={<Typography.Text strong>Unit Cost</Typography.Text>}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      placeholder="Unit Cost"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="vendor_id"
                    label={<Typography.Text strong>Vendor</Typography.Text>}
                  >
                    <Select
                      placeholder="Vendor"
                      loading={vendorLoading}
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      allowClear
                      options={vendorList?.map((d) => ({
                        label: d?.name,
                        value: d?.id,
                      }))}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="brand_id"
                    label={<Typography.Text strong>Brand</Typography.Text>}
                  >
                    <Select
                      loading={brandLoading}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      placeholder="Select brand"
                      showSearch
                      allowClear
                      options={brandList?.map((d) => ({
                        value: d?.b_id,
                        label: d?.b_name,
                      }))}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="upc"
                    label={<Typography.Text strong>UPC</Typography.Text>}
                  >
                    <InputNumber style={{ width: "100%" }} placeholder="UPC" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="case_qty"
                    label={<Typography.Text strong>Case Qty</Typography.Text>}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      placeholder="Case Qty"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="moq"
                    label={<Typography.Text strong>MOQ</Typography.Text>}
                  >
                    <InputNumber style={{ width: "100%" }} placeholder="MOQ" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="vendor_sku"
                    label={<Typography.Text strong>Vendor SKU</Typography.Text>}
                  >
                    <Input style={{ width: "100%" }} placeholder="Vendor SKU" />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </AdvancedFilterWrapper>
    </Modal>
  );
};

export default AdvancedFilter;
