import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { DotChartOutlined } from "@ant-design/icons";
import {
  Empty,
  message,
  Segmented,
  Select,
  Skeleton,
  Statistic,
  Tabs,
  Tag,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import CountUp from "react-countup";
import { MakeApiCall } from "../../../apis";
import RangePickerCF from "../../../Components/datepicker";
import MarketplaceSelect from "../../../Components/marketplace";
import { TableLoading } from "../../../Components/table-animation";
import Table1 from "../../../Components/tables/table1";
import {
  ConvertParams,
  FormatNumber,
  FormatUserName,
  PropsFilter,
  RenderTable,
} from "../../../config";
import { SegmentedWrapper, TabWrapper, TopCardWrapper } from "./style";
import ReactDOMServer from "react-dom/server";
import * as d3 from "d3";
import { CFTooltip } from "../../../Components/tooltip";

const formatterNumber = (value) => {
  const formattedString = value;
  const normalizedString = formattedString.replace(/,/g, ""); // Remove commas
  const number = parseFloat(normalizedString);
  // Format the number with commas and two decimal places
  return number;
};

const formatter = (value) => {
  return (
    <CountUp
      end={formatterNumber(value)}
      separator=","
      decimals={
        formatterNumber(value)?.toString().split(".")[1]?.length ? 2 : 0
      }
    />
  );
};

const Dashboard = ({ userData }) => {
  const sign = "$";
  const [marketplaceList, setMarketplaceList] = useState([]);
  const [filtersList, setFiltersList] = useState({});
  const [cardsData, setCardsData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [kp_filters, setKp_filters] = useState([]);
  const [kp_data, setKp_data] = useState({});
  const [graph_data, setGraph_data] = useState([]);
  const [card_data, setCard_data] = useState({});

  const [tableDataGraph, setTableDataGraph] = useState([]);

  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(0);

  const [salesOverviewType, setSalesOverviewType] = useState("chart");

  const [loaders, setLoaders] = useState({
    marketplace: false,
    filters: false,
    cards: false,
    table: false,
    tableGraph: false,
    kpFilter: false,
    kp_data: false,
    graph: false,
    topCard: false,
  });

  const [selectedFilters, setSelectedFilters] = useState({
    marketplace_id: null,
    asin: null,
    brand_id: null,
    product_line_id: null,
    sku: null,
    start_date: dayjs().add(-6, "d").subtract(1, "d").format("YYYY-MM-DD"),
    end_date: dayjs().subtract(1, "d").format("YYYY-MM-DD"),
    period_type: "month",
    column_type: null,
    graph_time_period: "day",
  });

  const signFind = {
    conversion: "%",
    spend: sign,
    avg_daily_sales: sign,
    sns: sign,
    roas: "%",
  };

  const options1 = {
    chart: {
      height: 150,
      type: "line",
      toolbar: { show: false },
      dropShadow: {
        enabled: true,
        top: 12,
        left: 0,
        bottom: 0,
        right: 0,
        blur: 2,
        color: "rgba(132, 145, 183, 0.3)",
        opacity: 0.45,
      },
    },
    markers: {
      size: 5,
      hover: {
        size: 9,
      },
    },
    colors: ["#0079AE", "#000", "#36BA98"],
    plotOptions: {
      bar: { horizontal: false, columnWidth: "50%", borderRadius: 3 },
    },
    stroke: {
      show: true,
      curve: "smooth",
      width: [0, 3.5, 3.5],
      dashArray: [0, 4],
      lineCap: "round",
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: [1],
    },
    grid: {
      strokeDashArray: 3,
      xaxis: { lines: { show: false } },
      yaxis: { lines: { show: true } },
    },
    labels: tableData?.map((d) => d?.label) || [],
    xaxis: {
      // type: 'datetime'
    },
    yaxis: [
      {
        labels: {
          formatter: function (value) {
            return sign + FormatNumber(value);
          },
          offsetX: -12,
          offsetY: 0,
        },
      },
    ],
  };
  function formatNumber(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const columnsSalesOverview = [
    {
      title: "Brand",
      dataIndex: "brand_name",
      key: "brand_name",
      render: (text, record) => <Tag color="blue">{text}</Tag>,
    },
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
      render: (text, record) => <Tag color="cyan">{text}</Tag>,
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
      render: (text, record) => <Tag color="green">{text}</Tag>,
    },
    {
      title: "Product Line",
      dataIndex: "product_line_name",
      key: "product_line_name",
    },
    {
      title: "Item",
      dataIndex: "productName",
      key: "productName",
      width: 400,
      render: (e) => {
        return (
          <CFTooltip rule row={2} placement={"bottom"}>
            {e}
          </CFTooltip>
        );
      },
    },
    {
      title: "ASIN Cost",
      dataIndex: "asin_cost",
      key: "asin_cost",
      render: (e) => {
        return (
          <>
            {sign}
            {FormatNumber(e || 0)}
          </>
        );
      },
    },
    {
      title: "Sales",
      dataIndex: "sales",
      key: "sales",
      render: (e) => {
        return (
          <>
            {sign}
            {FormatNumber(e || 0)}
          </>
        );
      },
    },
    {
      title: "Units",
      dataIndex: "quantity",
      key: "quantity",
      render: (e) => {
        return <>{parseInt(e || 0)}</>;
      },
    },
    {
      title: "% of Total Units",
      dataIndex: "percentage_total_quantity",
      key: "percentage_total_quantity",
      render: (e) => {
        return <>{FormatNumber(parseInt(e || 0))}%</>;
      },
    },

    {
      title: "% of Total Sales",
      dataIndex: "percentage_total_sales",
      key: "percentage_total_sales",
      render: (e) => {
        return <>{FormatNumber(parseInt(e || 0))}%</>;
      },
    },
    {
      title: "Organic Conversion",
      dataIndex: "organic_conversion",
      key: "organic_conversion",
      render: (e) => {
        return <>{FormatNumber(parseInt(e || 0))}%</>;
      },
    },
    {
      title: "Avg. Buy Box",
      dataIndex: "average_buy_box",
      key: "average_buy_box",
      render: (e) => {
        return (
          <>
            {sign}
            {FormatNumber(parseFloat(e || 0))}
          </>
        );
      },
    },
    {
      title: "Avg. Daily Units",
      dataIndex: "average_daily_quantity",
      key: "average_daily_quantity",
      render: (e) => {
        return <>{FormatNumber(parseInt(e || 0))}</>;
      },
    },
    {
      title: "Avg. Daily Sales",
      dataIndex: "average_daily_sales",
      key: "average_daily_sales",
      render: (e) => {
        return (
          <>
            {sign}
            {FormatNumber(parseFloat(e || 0))}
          </>
        );
      },
    },
  ];

  const viewMyTooltip = (e) => {
    const dataObj =
      graph_data?.filter((d) => d?.report_date === e?.x)?.[0] || {};

    const width = 200;
    const height = 130;
    const radius = Math.min(width, height) / 2;
    const color = ["#8b69a9", "#0675b4"];

    const data = [
      {
        name: "Ad Sales",
        value: parseFloat(dataObj?.adv_sales || 0).toFixed(2),
      },
      {
        name: "Ad Spend",
        value: parseFloat(dataObj?.spend || 0).toFixed(2),
      },
    ];

    setTimeout(() => {
      if (!e?.x) return;
      const arc = d3
        .arc()
        .outerRadius(radius - 10)
        .innerRadius(radius - 30);

      const pie = d3
        .pie()
        .sort(null)
        .value((d) => d.value);

      const svg = d3
        .select(
          document.getElementById(
            typeof e?.x === "string"
              ? e?.x?.split("-")?.join("_")
              : `${e?.x}_year`
          )
        )
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", `translate(${width / 2},${height / 2})`);

      const g = svg
        .selectAll(".arc")
        .data(pie(data))
        .enter()
        .append("g")
        .attr("class", "arc");

      g.append("path")
        .attr("d", arc)
        .style("fill", (d, i) => color?.[i])

        .style("opacity", 1); // Final opacity
    }, 100);

    return (
      <div className="w-375px dashboard-tooltip-custom-view">
        <div className="header-view py-4 fs-6">
          Details for Period
          <span className="fs-8 mt-2">{e?.x || "-"}</span>
        </div>
        <div className="d-flex align-items-center justify-content-around graph-tooltip-view mt-3 h-120px">
          {data?.map((d) => parseInt(d?.value))?.filter((d) => d === 0)
            ?.length === 2 ? (
            <div className="h-125px d-flex align-items-center">No Data</div>
          ) : (
            <>
              <svg
                id={
                  typeof e?.x === "string"
                    ? e?.x?.split("-")?.join("_")
                    : `${e?.x}_year`
                }
              ></svg>

              <div className="">
                {data?.map((d, i) => (
                  <div className="w-125px mb-3">
                    <div
                      style={{
                        border: `0.7px solid ${color?.[i]}`,
                        padding: "5px",
                        borderRadius: "5px",
                        color: "#353535",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        background: `${color?.[i]}20`,
                      }}
                    >
                      <span>{d?.name}</span>
                      <b style={{ color: color?.[i] }}>
                        {FormatNumber(d?.value || 0)}
                      </b>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>

        <div className="container" style={{ zoom: 0.8 }}>
          <div className="row mb-3">
            <div className="col box roas ms-3">
              <div className="label">ROAS</div>
              <div className="value">{FormatNumber(dataObj?.roas || 0)}%</div>
            </div>
            <div className="col ms-3 me-3 box page-conv">
              <div className="label">Organic Conversion</div>
              <div className="value">
                {FormatNumber(dataObj?.organic_conversion || 0)}%
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col ms-3 box buy-box">
              <div className="label">Buy Box</div>
              <div className="value">
                {FormatNumber(dataObj?.buy_box_percentage || 0)}%
              </div>
            </div>

            <div className="col ms-3 me-3 box adv-sales">
              <div className="label">% of Advertising / Sales</div>
              <div className="value">
                {FormatNumber(dataObj?.percentage_adv_sales || 0)}%
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col ms-3 box sales">
              <div className="label">Sales</div>
              <div className="value">${FormatNumber(dataObj?.sales || 0)}</div>
            </div>
            <div className="col ms-3 me-3 box units">
              <div className="label">Units</div>
              <div className="value">
                {FormatNumber(parseInt(dataObj?.quantity || 0))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const chartOptions = {
    chart: {
      height: 500,
      type: "line",
    },
    title: {
      text: "Sales and Conversions",
    },
    xAxis: {
      categories: graph_data.map((d) => d.report_date),
      type: "datetime",
    },
    yAxis: [
      {
        title: {
          text: "Organic Conversion",
          style: { color: "#333", fontSize: "14px" },
        },
        labels: {
          formatter: function () {
            return formatNumber(this.value) + "%";
          },
        },
      },
      {
        opposite: true,
        title: {
          text: "Total Sales",
          style: { color: "#333", fontSize: "14px" },
        },
        labels: {
          formatter: function () {
            return "$" + formatNumber(this.value);
          },
          offsetX: -12,
          offsetY: 0,
        },
      },
    ],
    series: [
      {
        name: "Sales",
        data: graph_data.map((d) => d.sales || 0),
        type: "column",
        color: "#0079AE",
        yAxis: 1,
        tooltip: {
          valuePrefix: "$",
        },
      },
      {
        name: "Buy Box",
        data: graph_data.map((d) => d.buy_box_percentage || 0),
        type: "line",
        yAxis: 0,
        color: "#36BA98",
        tooltip: {
          valueSuffix: "%",
        },
      },
      {
        name: "Organic Conversion",
        data: graph_data.map((d) => d.organic_conversion || 0),
        type: "line",
        color: "#000",
        yAxis: 0,
        tooltip: {
          valueSuffix: "%",
        },
      },
    ],
    plotOptions: {
      series: {
        shadow: {
          color: "rgba(132, 145, 183, 0.3)",
          width: 5,
          opacity: 0.45,
        },
        marker: {
          radius: 5,
          states: {
            hover: {
              enabled: true,
              radius: 9,
            },
          },
        },
      },
      column: {
        borderRadius: 3,
      },
    },
    tooltip: {
      useHTML: true,
      shared: true,
      backgroundColor: "transparent", // Set background color to transparent
      shadow: false, // Disable shadow
      borderWidth: 0, // Remove border
      style: {
        color: "#333", // Customize text color if needed
        fontWeight: "bold",
      },
      formatter: function () {
        return ReactDOMServer.renderToString(viewMyTooltip(this));
      },
    },
  };

  const series = [
    {
      name: "Sales",
      type: "column",
      data: tableData?.map((d) => d?.sales) || [],
    },
  ];

  const options2 = {
    chart: {
      height: 250,
      type: "bar",
      toolbar: { show: false },
    },
    markers: {
      size: 5,
      hover: {
        size: 9,
      },
    },
    colors: ["#ffd240", "#0079AE", "#36BA98"],
    plotOptions: {
      bar: { horizontal: true, columnWidth: "20%", borderRadius: 5 },
    },
    stroke: {
      show: true,
      width: 4,
      colors: ["#fff"],
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      strokeDashArray: 3,
      xaxis: { lines: { show: true } },
      yaxis: { lines: { show: false } },
    },
    labels: [""],
    xaxis: {
      type: "",
      labels: {
        formatter: function (value) {
          return (
            (signFind?.[selectedFilters?.column_type] !== "%" ? sign : "") +
            FormatNumber(value) +
            (signFind?.[selectedFilters?.column_type] === "%" ? "%" : "")
          );
        },
        offsetX: -12,
        offsetY: 0,
      },
    },
    yaxis: [
      {
        title: {
          text: `${FormatUserName(selectedFilters?.column_type)} ${
            signFind?.[selectedFilters?.column_type]
          }`,
          style: { colors: "#333", fontSize: "14px" },
        },
        labels: {
          show: false,
        },
      },
    ],
    tooltip: {
      y: {
        formatter: function (value) {
          return (
            (signFind?.[selectedFilters?.column_type] !== "%" ? sign : "") +
            FormatNumber(value) +
            (signFind?.[selectedFilters?.column_type] === "%" ? "%" : "")
          );
        },
      },
    },
  };

  const series2 = [
    {
      name: "Variance",
      data: [parseFloat(kp_data?.changePercentage || 0).toFixed(2)],
    },
    {
      name: FormatUserName(selectedFilters?.column_type),
      data: [parseFloat(kp_data?.currentValue || 0).toFixed(2)],
    },
    {
      name: `${FormatUserName(selectedFilters?.column_type)} previous Month`,
      data: [parseFloat(kp_data?.periodValue || 0).toFixed(2)],
    },
  ];

  const chartRef = useRef(null);

  const userSettingFilter = async (data) => {
    try {
      setFiltersList({});

      setLoaders((prev) => ({
        ...prev,
        filters: true,
      }));
      const {
        start_date,
        end_date,
        period_type,
        column_type,
        graph_time_period,
        ...rest
      } = data;

      const response = await MakeApiCall(
        `user-setting/filters${ConvertParams(rest)}`, // ${ConvertParams(data)}
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setFiltersList(response?.data);
        setLoaders((prev) => ({
          ...prev,
          filters: false,
        }));
      } else {
        setFiltersList({});
        setLoaders((prev) => ({
          ...prev,
          filters: false,
        }));
        message.warning(response?.message);
      }
    } catch (error) {
      message.warning(error?.message);
      setFiltersList({});
      setLoaders((prev) => ({
        ...prev,
        filters: false,
      }));
    }
  };
  const DashboardKPIData = async (data) => {
    try {
      setKp_data({});
      setLoaders((prev) => ({
        ...prev,
        kp_data: true,
      }));

      const response = await MakeApiCall(
        `dashboard-kpi-data${ConvertParams(data)}`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setKp_data(response?.data?.kpi_data);
        setLoaders((prev) => ({
          ...prev,
          kp_data: false,
        }));
      } else {
        setKp_data({});
        setLoaders((prev) => ({
          ...prev,
          kp_data: false,
        }));
        message.warning(response?.message);
      }
    } catch (error) {
      message.warning(error?.message);
      setKp_data({});
      setLoaders((prev) => ({
        ...prev,
        kp_data: false,
      }));
    }
  };
  const DashboardFilterData = async () => {
    try {
      setKp_filters([]);
      setLoaders((prev) => ({
        ...prev,
        kpFilter: true,
      }));

      const response = await MakeApiCall(
        `dashboard-filter-data`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        const filterList =
          Object.entries(response?.data?.kpi || {})?.map(([key, value]) => ({
            label: value,
            value: key,
          })) || [];
        setKp_filters(filterList);
        DashboardKPIData({
          ...selectedFilters,
          column_type: filterList[0]?.value,
        });
        setSelectedFilters((prev) => ({
          ...prev,
          column_type: filterList[0]?.value,
        }));
        setLoaders((prev) => ({
          ...prev,
          kpFilter: false,
        }));
      } else {
        setKp_filters([]);
        setLoaders((prev) => ({
          ...prev,
          kpFilter: false,
        }));
        message.warning(response?.message);
      }
    } catch (error) {
      message.warning(error?.message);
      setKp_filters([]);
      setLoaders((prev) => ({
        ...prev,
        kpFilter: false,
      }));
    }
  };
  const DashboardCardData = async (data) => {
    try {
      setCardsData({});
      setLoaders((prev) => ({
        ...prev,
        cards: true,
      }));
      const { period_type, column_type, ...rest } = data;
      const response = await MakeApiCall(
        `dashboard-card-data${ConvertParams(rest)}`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setCardsData(response?.data || {});
        setLoaders((prev) => ({
          ...prev,
          cards: false,
        }));
      } else {
        setCardsData({});
        setLoaders((prev) => ({
          ...prev,
          cards: false,
        }));
        message.warning(response?.message);
      }
    } catch (error) {
      message.warning(error?.message);
      setCardsData({});
      setLoaders((prev) => ({
        ...prev,
        cards: false,
      }));
    }
  };

  const DashboardTopCardData = async (data) => {
    try {
      setCard_data({});
      setLoaders((prev) => ({
        ...prev,
        topCard: true,
      }));
      const {
        period_type,
        start_date,
        end_date,
        column_type,
        graph_time_period,
        ...rest
      } = data;
      const response = await MakeApiCall(
        `dashboard-card-month-data${ConvertParams(rest)}`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setCard_data(response?.data?.card_data || {});
        setLoaders((prev) => ({
          ...prev,
          topCard: false,
        }));
      } else {
        setCard_data({});
        setLoaders((prev) => ({
          ...prev,
          topCard: false,
        }));
        message.warning(response?.message);
      }
    } catch (error) {
      message.warning(error?.message);
      setCard_data({});
      setLoaders((prev) => ({
        ...prev,
        topCard: false,
      }));
    }
  };
  const DashboardGraphTableData = async (data) => {
    try {
      setTableDataGraph([]);
      setLoaders((prev) => ({
        ...prev,
        tableGraph: true,
      }));

      const response = await MakeApiCall(
        `dashboard-graph-table-data${ConvertParams(data)}`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setTotalPage(response?.data?.pagination?.totalCount || 0);
        setCurrentPage(response?.data?.pagination?.page || 1);
        setPageSize(response?.data?.pagination?.page_size || 1);
        setTableDataGraph(response?.data?.table_data || []);

        setLoaders((prev) => ({
          ...prev,
          tableGraph: false,
        }));
      } else {
        setTableDataGraph([]);
        setLoaders((prev) => ({
          ...prev,
          tableGraph: false,
        }));
        message.warning(response?.message);
      }
    } catch (error) {
      message.warning(error?.message);
      setTableDataGraph([]);
      setLoaders((prev) => ({
        ...prev,
        tableGraph: false,
      }));
    }
  };
  const DashboardGraphData = async (data) => {
    try {
      DashboardGraphTableData(data);
      setGraph_data([]);
      setLoaders((prev) => ({
        ...prev,
        graph: true,
      }));
      const { period_type, column_type, ...rest } = data;
      const response = await MakeApiCall(
        `dashboard-graph-data${ConvertParams(rest)}`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setGraph_data(response?.data?.graph_data || []);
        setLoaders((prev) => ({
          ...prev,
          graph: false,
        }));
      } else {
        setGraph_data([]);
        setLoaders((prev) => ({
          ...prev,
          graph: false,
        }));
        message.warning(response?.message);
      }
    } catch (error) {
      message.warning(error?.message);
      setGraph_data([]);
      setLoaders((prev) => ({
        ...prev,
        graph: false,
      }));
    }
  };

  const DashboardPerformanceData = async (data) => {
    try {
      setTableData([]);
      setLoaders((prev) => ({
        ...prev,
        table: true,
      }));
      const { start_date, column_type, end_date, ...rest } = data;
      const response = await MakeApiCall(
        `dashboard-performance-data${ConvertParams(rest)}`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setTableData(response?.data?.performance_data || {});
        setLoaders((prev) => ({
          ...prev,
          table: false,
        }));
      } else {
        setTableData([]);
        setLoaders((prev) => ({
          ...prev,
          table: false,
        }));
        message.warning(response?.message);
      }
    } catch (error) {
      message.warning(error?.message);
      setTableData([]);
      setLoaders((prev) => ({
        ...prev,
        table: false,
      }));
    }
  };

  const MassFire = async (data, type) => {
    // try {
    //   if (type !== "date") {
    //     await DashboardTopCardData({ ...data });
    //   }
    //   await DashboardCardData({ ...data });
    //   await DashboardGraphData({ ...data });
    //   await DashboardKPIData({ ...data });
    //   await DashboardPerformanceData({ ...data });
    // } catch (error) {
    //   console.error("Error in MassFire:", error);
    // }
  };
  const FilterUpdate = (data) => {
    // userSettingFilter(data);
  };
  const callAfterMarketPlace = async (marketplace_id) => {
    try {
      // await userSettingFilter({ marketplace_id });
      // await DashboardTopCardData({ ...selectedFilters, marketplace_id });
      // await DashboardCardData({ ...selectedFilters, marketplace_id });
      // await DashboardGraphData({ ...selectedFilters, marketplace_id });
      // await DashboardPerformanceData({ ...selectedFilters, marketplace_id });
      // await DashboardFilterData();
    } catch (error) {
      message.warning(error?.message);
    }
  };

  useEffect(() => {
    return () => {};
  }, []);

  useLayoutEffect(() => {
    if (Object?.keys(cardsData?.sales_forecast_data || {})?.length === 0)
      return;
    if (!chartRef.current) return;

    am5.addLicense("AM5C1231231231");
    // Initialize the root element
    const root = am5.Root.new(chartRef.current);

    // Apply themes
    root.setThemes([am5themes_Animated.new(root)]);

    // Create the chart
    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
        innerRadius: am5.percent(50),
        creditsDisabled: true,
      })
    );

    chart.set("credits", null);
    // Create the series
    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "value",
        categoryField: "category",
        alignLabels: false,
        creditsDisabled: true,
      })
    );

    series.labels.template.setAll({
      textType: "circular",
      centerX: 0,
      centerY: 0,
      forceHidden: true,
    });

    series.slices.template.setAll({
      strokeWidth: 3,
      stroke: am5.color(0xffffff),
    });
    series.slices.template.set("tooltipText", "");
    // Set the data
    series.data.setAll([
      {
        value: parseFloat(cardsData?.sales_forecast_data?.targetSales).toFixed(
          2
        ),
        category: "Target",
      },
      {
        value: parseFloat(cardsData?.sales_forecast_data?.actualSales).toFixed(
          2
        ),
        category: "Actual",
      },
    ]);

    // Create the legend
    const legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
      })
    );

    legend.data.setAll(series.dataItems);

    // Play initial series animation
    series.appear(1000, 100);

    return () => {
      root.dispose(); // Cleanup the chart on component unmount
    };
  }, [cardsData?.sales_forecast_data]);

  const cardItems = [
    {
      icon: "ki-basket-ok",
      prefix: sign,
      suffix: "",
      title: "Ordered Sales",
      key: "current_value",
    },
    {
      icon: "ki-calendar-tick",
      prefix: sign,
      suffix: "",
      title: "Previous",
      key: "previous_value",
    },
    {
      icon: "ki-basket",
      prefix: sign,
      suffix: "",
      title: "Trend",
      key: "trend",
    },
    {
      icon: "ki-fat-rows",
      prefix: sign,
      suffix: "",
      title: "Variance",
      key: "variance",
    },
    {
      icon: "ki-percentage",
      prefix: "",
      suffix: "%",
      title: "Change",
      key: "change",
    },
  ];
  const boxData = [
    {
      icon: "ki-chart-simple-3",
      prefix: sign,
      suffix: "",
      title: "Sales",
      key: "item_price",
    },
    {
      icon: "ki-directbox-default",
      prefix: "",
      suffix: "",
      title: "Units",
      key: "quantity",
    },
    {
      icon: "ki-delivery-3",
      prefix: sign,
      suffix: "",
      title: "Avg. Daily Sales",
      key: "average_daily_sales",
    },
    {
      icon: "ki-parcel",
      prefix: "",
      suffix: "",
      title: "Avg. Daily Units",
      key: "average_daily_quantity",
    },
    {
      icon: "ki-monitor-mobile",
      prefix: sign,
      suffix: "",
      title: "Ad Sales",
      key: "sales",
    },
    {
      icon: "ki-dollar",
      prefix: sign,
      suffix: "",
      title: "Ad Spend",
      key: "spend",
    },
    {
      icon: "ki-award",
      prefix: "",
      suffix: "%",
      title: "ROAS",
      key: "roas",
    },
    {
      icon: "ki-percentage",
      prefix: "",
      suffix: "%",
      title: "Organic Conversion",
      key: "total_organic_conversion",
    },
  ];
  const renderPeriod = (text, index) => (
    <b className="text-primary">{text?.split(" ")?.[index]}</b>
  );
  const renderSales = (e) => (
    <div>
      {sign}
      {FormatNumber(e)}
    </div>
  );
  const renderVariance = (e) => (
    <div>
      {sign}
      {FormatNumber(parseFloat(e))}
    </div>
  );
  const renderPercentageChange = (text, record) => {
    if (Math.round(parseFloat(text)) === 0) {
      return (
        <div className="fw-bolder" style={{ paddingLeft: "20px" }}>
          0%
        </div>
      );
    }
    return (
      <div
        className={`fw-bolder ${
          parseFloat(record.percentage_change) > 0
            ? "text-success"
            : "text-danger"
        }`}
      >
        {parseFloat(record.percentage_change) > 0 ? (
          <i className="ki-outline ki-arrow-up fs-4 text-success" />
        ) : (
          <i className="ki-outline ki-arrow-down fs-4 text-danger" />
        )}{" "}
        {FormatNumber(text)}%
      </div>
    );
  };

  const createColumns = (isYearly) => [
    {
      title: "Year",
      dataIndex: "period",
      key: "period",
      width: 100,
      render: (text) => renderPeriod(text, 1),
    },
    {
      title: isYearly ? "Quarter" : "Month",
      dataIndex: "period",
      key: "period",
      width: 100,
      render: (text) => renderPeriod(text, 0),
    },
    {
      title: "Sales",
      dataIndex: "sales",
      key: "sales",
      render: renderSales,
    },
    {
      title: `Previous ${isYearly ? "Quarter" : "Month"}`,
      dataIndex: "previous_period_sales",
      key: "previous_period_sales",
      render: renderVariance,
    },
    {
      title: `${isYearly ? "QoQ" : "MoM"} Variance`,
      dataIndex: "variance",
      key: "variance",
      render: renderVariance,
    },
    {
      title: "Change (%)",
      dataIndex: "percentage_change",
      key: "percentage_change",
      render: renderPercentageChange,
    },
  ];

  const columnsMoM = createColumns(false);
  const columnsQoQ = createColumns(true);
  const columnsYoY = [
    {
      title: "Year",
      dataIndex: "period",
      key: "period",
      render: (text) => <b className="text-primary">{text}</b>,
    },
    {
      title: "Sales",
      dataIndex: "sales",
      key: "sales",
      render: renderSales,
    },
    {
      title: "Previous Year",
      dataIndex: "previous_period_sales",
      key: "previous_period_sales",
      render: renderVariance,
    },
    {
      title: "YoY Variance",
      dataIndex: "variance",
      key: "variance",
      render: renderVariance,
    },
    {
      title: "Change (%)",
      dataIndex: "percentage_change",
      key: "percentage_change",
      render: renderPercentageChange,
    },
  ];

  const findColumns = {
    quarter: columnsQoQ,
    year: columnsYoY,
    month: columnsMoM,
  };

  if (!loaders.marketplace && marketplaceList?.length === 0) return <Empty />;

  return (
    <div>
      <div className="element row mb-7 row-gap-4">
        <div className="position-relative col-auto">
          <Select
            allowClear
            size="large"
            className="w-150px"
            showSearch
            optionFilterProp="label"
            placeholder="Select Seller"
          />
        </div>
        <div className="position-relative col-auto">
          <MarketplaceSelect
            callAfterMarketPlace={callAfterMarketPlace}
            showSearch
            MarketplaceData={(e) => {
              setMarketplaceList(e);
              setLoaders({
                ...loaders,
                marketplace: false,
              });

              const obj = {
                ...selectedFilters,
                marketplace_id: e?.[0]?.value,
              };

              setSelectedFilters(obj);
            }}
            onChange={(e) => {
              const obj = {
                ...selectedFilters,
                marketplace_id: e,
              };
              MassFire(obj);
              FilterUpdate(obj);
              setSelectedFilters(obj);
            }}
            userData={userData}
          />
        </div>
        <div className="position-relative col-auto">
          <RangePickerCF
            size="large"
            className="w-250px"
            id={Math.random()}
            allowClear={false}
            needConfirm
            onChange={(e) => {
              const obj = {
                ...selectedFilters,
                start_date: dayjs(e[0]).format("YYYY-MM-DD"),
                end_date: dayjs(e[1]).format("YYYY-MM-DD"),
              };
              MassFire({ ...obj }, "date");
              setSelectedFilters(obj);
            }}
            value={[
              dayjs(selectedFilters?.start_date, "YYYY-MM-DD"),
              dayjs(selectedFilters?.end_date, "YYYY-MM-DD"),
            ]}
            suffixIcon={
              <i className="ki-duotone ki-calendar-8 fs-1 ms-2 me-0">
                <span className="path1" />
                <span className="path2" />
                <span className="path3" />
                <span className="path4" />
                <span className="path5" />
                <span className="path6" />
              </i>
            }
          />
        </div>
        <div className="position-relative col-auto">
          <Select
            options={filtersList?.asin}
            allowClear
            size="large"
            loading={loaders?.filters}
            className="w-150px"
            value={selectedFilters?.asin}
            onChange={(e) => {
              const obj = {
                ...selectedFilters,
                asin: e,
              };
              MassFire({ ...obj });
              FilterUpdate({ ...obj });
              setSelectedFilters(obj);
            }}
            showSearch
            optionFilterProp="label"
            placeholder="Select ASIN"
          />
        </div>
        <div className="position-relative col-auto">
          <Select
            size="large"
            optionFilterProp="label"
            options={filtersList?.productLine}
            allowClear
            value={selectedFilters?.product_line_id}
            loading={loaders?.filters}
            showSearch
            onChange={(e) => {
              const obj = {
                ...selectedFilters,
                product_line_id: e,
              };
              MassFire({ ...obj });
              FilterUpdate({ ...obj });
              setSelectedFilters(obj);
            }}
            className="w-200px"
            placeholder="Select Product Line"
          />
        </div>
        <div className="position-relative col-auto">
          <Select
            size="large"
            allowClear
            className="w-275px"
            optionFilterProp="label"
            placeholder="Select Product"
            options={filtersList?.product?.map((d) => ({
              ...d,
              label: (
                <div
                  style={{
                    maxWidth: "100%",
                    whiteSpace: "normal",
                    wordBreak: "break-all",
                    overflowWrap: "breakWord",
                    hyphens: "auto",
                    paddingBottom: "7px",
                    borderBottom: "1px solid #adadad",
                  }}
                >
                  <div>{d?.label}</div>
                </div>
              ),
            }))}
            value={selectedFilters?.sku}
            loading={loaders?.filters}
            showSearch
            onChange={(e) => {
              const obj = {
                ...selectedFilters,
                sku: e,
              };
              MassFire({ ...obj });
              FilterUpdate({ ...obj });
              setSelectedFilters(obj);
            }}
          />
        </div>
        <div className="position-relative col-auto">
          <Select
            optionFilterProp="label"
            size="large"
            className="w-175px"
            allowClear
            placeholder="Select Brand"
            value={selectedFilters?.brand_id}
            loading={loaders?.filters}
            showSearch
            options={filtersList?.brand}
            onChange={(e) => {
              const obj = {
                ...selectedFilters,
                brand_id: e,
              };
              MassFire({ ...obj });
              FilterUpdate({ ...obj });
              setSelectedFilters(obj);
            }}
          />
        </div>
      </div>

      <div className="element mb-5">
        <div className="rounded flex-wrap p-0 row d-flex gap-5 ">
          {cardItems?.map((d, i) => (
            <TopCardWrapper
              key={i}
              className="col d-flex align-items-center me-2 bg-light border p-3 rounded px-5 pe-7"
            >
              <div className="symbol symbol-40px me-5">
                {loaders?.topCard ? (
                  <Skeleton.Avatar active shape={"circle"} />
                ) : (
                  <div className="symbol-label bg-white shadow-sm">
                    <i className={`ki-outline ${d?.icon} fs-1`} />
                  </div>
                )}
              </div>

              {loaders?.topCard ? (
                <Skeleton.Button active shape={"round"} />
              ) : (
                <div>
                  <div className="fs-3 text-gray-900 fw-bolder">
                    <Statistic
                      value={FormatNumber(card_data?.item_price?.[d?.key] || 0)}
                      precision={2}
                      formatter={formatter}
                      valueRender={(e) => (
                        <div className="fs-3 text-gray-900 d-flex fw-bolder">
                          {d?.prefix || ""}
                          {e}
                          {d?.suffix}{" "}
                          {d?.key === "change" ? (
                            parseFloat(e) > 0 ? (
                              <i
                                style={{ top: 5 }}
                                className="position-relative ki-outline ki-arrow-up fs-4 text-success"
                              />
                            ) : (
                              <i
                                style={{ top: 5 }}
                                className="position-relative ki-outline ki-arrow-down fs-4 text-danger"
                              />
                            )
                          ) : null}
                        </div>
                      )}
                    />
                  </div>
                  <div className="fs-7 text-gray-800 fw-bold">{d?.title}</div>
                </div>
              )}
            </TopCardWrapper>
          ))}
        </div>
      </div>

      <div className="element row g-3 g-lg-6 mb-7">
        {boxData?.map((d, i) => (
          <div key={i} className="col-xxl col-lg-3 col-md-3 col-sm-6 col-12">
            <div className="bg-gray-100 bg-cus-grad rounded-2 px-6 py-5 position-relative overflow-hidden">
              <i
                className={`ki-outline ${d?.icon} fs-1 text-primary card-des-icon`}
              />

              <div className="symbol symbol-50px me-5 mb-8">
                {loaders?.cards ? (
                  <Skeleton.Avatar active shape={"circle"} />
                ) : (
                  <span className="symbol-label bg-white shadow-sm">
                    <i className={`ki-outline ${d?.icon} fs-1 text-primary`} />
                  </span>
                )}
              </div>

              {loaders?.cards ? (
                <Skeleton.Input active shape={"circle"} />
              ) : (
                <div className="m-0 d-block">
                  <Statistic
                    value={FormatNumber(cardsData?.box_data?.[d?.key] || 0)}
                    precision={2}
                    formatter={formatter}
                    valueRender={(e) => (
                      <div className="text-gray-800 fw-bolder d-flex fs-1 lh-1 ls-n1 mb-1">
                        {d?.prefix || ""}
                        {e}
                        {d?.suffix}
                      </div>
                    )}
                  />

                  <span className=" text-gray-500 fw-semibold fs-6">
                    {d?.title}
                  </span>
                </div>
              )}
              {/*end::Stats*/}
            </div>
            {/*end::Items*/}
          </div>
        ))}
      </div>

      <div className="element row gx-5 gx-xl-10">
        <div className="col-xl-9 mb-5 mb-xl-5">
          <div className="card card-flush h-xl-100">
            <div className="card-header  mb-0">
              <h3 className="card-title ">
                <span className="card-label fw-bold text-gray-800">
                  Sales Overview
                </span>
                <SegmentedWrapper
                  value={salesOverviewType}
                  options={[
                    {
                      label: (
                        <i className="ki-duotone ki-questionnaire-tablet fs-2 mt-2">
                          <span className="path1" />
                          <span className="path2" />
                          <span className="path3" />
                          <span className="path4" />
                        </i>
                      ),
                      value: "list",
                    },
                    {
                      label: (
                        <i className="ki-duotone ki-graph-3  fs-3 mt-2">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      ),
                      value: "chart",
                    },
                  ]}
                  onChange={(value) => {
                    setSalesOverviewType(value);
                  }}
                />
              </h3>

              <TabWrapper className="card-toolbar">
                <Tabs
                  className="w-250px"
                  onChange={(e) => {
                    const obj = {
                      ...selectedFilters,
                      graph_time_period: e,
                    };
                    setSelectedFilters(obj);
                    DashboardGraphData({
                      ...obj,
                    });
                  }}
                  activeKey={selectedFilters?.graph_time_period}
                >
                  <Tabs.TabPane
                    disabled={loaders?.cards || loaders?.topCard}
                    tab="Days"
                    key="day"
                  ></Tabs.TabPane>
                  <Tabs.TabPane
                    disabled={loaders?.cards || loaders?.topCard}
                    tab="Months"
                    key="month"
                  ></Tabs.TabPane>
                  <Tabs.TabPane
                    disabled={loaders?.cards || loaders?.topCard}
                    tab="Years"
                    key="year"
                  ></Tabs.TabPane>
                </Tabs>
              </TabWrapper>
            </div>
            <div className="card-body  h-500px pb-0 d-flex align-items-center pt-0">
              {loaders?.graph ? (
                <div
                  style={{
                    position: "absolute",
                    inset: 0,
                    margin: "auto",
                    width: "fit-content",
                    height: "fit-content",
                  }}
                >
                  <Skeleton.Node active>
                    <DotChartOutlined
                      style={{
                        fontSize: 40,
                        color: "#bfbfbf",
                      }}
                    />
                  </Skeleton.Node>
                </div>
              ) : salesOverviewType === "list" ? (
                <div className="w-100 h-500px ">
                  <Table1
                    columns={columnsSalesOverview?.map((d) => ({
                      ...d,
                      ...PropsFilter(d?.dataIndex || d?.filterKey),
                      render: (props, row, index) =>
                        RenderTable(props, row, index, d),
                    }))}
                    dataSource={tableDataGraph}
                    pagination={{
                      pageSize: pageSize,
                      current: currentPage,
                      total: totalPage,
                      onChange: (e, f) => {
                        setPageSize(f);
                        setCurrentPage(e);
                        DashboardGraphTableData({
                          ...selectedFilters,
                          page: e,
                          "page-size": f,
                        });
                      },
                      showSizeChanger: true, // To show page size options
                      pageSizeOptions: [10, 20, 50, 100], // Page size options
                      showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`,
                    }}
                    scroll={{ x: "max-content", y: 380 }}
                  />
                </div>
              ) : (
                <div id="chart_sales_overview" className="w-100 h-500px">
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptions}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-xl-3 mb-5 mb-xl-10">
          <div className="card card-flush h-xl-50 mb-5">
            {/*begin::Header*/}
            <div className="card-header min-h-50px mb-0">
              {/*begin::Title*/}
              <h3 className="card-title pt-0 align-items-center d-flex flex-row ">
                <span className="card-label fw-bold text-gray-800">
                  Sales Forecast
                </span>
              </h3>
              {/*end::Title*/}
              {/*begin::Toolbar*/}
              <div className="card-toolbar"></div>
              {/*end::Toolbar*/}
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body  pb-0 d-flex align-items-start pt-0">
              {loaders?.cards ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "-webkit-fill-available",
                    height: "200px",
                  }}
                >
                  <Skeleton.Node fullSize={true} active>
                    <DotChartOutlined
                      style={{
                        fontSize: 10,
                        color: "#bfbfbf",
                      }}
                    />
                  </Skeleton.Node>
                </div>
              ) : (
                <Tooltip
                  placement="bottom"
                  title={
                    <>
                      <div className="d-flex">
                        <b className="w-200px text-start">Target:</b>
                        <div className="text-start">
                          {sign}
                          {FormatNumber(
                            cardsData?.sales_forecast_data?.targetSales || 0
                          )}
                        </div>
                      </div>
                      <div className="d-flex">
                        <b className="w-200px text-start">Actual:</b>
                        <div className="text-start">
                          {sign}
                          {FormatNumber(
                            cardsData?.sales_forecast_data?.actualSales || 0
                          )}
                        </div>
                      </div>
                      <div className="d-flex">
                        <b className="w-200px text-start">
                          Percentage Remaining:
                        </b>
                        <div className="text-start">
                          {FormatNumber(
                            cardsData?.sales_forecast_data
                              ?.percentageRemaining || 0
                          )}
                          %
                        </div>
                      </div>
                      <div className="d-flex">
                        <b className="w-200px text-start">
                          Percentage Completed:
                        </b>
                        <div className="text-start">
                          {FormatNumber(
                            cardsData?.sales_forecast_data
                              ?.percentageCompleted || 0
                          )}
                          %
                        </div>
                      </div>
                    </>
                  }
                >
                  {/* <Icon icon="fluent:info-32-filled" width={20} height={20} /> */}

                  <div
                    id="kt_amcharts_3"
                    ref={chartRef}
                    style={{ height: "200px", width: "100%" }}
                  ></div>
                </Tooltip>
              )}
            </div>
            {/*end::Body*/}
          </div>
          <div className="card card-flush h-xl-50">
            {/*begin::Header*/}
            <div className="card-header min-h-50px mb-0">
              {/*begin::Title*/}
              <h3 className="card-title pt-0 align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">
                  Purchase
                </span>
                {/* <span class="text-gray-500 mt-1 fw-semibold fs-6">8k social visitors</span> */}
              </h3>

              <div className="card-toolbar"></div>
              {/*end::Toolbar*/}
            </div>

            <div className="card-body h-200px  pb-0 px-4 d-flex align-items-start pt-0">
              {loaders?.table ? (
                <div
                  style={{
                    position: "absolute",
                    inset: 0,
                    margin: "auto",
                    width: "fit-content",
                    height: "fit-content",
                  }}
                >
                  <Skeleton.Node fullSize={true} active>
                    <DotChartOutlined
                      style={{
                        fontSize: 10,
                        color: "#bfbfbf",
                      }}
                    />
                  </Skeleton.Node>
                </div>
              ) : (
                <div
                  id="chart_sales_overview1"
                  className
                  style={{ width: "100%" }}
                >
                  <Chart
                    options={options1}
                    series={series}
                    type="line"
                    height={200}
                  />
                </div>
              )}
            </div>
            {/*end::Body*/}
          </div>
        </div>
      </div>
      <div className="element row gx-5 gx-xl-10">
        <div className="col-xl-6 mb-5 mb-xl-5">
          {/*begin::Chart widget 31*/}
          <div className="card card-flush h-xl-100">
            {/*begin::Header*/}
            <div className="card-header  mb-0">
              {/*begin::Title*/}
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">
                  KPI Snapshot
                </span>
                {/* <span class="text-gray-500 mt-1 fw-semibold fs-6">8k social visitors</span> */}
              </h3>
              {/*end::Title*/}
              {/*begin::Toolbar*/}
              <div className="card-toolbar">
                <Select
                  size="large"
                  defaultValue="Conversion"
                  className="w-200px"
                  options={kp_filters}
                  loading={loaders?.kpFilter}
                  value={selectedFilters?.column_type}
                  onChange={(e) => {
                    const obj = {
                      ...selectedFilters,
                      column_type: e,
                    };

                    DashboardKPIData(obj);
                    setSelectedFilters(obj);
                  }}
                />
              </div>
              {/*end::Toolbar*/}
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body  h-275px pb-0 d-flex align-items-center pt-0">
              {/*begin::Chart*/}
              {loaders?.kp_data ? (
                <div
                  style={{
                    position: "absolute",
                    inset: 0,
                    margin: "auto",
                    width: "fit-content",
                    height: "fit-content",
                  }}
                >
                  <Skeleton.Node active>
                    <DotChartOutlined
                      style={{
                        fontSize: 40,
                        color: "#bfbfbf",
                      }}
                    />
                  </Skeleton.Node>
                </div>
              ) : (
                <div style={{ height: 275, width: "100%" }}>
                  <Chart
                    options={options2}
                    series={series2}
                    type="bar"
                    height={250}
                  />
                </div>
              )}
              {/*end::Chart*/}
            </div>
            {/*end::Body*/}
          </div>
          {/*end::Chart widget 31*/}
        </div>
        <div className="col-xl-6 mb-5 mb-xl-5">
          {/*begin::Chart widget 31*/}
          <div className="card card-flush h-xl-100">
            {/*begin::Header*/}
            <div className="card-header  mb-0">
              {/*begin::Title*/}
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">
                  Performance
                </span>
                {/* <span class="text-gray-500 mt-1 fw-semibold fs-6">8k social visitors</span> */}
              </h3>

              <TabWrapper className="card-toolbar">
                <Tabs
                  onChange={(e) => {
                    setSelectedFilters((prev) => {
                      const obj = {
                        ...prev,
                        period_type: e,
                      };
                      DashboardPerformanceData({
                        ...obj,
                        marketplace_id: selectedFilters?.marketplace_id,
                      });
                      return obj;
                    });
                  }}
                  activeKey={selectedFilters?.period_type}
                  className="w-250px"
                >
                  <Tabs.TabPane tab="MoM" key="month"></Tabs.TabPane>
                  <Tabs.TabPane tab="QoQ" key="quarter"></Tabs.TabPane>
                  <Tabs.TabPane tab="YoY" key="year"></Tabs.TabPane>
                </Tabs>
              </TabWrapper>
            </div>

            {loaders?.table ? (
              <TableLoading
                id="test-table"
                row={3}
                columns={[1, 2, 3, 4]}
                checkBoxVal={false}
                actions={[]}
                style={{ height: "260px", overflow: "hidden" }}
              />
            ) : (
              <Table1
                dataSource={tableData}
                columns={findColumns?.[selectedFilters?.period_type]}
                scroll={{ x: "max-content", y: 200 }}
                pagination={false}
                loading={loaders?.table}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
