import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../apis";

export default function MarketplaceSelect({
  callAfterMarketPlace,
  onChange = () => {},
  MarketplaceData = () => {},
  ...rest
}) {
  const [marketplaceList, setMarketplaceList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedMarketplace, setSelectedMarketplace] = useState(null);

  const getUserMarketplaceList = async () => {
    setMarketplaceList([]);
    setLoading(true);
    const response = await MakeApiCall(
      `user-marketplace`,
      "get",
      null,
      true,
      {},
      rest?.userData?.user?.auth_token
    );
    if (response?.status) {
      if (response?.data?.records?.length > 0) {
        const marketplace_id = response?.data?.records?.[0]?.marketplace_id;
        const marketplaceList = response?.data?.records?.map((d) => ({
          label: (
            <div className="d-flex align-items-center">
              <img
                src={`/assets/media/domainImage/${d?.marketplace_id}.png`}
                alt={`${d?.marketplace}`}
                width={20}
                height={20}
                style={{
                  objectFit: "contain",
                  marginRight: 7,
                }}
              />
              <span style={{fontSize:'14px', fontWeight:600}}> {d?.marketplace}</span>
            </div>
          ),
          value: d?.marketplace_id,
        }));

        setSelectedMarketplace(marketplace_id);
        setMarketplaceList(marketplaceList);
        MarketplaceData(marketplaceList);
        callAfterMarketPlace(marketplace_id);
        setLoading(false);
      } else {
        setMarketplaceList([]);
        MarketplaceData([]);
        callAfterMarketPlace(null);
        setLoading(false);
        setSelectedMarketplace(null);
      }
    } else {
      setMarketplaceList([]);
      MarketplaceData([]);
      callAfterMarketPlace(null);
      setLoading(false);
      setSelectedMarketplace(null);
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    getUserMarketplaceList();

    return () => {};
  }, []);

  return (
    <Select
      size="large"
      className="w-175px"
      placeholder="Select Marketplace"
      loading={loading}
      options={marketplaceList}
      value={selectedMarketplace}
      onChange={(e, rest) => {
        onChange(e, rest);
        setSelectedMarketplace(e);
      }}
      {...rest}
    />
  );
}
