import React from "react";
import { MakeApiCall } from "../../../apis";
import { APPID, ClientID, urlDecode } from "../../../config";
import MarketplaceView from "./marketplace-view";

const MarketplaceCredentials = ({ userData }) => {
  const APIS = {
    list: "user-credentials",
    generateRefreshToken: "user-sp-credentials/callback-url",
    AdsRefreshToken: "user-advertising-credentials/callback-url",
    SPCallBack: "/callback_spapi",
    AdsCallBack: "/callback_ads",
    APPID: APPID,
    ClientID: ClientID,
    returnURL: "http://18.233.1.243/callback_ads/",
    saveProfile: "user-advertising-credentials/save-advertising-profile",
    urlDecode: urlDecode,
    MakeApiCall: MakeApiCall,
    userData: userData,
  };

  return <MarketplaceView {...APIS} />;
};

export default MarketplaceCredentials;
