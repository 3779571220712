import { Segmented } from "antd";
import styled from "styled-components";

export const TopCardWrapper = styled.div`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

  &:nth-child(1) {
    .symbol-label {
      background-color: rgba(52, 96, 255, 0.15) !important;
      i {
        color: #3461ff !important;
      }
    }
  }
  &:nth-child(2) {
    .symbol-label {
      background-color: rgb(231 46 46 / 15%) !important;
      i {
        color: #e72e2e !important;
      }
    }
  }
  &:nth-child(3) {
    .symbol-label {
      background-color: rgb(18 191 35 / 15%) !important;
      i {
        color: #12bf24 !important;
      }
    }
  }
  &:nth-child(4) {
    .symbol-label {
      background-color: rgba(50, 190, 255, 0.15) !important;
      i {
        color: #32bfff !important;
      }
    }
  }
  &:nth-child(5) {
    .symbol-label {
      background-color: rgba(135, 50, 255, 0.15) !important;
      i {
        color: #8932ff !important;
      }
    }
  }
`;
export const TabWrapper = styled.div`
  .ant-tabs-ink-bar {
    background: none !important;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: none !important;
  }
  .ant-tabs-tab {
    padding: 0px 15px;
    // width: fit-content !important;
    height: 33px !important;
    // justify-content: center !important;
    border-radius: 10px !important;
  }
  .ant-tabs-tab-btn {
    font-weight: 600 !important;
    font-size: 1.075rem !important;
    color: #0079ae;
    transition: color 0.15s ease-in-out;
  }
  .ant-tabs-tab-active {
    background-color: #0079ae !important;
    .ant-tabs-tab-btn {
      color: #ffffff !important;
    }
  }
  .ant-tabs .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 10px !important;
  }
`;
export const SegmentedWrapper = styled(Segmented)`
  .ant-segmented-item-selected {
    background-color: #0479af;
    i {
      color: #fff;
    }
  }
`;
