import { InboxOutlined } from "@ant-design/icons";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Button, message, theme, Tooltip, Upload } from "antd";
import React, { useState } from "react";
import { MakeApiCall } from "../../apis";
import { Wrapper } from "./style";

const { Dragger } = Upload;
const { useToken } = theme;

export default function ImportFIle({
  fileType,
  multiple,
  documentType,
  children,
  onUpload,
  methodKey,
  downloadSamplePath,
  endpoint,
  onResponse,
  userData,
  bodyKey,
  paramKey,
  tokenKey,
  ...rest
}) {
  const [open, setOpen] = useState(false);
  const [fileList, setFileList] = useState([]);
  const { token } = useToken();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    width: "450px",
    borderRadius: "18px",
  };

  const props = {
    name: "file",
    multiple: multiple,
    onChange(info) {
      if (!multiple) {
        return setFileList(info.fileList.slice(-1));
      }
      setFileList(info.fileList);
    },

    accept: fileType || ".csv",
    fileList: fileList,
    beforeUpload: (file) => {
      const isCSV = file.type === documentType;
      if (!isCSV && fileType && documentType) {
        message.warning(`You can only upload ${fileType} files!`);
        return Upload.LIST_IGNORE;
      }
      return false;
    },
  };

  const UploadFile = async (file) => {
    try {
      const formData = new FormData();
      Object.entries(bodyKey)?.forEach(([key, value]) => {
        formData.append(key, value); // Static value
      });

      formData.append(paramKey, file); // File

      const response = await MakeApiCall(
        endpoint,
        "post",
        formData,
        true,
        { "Content-Type": "multipart/form-data" },
        tokenKey
      );
      setOpen(false);
      if (response?.status === true) {
        setFileList([]);
      }
      onResponse({ ...response, file: file });
    } catch (error) {
      onResponse({ ...error, file: file });
      if (!endpoint) {
        setOpen(false);
        setFileList([]);
      }
    }
  };
  const handleUpload = () => {
    if (fileList.length === 0) {
      message.destroy();
      message.error("Please select a file before uploading.");
      return;
    }
    UploadFile(multiple ? fileList : { ...fileList?.[0] }?.originFileObj);
    // onUpload(multiple ? fileList : { ...fileList?.[0] });
  };

  return (
    <Wrapper
      menu={{
        items: [],
      }}
      open={open}
      onOpenChange={(e) => {
        if (!e) {
          setFileList([]);
        }
        setOpen(e);
      }}
      dropdownRender={() => (
        <div style={contentStyle} className="card p-0">
          {downloadSamplePath && (
            <div className="card-header mb-3 px-0 d-flex align-items-center justify-content-start">
              <Tooltip className="d-flex align-items-center justify-content-start cursor-pointer">
                <a
                  onClick={() => {
                    window.open(downloadSamplePath);
                  }}
                >
                  <Icon
                    icon="material-symbols:info"
                    width={20}
                    // style={{ color: primaryColor }}
                    className="me-2 ms-3"
                  />
                  Download Sample File
                </a>
              </Tooltip>
            </div>
          )}
          <div className="card-body p-3">
            <Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a {multiple ? "multiple" : "single"} upload.
              </p>
            </Dragger>{" "}
          </div>
          <div className="card-footer d-flex justify-content-end p-3">
            <Button
              onClick={() => setOpen(false)}
              className="me-3"
              type="dashed"
            >
              Close
            </Button>
            <Button onClick={handleUpload} type="primary">
              Save
            </Button>
          </div>
        </div>
      )}
      {...rest}
    >
      <a onClick={(e) => e.preventDefault()}>{children}</a>
    </Wrapper>
  );
}
