import { Modal, Table } from "antd";
import React from "react";

const AsinHistory = ({ asinCostHistory, handleCancel, columnsASINCost }) => {
  return (
    <Modal
      title="ASIN Details"
      visible={asinCostHistory?.show}
      onCancel={handleCancel}
      footer={null}
    >
      <Table
        columns={columnsASINCost}
        dataSource={asinCostHistory?.data}
        pagination={false}
        scroll={{ x: "max-content" }}
      />
    </Modal>
  );
};

export default AsinHistory;
