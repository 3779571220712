import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, message, Row, Col, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { MakeApiCall } from "../../../../../apis";
import { Wrapper } from "./style";
import { countryData } from "../../../../../config/countryCode";

import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { parsePhoneNumber } from 'libphonenumber-js';



const { Option } = Select;

const BrandModalForm = ({
  setVisible,
  visible,
  userData,
  onSubmit,
  selectedRow,
}) => {
  const [form] = Form.useForm();
  const [submitLoading, setSubmitLoading] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState(countryData[0]); // Default to first country
  const [value, setValue] = useState();
  const [isValid, setIsValid] = useState(true);
const [lengthError, setLengthError] = useState(false);

const validatePhoneNumber = (_, phoneValue) => {
  if (!phoneValue) {
    return Promise.reject(new Error("Please enter a phone number"));
  }
  if (!isValidPhoneNumber(phoneValue)) {
    return Promise.reject(new Error("Invalid phone number format"));
  }

  try {
    const phoneNumber = parsePhoneNumber(phoneValue);
    if (phoneNumber && (phoneNumber.nationalNumber.length < 10 || phoneNumber.nationalNumber.length > 15)) {
      return Promise.reject(new Error("Phone number must be between 10 and 15 digits"));
    }
  } catch {
    return Promise.reject(new Error("Invalid phone number"));
  }

  return Promise.resolve();
};



  useEffect(() => {
    if (Object.keys(selectedRow || {})?.length > 0) {
      form.setFieldsValue(selectedRow);
    } else {
      form.resetFields();
    }
  }, [selectedRow]);

  // Function to handle modal close
  const handleCancel = () => {
    setVisible(false);
  };

  const CreateBrand = async (data) => {
    setSubmitLoading(true);
    try {
      const response = await MakeApiCall(
        `${
          Object.keys(selectedRow || {})?.length > 0
            ? `brand/${selectedRow?.b_id}`
            : "brand"
        }`,
        Object.keys(selectedRow || {})?.length > 0 ? "PATCH" : "POST",
        data,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        form.resetFields();
        onSubmit();
        setSubmitLoading(false);
        message.destroy();
        message.success(response?.message);
      } else {
        message.destroy();
        setSubmitLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setSubmitLoading(false);
      message.destroy();
      message.error(error?.message);
    }
  };

  // Function to handle form submit
  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const phoneNumber = parsePhoneNumber(values.phone_number);
  
        if (phoneNumber) {
          console.log("Full Number:", phoneNumber.number); // +14155552671
          console.log("Country Code:", phoneNumber.countryCallingCode); // 1 (for USA)
          console.log("Country:", phoneNumber.country); // "US" (for USA)
          console.log("National Number:", phoneNumber.nationalNumber); // 4155552671
  
          // Add phone details to values before submitting
          values.phone_code = phoneNumber.country;
          // values.country_code = phoneNumber.countryCallingCode;
        } else {
          console.log("Invalid phone number");
          values.phone_code = null;
          // values.country_code = null;
        }
  
        // Call the function with updated values
        CreateBrand(values);
      })
      .catch((info) => {
        console.log("Validation failed:", info);
      });
  };
  return (
    <>
      <Modal
        title={
          Object.keys(selectedRow || {})?.length > 0
            ? "Edit Brand"
            : "Add Brand"
        }
        visible={visible}
        okButtonProps={{ loading: submitLoading }}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Wrapper>
        <Form form={form} layout="vertical" name="supply_form">
          {/* Brand Name */}
          <Form.Item
            label="Brand Name"
            name="b_name"
            rules={[
              { required: true, message: "Please input the brand name!" },
            ]}
          >
            <Input />
          </Form.Item>

          {/* Contact Name */}
          <Form.Item
            label="Contact Name"
            name="contact_name"
            rules={[
              { required: true, message: "Please input the contact name!" },
            ]}
          >
            <Input />
          </Form.Item>

          {/* Contact Email */}
          <Form.Item
            label="Contact Email"
            name="contact_email"
            rules={[
              { required: true, message: "Please input the contact email!" },
              { type: "email", message: "Please enter a valid email!" },
            ]}
          >
            <Input />
          </Form.Item>

          {/* Contact Note */}
          <Form.Item label="Contact Note" name="contact_note">
            <TextArea rows={3} />
          </Form.Item>
          <div>

        
        </div>
        {/* <Form.Item
          className=""
            label="Phone"
            name="phone_number"
            rules={[
              { required: true, message: "Please input the phone number!" },
            ]}
          >
         
         <PhoneInput
            placeholder="Enter phone number"
            value={value}
            onChange={handleChange}
          />
          {!isValid && <p style={{ color: 'red' }}>Please enter a valid phone number</p>}
          {lengthError && <p style={{ color: 'red' }}>Phone number must be between 10 and 15 digits</p>}
          </Form.Item> */}
        <Form.Item
        label="Phone"
        name="phone_number"
        rules={[{ validator: validatePhoneNumber }]}
      >
        <PhoneInput
        className="form-control py-2 cus-input"
          placeholder="Enter phone number"
          value={value}
          onChange={setValue}
        />
      </Form.Item>

          {/* Phone */}
          {/* <Form.Item
          className=""
            label="Phone"
            name="phone_number"
            rules={[
              { required: true, message: "Please input the phone number!" },
              {
                pattern: new RegExp(
                  /^(\d{3}-\d{3}-\d{4}|\(\d{3}\) \d{3}-\d{4}|\d{10})$/
                ),
               
                message: "Please enter a valid phone number!",
              },
            ]}
          >
           <PhoneInput
        country={"us"} 
        enableSearch={true} 
        inputStyle={{ width: "100%" }}
        
      />
      
          </Form.Item> */}

          { Object.keys(selectedRow || {})?.length > 0 ?
          ''
          :
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="u_password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Please input the password!",
                  },
                ]}
              >
                <Input.Password autocomplete="new-password" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="u_confirm_password"
                label="Confirm Password"
                dependencies={["u_password"]}
                rules={[
                  {
                    required: true,
                    message: "Please confirm the password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("u_password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Passwords do not match!")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password autocomplete="new-password" />
              </Form.Item>
            </Col>
          </Row>
          
          }
        </Form>
        </Wrapper>
      </Modal>
    </>
  );
};

export default BrandModalForm;
