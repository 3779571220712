import { Button, Form, Modal, Select } from "antd";

const { Option } = Select;

const ExistingCreatePo = (props) => {
  const { data, show, closeModal, submitPo, selectedRows } = props;
  const [form] = Form.useForm();

  console.log(data, selectedRows, "data");

  return (
    <Modal
      title="Add To Existing Purchase Order"
      open={show}
      onCancel={closeModal}
      centered
      footer={[
        <Button key="close" type="dashed" size="small" onClick={closeModal}>
          Close
        </Button>,
        <Button
          key="close"
          type="primary"
          size="small"
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                const { po_id, vendor } = data?.find(
                  (d) => parseInt(d?.po_id) === parseInt(values?.selectedVendor)
                );

                submitPo({
                  po_id: po_id,
                  selectedPoItems: selectedRows,
                  selectedVendor: vendor,
                });
                closeModal();
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              });
          }}
        >
          Add
        </Button>,
      ]}
      width={500}
    >
      <Form form={form} name="basic" layout="vertical" autoComplete="off">
        <Form.Item
          label="Select Purchase Order"
          name="selectedVendor"
          rules={[
            { required: true, message: "Please select an Purchase Order!" },
          ]}
        >
          <Select>
            {data.map((option, index) => (
              <Option key={index} value={option?.po_id}>
                {option?.vendor_name +
                  " - " +
                  option.brand +
                  " - " +
                  option.reference +
                  " - PO# " +
                  option.status}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ExistingCreatePo;
