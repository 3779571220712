import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Switch,
  Select,
  InputNumber,
  Row,
  Col,
  message,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { MakeApiCall } from "../../../../../apis";
// import { MakeApiCall } from "../../../../apis";
import { Wrapper } from "./style";

import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { parsePhoneNumber } from 'libphonenumber-js';


const VendorModalForm = ({
  setVisible,
  visible,
  userData,
  onSubmit,
  selectedRow,
}) => {
  const [form] = Form.useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [poList, setPoList] = useState([]);
  const [poListLoading, setPoListLoading] = useState(true);

  const [value, setValue] = useState();

const validatePhoneNumber = (_, phoneValue) => {
  if (!phoneValue) {
    return Promise.reject(new Error("Please enter a phone number"));
  }
  if (!isValidPhoneNumber(phoneValue)) {
    return Promise.reject(new Error("Invalid phone number format"));
  }

  try {
    const phoneNumber = parsePhoneNumber(phoneValue);
    if (phoneNumber && (phoneNumber.nationalNumber.length < 10 || phoneNumber.nationalNumber.length > 15)) {
      return Promise.reject(new Error("Phone number must be between 10 and 15 digits"));
    }
  } catch {
    return Promise.reject(new Error("Invalid phone number"));
  }

  return Promise.resolve();
};



  useEffect(() => {
    if (Object.keys(selectedRow || {}).length > 0) {
      form.setFieldsValue(selectedRow);
    } else {
      form.resetFields();
    }
  }, [selectedRow]);

  
  // Function to handle modal close
  const handleCancel = () => {
    setVisible(false);
  };

  const CreateVendor = async (data) => {
    setSubmitLoading(true);
    try {
      const response = await MakeApiCall(
        `${
          Object.keys(selectedRow || {})?.length > 0
            ? `vendor/${selectedRow?.id}`
            : "vendor"
        }`,
        Object.keys(selectedRow || {})?.length > 0 ? "patch" : "post",
        data,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        form.resetFields();
        onSubmit();
        setSubmitLoading(false);
        message.destroy();
        message.success(response?.message);
      } else {
        message.destroy();
        setSubmitLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setSubmitLoading(false);
      message.destroy();
      message.error(error?.message);
    }
  };
  const GetPoList = async () => {
    setPoListLoading(true);
    try {
      const response = await MakeApiCall(
        `po-all-templates`,
        "GET",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setPoList(response?.data?.records || []);
        setPoListLoading(false);
      } else {
        message.destroy();
        setPoListLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setPoListLoading(false);
      message.destroy();
      message.error(error?.message);
    }
  };

  useEffect(() => {
    GetPoList();
    setPoListLoading(true);
    return () => {};
  }, []);

  // Function to handle form submit
  // const handleOk = () => {
  //   form
  //     .validateFields()
  //     .then((values) => {
  //       values.days_of_stock = String(values.days_of_stock); // Convert to string
  //     values.is_enabled = values.is_enabled ? 1 : 0; // Convert boolean to integer
  //       CreateVendor(values);
  //     })
  //     .catch((info) => {});
  // };
  // Function to handle form submit
  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const phoneNumber = parsePhoneNumber(values.phone_number);
  
        if (phoneNumber) {
          // Add phone details to values before submitting
          values.phone_code = phoneNumber.country;
          // values.country_code = phoneNumber.countryCallingCode;
        } else {
          values.phone_code = null;
          // values.country_code = null;
        }
        values.days_of_stock = String(values.days_of_stock); // Convert to string
        values.is_enabled = values.is_enabled ? 1 : 0; // Convert boolean to integer
        // Call the function with updated values
        CreateVendor(values);
      })
      .catch((info) => {
        console.log("Validation failed:", info);
      });
  };
  return (
    <>
      <Modal
      //  title={isEditMode ? "Edit Vendor" : "Add Vendor"}
       title={
        Object.keys(selectedRow || {})?.length > 0
          ? "Edit Vendor"
          : "Add Vendor"
      }
        visible={visible}
        onOk={handleOk}
        okButtonProps={{ loading: submitLoading }}
        onCancel={handleCancel}
      >
        <Wrapper>
        <Form
          form={form}
          layout="vertical"
          // onFinish={onFinish}
          initialValues={{
            status: true,
          }}
        >
          <Row gutter={16}>
            <Col span={12}>
              {/* Vendor Name */}
              <Form.Item
                label="Vendor Name"
                name="name"
                rules={[
                  { required: true, message: "Please input the vendor name!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              {/* Contact Name */}
              <Form.Item
                label="Contact Name"
                name="contact_name"
                rules={[
                  { required: true, message: "Please input the contact name!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              {/* Vendor Email */}
              <Form.Item
                label="Vendor Email"
                name="email"
                rules={[
                  { required: true, message: "Please input the vendor email!" },
                  { type: "email", message: "Please enter a valid email!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              {/* Phone No */}
              {/* <Form.Item
                label="Phone No"
                name="phone_no"
                rules={[
                              { required: true, message: "Please input the phone number!" },
                              {
                             
                                message: "Please enter a valid phone number!",
                              },
                            ]}
              >
                 <PhoneInput
                country={"us"} // Default country code
                enableSearch={true} // Enable country search
                inputStyle={{ width: "100%" }}
              />
              </Form.Item> */}
                  <Form.Item
        label="Phone"
        name="phone_number"
        rules={[{ validator: validatePhoneNumber }]}
      >
        <PhoneInput
        className="form-control py-2 cus-input"
          placeholder="Enter phone number"
          value={value}
          onChange={setValue}
        />
      </Form.Item>
            </Col>
            <Col span={12}>
              {/* Days of Stock */}
              <Form.Item
                label="Days of Stock"
                name="days_of_stock"
                rules={[
                  {
                    required: true,
                    message: "Please input the days of stock!",
                  },
                ]}
              >
                <InputNumber style={{ width: "100%" }} size="large" min={0} />
              </Form.Item>
            </Col>
            <Col span={12}>
              {/* Vendor Lead Time */}
              <Form.Item
                label="Vendor Lead Time"
                name="lead_time"
                rules={[
                  {
                    required: true,
                    message: "Please input the vendor lead time!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              {/* Outbound Lead Time */}
              <Form.Item
                label="Outbound Lead Time"
                name="outbound_lead_time"
                rules={[
                  {
                    required: true,
                    message: "Please input the outbound lead time!",
                  },
                  {
                    pattern: /^\d+$/,
                    message: "Please enter only number!",
                  },
                ]}
              >
                <Input
                  placeholder="Enter outbound lead time"
                  style={{ width: "100%" }}
                  type="number"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              {/* Payment Term */}
              <Form.Item
                label="Payment Term"
                name="payment_terms"
                rules={[
                  { required: true, message: "Please input the payment term!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              {/* Address */}
              <Form.Item
                label="Address"
                name="address"
                rules={[
                  { required: true, message: "Please input the address!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={20}>
              {/* Select PO Template */}
              <Form.Item
                label="Select PO Template"
                name="template"
                rules={[
                  { required: true, message: "Please select a PO template!" },
                ]}
              >
                <Select
                  style={{ borderRadius: "2px" }}
                  size="large"
                  placeholder="Select PO Template"
                  loading={poListLoading}
                  options={poList?.map((d) => ({
                    label: d?.name,
                    value: d?.t_id,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
           { Object.keys(selectedRow || {})?.length > 0
          ? 
          ""
          : 
          <Form.Item
                label="Status"
                name="is_enabled"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
          }
            </Col>
            <Col span={24}>
              {/* Vendor Notes */}
              <Form.Item label="Vendor Notes" name="notes">
                <TextArea rows={2} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        </Wrapper>
      </Modal>
    </>
  );
};

export default VendorModalForm;
