import React, { useEffect, useState } from "react";

import { MakeApiCall } from "../../../apis";

import { Link, useParams, useNavigate } from "react-router-dom";

// import AddComponent from "./addComponent";

import {
  Tooltip,
  Spin,
  message,
  Select,
  Empty,
  Popover,
  DatePicker,
  Input,
  Skeleton,
  Card,
  Button,
  Dropdown,
  Menu,
  Typography,
  Flex,
  Form,
  Row,
  Col,
  Table,
  Tag,
} from "antd";
// import ExpandableComponentUpdate from "./ExpandableComponentUpdate";
import moment from "moment";

import styled from "styled-components";
import { checkPropTypes } from "prop-types";
import dayjs from "dayjs";
import { Icon } from "@iconify/react/dist/iconify.js";
import ExpandableComponentUpdate from "./ExpandableComponentUpdate";
import Table2 from "../../../Components/tables/table2";
import { CFTooltip } from "../../../Components/tooltip";
import { PrepLogModal } from "./PrepLog";
import AddComponent from "./addComponent";
import { MainURL } from "../../../config";

const Wrapper = styled.div`
  .ant-spin-dot-item {
    background-color: #f7f7f7 !important;
  }
  .ant-spin-spinning {
    margin-right: 10px !important;
  }
  .ant-spin-dot {
    font-size: 17px !important;
  }
`;
const TableWrapper = styled.div`
  .rdt_TableBody {
    // height: calc(100vh - 450px);
    overflow-x: hidden;
    overflow-y: auto;
  }
  .overflowText {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 10px;
  }
`;

const statusOp = [
  {
    label: "-- Select --",
    value: "",
  },
  {
    label: "Draft",
    value: "draft",
  },
  {
    label: "Open",
    value: "open",
  },
  {
    label: "Received",
    value: "received",
  },
  {
    label: "Closed",
    value: "closed",
  },
];

const CreatePo = (props) => {
  const { userData, setUser } = props;

  const { po, replacementId, vendorId, status } = useParams();

  const URL = MainURL;
  const navigate = useNavigate();

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const [data, setData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const [infoData, setInfoData] = useState({});
  const [vendor, setVendor] = useState([]);
  const [poTemplate, setPoTemplate] = useState([]);
  const [comModal, setComModal] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [componentList, setComponentList] = useState([]);
  const [componentModal, setComponentModal] = useState(false);
  const [selectRows, setSelectRows] = useState([]);
  const [compoentList, setCompoentList] = useState([]);
  const [actionLoading, setActionLoading] = useState(false);
  const [totalData, setTotalData] = useState({
    units: 0,
    asinCount: 0,
    orderAsinUnit: 0,
    totalCases: 0,
    totalCost: 0,
  });

  const [prepLog, setPrepLog] = useState(false);
  const [prepLogList, setPrepLogList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [noData, setNoData] = useState(false);

  const [datagetStatus, setDatagetStatus] = useState(false);

  const [allData, setAllData] = useState({});

  const [totalPurchaseCostValue, setTotalPurchaseCostValue] = useState(0);
  const compoentListQuantity = (e, row) => {
    const compoentListSet = compoentList;
    const index = compoentListSet.findIndex((d) => d.id === row.id);
    if (index !== -1) {
      compoentListSet[index].quantity = e === "" ? "" : e;
      setCompoentList([...compoentListSet]);
    }
  };
  // console.log(infoData, 'infoData-----------')
  const GetEstimatedOnShelfDate = () => {
    if (infoData.estimated_on_shelf_date !== undefined) {
      var d = new Date(infoData.estimated_on_shelf_date);
      const addedDays = parseInt(
        parseInt(
          vendor.filter(
            (option) =>
              option.value ===
              (infoData.vendor !== undefined && parseInt(infoData.vendor))
          )?.[0]?.v_lead_time || 0
        ) +
        parseInt(
          vendor.filter(
            (option) =>
              option.value ===
              (infoData.vendor !== undefined && parseInt(infoData.vendor))
          )?.[0]?.v_outbound_lead_time || 0
        )
      );
      d.setDate(d.getDate() + addedDays);
      return moment(d).format("YYYY-MM-DD");
    }
    return null;
  };

  const [loading, setLoading] = useState(true);
  const getTableRecord = async () => {
    setDatagetStatus(false);
    setLoading(true);
    setNoData(false);

    const replenishment_qtyOld = userData?.replenishment;

    try {
      const res = await MakeApiCall(
        `view-purchase-order/${po}`,
        "patch",
        null,
        true,
        {},
        userData?.user?.auth_token
      );

      setLoading(false);
      setNoData(false);
      setDataLoading(false);
      setDatagetStatus(true);

      if (res.status === true) {
        setTotalPurchaseCostValue(res?.data?.poModel?.total_asin_cost || 0);
        setAllData(res.data);
        if (res.data === null) return;
        setDataLoading(false);
        setCompoentList(res.data.newComModels);
        if (window.location.pathname.split("/")[2].split("=").length !== 2) {
          setInfoData({
            ...res.data.poModel,
            total_Discount: res.data.poModel.discount,
          });
        }
        const tableDataSet = [];
        res.data.model.forEach((e) => {
          let sum = 0;
          e.componentData.length !== 0 &&
            e.componentData.forEach((c) => {
              sum += c.quantity * parseFloat(c.componentData.unit_cost);
            });

          // console.log(replenishmentQty(e), "e.replenishment_qty");

          if (status === "create" && replenishment_qtyOld) {
            tableDataSet.push({
              ...e,
              purchase_Cost:
                status === "create"
                  ? "0.00"
                  : parseFloat(e.replenishment_qty || 0) * sum,
              fbaAllListing: {
                ...e.productReport,
                asin_cost: sum,
                replenishment_qty: replenishmentQty(e),
                replenishment_OLD:
                  replenishment_qtyOld[e.fald_id].replenishment_QTY_ori || 0,
              },
              replenishment_qty:
                replenishment_qtyOld[e.fald_id].replenishment_QTY_ori || 0,
            });
          } else {
            tableDataSet.push({
              ...e,
              purchase_Cost:
                status === "create"
                  ? "0.00"
                  : parseFloat(e.replenishment_qty) * sum,
              fbaAllListing: {
                ...e.productReport,
                asin_cost: sum,
                replenishment_qty: replenishmentQty(e),
                replenishment_OLD:
                  replenishment_qtyOld[e.fald_id].replenishment_QTY_ori || 0,
              },
            });
          }
        });

        setData(res.data);
        setTableData(tableDataSet);
        GetVendor();
      } else if (res.status === false) {
        setDataLoading(false);
        setNoData(true);
        setInfoData({});
        setData([]);
      }
    } catch (error) {
      GetVendor();
    }
  };
  const [updateData, setUpdateData] = useState(false);
  useEffect(() => {
    if (tableData.length !== 0 && updateData === false) {
      setUpdateData(true);

      tableData.forEach((d) => {
        // console.log(replenishmentQty(d), "here");
        SetData(replenishmentQty(d), d);
      });
    }
  }, [tableData]);

  const SubmitPo = async (PO_id) => {
    console.log(PO_id, "PO_id");
    const tableIndex = tableData.filter((d) => d.replenishment_qty < 0);
    const compoentListIndex =
      compoentList.length !== 0 &&
      compoentList.filter((d) =>
        !parseInt(d.quantity) ? parseInt(d.moq) < 1 : parseInt(d.quantity) < 1
      );
    if (tableIndex.length > 0) {
      alert("Please enter a valid quantity!");
    } else if (compoentListIndex.length > 0) {
      alert("Please enter a valid quantity!");
    } else {
      let AsinGet = {};
      let hiddencost = {};
      let caseOrderQty = {};
      let discountComponent = [];
      let order_qty = {};
      let replenishment = {};
      let selection = [];
      let hiddencasequty = [];
      let order_qty_extra = {};
      let com_id_selection = {};
      let discountList = {};
      let poDiscount = [];
      tableData.forEach((e) => {
        replenishment = Object.assign(replenishment, {
          [e.id]: parseInt(e.replenishment_qty),
        });
        e.componentData.length !== 0 &&
          e.componentData.forEach((c) => {
            AsinGet = Object.assign(AsinGet, { [c.id]: e.asin });
            hiddencost = Object.assign(hiddencost, {
              [c.components_id]:
                parseInt(c.order_quantity) *
                parseInt(c.componentData.unit_cost),
            });
            caseOrderQty = Object.assign(caseOrderQty, {
              [c.id]:
                parseInt(c.order_quantity) /
                parseInt(c.componentData.case_quantity),
            });
            order_qty = Object.assign(order_qty, {
              [c.id]: c.order_quantity,
            });
            order_qty_extra = Object.assign(order_qty_extra, {
              [c.id]: c.order_quantity,
            });
            discountList = Object.assign(discountList, {
              [c.id]: parseFloat(c.discount || 0),
            });
            com_id_selection = Object.assign(com_id_selection, {
              [c.id]: c["componentData"].id,
            });
            hiddencasequty.push({
              [c.id]: c["componentData"].case_quantity,
            });
            selection.push(c.id);
            if (infoData.discount_type !== "-- Select --") {
              if (infoData.discount_type === "percentage") {
                poDiscount.push(
                  parseFloat(c.discount || c.componentData.discount || 0)
                );
              } else {
                poDiscount.push(
                  parseFloat(c.discount || c.componentData.discount || 0)
                );
              }
            }
          });
      });
      let new_component_order_qty = [];
      let new_component = [];
      let totalDiscountComponent = [];
      compoentList.forEach((e) => {
        new_component_order_qty.push(
          !parseInt(e.quantity) ? parseInt(e.moq) : parseInt(e.quantity)
        );
        new_component.push(e.id);
        hiddencost = Object.assign(hiddencost, {
          [e.id]:
            parseInt(
              parseInt(e.quantity) === 0
                ? parseInt(e.moq)
                : parseInt(e.quantity)
            ) * parseInt(e.unit_cost),
        });
        totalDiscountComponent.push(parseFloat(e.discount));
        discountComponent.push(parseFloat(e.discount));
      });

      const makeData = {
        vendor: infoData?.vendor,
        saveType: "edit",
        discount: discountList,
        discount_type:
          infoData.discount_type === "-- Select --" || !infoData.discount_type
            ? null
            : infoData.discount_type,
        po_discount: infoData.total_Discount || null,
        new_component_discount: discountComponent,
        expected_date: infoData.expected_date.format("YYYY-MM-DD"),
        created_date: infoData.created_date.format("YYYY-MM-DD"),
        estimated_on_shelf_date: GetEstimatedOnShelfDate(),
        actual_inbound_delivery_date:
          infoData.actual_inbound_delivery_date.format("YYYY-MM-DD"),
        actual_on_shelf_date:
          infoData.actual_on_shelf_date.format("YYYY-MM-DD"),
        status: infoData.status,
        PurchaseOrders: {
          reference: infoData.reference,
          po_template: infoData.po_template,
        },
        note: infoData.note,
        shipping_cost: infoData.shipping_cost,
        order_qty: order_qty,
        order_qty_extra: order_qty_extra,
        selection: selection,
        com_id_selection: com_id_selection,
        hiddencost: hiddencost,
        asin: AsinGet,
        case_order_qty: caseOrderQty,
        replenishmentqty: replenishment,
        total_asin_unit: TotalUnits("asinCount"),
        new_component_order_qty: new_component_order_qty,
        new_component: new_component,
        total_asin_cost: totalPurchaseCostValue?.split(",")?.join(""),
      };
      // console.log(makeData && Object.entries(makeData).length !== 0, 'makeData && Object.entries(makeData).length !== 0')

      if (makeData && Object.entries(makeData).length !== 0) {
        const res = await MakeApiCall(
          `edit-purchase-order/${PO_id === undefined
            ? window.location.pathname.split("/").length === 4
              ? window.location.pathname.split("/")[3]
              : po
            : PO_id
          }`,
          "patch",
          makeData,
          true,
          {},
          userData?.user?.auth_token
        );

        if (res.status === true) {
          setActionLoading(false);

          setTimeout(() => {
            if (PO_id == undefined) {
              navigate(
                `/po-list/Approve/${window.location.pathname.split("/").length === 4
                  ? window.location.pathname.split("/")[3]
                  : po
                }`
              );
              // navigate(`/purchase/po-list/${PO_id}`);
              window.location.reload();
            } else {
              navigate(`/po-list/${PO_id}`);
              window.location.reload();
            }
          }, 200);
        } else {
          setActionLoading(false);
          if (
            Object.entries(res.validation_error)[0][1]?.[0]?.includes(
              "has already been taken"
            )
          ) {
            EditData(parseInt(infoData.reference) + 1, "reference");

            message.destroy();
            message.warning(Object.entries(res.validation_error)[0][1]);

            message.info("Please try again");
          }

          message.destroy();
          message.warning(Object.entries(res.validation_error)[0][1]);
        }
      }
    }
  };

  const UpdatePo = (PO_id) => {
    tableData.forEach((d) => {
      SetData(replenishmentQty(d), d);
    });
    const tableIndex = tableData.filter((d) => d.replenishment_qty < 0);
    const compoentListIndex =
      compoentList.length !== 0 &&
      compoentList.findIndex((d) =>
        !d.quantity ? parseInt(d.moq) < 1 : d.quantity < 1
      );
    if (tableIndex.length > 0) {
      alert("Please enter a valid quantity!");
    } else if (compoentList.length !== 0 && compoentListIndex !== -1) {
      alert("Please enter a valid quantity!");
    }
  };

  const ClonePO = async () => {
    const po_id = po;
    try {
      const res = await MakeApiCall(
        `clone-purchase-order/${po_id}`,
        "patch",
        null,
        true,
        {},
        userData?.user?.auth_token
      );

      if (res.status) {
        setTimeout(() => {
          navigate(`/po-list/${res.data.po_id}`);
          window.location.reload();
        }, 200);
        message.destroy();
        message.success(res.message);
      } else {
        message.destroy();
        message.warning(res.message);
      }
    } catch (error) {
      message.destroy();
      message.error("something went wrong");
    }
  };

  const DeletePo = async () => {
    const po_id = po;

    try {
      const res = await MakeApiCall(
        `bulk-action`,
        "post",
        {
          selectedPoItems: [po_id],
          status: "Delete",
        },
        true,
        {},
        userData?.user?.auth_token
      );

      if (res.status === true) {
        setDataLoading(false);
        setTimeout(() => {
          navigate(`/po-list`);
        }, 200);
        message.success(res.message);
      } else if (res.status === false) {
        message.warning(res.message);
      }
    } catch (error) {
      message.destroy();
      message.warning("something went wrong");
    }
  };

  const GetPoTemplate = async () => {
    try {
      const res = await MakeApiCall(
        `po-all-templates`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );

      if (res.status === true) {
        setPoTemplate(
          res.data.records.map((item) => {
            return {
              label: item.name,
              value: item.t_id,
              outbound_lead_time: item.outbound_lead_time,
            };
          })
        );
      }
    } catch (error) {
      message.destroy();
      message.warning("something went wrong");
    }
  };

  const getVendorData = async () => {
    try {
      const res = await MakeApiCall(
        `vendors?page=all`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );

      if (res.status) {
        setVendor(
          res.data.records.map((item) => {
            return {
              label: item.name,
              value: item.id,
              v_lead_time: item.lead_time,
              v_outbound_lead_time: item.outbound_lead_time,
            };
          })
        );
        const index = res.data.records.findIndex(
          (d) => parseInt(d.id) === parseInt(vendorId)
        );

        if (status === "create") {
          const created = dayjs();
          console.log(created, res.data.records, index, "created");

          const expected = created.add(
            parseInt(res.data.records[index].lead_time || 0),
            "day"
          );

          const infoDataSet = infoData;
          infoDataSet.expected_date = expected;
          infoData.actual_inbound_delivery_date = expected;
          infoData.actual_on_shelf_date = expected;
          infoDataSet.created_date = created;
          infoDataSet.shipment_date = created;
          infoDataSet.status = "draft";
          infoDataSet.reference =
            parseInt(userData?.user?.user_data?.po_reference) + 1 || 0;
          infoDataSet.po_template = "";
          infoDataSet.note = "";
          infoDataSet.vendor = vendorId;
          infoDataSet.estimated_on_shelf_date = new Date();
          setInfoData({ ...infoDataSet });
        }
      }
    } catch (error) {
      console.log(error, "error");

      message.destroy();
      message.warning("something went wrong");
    }
  };

  const GetVendor = async () => {
    await GetPoTemplate();
    await getVendorData();
  };

  const getList = async () => {
    try {
      const res = await MakeApiCall(
        `brands?page=all`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );

      if (res.status) {
        setBrandList(
          res.data.records.map((item) => {
            return { label: item.b_name, value: item.b_id };
          })
        );
      }
    } catch (error) {
      message.destroy();
      message.warning("something went wrong");
    }
  };

  const ORDERQUANTITYCAL = (row) => {
    if (row.order_quantity !== undefined) {
      return row.order_quantity;
    }
    var oqty = parseInt(data.replenishment_qty) * parseInt(row.quantity);
    var orderQty =
      Math.ceil(oqty / parseInt(row.componentData.case_quantity)) *
      parseInt(row.componentData.case_quantity);
    return orderQty;
  };

  const ORDERQUANTITYCALUPDATE = (row) => {
    if (row.order_quantity && typeof row.order_quantity === "number") {
      return row.order_quantity;
    }
    var orderQty = row.comModels.quantity;
    return orderQty;
  };

  const getUpdatePurchaseCost = (item) => {
    const unit_cost = parseFloat(item.componentData?.unit_cost || 0);
    const quantity = parseFloat(item.componentData?.quantity || 0);
    const costs = quantity * unit_cost;

    return infoData?.discount_type === "percentage"
      ? parseFloat(
        parseFloat(
          parseFloat(item.discount || item?.componentData?.discount || 0) ===
            0
            ? costs
            : (costs *
              parseFloat(
                item.discount || item?.componentData?.discount || 0
              )) /
            100
        ).toFixed(2)
      )
      : parseFloat(
        parseFloat(
          (parseFloat(item.componentData.unit_cost || 0).toFixed(2) -
            (infoData?.discount_type === "percentage"
              ? parseFloat(
                (parseFloat(item.componentData.unit_cost || 0).toFixed(2) *
                  parseFloat(
                    item.discount || item?.componentData?.discount || 0
                  ).toFixed(2)) /
                100
              ).toFixed(2)
              : parseFloat(0).toFixed(2))) *
          parseFloat(ORDERQUANTITYCALUPDATE(item)).toFixed(2)
        )
      ).toFixed(2);
  };
  const ExtraComponent = (item) => {
    return item !== null
      ? item.quantity === ""
        ? ""
        : (parseInt(item.quantity) !== 0
          ? parseInt(item.quantity)
          : parseFloat(item.moq || 0).toFixed(2)) ||
        parseFloat(item.moq || 0).toFixed(2)
      : 0;
  };
  const getExtraUpdatePurchaseCost = (item) => {
    return parseFloat(
      parseFloat(
        (parseFloat(item.unit_cost || 0).toFixed(2) -
          (infoData?.discount_type === "percentage"
            ? parseFloat(
              (parseFloat(item.unit_cost || 0).toFixed(2) *
                parseFloat(
                  item.discount || item?.componentData?.discount || 0
                ).toFixed(2)) /
              100
            ).toFixed(2)
            : parseFloat(
              item.discount || item?.componentData?.discount || 0
            ).toFixed(2))) *
        parseFloat(ExtraComponent(item)).toFixed(2)
      )
    ).toFixed(2);
  };

  const UpdateTotalData = (tableData_, compoentList_) => {
    if (tableData_.length !== 0) {
      const asinUnit = [];
      const totalCost = [];
      const totalUnit = [];
      const totalCase = [];
      const disCount = [];
      tableData_.forEach((e) => {
        asinUnit.push(parseInt(e.replenishment_qty));
        e.componentData.forEach((c) => {
          totalCost.push(
            getUpdatePurchaseCost(c) < 0 ? 0 : getUpdatePurchaseCost(c)
          );

          // totalCost.push(parseFloat(purchaseCost(c)).toFixed(2))
          if (infoData?.discount_type === "percentage") {
            disCount.push(
              ((parseFloat(c.componentData.unit_cost).toFixed(4) *
                parseFloat(
                  c.discount || c?.componentData?.discount || 0
                ).toFixed(4)) /
                100) *
              parseInt(c?.order_quantity || c?.componentData?.quantity)
            );
          } else if ("price" === infoData?.discount_type) {
            disCount.push(
              parseFloat(c.discount || c?.componentData?.discount || 0).toFixed(
                4
              )
            );
          }
          // disCount.push(
          //   infoData?.discount_type === "percentage"
          //     ? parseFloat(
          //         (parseFloat(c.componentData.unit_cost || 0).toFixed(2) *
          //           parseFloat(
          //             c.discount || c?.componentData?.discount || 0
          //           ).toFixed(2)) /
          //           100
          //       ).toFixed(2)
          //     : parseFloat(
          //         c.discount || c?.componentData?.discount || 0
          //       ).toFixed(2)
          // );
          totalUnit.push(
            parseInt(
              window.location.pathname.split("/")[2].split("=").length === 2
                ? c.order_quantity
                : c.componentData.quantity
              // : c.componentData.case_order_quantity *
              //     c.componentData.case_quantity || 0
            )
          );

          totalCase.push(
            parseInt(
              c[
                window.location.pathname.split("/")[2].split("=").length === 2
                  ? "componentData"
                  : "componentData"
              ].case_order_quantity
            )
          );
        });
      });
      const totalDataSet = totalData;
      totalDataSet.totalCases = totalCase.reduce(
        (a, b) => parseFloat(a) + parseFloat(b),
        0
      );
      totalDataSet.orderAsinUnit = asinUnit.reduce((a, b) => a + b, 0);
      totalDataSet.asinCount = asinUnit.length;
      totalDataSet.totalCost = totalCost.reduce(
        (a, b) => parseFloat(a) + parseFloat(b),
        0
      );
      const componentUnits = [];
      compoentList_.forEach((e) => {
        totalCost.push(
          parseFloat(getExtraUpdatePurchaseCost(e)) < 0
            ? 0
            : parseFloat(getExtraUpdatePurchaseCost(e))
        );
        if (infoData?.discount_type === "percentage") {
          disCount.push(
            ((parseFloat(e.unit_cost || 0).toFixed(4) *
              parseFloat(e?.discount || 0).toFixed(4)) /
              100) *
            parseInt(
              e !== null
                ? e.quantity === ""
                  ? ""
                  : (parseInt(e.quantity) !== 0
                    ? parseInt(e.quantity)
                    : parseFloat(e.moq || 0).toFixed(2)) ||
                  parseFloat(e.moq || 0).toFixed(2)
                : 0
            )
          );
        } else if ("price" === infoData?.discount_type) {
          disCount.push(parseFloat(e?.discount || 0).toFixed(4));
        }
        // disCount.push(
        //   infoData?.discount_type === "percentage"
        //     ? parseFloat(
        //         (parseFloat(e.unit_cost || 0).toFixed(2) *
        //           parseFloat(e.discount || e?.discount || 0).toFixed(2)) /
        //           100
        //       ).toFixed(2)
        //     : parseFloat(e?.discount || 0).toFixed(2)
        // );
        if (e.quantity !== undefined) {
          componentUnits.push(parseInt(e.quantity));
        }
      });
      // const totalDataSet = totalData;
      let unit = componentUnits.reduce(
        (a, b) => parseFloat(a) + parseFloat(b),
        0
      );
      let table = totalUnit.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
      totalDataSet.units = table + unit;
      totalDataSet.disCount = disCount.reduce(
        (a, b) => parseFloat(a) + parseFloat(b),
        0
      );

      setTotalData({ ...totalDataSet });
    }
  };
  useEffect(() => {
    UpdateTotalData(tableData, compoentList);
  }, [tableData, compoentList]);

  useEffect(() => {
    getTableRecord();

    setDataLoading(true);
    // setInfoDataloading(true);
    getList();
    return () => { };
  }, []);
  const EditData = (value, type) => {
    if (type === "reference") {
      setUser({
        ...userData,
        user: {
          ...userData.user,
          user_data: {
            ...userData.user.user_data,
            po_reference: value,
          },
        },
      });
    }
    const infoData_ = { ...infoData };
    infoData_[type] = value;
    infoData_.saveType = "edit";
    setInfoData(infoData_);
  };

  const [statusColor, setStatusColor] = useState(false);

  const SetData = (value, row) => {
    let tableDataSet = tableData;
    const index = tableDataSet.findIndex((d) => d.id === row.id);
    const replenishmentQty = parseFloat(value || 0);

    tableDataSet[index].purchase_Cost =
      value === ""
        ? 0.0
        : (parseFloat(value) * parseFloat(row.fbaAllListing.asin_cost)).toFixed(
          2
        );
    row.componentData.length !== 0 &&
      row.componentData.forEach((_, i) => {
        var oqty = parseInt(value) * parseInt(_.quantity);
        var orderQty =
          Math.ceil(oqty / parseInt(_.componentData.case_quantity)) *
          parseInt(_.componentData.case_quantity);
        tableDataSet[index].componentData[i].purchase_Cost =
          value === ""
            ? 0.0
            : (
              parseFloat(value) * parseFloat(row.fbaAllListing.asin_cost)
            ).toFixed(2);
        tableDataSet[index].componentData[i][
          window.location.pathname.split("/")[2].split("=").length === 2
            ? "componentData"
            : "comModels"
        ].case_order_quantity =
          value === ""
            ? 0
            : parseFloat(value) === 0
              ? 0
              : orderQty === 0
                ? 1
                : orderQty;
        tableDataSet[index].componentData[i].order_quantity = orderQty;
      });
    const lic_quantity = parseInt(row.componentData[0].quantity);
    const order_quantity = parseInt(row.componentData[0].order_quantity);

    if (statusColor === false) {
      tableDataSet[index].replenishment_qty = order_quantity / lic_quantity;
    } else {
      tableDataSet[index].replenishment_qty = replenishmentQty;
    }

    setTableData([...tableDataSet]);
    MakeApiCall(
      `ajax-save-qty`,
      "post",
      {
        id: row.id,
        qty:
          value !== ""
            ? value
            : (
              parseFloat(value) * parseFloat(row.fbaAllListing.asin_cost)
            ).toFixed(2),
      },
      true,
      {},
      userData?.user?.auth_token
    );
  };

  const updateQuantity = (e, row) => {
    const prepLogListSet = prepLogList;
    const index = prepLogListSet.findIndex((d) => d.fald_id === row.fald_id);
    if (index !== -1) {
      prepLogListSet[index].quantityUpdate = e;
      setPrepLogList([...prepLogListSet]);
    }
  };

  const submitAddComponent = () => {
    const compoentListSet = compoentList;
    selectRows.forEach((e) => {
      const index = compoentList.findIndex(
        (d) => parseInt(d.id) === parseInt(e.id)
      );
      if (index === -1) {
        compoentListSet.push({ ...e, order_quantity: 0 });
      }
    });
    setComponentModal(false);
    setCompoentList([...compoentListSet]);
  };

  const replenishmentQty = (row) => {
    try {
      const moq = parseFloat(row.fbaAllListing.moq || 0);
      const replenishmentQty = parseFloat(row.replenishment_qty || 0);
      const replenishment_qtyEdit = userData?.replenishmentEdit;

      if (replenishment_qtyEdit?.[row.fald_id]?.replenishment_QTY !== null) {
        if (moq > replenishment_qtyEdit?.[row.fald_id]?.replenishment_QTY) {
          return moq;
        }
        if (
          // parseInt(row.componentData[0].quantity) > 1 &&
          replenishmentQty !== 0
        ) {
          return replenishmentQty;
        }
        return parseFloat(
          replenishment_qtyEdit?.[row.fald_id]?.replenishment_QTY
        ).toFixed(2);
      }
      if (row.replenishment_qty === "0") {
        if (row.salesSum && row.salesSum.replenishment_QTY) {
          if (moq > parseInt(row.salesSum.replenishment_QTY)) {
            return moq;
          }
          return parseInt(row.salesSum.replenishment_QTY);
        }
        return moq || 0;
      } else {
        if (moq > replenishmentQty) {
          return moq;
        }
        return replenishmentQty;
      }
    } catch (error) {
      return 0;
    }
  };

  const numberWithCommas = (x) => {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return x;
  };

  const DeletePoRow = async (row) => {
    if (window.confirm("Are You Sure Delete")) {
      const res = await MakeApiCall(
        `ajax-po-item-delete/${row.id}`,
        "delete",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (res.status === true) {
        let tableDataSet = tableData;
        tableDataSet = tableDataSet.filter(
          (d) => parseInt(d.id) !== parseInt(row.id)
        );
        setTableData([...tableDataSet]);
      } else {
        message.destroy();
        message.warning(res.message);
      }
    }
    // row
  };

  console.log("tableData", tableData)

  const columns = [
    {
      title: <span className="table-heading">#</span>,
      dataIndex: "reference",
      key: "reference",
      sorter: true,
      render: (_, __, index) => <span className="table-row">{index + 1}</span>,
    },
    // {
    //   title: <span className="table-heading">ASIN Title</span>,
    //   dataIndex: ["productReport", "item_name"],
    //   key: "item_name",
    //   sorter: true,
    //   width: 300,
    //   render: (text, row) => (
    //     <span>
    //       <CFTooltip style={{ textAlign: "left" }} row={2} rule>
    //         {text || "-"}
    //       </CFTooltip>
    //     </span>
    //   ),
    // },
    {
      title: <span className="table-heading">ASIN Title</span>,
      dataIndex: ["productReport", "item_name"],
      key: "item_name",
      sorter: true,
      width: 300,
      render: (text, row) => {
        const isReplenishable = row?.productReport?.is_replenishable;
        const isActive = row?.productReport?.active;
    
        // Define styles based on conditions
        const conditions = {
          "0_0": {
            message: "Product is disabled and non-replenishable!",
            borderColor: "rgb(169 1 1 / 66%)",
            backgroundColor: "rgb(244 236 236)",
          },
          "0_1": {
            message: "Product is non-replenishable!",
            borderColor: "rgb(169 1 1 / 66%)",
            backgroundColor: "rgb(244 236 236)",
          },
          "1_0": {
            message: "Product is disabled!",
            borderColor: "rgb(169 1 1 / 66%)",
            backgroundColor: "rgb(244 236 236)",
          },
          "1_1": {
            borderColor: "#0179b0a8",
            backgroundColor: "#ecf1f4",
          },
        };
    
        const { message, borderColor, backgroundColor } =
          conditions[`${isReplenishable}_${isActive}`] || {};
    
        return (
          <div
            className="d-flex box-view-columns"
            style={{
              padding: "10px",
              borderRadius: "10px",
              border: `1px solid ${borderColor || "#0179b0a8"}`,
              background: backgroundColor || "#ecf1f4",
            }}
          >
            <Tooltip title={message} placement="top" color={message ? "#ff4d4f" : undefined}>
              <span>{text || "-"}</span>
            </Tooltip>
          </div>
        );
      },
    },
    

    {
      title: <span className="table-heading">ASIN</span>,
      dataIndex: "asin",
      key: "asin",
      sorter: true,
      render: (asin) => (
        <Tag
          color="volcano"
          bordered={false}
          style={{ cursor: "pointer" }}
          onClick={() => window.open(`https://www.amazon.com/dp/${asin}`)}
          className="table-row"
        >
          {asin}
        </Tag>
      ),
    },
    {
      title: <span className="table-heading">MSKU</span>,
      dataIndex: "sku",
      key: "sku",
      sorter: true,
      render: (sku) => <span className="table-row">{sku || "-"}</span>,
    },
    {
      title: <span className="table-heading">FNSKU</span>,
      dataIndex: ["fbaAllListing", "fnsku"],
      key: "fnsku",
      sorter: true,
      render: (fnsku) => (
        <span className="table-row">
          <Tag color="purple" bordered={false}>
            {fnsku || "(not set)"}
          </Tag>
        </span>
      ),
    },
    {
      title: <span className="table-heading">MOQ</span>,
      dataIndex: ["fbaAllListing", "moq"],
      key: "moq",
      sorter: true,
      width: 30,
      render: (moq) => <b className="table-row">{moq || 0.0}</b>,
    },
    {
      title: <span className="table-heading">Replenishment Qty</span>,
      dataIndex: ["fbaAllListing", "replenishment_OLD"],
      key: "replenishmentQty",
      sorter: true,
      width: 150,
      render: (replenishment_OLD, row) => (
        <span className="table-row ">
          {/* {console.log(replenishment_OLD, 'replenishment_OLD------')} */}
          {/* {console.log(row?.replenishment_qty, 'row--------')} */}
          <div style={{ position: "relative" }}>
            {status === "create" && (
              <span style={{ fontSize: "12px" }}>
                Original Repl. Qty:{" "}
                {parseFloat(replenishment_OLD || 0).toFixed(0)}
              </span>
            )}
            <div style={{ display: "flex", alignItems: "center" }}>
              {console.log(
                parseFloat(replenishmentQty(row)).toFixed(0) || 0,
                "replenishmentQty(row)213"
              )}
              <Input
                addonBefore={
                  <Tooltip
                    placement="left"
                    // title={`Replenishment quantity changed to ${parseFloat(
                    //   replenishmentQty(row)
                    // ).toFixed(0)} from ${parseFloat(
                    //   replenishment_OLD || 0
                    // ).toFixed(0)}`}
                    title={`Replenishment quantity changed to 
                      ${parseFloat(replenishmentQty(row)).toFixed(0)} 
                      from ${parseFloat(
                      row?.fbaAllListing?.replenishment_OLD
                    ).toFixed(0) || 0
                      }
                      ${parseInt(row.componentData[0].quantity) > 1
                        ? `due to case quantity`
                        : `due to MOQ`
                      }  of ${parseInt(row.componentData[0].quantity) > 1
                        ? parseInt(
                          row?.componentData[0]?.componentData?.case_quantity
                        )
                        : row.fbaAllListing.moq || 0
                      }.`}
                  >
                    <Icon
                      icon="material-symbols:info"
                      width={20}
                      style={{
                        color:
                          statusColor === false
                            ? "#555555"
                            : replenishmentQty(row) === 0
                              ? "red"
                              : "#c37c00",
                      }}
                    />
                  </Tooltip>
                }
                type="number"
                value={parseFloat(replenishmentQty(row)).toFixed(0) || 0}
                style={{
                  padding: "0px 30px",
                  height: "38px",
                  width: "200px",
                  margin: "10px 0px",
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") e.target.blur();
                }}
                disabled={status !== "create"}
                // id={`${row.id}___input_edit_page`}
                onChange={(e) => {
                  if (
                    parseInt(e.target.value) <
                    parseInt(row.fbaAllListing.moq || 0)
                  ) {
                    return alert("Replenishment qty is smaller than MOQ");
                  }
                  setStatusColor(true);
                  SetData(e.target.value, row);
                }}
              />
            </div>
          </div>
        </span>
      ),
    },
    {
      title: <span className="table-heading">ASIN Cost</span>,
      dataIndex: ["fbaAllListing", "asin_cost"],
      key: "asin_cost",
      sorter: true,
      render: (asin_cost) => (
        <span className="table-row">
          {"$" + parseFloat(asin_cost || 0).toFixed(2)}
        </span>
      ),
    },
    {
      title: <span className="table-heading">Purchase Cost</span>,
      dataIndex: "purchase_Cost",
      key: "purchase_Cost",
      sorter: true,
      render: (_, row) => (
        <span className="table-row">
          {`$${numberWithCommas(
            parseFloat(
              parseFloat(replenishmentQty(row) || 0) *
              parseFloat(row.fbaAllListing.asin_cost || 0)
            ).toFixed(2)
          )}`}
        </span>
      ),
    },
    {
      title: "Action",
      render: (item) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "45px",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            DeletePoRow(item);
          }}
        >
          <Icon icon="tabler:trash-filled" width={20} />
        </div>
      ),
    },
  ];
  useEffect(() => {
    if (
      infoData.total_Discount &&
      infoData.total_Discount !== undefined &&
      infoData.total_Discount !== 0 &&
      infoData.total_Discount !== null
    ) {
      setCompoentList(
        compoentList.map((d) => {
          return { ...d, discount: infoData.total_Discount };
        })
      );
      tableData.forEach((_, index) => {
        const tableDataSet = tableData;
        tableData[index].componentData.forEach((item, comIndex) => {
          tableDataSet[index].componentData[comIndex].discount =
            infoData.total_Discount;
          tableDataSet[index].componentData[comIndex].componentData.discount =
            infoData.total_Discount;
          setTableData([...tableDataSet]);
        });
      });
    }
  }, [infoData]);
  const CASEORDERQUANTITY = (row, data) => {
    return ~~(
      parseInt(row.order_quantity) / parseInt(row.componentData.case_quantity)
    );
  };

  const TotalUnits = (type) => {
    const asinUnit = [];
    const totalCost = [];
    const totalUnit = [];
    const totalCase = [];
    const disCount = [];
    tableData.forEach((e) => {
      asinUnit.push(parseInt(replenishmentQty(e)));
      e.componentData.forEach((c) => {
        totalCost.push(
          getUpdatePurchaseCost(c) < 0 ? 0 : getUpdatePurchaseCost(c)
        );
        if (infoData?.discount_type === "percentage") {
          disCount.push(
            ((parseFloat(c.componentData.unit_cost).toFixed(4) *
              parseFloat(c.discount || c?.componentData?.discount || 0).toFixed(
                4
              )) /
              100) *
            parseInt(c?.order_quantity || c?.componentData?.quantity)
          );
        } else if ("price" === infoData?.discount_type) {
          disCount.push(
            parseFloat(c.discount || c?.componentData?.discount || 0).toFixed(4)
          );
        }

        totalUnit.push(parseInt(c.comModels.case_order_quantity));
        totalCase.push(parseInt(CASEORDERQUANTITY(c, e)));
      });
    });
    let table = totalUnit.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    const componentUnits = [];
    compoentList.forEach((e) => {
      totalCost.push(
        parseFloat(getExtraUpdatePurchaseCost(e)) < 0
          ? 0
          : parseFloat(getExtraUpdatePurchaseCost(e))
      );
      if (infoData?.discount_type === "percentage") {
        disCount.push(
          ((parseFloat(e.unit_cost || 0).toFixed(4) *
            parseFloat(e?.discount || 0).toFixed(4)) /
            100) *
          parseInt(
            e !== null
              ? e.quantity === ""
                ? ""
                : (parseInt(e.quantity) !== 0
                  ? parseInt(e.quantity)
                  : parseFloat(e.moq || 0).toFixed(2)) ||
                parseFloat(e.moq || 0).toFixed(2)
              : 0
          )
        );
      } else if ("price" === infoData?.discount_type) {
        disCount.push(
          parseFloat(e?.discount || 0).toFixed(4) *
          parseInt(
            e !== null
              ? e.quantity === ""
                ? ""
                : (parseInt(e.quantity) !== 0
                  ? parseInt(e.quantity)
                  : parseFloat(e.moq || 0).toFixed(2)) ||
                parseFloat(e.moq || 0).toFixed(2)
              : 0
          )
        );
      }
      // disCount.push(
      //   infoData?.discount_type === "percentage"
      //     ? parseFloat(
      //         (parseFloat(e.unit_cost || 0).toFixed(2) *
      //           parseFloat(e.discount || e?.discount || 0).toFixed(2)) /
      //           100
      //       ).toFixed(2)
      //     : parseFloat(e?.discount || 0).toFixed(2)
      // );
      if (e.quantity !== undefined) {
        componentUnits.push(parseInt(e.quantity));
      }
    });
    let unit = componentUnits.reduce(
      (a, b) => parseFloat(a) + parseFloat(b),
      0
    );
    if ("units" === type) {
      return table + unit;
    }
    if ("asinCount" === type) {
      return asinUnit.length;
    }
    if ("orderAsinUnit" === type) {
      return asinUnit.reduce((a, b) => a + b, 0);
    }
    if ("totalCases" === type) {
      return totalCase.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    }
    if ("totalCost" === type) {
      return totalCost.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    }
    if ("Discount" === type) {
      // if (
      //   infoData.discount_type === 'price'
      // ) {
      //   return parseFloat(infoData.total_Discount).toFixed(2);
      // }
      return disCount.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    }
    return type;
  };

  const updateOrder = (e, row, item) => {
    // console.log(e, row, item, "e, row, item");

    const index = tableData.findIndex((d) => d.fald_id === row.fald_id);
    const tableDataSet = tableData;
    const comIndex = tableDataSet[index].componentData.findIndex(
      (d) => parseInt(d.id) === parseInt(item.id)
    );

    tableDataSet[index].componentData[comIndex].order_quantity =
      e === "" ? 0 : parseFloat(e);
    tableDataSet[index].componentData[
      comIndex
    ].componentData.case_order_quantity =
      e === ""
        ? 0
        : parseFloat(e) === 0
          ? 0
          : (
            parseFloat(e) /
            row.componentData[comIndex].componentData.case_quantity
          ).toFixed(2);
    setTableData([...tableDataSet]);
  };
  const updateDiscount = (e, row, item) => {
    const index = tableData.findIndex((d) => d.fald_id === row.fald_id);
    const tableDataSet = tableData;
    const comIndex = tableDataSet[index].componentData.findIndex(
      (d) => parseInt(d.id) === parseInt(item.id)
    );

    tableDataSet[index].componentData[comIndex].discount = e;
    tableDataSet[index].componentData[comIndex].componentData.discount = e;
    setTableData([...tableDataSet]);
  };
  const addComponent = async () => {
    try {
      const res = await MakeApiCall(
        `components?page=all&per-page=20&filter=all&filters[vendor_id]=${parseInt(
          vendor.filter(
            (option) =>
              option.value ===
              (infoData.vendor !== undefined && parseInt(infoData.vendor))
          )[0].value
        )}`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );

      setDataLoading(false);
      if (res.status === true) {
        setComponentList(res.data.records);
        setComponentModal(true);
      }
    } catch (error) {
      message.destroy();
      message.warning("something went wrong");
    }
  };
  const DownloadFiles = async (type) => {
    try {
      const po_id = po;
      const res = await MakeApiCall(
        `${type}/${po_id}`,
        "post",
        null,
        true,
        {},
        userData?.user?.auth_token
      );

      if (res.status) {
        if (type === "export-po-excel") {
          const link = document.createElement("a");
          link.href = `${URL}cheddy_php_v3${res.data.file}`;
          link.click();
        } else {
          const link = document.createElement("a");
          link.href = `${URL}cheddy_php_v3${res.data.file}`;
          link.target = "_blank";
          link.click();
        }
        message.destroy();
        message.success(res.message);
      } else {
        message.destroy();
        message.warning(res.message);
      }
    } catch (error) {
      message.destroy();
      message.warning("something went wrong");
    }
  };

  const OpenPrepLog = () => {
    setPrepLogList([]);
    const prepLogListSet = [];
    tableData.forEach((e) => {
      prepLogListSet.push({
        ...e,
        isLinked: true,
        componentData: [e.componentData],
      });
      setPrepLogList([...prepLogListSet]);

      if (prepLogListSet.length === tableData.length) {
        setPrepLog(true);
      }
    });
  };

  const NumberWithDecimal = (value) => {
    if (value) {
      let text = value.replaceAll(".", "");
      if (text?.length > 2) {
        return text.slice(0, -2) + "." + text.slice(-2);
      }
      return value;
    } else {
      return value;
    }
  };

  const totalDiscount_ = parseFloat(
    (parseFloat(
      totalPurchaseCostValue ? totalPurchaseCostValue?.split(",")?.join("") : 0
    ) || parseFloat(TotalUnits("totalCost"))) -
    (infoData?.total_Discount || TotalUnits("Discount") || 0)
  );

  const TotalPurchaseCostValueFooter = {
    price: {
      total_purchase_cost: parseFloat(TotalUnits("totalCost")),
      discount_amount: parseFloat(TotalUnits("totalCost")) - totalDiscount_,
      final_purchase_cost: totalDiscount_,
    },
    percentage: {
      total_purchase_cost:
        parseFloat(infoData?.total_Discount || TotalUnits("Discount") || 0) ===
          0
          ? parseFloat(TotalUnits("totalCost"))
          : (parseFloat(TotalUnits("totalCost")) /
            parseFloat(
              infoData?.total_Discount || TotalUnits("Discount") || 0
            )) *
          100,
      discount_amount:
        parseFloat(infoData?.total_Discount || TotalUnits("Discount") || 0) ===
          0
          ? 0
          : parseFloat(TotalUnits("totalCost")),
      discount_pr: parseFloat(
        infoData?.total_Discount || TotalUnits("Discount") || 0
      ),
      final_purchase_cost:
        parseFloat(infoData?.total_Discount || TotalUnits("Discount") || 0) ===
          0
          ? parseFloat(TotalUnits("totalCost"))
          : (parseFloat(TotalUnits("totalCost")) /
            parseFloat(
              infoData?.total_Discount || TotalUnits("Discount") || 0
            )) *
          100 -
          parseFloat(TotalUnits("totalCost")),
    },
  };

  if (noData) {
    return (
      <div
        style={{
          width: "fit-content",
          height: "fit-content",
          inset: 0,
          margin: "auto",
          position: "absolute",
        }}
      >
        <Empty />
      </div>
    );
  }

  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => {
          DownloadFiles("export-po-excel");
        }}
      >
        Excel
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => {
          DownloadFiles("export-po-pdf");
        }}
      >
        PDF
      </Menu.Item>
    </Menu>
  );
  const DeleteComponenet = (row) => {
    if (window.confirm("Are You Sure Delete")) {
      let compoentListSet = compoentList;
      compoentListSet = compoentListSet.filter(
        (d) => parseInt(d.item_id || d.id) !== parseInt(row.item_id || row.id)
      );
      setCompoentList(compoentListSet);
    }
    // row
  };
  const columnsCustomComponent = [
    {
      title: "Component ID",
      dataIndex: "component_id",
      key: "component_id",
    },
    {
      title: "PO Description",
      dataIndex: "item_description",
      key: "item_description",
    },
    {
      title: "Order Quantity",
      render: (item) => {
        return (
          <Input
            name="replenishmentBox"
            value={
              item !== null
                ? item.quantity === ""
                  ? ""
                  : (parseInt(item.quantity) !== 0
                    ? parseInt(item.quantity)
                    : parseFloat(item.moq || 0).toFixed(2)) ||
                  parseFloat(item.moq || 0).toFixed(2)
                : 0
            }
            defaultValue={
              item !== null
                ? (parseInt(item.quantity) !== 0
                  ? parseInt(item.quantity)
                  : parseFloat(item.moq || 0).toFixed(2)) ||
                parseFloat(item.moq || 0).toFixed(2)
                : 0
            }
            style={{
              padding: "0px 30px",
              height: "38px",
              margin: "10px 0px",
              width: "150px",
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && e.target.value) {
                e.target.blur();
              }
            }}
            onChange={(e) => {
              compoentListQuantity(e.target.value, item);
            }}
          />
        );
      },
    },
    {
      title: "Quantity",
      render: (item) => {
        return (
          <>
            {item !== null
              ? parseFloat(
                parseFloat(item.moq || 0).toFixed(2) *
                parseFloat(item.unit_cost || 0).toFixed(2)
              ).toFixed(2)
              : ""}
          </>
        );
      },
    },
    {
      title: "Component Cost",
      render: (item) => {
        return <>${item !== null ? item.unit_cost : ""}</>;
      },
    },
    {
      title: "Discount",
      render: (item) => {
        return (
          <>
            <Input
              className="w-100px"
              disabled={
                infoData?.discount_type
                  ? infoData?.discount_type !== "-- Select --"
                    ? status === "create"
                      ? infoData?.total_Discount
                        ? true
                        : false
                      : true
                    : true
                  : true
              }
              placeholder="Enter Discount"
              step=".01"
              value={item.discount}
              onChange={(e) => {
                setCompoentList((pre) => {
                  let preSet = pre;
                  const index = preSet.findIndex((d) => d.id === item.id);
                  if (preSet[index].id === item.id) {
                    preSet[index].discount = e.target.value;
                  }
                  return [...preSet];
                });
              }}
            />
          </>
        );
      },
    },
    {
      title: "Purchase Cost",
      render: (item) => {
        return (
          <>
            {numberWithCommas(
              parseFloat(
                parseFloat(
                  (parseFloat(item.unit_cost || 0).toFixed(2) -
                    (infoData?.discount_type === "percentage"
                      ? parseFloat(
                        (parseFloat(item.unit_cost || 0).toFixed(2) *
                          parseFloat(
                            item.discount ||
                            item?.componentData?.discount ||
                            0
                          ).toFixed(2)) /
                        100
                      ).toFixed(2)
                      : parseFloat(
                        item.discount || item?.componentData?.discount || 0
                      ).toFixed(2))) *
                  parseFloat(ExtraComponent(item)).toFixed(2)
                )
              ).toFixed(2) < 0
                ? 0
                : parseFloat(
                  parseFloat(
                    (parseFloat(item.unit_cost || 0).toFixed(2) -
                      (infoData?.discount_type === "percentage"
                        ? parseFloat(
                          (parseFloat(item.unit_cost || 0).toFixed(2) *
                            parseFloat(
                              item.discount ||
                              item?.componentData?.discount ||
                              0
                            ).toFixed(2)) /
                          100
                        ).toFixed(2)
                        : parseFloat(
                          item.discount ||
                          item?.componentData?.discount ||
                          0
                        ).toFixed(2))) *
                    parseFloat(ExtraComponent(item)).toFixed(2)
                  )
                ).toFixed(2)
            )}
            <Tooltip
              placement="right"
              title={`Component Cost $${parseFloat(item.unit_cost || 0).toFixed(
                2
              )} - $${infoData?.discount_type === "percentage"
                  ? parseFloat(
                    (parseFloat(item.unit_cost || 0).toFixed(2) *
                      parseFloat(
                        item.discount || item?.componentData?.discount || 0
                      ).toFixed(2)) /
                    100
                  ).toFixed(2)
                  : parseFloat(
                    item.discount || item?.componentData?.discount || 0
                  ).toFixed(2)
                }
              Discount = $${parseFloat(
                  parseFloat(item.unit_cost || 0).toFixed(2) -
                  (infoData?.discount_type === "percentage"
                    ? parseFloat(
                      (parseFloat(item.unit_cost || 0).toFixed(2) *
                        parseFloat(
                          item.discount || item?.componentData?.discount || 0
                        ).toFixed(2)) /
                      100
                    ).toFixed(2)
                    : parseFloat(
                      item.discount || item?.componentData?.discount || 0
                    ).toFixed(2))
                ).toFixed(2)} * $${parseFloat(ExtraComponent(item)).toFixed(
                  2
                )} Units = Purchase Cost $${parseFloat(
                  parseFloat(
                    (parseFloat(item.unit_cost || 0).toFixed(2) -
                      (infoData?.discount_type === "percentage"
                        ? parseFloat(
                          (parseFloat(item.unit_cost || 0).toFixed(2) *
                            parseFloat(
                              item.discount ||
                              item?.componentData?.discount ||
                              0
                            ).toFixed(2)) /
                          100
                        ).toFixed(2)
                        : parseFloat(
                          item.discount || item?.componentData?.discount || 0
                        ).toFixed(2))) *
                    parseFloat(ExtraComponent(item)).toFixed(2)
                  )
                ).toFixed(2) < 0
                  ? 0
                  : parseFloat(
                    parseFloat(
                      (parseFloat(item.unit_cost || 0).toFixed(2) -
                        (infoData?.discount_type === "percentage"
                          ? parseFloat(
                            (parseFloat(item.unit_cost || 0).toFixed(2) *
                              parseFloat(
                                item.discount ||
                                item?.componentData?.discount ||
                                0
                              ).toFixed(2)) /
                            100
                          ).toFixed(2)
                          : parseFloat(
                            item.discount ||
                            item?.componentData?.discount ||
                            0
                          ).toFixed(2))) *
                      parseFloat(ExtraComponent(item)).toFixed(2)
                    )
                  ).toFixed(2)
                }`}
            >
              <Button
                style={{ marginLeft: "10px" }}
                variant="filled"
                color="primary"
                id={`popTop_${item.id || ""}`}
              >
                <Icon icon="material-symbols:info" width={20} />
              </Button>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Vendor SKU",
      render: (item) => {
        return <>{item !== null ? item.vendor_sku : ""}</>;
      },
    },
    {
      title: "UPC",
      render: (item) => {
        return <>{item !== null ? item.upc : ""}</>;
      },
    },
    {
      title: "Case Quantity",
      render: (item) => {
        return <>{item !== null ? item.case_quantity : ""}</>;
      },
    },
    {
      title: "Action",

      render: (item) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "45px",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            DeleteComponenet(item);
          }}
        >
          <Icon icon="tabler:trash-filled" width={20} />
        </div>
      ),
    },
  ];

  const expandedRowRender = (record) => {
    return (
      <ExpandableComponentUpdate
        data={record}
        infoData={infoData}
        orderQuantity={(e, row, item) => updateOrder(e, row, item)}
        updateDiscount={(e, row, item) => updateDiscount(e, row, item)}
      />
    );
  };

  const vendorLabel = vendor.filter(
    (option) =>
      option.value ===
      (infoData.vendor !== undefined && parseInt(infoData.vendor))
  )?.[0]?.label;

  return (
    <Wrapper>
      <div style={{ filter: `blur(${loading ? 5 : 0}px)` }}>
        <Flex
          gap={5}
          align="center"
          className="d-flex"
          style={{ justifyContent: "space-between" }}
        >
          <Flex>
            <Row>
              <Col span={24}>
                <Typography.Text>
                  Vendor&nbsp;:&nbsp;
                  <Tag fontSize color="blue">
                    {vendorLabel}
                  </Tag>
                </Typography.Text>
                <Select
                  disabled={status === "create" ? false : true}
                  placeholder="Status"
                  size="small"
                  className="w-150px"
                  value={statusOp.filter(
                    (option) =>
                      option.value ===
                      (infoData.status !== undefined && infoData.status)
                  )}
                  onChange={(e) => {
                    EditData(e, "status");
                  }}
                  options={statusOp}
                />
              </Col>
            </Row>
          </Flex>

          <Flex
            gap={5}
            className="d-flex"
            style={{ justifyContent: "flex-end" }}
          >
            {status === "create" && (
              <Button
                onClick={() => {
                  OpenPrepLog();
                }}
                color="primary"
                size="middle"
                style={{ color: "#fb8500", background: "#ffeedf" }}
                variant="filled"
              >
                Create Prep Log
              </Button>
            )}
            {status === "create" && (
              <Button
                size="middle"
                onClick={() => addComponent()}
                color="primary"
              >
                Add Component
              </Button>
            )}
            {status === "create" && (
              <Dropdown overlay={menu} trigger={["click"]}>
                <Button size="middle" type="primary">
                  Download To <span style={{ marginLeft: "4px" }}>&#9660;</span>
                </Button>
              </Dropdown>
            )}
          </Flex>
        </Flex>

        <Card
          style={{
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            marginTop: "10px",
            border: "1px dashed #0179af",
          }}
        >
          <Form layout="vertical">
            <Row gutter={[16, 16]}>
              <Col span={4}>
                <Form.Item label="Reference ID">
                  {" "}
                  <Input
                    name="searchBox"
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                      background: "#fb850026",
                      border: "1px solid #fb8500",
                    }}
                    variant="filled"
                    value={
                      infoData.reference !== undefined ? infoData.reference : ""
                    }
                    placeholder="Reference"
                    onChange={(e) => {
                      EditData(e.target.value, "reference");
                    }}
                    disabled={status === "create" ? false : true}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                {console.log(infoData.expected_date, "infoData.expected_date")}
                <Form.Item label="Estimated Inbound Delivery Date">
                  <DatePicker
                    variant="filled"
                    style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
                    value={infoData.expected_date}
                    className="w-100"
                    id="range-picker"
                    placeholder="Select Date"
                    onChange={(date) => EditData(date, "expected_date")}
                    disabled={status === "create" ? false : true}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Submission Date">
                  <DatePicker
                    id="range-picker"
                    variant="filled"
                    style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
                    className="w-100"
                    value={infoData.created_date}
                    onChange={(e) => {
                      EditData(e, "created_date");
                    }}
                    placeholder="Submission Date"
                    disabled={status === "create" ? false : true}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item className="" label="Estimated On-Shelf Date">
                  <DatePicker
                    variant="filled"
                    style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
                    value={dayjs(new Date(GetEstimatedOnShelfDate()))}
                    className="w-100"
                    onChange={(e) => {
                      EditData(e, "estimated_on_shelf_date");
                    }}
                    id="range-picker"
                    placeholder="Estimated On-Shelf Date"
                    disabled={status === "create" ? false : true}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Actual On Shelf Date">
                  <DatePicker
                    variant="filled"
                    style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
                    value={infoData.actual_on_shelf_date}
                    className="w-100"
                    onChange={(e) => {
                      EditData(e, "actual_on_shelf_date");
                    }}
                    placeholder="Actual On Shelf Date"
                    disabled={status === "create" ? false : true}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Actual Inbound Delivery Date">
                  <DatePicker
                    variant="filled"
                    style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
                    value={infoData.actual_inbound_delivery_date}
                    className="w-100"
                    onChange={(e) => {
                      EditData(e, "actual_inbound_delivery_date");
                    }}
                    placeholder="Actual Inbound Delivery Date"
                    disabled={status === "create" ? false : true}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={4}>
                <Form.Item label="Notes">
                  <Input.TextArea
                    style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
                    variant="filled"
                    value={infoData.note !== undefined ? infoData.note : ""}
                    name="NotesBox"
                    rows={1}
                    placeholder="Notes"
                    onChange={(e) => {
                      EditData(e.target.value, "note");
                    }}
                    disabled={status === "create" ? false : true}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Shipping Fee">
                  <Input
                    step=".01"
                    style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
                    variant="filled"
                    value={infoData.shipping_cost || ""}
                    placeholder="Shipping Fee"
                    onChange={(e) => {
                      EditData(e.target.value, "shipping_cost");
                    }}
                    disabled={status === "create" ? false : true}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Total Purchase Cost">
                  <Input
                    variant="filled"
                    style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
                    value={
                      parseFloat(totalPurchaseCostValue) < 1
                        ? parseFloat(TotalUnits("totalCost")).toFixed(2)
                        : totalPurchaseCostValue
                    }
                    placeholder="Total Purchase Cost"
                    onChange={(e) => {
                      setTotalPurchaseCostValue(e.target.value);
                    }}
                    disabled={infoData.status !== "open" ? true : false}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Po Template">
                  <Select
                    style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
                    variant="filled"
                    value={poTemplate.filter(
                      (option) =>
                        option.value ===
                        (infoData.po_template !== undefined &&
                          parseInt(infoData.po_template))
                    )}
                    onChange={(e) => {
                      EditData(e, "po_template");
                    }}
                    placeholder="--- Select template ---"
                    disabled={status === "create" ? false : true}
                    options={poTemplate}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Discount Type">
                  <Select
                    variant="filled"
                    style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
                    disabled={infoData.status !== "open" ? true : false}
                    placeholder="Discount"
                    value={[
                      {
                        label: "-- Select --",
                        value: "-- Select --",
                      },
                      {
                        label: "Percentage",
                        value: "percentage",
                      },

                      {
                        label: "Price",
                        value: "price",
                      },
                    ].filter(
                      (option) =>
                        option.value ===
                        (infoData.discount_type !== undefined &&
                          infoData.discount_type)
                    )}
                    onChange={(e) => {
                      if (e === "-- Select --") {
                        setInfoData({
                          ...infoData,
                          total_Discount: "",
                          discount_type: e,
                          saveType: "edit",
                        });
                        setCompoentList(
                          compoentList.map((d) => {
                            return { ...d, discount: 0 };
                          })
                        );
                        tableData.forEach((_, index) => {
                          const tableDataSet = tableData;
                          tableData[index].componentData.forEach(
                            (item, comIndex) => {
                              tableDataSet[index].componentData[
                                comIndex
                              ].discount = 0;
                              tableDataSet[index].componentData[
                                comIndex
                              ].componentData.discount = 0;
                              setTableData([...tableDataSet]);
                            }
                          );
                        });
                      } else {
                        setInfoData({
                          ...infoData,
                          discount_type: e,
                          saveType: "edit",
                        });
                      }
                    }}
                    options={[
                      {
                        label: "-- Select --",
                        value: "-- Select --",
                      },
                      {
                        label: "Percentage",
                        value: "percentage",
                      },
                      {
                        label: "Price",
                        value: "price",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Total Discount">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {infoData?.discount_type !== "percentage" && (
                      <span
                        style={{
                          background: "white",
                          border: "1px solid #d8d6de",
                          padding: "7.5px",
                          borderRight: "none",
                        }}
                      >
                        $
                      </span>
                    )}
                    <Input
                      variant="filled"
                      step=".01"
                      style={{
                        borderLeft:
                          infoData?.discount_type !== "percentage"
                            ? "none"
                            : "1px solid #d8d6de",
                        borderRight:
                          infoData?.discount_type === "percentage"
                            ? "none"
                            : "1px solid #d8d6de",
                        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                      }}
                      value={
                        infoData.total_Discount !== undefined
                          ? infoData.total_Discount
                          : ""
                      }
                      onBlur={(e) => {
                        setTimeout(() => {
                          if (
                            parseFloat(
                              TotalPurchaseCostValueFooter?.[
                                infoData?.discount_type || "price"
                              ]?.final_purchase_cost
                            ) < 0
                          ) {
                            message.destroy();
                            message.error(
                              "Discount amount exceeds the Purchase Cost"
                            );
                          }
                        }, 200);
                      }}
                      name="Total_Discount"
                      placeholder="Total Discount"
                      onChange={(e) => {
                        if (
                          infoData?.discount_type === "percentage" &&
                          parseInt(e.target.value) > 100
                        ) {
                          return;
                        }
                        setCompoentList(
                          compoentList.map((d) => {
                            return {
                              ...d,
                              discount: NumberWithDecimal(e.target.value),
                            };
                          })
                        );
                        tableData.forEach((_, index) => {
                          const tableDataSet = tableData;
                          tableData[index].componentData.forEach(
                            (item, comIndex) => {
                              tableDataSet[index].componentData[
                                comIndex
                              ].discount = e.target.value
                                  ? parseFloat(e.target.value).toFixed(2)
                                  : "";
                              tableDataSet[index].componentData[
                                comIndex
                              ].componentData.discount = parseFloat(
                                e.target.value
                              ).toFixed(2);
                              setTableData([...tableDataSet]);
                            }
                          );
                        });
                        EditData(
                          NumberWithDecimal(e.target.value),
                          "total_Discount"
                        );
                        // EditData(
                        //   e.target.value
                        //     ? parseFloat(e.target.value).toFixed(2)
                        //     : "",
                        //   "total_Discount"
                        // );
                        // setTimeout(() => {
                        //   if (totalData?.totalCost === 0) {
                        //     message.destroy();
                        //     message.error(
                        //       "Discount amount exceeds the Purchase Cost"
                        //     );
                        //   }
                        // }, 100);
                      }}
                      disabled={
                        infoData.status !== "open"
                          ? true
                          : false
                            ? infoData?.discount_type
                              ? infoData?.discount_type !== "-- Select --"
                                ? false
                                : true
                              : true
                            : true
                      }
                    />
                    {infoData?.discount_type === "percentage" && (
                      <span
                        style={{
                          background: "white",
                          border: "1px solid #d8d6de",
                          padding: "7.5px",
                          borderLeft: "none",
                        }}
                      >
                        %
                      </span>
                    )}
                  </div>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <>
          <Card style={{ marginBottom: "0px", marginTop: "10px" }}>
            <>
              {dataLoading && <Spin />}
              {!dataLoading && (
                <>
                  <Table2
                    pagination={false}
                    columns={columns}
                    rowKey="id"
                    dataSource={tableData}
                    loading={dataLoading}
                    expandable={{
                      expandedRowRender,
                    }}
                    scroll={{ x: "max-content" }}
                  />
                </>
              )}
              {compoentList.length !== 0 && (
                <Table
                  columns={columnsCustomComponent}
                  loading={dataLoading}
                  pagination={false}
                  dataSource={compoentList}
                  scroll={{ x: "max-content" }}
                />
              )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  borderTop: "2px solid #0079ae",
                }}
              >
                <div className="text-right mt-2 mb-2">
                  Total Units:&nbsp;
                  <span className="totUnit" style={{ fontWeight: "700" }}>
                    {/* {console.log(TotalUnits("units"), 'TotalUnits("units")')} */}
                    {numberWithCommas(TotalUnits("units") || 0)}
                  </span>
                </div>
                <div className="text-right mt-2 mb-2">
                  ASIN Count:&nbsp;
                  <span className="totAsinCount" style={{ fontWeight: "700" }}>
                    {numberWithCommas(TotalUnits("asinCount"))}
                  </span>
                </div>
                <div className="text-right mt-2 mb-2">
                  Ordered ASIN Unit(s):&nbsp;
                  <span className="totAsinUnit" style={{ fontWeight: "700" }}>
                    {numberWithCommas(TotalUnits("orderAsinUnit"))}
                  </span>
                </div>
                <div className="text-right mt-2 mb-2">
                  Total Cases:&nbsp;
                  <span className="totAsinUnit" style={{ fontWeight: "700" }}>
                    {numberWithCommas(TotalUnits("totalCases"))}
                  </span>
                </div>
                <div className="text-right mt-2 mb-2">
                  <Popover
                    title={
                      <div className="row" style={{ padding: "0px 10px" }}>
                        <div className="col-12">
                          <div className="row">
                            <div
                              className="col-auto"
                              style={{
                                color: "#6f6f6f",
                                fontWeight: 500,
                              }}
                            >
                              Total Purchase Cost :
                            </div>
                            <b
                              className="col text-end"
                              style={{ textAlign: "end" }}
                            >
                              $
                              {numberWithCommas(
                                parseFloat(
                                  parseFloat(
                                    TotalPurchaseCostValueFooter?.[
                                      infoData?.discount_type === "-- Select --"
                                        ? "price"
                                        : infoData?.discount_type
                                    ]?.total_purchase_cost || 0
                                  ).toFixed(2)
                                )
                              )}
                            </b>
                          </div>
                          <div className="row">
                            <div
                              className="col-auto"
                              style={{
                                color: "#6f6f6f",
                                fontWeight: 500,
                              }}
                            >
                              Discount Amount :
                            </div>
                            <b
                              className="col  text-end"
                              style={{ textAlign: "end" }}
                            >
                              $
                              {numberWithCommas(
                                parseFloat(
                                  parseFloat(
                                    TotalPurchaseCostValueFooter?.[
                                      infoData?.discount_type === "-- Select --"
                                        ? "price"
                                        : infoData?.discount_type
                                    ]?.discount_amount || 0
                                  ).toFixed(2)
                                )
                              )}
                            </b>
                          </div>
                          <div className="row">
                            <div
                              className="col-auto"
                              style={{
                                color: "#6f6f6f",
                                fontWeight: 500,
                              }}
                            >
                              Final Purchase Cost :
                            </div>
                            <b
                              className="col text-end"
                              style={{ textAlign: "end" }}
                            >
                              $
                              {numberWithCommas(
                                parseFloat(
                                  parseFloat(
                                    TotalPurchaseCostValueFooter?.[
                                      infoData?.discount_type === "-- Select --"
                                        ? "price"
                                        : infoData?.discount_type
                                    ]?.final_purchase_cost || 0
                                  ).toFixed(2)
                                )
                              )}
                            </b>
                          </div>
                        </div>
                      </div>
                    }
                  >
                    Final Purchase Cost:
                  </Popover>
                  &nbsp;<b>$</b>
                  <span className="totAsinUnit" style={{ fontWeight: "700" }}>
                    {numberWithCommas(
                      parseFloat(
                        parseFloat(
                          TotalPurchaseCostValueFooter?.[
                            infoData?.discount_type === "-- Select --"
                              ? "price"
                              : infoData?.discount_type
                          ]?.final_purchase_cost || 0
                        ).toFixed(2)
                      )
                    )}
                  </span>
                </div>
                <div className="text-right mt-2 mb-2">
                  Shipping Fee:&nbsp;<b>$</b>
                  <span className="totAsinUnit" style={{ fontWeight: "700" }}>
                    {numberWithCommas(
                      parseFloat(infoData.shipping_cost || 0).toFixed(2)
                    )}
                  </span>
                </div>
                <div className="text-right mt-2 mb-2">
                  Total Discount:&nbsp;<b></b>
                  <span className="totAsinUnit" style={{ fontWeight: "700" }}>
                    {`$${infoData?.discount_type === "percentage"
                        ? numberWithCommas(
                          parseFloat(
                            parseFloat(
                              TotalPurchaseCostValueFooter?.[
                                infoData?.discount_type === "-- Select --"
                                  ? "price"
                                  : infoData?.discount_type
                              ]?.discount_amount || 0
                            ).toFixed(2)
                          )
                        )
                        : infoData?.total_Discount ||
                        numberWithCommas(
                          parseFloat(TotalUnits("Discount") || 0).toFixed(2)
                        ) ||
                        0
                      }`}

                    {infoData?.discount_type === "percentage" && (
                      <>
                        {`(${numberWithCommas(
                          parseFloat(
                            parseFloat(
                              TotalPurchaseCostValueFooter?.[
                                infoData?.discount_type === "-- Select --"
                                  ? "price"
                                  : infoData?.discount_type
                              ]?.discount_pr || 0
                            ).toFixed(2)
                          )
                        )}%)`}
                      </>
                    )}
                  </span>
                </div>
              </div>
            </>
            <Flex gap={4}>
              {/* {console.log(status === "create", 'status === create')} */}

              {status === "create" ? (
                <>
                  {" "}
                  <Button
                    type="primary"
                    className=""
                    style={{ marginRight: "15px" }}
                    disabled={dataLoading}
                    onClick={() => {
                      if (actionLoading) {
                        return;
                      }
                      SubmitPo();
                      setActionLoading(true);
                    }}
                  >
                    {actionLoading ? (
                      <Spin />
                    ) : (
                      <Icon
                        icon="ic:baseline-plus"
                        width={16}
                        style={{ position: "relative", top: "-2px" }}
                      />
                    )}{" "}
                    Create
                  </Button>
                  <Button
                    type="link"
                    onClick={() => {
                      window.location.replace("/create-order");
                    }}
                    className=""
                    style={{ marginRight: "15px" }}
                  >
                    Cancel
                  </Button>{" "}
                </>
              ) : editStatus ? (
                <>
                  {" "}
                  <Button
                    type="primary"
                    disabled={
                      parseFloat(
                        TotalPurchaseCostValueFooter?.[
                          infoData?.discount_type || "price"
                        ]?.final_purchase_cost
                      ) < 0
                    }
                    style={{ marginRight: "15px" }}
                    onClick={() => SubmitPo(po)}
                  >
                    <Icon
                      icon="material-symbols:save"
                      width={16}
                      style={{ position: "relative", top: "-2px" }}
                    />
                    Save
                  </Button>
                  <Button
                    type="link"
                    onClick={() => {
                      setEditStatus(false);
                      getTableRecord();
                      setDataLoading(true);
                      setData([]);
                      setInfoData({});
                    }}
                    style={{ marginRight: "15px" }}
                  >
                    Cancel
                  </Button>{" "}
                  <Link
                    style={{ position: "relative", left: "15px" }}
                    to="/po-list"
                  >
                    Po List
                  </Link>
                </>
              ) : (
                <>
                  {window.location.pathname.split("/").length === 4 ? (
                    <>
                      <Button
                        type="primary"
                        style={{ marginRight: "15px" }}
                        disabled={dataLoading}
                        onClick={() => {
                          UpdatePo(po);
                        }}
                      >
                        <Icon
                          icon="material-symbols:save"
                          width={16}
                          style={{ position: "relative", top: "-2px" }}
                        />{" "}
                        Approve
                      </Button>
                      <Button
                        type="primary"
                        style={{ marginRight: "15px" }}
                        className=""
                        disabled={
                          dataLoading ||
                          parseFloat(
                            TotalPurchaseCostValueFooter?.[
                              infoData?.discount_type || "price"
                            ]?.final_purchase_cost
                          ) < 0
                        }
                        onClick={() => SubmitPo(po)}
                      >
                        <Icon
                          icon="material-symbols:save"
                          width={16}
                          style={{ position: "relative", top: "-2px" }}
                        />{" "}
                        Save
                      </Button>
                      <Button
                        type="link"
                        onClick={() => {
                          navigate("/po-list");
                        }}
                        style={{ marginRight: "15px" }}
                      >
                        Cancel
                      </Button>
                      <Link
                        style={{ position: "relative", left: "15px" }}
                        to="/po-list"
                      >
                        Po List
                      </Link>
                    </>
                  ) : (
                    <>
                      <Button
                        type="primary"
                        style={{ marginRight: "15px" }}
                        disabled={dataLoading}
                        onClick={() => setEditStatus(true)}
                      >
                        <Icon
                          width={16}
                          icon="material-symbols:edit"
                          style={{ position: "relative", top: "-2px" }}
                        />{" "}
                        Edit PO
                      </Button>
                      <Button
                        type="primary"
                        disabled={
                          dataLoading ||
                          parseFloat(
                            TotalPurchaseCostValueFooter?.[
                              infoData?.discount_type || "price"
                            ]?.final_purchase_cost
                          ) < 0
                        }
                        style={{ marginRight: "15px" }}
                        onClick={() => ClonePO()}
                      >
                        <Icon
                          width={16}
                          icon="solar:copy-bold"
                          style={{ position: "relative", top: "-2px" }}
                        />{" "}
                        Clone PO
                      </Button>
                      <Button
                        type="danger"
                        disabled={dataLoading}
                        onClick={() => {
                          if (window.confirm("Are You Sure Delete Po")) {
                            DeletePo();
                          }
                        }}
                      >
                        <Icon
                          style={{ position: "relative", top: "-2px" }}
                          icon="tabler:trash-filled"
                          width={20}
                        />
                        Delete
                      </Button>
                      <Link
                        style={{ position: "relative", left: "15px" }}
                        to="/po-list"
                      >
                        Po List
                      </Link>
                    </>
                  )}
                </>
              )}
            </Flex>
          </Card>
          {prepLog && (
            <PrepLogModal
              closeModal={() => {
                setPrepLog(false);
                setPrepLogList([]);
              }}
              view={prepLogList}
              userData={userData}
              type="po"
              isOpen={prepLog}
              updateQuantity={(e, d) => updateQuantity(e, d)}
            />
          )}

          <AddComponent
            show={componentModal}
            close={() => setComponentModal(false)}
            data={componentList}
            submit={() => submitAddComponent()}
            vendor={vendor}
            brandList={brandList}
            setSelectRows={setSelectRows}
            poId={infoData.po_id}
            selectRows={selectRows}
          />
        </>
      </div>
    </Wrapper>
  );
};
export default CreatePo;
