import {
  Badge,
  Button,
  Empty,
  Flex,
  message,
  Modal,
  Spin,
  Table,
  Tag,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { MakeApiCall } from "../../../../apis";
import { FormatNumber } from "../../../../config";

const OnOrderModal = (props) => {
  const { isOpen, data, closeModal, userData } = props;

  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);

  const getOrderList = async () => {
    setLoading(true);
    setListData([]);
    const response = await MakeApiCall(
      `model-on-order`,
      "POST",
      {
        asin: data?.value?.asin1,
      },
      true,
      {},
      userData?.user?.auth_token
    );
    if (response?.status === true) {
      setListData(response?.data?.model || []);
      setLoading(false);
    } else if (response?.status === false) {
      setLoading(false);
      message.destroy();
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    getOrderList();

    return () => {};
  }, []);

  const columns = [
    {
      title: "#PO",
      dataIndex: "reference",
      key: "reference",
      render: (text, record) => (
        <Link to={`/po-list/${record.po_id}`} target="_blank" title="View Po">
          {text}
        </Link>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => <Badge status="success" text={status} />,
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "Created Date",
      dataIndex: "created_date",
      key: "created_date",
      render: (date) =>
        !date || !moment(date).isValid()
          ? "-"
          : moment(date).format("YYYY-MM-DD"),
    },
    {
      title: "Expected Date",
      dataIndex: "expected_date",
      key: "expected_date",
      render: (date) =>
        !date || !moment(date).isValid()
          ? "-"
          : moment(date).format("YYYY-MM-DD"),
    },
    {
      title: "Ordered",
      dataIndex: "replenishment_qty",
      key: "replenishment_qty",
      render: (qty) => FormatNumber(qty ?? 0),
    },
  ];

  return (
    <Modal
      open={isOpen}
      onCancel={closeModal}
      title={
        <>
          On Order <Tag color="blue">{FormatNumber(data?.totalvalue || 0)}</Tag>
        </>
      }
      width={800}
      footer={[
        <Button
          key="close"
          size="small"
          variant="solid"
          color="default"
          onClick={closeModal}
        >
          Close
        </Button>,
      ]}
    >
      {listData.length === 0 ? (
        <Flex justify="center" align="center" style={{ height: "200px" }}>
          {loading ? <Spin tip="Loading..." /> : <Empty />}
        </Flex>
      ) : (
        <Table
          columns={columns}
          dataSource={listData}
          rowKey={(record, index) => index}
          pagination={false}
          loading={loading}
        />
      )}
    </Modal>
  );
};

export default OnOrderModal;
