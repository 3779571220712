import { ConfigProvider } from "antd";
import "antd/dist/reset.css";
import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import GlobalCommonContextProvider from "./common-context";
import { persistor, store } from "./redux/store";
import Routers from "./routers";
import GlobalStyles from "./styles/GlobalStyles";

function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Disable React Developer Tools in production
    if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ !== "undefined") {
      window.__REACT_DEVTOOLS_GLOBAL_HOOK__.inject = function () {};
    }

    // Function to create and append a link element
    // const createLinkElement = (
    //   href,
    //   rel = "stylesheet",
    //   crossOrigin = null
    // ) => {
    //   const link = document.createElement("link");
    //   link.href = href;
    //   link.rel = rel;
    //   if (crossOrigin) {
    //     link.crossOrigin = crossOrigin;
    //   }
    //   document.head.appendChild(link);
    //   return link;
    // };

    // Create and append link elements for CSS and fonts
    // const links = [
    //   createLinkElement("/assets/plugins/global/plugins.bundle.css"),
    //   createLinkElement("/assets/css/style.bundle.css"),
    //   createLinkElement("/assets/css/style.css"),
    //   createLinkElement("https://fonts.googleapis.com", "preconnect"),
    //   createLinkElement("https://fonts.gstatic.com","preconnect","crossorigin"),
    //   createLinkElement(
    //     "https://fonts.googleapis.com/css2?family=Montserrat&display=swap"
    //   ),
    //   createLinkElement(
    //     "https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap"
    //   ),
    // ];
    // const handleDOMContentLoaded = () => {};

    // const handleLoad = () => {
    //   setLoading(false);
    // };

    // window.addEventListener("DOMContentLoaded", handleDOMContentLoaded);
    // window.addEventListener("load", handleLoad);
    return () => {
      // setLoading(true);
      // window.removeEventListener("DOMContentLoaded", handleDOMContentLoaded);
      // window.removeEventListener("load", handleLoad);
      // links.forEach((link) => document.head.removeChild(link));
    };
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConfigProvider
          // Define the theme for the application.
          // The theme is used to customize the appearance of the components.
          theme={{
            token: {
              // Set the font family for the application.
              fontFamily: `Inter, Helvetica, sans-serif`,

              // Set the primary color for the application.
              colorPrimary: "#0079af",

              // Set the hover color for the primary color.
              colorPrimaryHover: "#0079af",
            },
            components: {
              // Define the styles for the Tabs component.
              Tabs: {
                // Set the color of the ink bar.
                inkBarColor: "rgb(0, 158, 251)",

                // Set the hover color for the tab items.
                itemHoverColor: "rgb(0, 158, 251)",

                // Set the active color for the tab items.
                itemActiveColor: "rgb(0, 158, 251)",

                // Set the selected color for the tab items.
                itemSelectedColor: "rgb(0, 158, 251)",

                // Set the border color for the primary color.
                colorPrimaryBorder: "rgb(0, 158, 251)",
              },
              Input: {
                // controlHeight: 40,
                colorBorder: "#0179b0",
                colorBgContainer: "#eef4f8",
              },
              DatePicker: {
                colorBorder: "#0179b0",
                colorBgContainer: "#eef4f8",
              },
              InputNumber: {
                colorBorder: "#0179b0",
                colorBgContainer: "#eef4f8",
              },
              Select: {
                colorBorder: "#0179b0",
                selectorBg: "#eef4f8",
                // colorBorder: "rgb(196, 202, 219)",
                // borderRadiusLG: 9,
                // borderRadiusSM: 9,
                // borderRadiusXS: 9,
                // borderRadius: 20,
              },
              Button: {
                controlHeight: 40,
                colorPrimary: "rgb(0, 121, 174)",
                colorPrimaryHover: "rgb(1, 102, 145)",
              },
            },
          }}
        >
          {/* Provide the common global context. */}
          <GlobalCommonContextProvider>
            {/* Apply global styles. */}
            <GlobalStyles />
            {/* Render the routers component. */}
            <Routers loading={loading} setLoading={setLoading} />
          </GlobalCommonContextProvider>
        </ConfigProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
