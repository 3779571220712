import React from "react";

import {
  Modal,
  Button,
  Table,
  Card,
  Statistic,
  Divider,
  Row,
  Col,
  Typography,
} from "antd";
import moment from "moment";

const { Text } = Typography;

const SellsOutInViewModal = (props) => {
  const { data, isOpen, closeModal } = props;
  console.log(data, "data");

  return (
    <Modal
      open={isOpen}
      onCancel={closeModal}
      title="Sells Out In"
      width={800}
      footer={[]}
    >
      <Card
        bodyStyle={{
          padding: 0,
        }}
        style={{ borderRadius: "8px", overflow: "hidden", padding: "16px" }}
      >
        <Row
          gutter={[16, 16]}
          style={{ marginBottom: "16px", paddingLeft: "16px" }}
        >
          <Col span={12}>
            <Text strong>Sales Period For Forecast:</Text>
          </Col>
          <Col span={6}>
            <Text>{moment(data.start_date).format("MMM DD YYYY")}</Text>
          </Col>
          <Col span={6}>
            <Text>{moment(data.end_date).format("MMM DD YYYY")}</Text>
          </Col>
        </Row>
        <Row
          gutter={[16, 16]}
          style={{ marginBottom: "16px", paddingLeft: "16px" }}
        >
          <Col span={12}>
            <Text strong>Current Stock:</Text>
          </Col>
          <Col span={6}>
            <Text>
              {data["currentStock"] === "00" || data["currentStock"] === "0"
                ? 0
                : data["currentStock"]}
            </Text>
          </Col>
          <Col span={6}>
            <Text>{moment(data.end_date).format("MMM DD YYYY")}</Text>
          </Col>
        </Row>
        <Row
          gutter={[16, 16]}
          style={{ marginBottom: "16px", paddingLeft: "16px" }}
        >
          <Col span={12}>
            <Text strong>Adjusted Sales Velocity:</Text>
          </Col>
          <Col span={6}>
            <Text>{`${parseFloat(data.salesVelocityPerDay).toFixed(
              2
            )} / days`}</Text>
          </Col>
          <Col span={6}>
            <Text>{`${parseFloat(data.salesVelocityPerMonth).toFixed(
              2
            )} / month`}</Text>
          </Col>
        </Row>
        <Row
          style={{
            backgroundColor: "#E0F7FA",
            padding: "16px",
            borderRadius: "4px",
          }}
        >
          <Col span={12}>
            <Text strong>Days of Stock Remaining:</Text>
          </Col>
          <Col span={12} style={{ paddingLeft: "16px" }}>
            <Text>
              {data?.sells_out_in[data?.sells_out_in?.length - 1] ||
                data?.sells_out_in ||
                0}
            </Text>
          </Col>
        </Row>
      </Card>
    </Modal>
  );
};

export default SellsOutInViewModal;
