import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Switch,
  Select,
  InputNumber,
  Row,
  Col,
  message,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { MakeApiCall } from "../../../../apis";

const VendorModalForm = ({
  setVisible,
  visible,
  userData,
  onSubmit,
  selectedRow,
}) => {
  const [form] = Form.useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [poList, setPoList] = useState([]);
  const [poListLoading, setPoListLoading] = useState(true);
  useEffect(() => {
    if (Object.keys(selectedRow || {})?.length > 0) {
      form.setFieldsValue(selectedRow);
    } else {
      form.resetFields();
    }
  }, [selectedRow]);

  // Function to handle modal close
  const handleCancel = () => {
    setVisible(false);
  };

  const CreateVendor = async (data) => {
    setSubmitLoading(true);
    try {
      const response = await MakeApiCall(
        `${
          Object.keys(selectedRow || {})?.length > 0
            ? `vendor/${selectedRow?.id}`
            : "vendor"
        }`,
        Object.keys(selectedRow || {})?.length > 0 ? "patch" : "post",
        data,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        form.resetFields();
        onSubmit();
        setSubmitLoading(false);
        message.destroy();
        message.success(response?.message);
      } else {
        message.destroy();
        setSubmitLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setSubmitLoading(false);
      message.destroy();
      message.error(error?.message);
    }
  };
  const GetPoList = async () => {
    setPoListLoading(true);
    try {
      const response = await MakeApiCall(
        `po-all-templates`,
        "GET",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setPoList(response?.data?.records || []);
        setPoListLoading(false);
      } else {
        message.destroy();
        setPoListLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setPoListLoading(false);
      message.destroy();
      message.error(error?.message);
    }
  };

  useEffect(() => {
    GetPoList();
    setPoListLoading(true);
    return () => {};
  }, []);

  // Function to handle form submit
  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        CreateVendor(values);
      })
      .catch((info) => {});
  };

  return (
    <>
      <Modal
        title="Add Vendor"
        visible={visible}
        onOk={handleOk}
        okButtonProps={{ loading: submitLoading }}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          layout="vertical"
          // onFinish={onFinish}
          initialValues={{
            status: true,
          }}
        >
          <Row gutter={16}>
            <Col span={12}>
              {/* Vendor Name */}
              <Form.Item
                label="Vendor Name"
                name="name"
                rules={[
                  { required: true, message: "Please input the vendor name!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              {/* Contact Name */}
              <Form.Item
                label="Contact Name"
                name="contact_name"
                rules={[
                  { required: true, message: "Please input the contact name!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              {/* Vendor Email */}
              <Form.Item
                label="Vendor Email"
                name="email"
                rules={[
                  { required: true, message: "Please input the vendor email!" },
                  { type: "email", message: "Please enter a valid email!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              {/* Phone No */}
              <Form.Item
                label="Phone No"
                name="phone_no"
                rules={[
                  { required: true, message: "Please input the phone number!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              {/* Days of Stock */}
              <Form.Item
                label="Days of Stock"
                name="days_of_stock"
                rules={[
                  {
                    required: true,
                    message: "Please input the days of stock!",
                  },
                ]}
              >
                <InputNumber style={{ width: "100%" }} size="large" min={0} />
              </Form.Item>
            </Col>
            <Col span={12}>
              {/* Vendor Lead Time */}
              <Form.Item
                label="Vendor Lead Time"
                name="lead_time"
                rules={[
                  {
                    required: true,
                    message: "Please input the vendor lead time!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              {/* Outbound Lead Time */}
              <Form.Item
                label="Outbound Lead Time"
                name="outbound_lead_time"
                rules={[
                  {
                    required: true,
                    message: "Please input the outbound lead time!",
                  },
                  {
                    pattern: /^\d+$/,
                    message: "Please enter only number!",
                  },
                ]}
              >
                <Input
                  placeholder="Enter outbound lead time"
                  style={{ width: "100%" }}
                  type="number"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              {/* Payment Term */}
              <Form.Item
                label="Payment Term"
                name="payment_terms"
                rules={[
                  { required: true, message: "Please input the payment term!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              {/* Address */}
              <Form.Item
                label="Address"
                name="address"
                rules={[
                  { required: true, message: "Please input the address!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={20}>
              {/* Select PO Template */}
              <Form.Item
                label="Select PO Template"
                name="template"
                rules={[
                  { required: true, message: "Please select a PO template!" },
                ]}
              >
                <Select
                  style={{ borderRadius: "2px" }}
                  size="large"
                  placeholder="Select PO Template"
                  loading={poListLoading}
                  options={poList?.map((d) => ({
                    label: d?.name,
                    value: d?.t_id,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              {/* Status */}
              <Form.Item
                label="Status"
                name="is_enabled"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={24}>
              {/* Vendor Notes */}
              <Form.Item label="Vendor Notes" name="notes">
                <TextArea rows={2} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default VendorModalForm;
