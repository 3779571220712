import { Button, Form, Input, message, Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../apis";

const ASINNote = ({
  asinNote,
  handleCancel,
  onSubmit,
  userData,
  selectedRows,
  getItemComponents
}) => {
  const [form] = Form.useForm();
  const multiEdit = Object.keys(selectedRows || {}).length !== 0;
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(()=>{
    if (asinNote?.data?.note) {
      form.setFieldsValue({
        itemNote: asinNote?.data?.note,
      });
    } else {
      form.setFieldsValue({
        itemNote: null,
      });
    }
  },[asinNote?.show])
 

  const SaveNoteAction = async (id, data) => {

    setSubmitLoading(true);
    try {
      const response = await MakeApiCall(
        `add-component-note`,
        "POST",
        data,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        onSubmit(data, id);
        handleCancel();
        getItemComponents();
        setSubmitLoading(false);
        message.success(response?.message);
      } else {
        message.destroy();
        setSubmitLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setSubmitLoading(false);
      message.destroy();
      message.error(error?.message);
    }
  };

  const SaveItemNoteAction = async (id, data) => {
    setSubmitLoading(true);
    try {
      const response = await MakeApiCall(
        `save-item-note`,
        "post",
        data,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        onSubmit(data, id);
        handleCancel();
        setSubmitLoading(false);
      } else {
        message.destroy();
        setSubmitLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setSubmitLoading(false);
      message.destroy();
      message.error(error?.message);
    }
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const payload = {
        selectedComponentItems: [asinNote?.data?.id], // Ensure this matches API requirements
        note: values.itemNote,
      };

      // console.log("payload" ,payload)
  
      if (multiEdit) {
        const obj = {
          selectedPoItems: Object.keys(selectedRows), // Ensure you send correct IDs
          note: values.itemNote,
        };
        // console.log("Multi Edit Payload:", obj);
        SaveItemNoteAction(obj);
      } else {
        SaveNoteAction(asinNote?.data?.id, payload);
      }
      
    }).catch((errorInfo) => {
      console.error("Validation Failed:", errorInfo);
    });
  };

  return (
    <Modal
      title="Component Note"
      visible={asinNote?.show}
      onCancel={handleCancel}
      footer={[
        <Button onClick={handleCancel}>Cancel</Button>,
        <Button loading={submitLoading} onClick={handleSubmit} type="primary">
          Submit
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="itemNote"
          label="Notes"
          rules={[{ required: true, message: "Please enter your notes" }]}
        >
          <Input.TextArea rows={4} placeholder="Enter your notes here" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ASINNote;
