import styled from "styled-components";

export const Wrapper = styled.div`
  .ant-input-disabled,
  .ant-select-disabled > .ant-select-selector {
    border-color: #0179b000 !important;
    border: #0179b000 !important;
  }
`;
export const ExpandWrapper = styled.div`
  .ant-table-wrapper .ant-table-thead > tr > th {
    background: #d8edf1 !important;
    color: #0179af !important;
  }
`;
