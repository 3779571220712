import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, message, Row, Col } from "antd";
import TextArea from "antd/es/input/TextArea";
import { MakeApiCall } from "../../../../../apis";

const SpeciesForm = ({  setVisible,  visible,  userData,  onSubmit,  selectedRow, selectedMarketplace}) => {


  const [form] = Form.useForm();
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    if (Object.keys(selectedRow || {})?.length > 0) {
      form.setFieldsValue(selectedRow);
      
    } else {
      form.resetFields();
    }
  }, [selectedRow]);

  // Function to handle modal close
  const handleCancel = () => {
    setVisible(false);
  };

  const CreateBrand = async (data) => {
    setSubmitLoading(true);
    try {
      const response = await MakeApiCall(
        `${
          Object.keys(selectedRow || {})?.length > 0
            ? `species/${selectedRow?.id}`
            : "species"
        }`,
        Object.keys(selectedRow || {})?.length > 0 ? "PATCH" : "POST",
        // data,
        { ...data, marketplace_id: selectedMarketplace }, // ✅ Ensure marketplace_id is included
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        form.resetFields();
        onSubmit();
        setSubmitLoading(false);
        message.destroy();
        message.success(response?.message);
      } else {
        message.destroy();
        setSubmitLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setSubmitLoading(false);
      message.destroy();
      message.error(error?.message);
    }
  };

  // Function to handle form submit
  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        CreateBrand(values);
      })
      .catch((info) => {});
  };

  return (
    <>
      <Modal
        title={
          Object.keys(selectedRow || {})?.length > 0
            ? "Edit Species "
            : "Add Species "
        }
        visible={visible}
        okButtonProps={{ loading: submitLoading }}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={form} layout="vertical" name="supply_form">
          {/* Brand Name */}
          <Form.Item
            label="Species Name"
            name="name"
            rules={[
              { required: true, message: "Please input the species name!" },
            ]}
          >
            <Input />
          </Form.Item>

             {/* Hidden field for Marketplace ID */}
        <Form.Item name="marketplace_id" hidden>
          <Input />
        </Form.Item>
          
        </Form>
      </Modal>
    </>
  );
};

export default SpeciesForm;
