import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";
import { Button, Card, Flex, Input, message, Popconfirm, Space } from "antd";

// import Table2 from "../../../../Components/tables/table2";
import { Icon } from "@iconify/react/dist/iconify.js";
import SupplyModalForm from "./add";
import { MakeApiCall } from "../../../../../apis";
import { ConvertParams } from "../../../../../config";
import Table2 from "../../../../../Components/tables/table2";
import ImportFIle from "../../../../../Components/import-file";
// import { ConvertParams } from "../../../../config";
// import { MakeApiCall } from "../../../../apis";

const { Search } = Input;


const SupplySetup = ({ userData,marketplace_id, selectedMarketplace }) => {
  const [visible, setVisible] = useState(false);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    page: 1,
    "per-page": 20,
    totalPage: 0,
    name: null
  });
  const [selectedRow, setSelectedRow] = useState({});

  const columns = [
    {
      title: "#",
      align: "left",
      render: (_, __, i) => {
        return (
          <span>{(filters?.page - 1) * filters?.["per-page"] + 1 + i}</span>
        );
      },
    },
    {
      title: "Supply",
      dataIndex: "supply",
      key: "supply",
    },
    {
      title: "Action",
      key: "action",
      width: 150,
      render: (text, record) => (
        <Space size="middle">
          <Button
            color="primary"
            variant="solid"
            onClick={() => handleEdit(record)}
            icon={<Icon width={22} icon="iconamoon:edit-fill" />}
          ></Button>
          <Popconfirm
            title="Are you sure to delete this supply?"
            onConfirm={() => handleDelete(record)}
            okText="Yes"
            placement="left"
            cancelText="No"
          >
            <Button
              color="danger"
              variant="filled"
              icon={<Icon width={22} icon="ic:round-delete" />}
            ></Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const ListSupplyAction = async (data) => {
    const { totalPage, ...rest } = data;
    setLoading(true);
    try {
      const response = await MakeApiCall(
        `supplys${ConvertParams(rest)}`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        const { totalCount, page, page_size } = response?.data?.pagination;

        setFilters({
          ...data,
          page: page,
          "per-page": page_size,
          totalPage: totalCount,
        });

        setList(response?.data?.records || []);

        setLoading(false);
      } else {
        message.destroy();
        setLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setLoading(false);
      message.destroy();
      message.error(error?.message);
    }
  };

  useEffect(() => {
    ListSupplyAction({ ...filters });
    return () => {};
  }, []);

  // Edit handler
  const handleEdit = (record) => {
    setSelectedRow(record);
    setVisible(true);
  };

  const DeleteSupply = async (id) => {
    try {
      const response = await MakeApiCall(
        `supply/${id}`,
        "delete",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        ListSupplyAction({ ...filters });
        setLoading(true);
        message.destroy();
        message.success(response?.message);
      } else {
        message.destroy();

        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      message.error(error?.message);
    }
  };

  // Delete handler
  const handleDelete = (record) => {
    DeleteSupply(record.id);
  };


  const handleSearch = (value) => {
    // setLoading(true);
    ListSupplyAction(filters);
  };

  return (
    <Wrapper>
      <Card className="mb-5" title={
       <>
      <Search
            placeholder="Search by supply name"
            allowClear
            size="large"
            className=""
            onSearch={handleSearch}
            value={filters?.name || ""}
            style={{
              width: 250,
            }}
            onChange={(e) => {
              setFilters({
                ...filters,
                name: e.target.value,
              });
            }}
            onClear={() => {
              setFilters({
                ...filters,
                name: null, // Ensure it's null or empty string
              });
              ListSupplyAction({ ...filters, name: null }); // Fetch updated data when cleared
            }}
          />
       </>
     } 
     extra={
     <Flex wrap>
     <Button
         type="primary"
         className="me-3"
         onClick={() => setVisible(true)}
         icon={<Icon width={20} icon="material-symbols:add" />}
       >
         Add Supply
       </Button>
       {/* <ImportFIle
                   fileType=".xlsx"
                   multiple={false}
                   documentType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                   downloadSamplePath={`${URL}Product Line Template.xlsx`}
                   trigger={["click"]}
                   endpoint="import-product-lines"
                   methodKey="POST"
                   bodyKey={{
                     marketplace_id: selectedMarketplace,
                   }}
                   paramKey="UploadedDataFile[import_file]"
                   onResponse={(e) => {
                     if (e?.status === true) {
                       message.destroy();
                       message.success(e?.message);
                     } else {
                       message.destroy();
                       message.error(e?.message);
                     }
                   }}
                   tokenKey={userData?.token}
                 >
                   <Button
                     type="primary"
                     style={{ marginRight: "0px", padding: "5px 10px" }}
                   >
                     <Icon icon="bi:filetype-xlsx" width={18} /> Import
                   </Button>
                 </ImportFIle> */}
       </Flex>
     }
     >
        <Table2
        dataSource={list}
        loading={loading}
        columns={columns}
        scroll={{ x: "max-content", y: 500 }}
      />
    </Card>
      {/* <Table2
        dataSource={list}
        loading={loading}
        columns={columns}
        scroll={{ x: "max-content" }}
      /> */}
      <SupplyModalForm
        selectedRow={selectedRow}
        userData={userData}
        onSubmit={() => {
          setSelectedRow({});
          setVisible(false);
          ListSupplyAction({
            ...filters,
          });
          setLoading(true);
        }}
        visible={visible}
        setVisible={setVisible}
      />
    </Wrapper>
  );
};

export default SupplySetup;
