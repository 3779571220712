import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, message } from "antd";
import { MakeApiCall } from "../../../../apis";

const SupplyModalForm = ({
  setVisible,
  userData,
  visible,
  onSubmit,
  selectedRow,
}) => {
  const [form] = Form.useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  // Function to open modal
  useEffect(() => {
    if (Object.keys(selectedRow || {})?.length > 0) {
      form.setFieldsValue(selectedRow);
    } else {
      form.resetFields();
    }
  }, [selectedRow]);
  // Function to handle modal close
  const handleCancel = () => {
    setVisible(false);
  };

  const CreateBrand = async (data) => {
    setSubmitLoading(true);
    try {
      const response = await MakeApiCall(
        `${
          Object.keys(selectedRow || {})?.length > 0
            ? `supply/${selectedRow?.id}`
            : "supply"
        }`,
        Object.keys(selectedRow || {})?.length > 0 ? "PATCH" : "POST",
        data,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        form.resetFields();
        onSubmit();
        setSubmitLoading(false);
        message.destroy();
        message.success(response?.message);
      } else {
        message.destroy();
        setSubmitLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setSubmitLoading(false);
      message.destroy();
      message.error(error?.message);
    }
  };

  // Function to handle form submit
  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        CreateBrand(values);
      })
      .catch((info) => {});
  };

  return (
    <>
      <Modal
        title="Add Supply"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ loading: submitLoading }}
      >
        <Form form={form} layout="vertical" name="supply_form">
          <Form.Item
            name="supply"
            label="Supply"
            rules={[{ required: true, message: "Please input the supply!" }]}
          >
            <Input placeholder="Enter supply" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default SupplyModalForm;
