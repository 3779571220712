import React from "react";

export default function ProfilePage() {
  return (
    <div>
      <div className="row gy-7 g-xl-7">
        {/*begin::Col*/}
        <div className="col-xl-6 ">
          {/*begin::Basic info*/}
          <div className="card card-flush h-lg-100 mb-5 mb-xl-5">
            {/*begin::Card header*/}
            <div
              className="card-header border-0 cursor-pointer"
              role="button"
              data-bs-toggle="collapse"
              data-bs-target="#kt_account_profile_details"
              aria-expanded="true"
              aria-controls="kt_account_profile_details"
            >
              {/*begin::Card title*/}
              <div className="card-title m-0">
                <h3 className="fw-bold m-0 gotham_black">Profile Details</h3>
              </div>
              {/*end::Card title*/}
            </div>
            {/*begin::Card header*/}
            {/*begin::Content*/}
            <div
              id="kt_account_settings_profile_details"
              className="collapse show"
            >
              {/*begin::Form*/}
              <form id="kt_account_profile_details_form" className="form">
                {/*begin::Card body*/}
                <div className="card-body border-top border-top-dashed p-9">
                  {/*begin::Input group*/}
                  <div className="row mb-6">
                    {/*begin::Label*/}
                    <label className="col-lg-4 col-form-label fw-bold fs-6 got">
                      Avatar
                    </label>
                    {/*end::Label*/}
                    {/*begin::Col*/}
                    <div className="col-lg-8">
                      {/*begin::Image input*/}
                      <div
                        className="image-input image-input-outline"
                        data-kt-image-input="true"
                        style={{
                          backgroundImage:
                            "url(/assets/media/avatars/blank.png)",
                        }}
                      >
                        {/*begin::Preview existing avatar*/}
                        <div
                          className="image-input-wrapper w-125px h-125px"
                          style={{
                            backgroundImage:
                              "url(/assets/media/avatars/300-16.jpg)",
                          }}
                        ></div>
                        {/*end::Preview existing avatar*/}
                        {/*begin::Label*/}
                        <label
                          className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                          data-kt-image-input-action="change"
                          data-bs-toggle="tooltip"
                          aria-label="Change avatar"
                          data-bs-original-title="Change avatar"
                          data-kt-initialized={1}
                        >
                          <i className="bi bi-pencil-fill fs-7" />
                          {/*begin::Inputs*/}
                          <input
                            type="file"
                            name="avatar"
                            accept=".png, .jpg, .jpeg"
                          />
                          <input type="hidden" name="avatar_remove" />
                          {/*end::Inputs*/}
                        </label>
                        {/*end::Label*/}
                        {/*begin::Cancel*/}
                        <span
                          className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                          data-kt-image-input-action="cancel"
                          data-bs-toggle="tooltip"
                          aria-label="Cancel avatar"
                          data-bs-original-title="Cancel avatar"
                          data-kt-initialized={1}
                        >
                          <i className="bi bi-x fs-2" />
                        </span>
                        {/*end::Cancel*/}
                        {/*begin::Remove*/}
                        <span
                          className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                          data-kt-image-input-action="remove"
                          data-bs-toggle="tooltip"
                          aria-label="Remove avatar"
                          data-bs-original-title="Remove avatar"
                          data-kt-initialized={1}
                        >
                          <i className="bi bi-x fs-2" />
                        </span>
                        {/*end::Remove*/}
                      </div>
                      {/*end::Image input*/}
                      {/*begin::Hint*/}
                      <div className="form-text mt-5">
                        Allowed file types: png, jpg, jpeg.
                      </div>
                      {/*end::Hint*/}
                    </div>
                    {/*end::Col*/}
                  </div>
                  {/*end::Input group*/}
                  {/*begin::Input group*/}
                  <div className="row mb-6">
                    {/*begin::Label*/}
                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                      Full Name
                    </label>
                    {/*end::Label*/}
                    {/*begin::Col*/}
                    <div className="col-lg-8">
                      {/*begin::Row*/}
                      <div className="row">
                        {/*begin::Col*/}
                        <div className="col-lg-6 fv-row">
                          <input
                            type="text"
                            name="fname"
                            className="form-control  mb-3 mb-lg-0"
                            placeholder="First name"
                            defaultValue="Paul"
                          />
                        </div>
                        {/*end::Col*/}
                        {/*begin::Col*/}
                        <div className="col-lg-6 fv-row">
                          <input
                            type="text"
                            name="lname"
                            className="form-control "
                            placeholder="Last name"
                            defaultValue="Melone"
                          />
                        </div>
                        {/*end::Col*/}
                      </div>
                      {/*end::Row*/}
                    </div>
                    {/*end::Col*/}
                  </div>
                  {/*end::Input group*/}
                  {/*begin::Input group*/}
                  <div className="row mb-6">
                    {/*begin::Label*/}
                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                      Email Address
                    </label>
                    {/*end::Label*/}
                    {/*begin::Col*/}
                    <div className="col-lg-8 fv-row">
                      <input
                        type="text"
                        name="company"
                        className="form-control "
                        placeholder="Email"
                        defaultValue="admin@paulmelone.com
  "
                      />
                    </div>
                    {/*end::Col*/}
                  </div>
                  {/*end::Input group*/}
                  {/*begin::Input group*/}
                  <div className="row mb-6">
                    {/*begin::Label*/}
                    <label className="col-lg-4 col-form-label fw-bold fs-6">
                      <span className="required">Contact Phone</span>
                      <i
                        className="fas fa-exclamation-circle ms-1 fs-7"
                        data-bs-toggle="tooltip"
                        aria-label="Phone number must be active"
                        data-bs-original-title="Phone number must be active"
                        data-kt-initialized={1}
                      />
                    </label>
                    {/*end::Label*/}
                    {/*begin::Col*/}
                    <div className="col-lg-8 fv-row">
                      <input
                        type="tel"
                        name="phone"
                        className="form-control "
                        placeholder="Phone number"
                        defaultValue="+1 252 000 1129"
                      />
                    </div>
                    {/*end::Col*/}
                  </div>
                  {/*end::Input group*/}
                </div>
                {/*end::Card body*/}
                {/*begin::Actions*/}
                <div className="card-footer d-flex justify-content-end py-6 px-9 border-top border-top-dashed">
                  <button
                    type="reset"
                    className="btn btn-light-primary me-2 fw-bold fs-7"
                  >
                    Discard
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary fw-bold fs-7"
                    id="kt_account_profile_details_submit"
                  >
                    Save Changes
                  </button>
                </div>
                {/*end::Actions*/}
              </form>
              {/*end::Form*/}
            </div>
            {/*end::Content*/}
          </div>
          {/*end::Basic info*/}
        </div>
        {/*end::Col*/}
        <div className="col-xl-6 ">
          {/*begin::Sign-in Method*/}
          <div className="card card-flush h-lg-100 mb-5 mb-xl-5">
            {/*begin::Card header*/}
            <div
              className="card-header border-0 cursor-pointer"
              role="button"
              data-bs-toggle="collapse"
              data-bs-target="#kt_account_signin_method"
            >
              <div className="card-title m-0">
                <h3 className="fw-bold m-0 gotham_black">Sign-in Method</h3>
              </div>
            </div>
            {/*end::Card header*/}
            {/*begin::Content*/}
            <div
              id="kt_account_settings_signin_method"
              className="collapse show"
            >
              {/*begin::Card body*/}
              <div className="card-body border-top border-top-dashed p-9">
                {/*begin::Email Address*/}
                <div className="d-flex flex-wrap align-items-center">
                  {/*begin::Label*/}
                  <div id="kt_signin_email">
                    <div className="fs-7 text-primary fw-bold mb-1">
                      Email Address
                    </div>
                    <div className="fw-semibold fs-6 text-gray-800">
                      admin@paulmelone.com
                    </div>
                  </div>
                  {/*end::Label*/}
                  {/*begin::Edit*/}
                  <div
                    id="kt_signin_email_edit"
                    className="flex-row-fluid d-none"
                  >
                    {/*begin::Form*/}
                    <form
                      id="kt_signin_change_email"
                      className="form fv-plugins-bootstrap5 fv-plugins-framework"
                      noValidate="novalidate"
                    >
                      <div className="row mb-6">
                        <div className="col-lg-6 mb-4 mb-lg-0">
                          <div className="fv-row mb-0 fv-plugins-icon-container">
                            <label
                              htmlFor="emailaddress"
                              className="form-label fs-6 fw-bold mb-3"
                            >
                              Enter New Email Address
                            </label>
                            <input
                              type="email"
                              className="form-control form-control-lg fs-7"
                              id="emailaddress"
                              placeholder="Email Address"
                              name="emailaddress"
                              defaultValue="admin@paulmelone.com
  "
                            />
                            <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="fv-row mb-0 fv-plugins-icon-container">
                            <label
                              htmlFor="confirmemailpassword"
                              className="form-label fs-6 fw-bold mb-3"
                            >
                              Confirm Password
                            </label>
                            <input
                              type="password"
                              className="form-control form-control-lg fs-7"
                              name="confirmemailpassword"
                              id="confirmemailpassword"
                            />
                            <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex">
                        <button
                          id="kt_signin_submit"
                          type="button"
                          className="btn btn-primary  me-2 px-6 fs-7"
                        >
                          Update Email
                        </button>
                        <button
                          id="kt_signin_cancel"
                          type="button"
                          className="btn btn-light-primary fw-bold px-6 fs-7"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                    {/*end::Form*/}
                  </div>
                  {/*end::Edit*/}
                  {/*begin::Action*/}
                  <div id="kt_signin_email_button" className="ms-auto">
                    <button className="btn btn-light btn-active-light-primary fs-7">
                      Change Email
                    </button>
                  </div>
                  {/*end::Action*/}
                </div>
                {/*end::Email Address*/}
                {/*begin::Separator*/}
                <div className="separator separator-dashed my-6" />
                {/*end::Separator*/}
                {/*begin::Password*/}
                <div className="d-flex flex-wrap align-items-center mb-10">
                  {/*begin::Label*/}
                  <div id="kt_signin_password">
                    <div className="fs-7 text-primary fw-bold mb-1">
                      Password
                    </div>
                    <div className="fw-semibold fs-6 text-gray-800">
                      ************
                    </div>
                  </div>
                  {/*end::Label*/}
                  {/*begin::Edit*/}
                  <div
                    id="kt_signin_password_edit"
                    className="flex-row-fluid d-none"
                  >
                    {/*begin::Form*/}
                    <form
                      id="kt_signin_change_password"
                      className="form fv-plugins-bootstrap5 fv-plugins-framework"
                      noValidate="novalidate"
                    >
                      <div className="row mb-1">
                        <div className="col-lg-4">
                          <div className="fv-row mb-0 fv-plugins-icon-container">
                            <label
                              htmlFor="currentpassword"
                              className="form-label fs-6 fw-bold mb-3"
                            >
                              Current Password
                            </label>
                            <input
                              type="password"
                              className="form-control form-control-lg "
                              name="currentpassword"
                              id="currentpassword"
                            />
                            <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="fv-row mb-0 fv-plugins-icon-container">
                            <label
                              htmlFor="newpassword"
                              className="form-label fs-6 fw-bold mb-3"
                            >
                              New Password
                            </label>
                            <input
                              type="password"
                              className="form-control form-control-lg "
                              name="newpassword"
                              id="newpassword"
                            />
                            <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="fv-row mb-0 fv-plugins-icon-container">
                            <label
                              htmlFor="confirmpassword"
                              className="form-label fs-6 fw-bold mb-3"
                            >
                              Confirm New Password
                            </label>
                            <input
                              type="password"
                              className="form-control form-control-lg "
                              name="confirmpassword"
                              id="confirmpassword"
                            />
                            <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />
                          </div>
                        </div>
                      </div>
                      <div className="form-text mb-5">
                        Password must be at least 8 character and contain
                        symbols
                      </div>
                      <div className="d-flex">
                        <button
                          id="kt_password_submit"
                          type="button"
                          className="btn btn-primary me-2 px-6 fs-7"
                        >
                          Update Password
                        </button>
                        <button
                          id="kt_password_cancel"
                          type="button"
                          className="btn  btn-light-primary px-6 fs-7"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                    {/*end::Form*/}
                  </div>
                  {/*end::Edit*/}
                  {/*begin::Action*/}
                  <div id="kt_signin_password_button" className="ms-auto">
                    <button className="btn btn-light btn-active-light-primary fs-7">
                      Reset Password
                    </button>
                  </div>
                  {/*end::Action*/}
                </div>
                {/*end::Password*/}
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Content*/}
          </div>
          {/*end::Sign-in Method*/}
        </div>
      </div>
    </div>
  );
}
