import React, { useState } from "react";
import { Wrapper } from "./style";
import { Button, Form, Input, message } from "antd";
import { Link } from "react-router-dom";
import { MakeApiCall } from "../../apis";

const ForgotPassword = () => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const onFinish = async (values) => {
    setSubmitLoading(true);
    const response = await MakeApiCall("forgot", "post", { ...values });

    if (response?.status === true) {
      setSubmitLoading(false);
      message.destroy();
      message.success(response?.message);
    } else {
      setSubmitLoading(false);
      message.destroy();
      message.error(response?.message);
    }
  };
  return (
    <Wrapper className="position-relative z-index-5">
      <div className="d-flex flex-column flex-root  " id="kt_app_root">
        {/*begin::Authentication - Sign-in */}
        <div
          className="d-flex flex-column flex-lg-row flex-column-fluid bg"
          style={{ background: "url(assets/media/bg-login.jpg)" }}
        >
          {/*begin::Body*/}
          <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1 ">
            {/*begin::Form*/}
            <div className="slide-bottom d-flex flex-center flex-column flex-lg-row-fluid  ">
              {/*begin::Wrapper*/}
              <div className="w-lg-500px p-15 bg-white rounded-3 position-relative back-change">
                <div className=" text-center mb-7">
                  <img src="assets/media/logo-1.png" className="h-60px" alt />
                </div>
                {/*begin::Heading*/}
                <div className="text-center mb-11">
                  <div className="text-black-500 fw-semibold fs-6">
                    Please enter your E-mail.
                  </div>
                  {/*end::Subtitle=*/}
                  {/*end::Subtitle=*/}
                </div>
                {/*begin::Form*/}
                <Form
                  name="forgot_password_form"
                  className="signin-form w-100 "
                  onFinish={onFinish}
                >
                  <Form.Item
                    name="u_email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Email!",
                      },
                    ]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={submitLoading}
                      className="signin-form-button w-100 "
                    >
                      Send Email
                    </Button>
                  </Form.Item>
                </Form>
                <div className="text-gray-900 text-center fw-semibold fs-6">
                  Already have an account?
                  <Link to="/login" className="link-primary">
                    Sign In
                  </Link>
                </div>
                {/*end::Form*/}
              </div>
              {/*end::Wrapper*/}
            </div>
            {/*end::Form*/}
          </div>
          {/*end::Body*/}
          {/*begin::Aside*/}
          <div
            className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2 position-relative bg-white shadow"
            style={{
              BackgroundImage: "url(assets/media/misc/auth-bg-new.png)",
              borderRadius: "100px 0 0 100px",
            }}
          >
            {/*begin::Content*/}
            <div className="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">
              {/*begin::Image*/}
              <img
                className="d-none d-lg-block mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20"
                src="assets/media/Visual_data-pana.svg"
                alt
              />
              {/*end::Image*/}
              {/*begin::Title*/}
              <h1 className="d-none d-lg-block text-gray-900 fs-2qx fw-bolder text-center mb-7">
                <div className="fs-4 mb-2">Expert Solution for </div>Order
                Tracking &amp; Replenishment
              </h1>
              {/*end::Title*/}
              {/*begin::Text*/}
              <div className="d-none d-lg-block text-gray-900 fs-7 text-center w-75">
                Achieve seamless integration with Amazon using our advanced
                application for effortless data acquisition and in-depth
                analysis. Maximize your business potential with actionable
                insights from Amazon's extensive dataset, all within our
                sophisticated platform.
              </div>
              {/*end::Text*/}
            </div>
            {/*end::Content*/}
          </div>
          {/*end::Aside*/}
        </div>
        {/*end::Authentication - Sign-in*/}
      </div>
    </Wrapper>
  );
};

export default ForgotPassword;
