export const countryData = [
    {
        "country_name": "Afghanistan",
        "ISO2": "AF",
        "ISO3": "AFG",
        "top_level_domain": "af",
        "phone_code": "93",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/af.png",
        "min_phone_number_length": 9,
        "max_phone_number_length": 9
    },
    {
        "country_name": "Albania",
        "ISO2": "AL",
        "ISO3": "ALB",
        "top_level_domain": "al",
        "phone_code": "355",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/al.png",
        "min_phone_number_length": 9,
        "max_phone_number_length": 9
    },
    {
        "country_name": "Algeria",
        "ISO2": "DZ",
        "ISO3": "DZA",
        "top_level_domain": "dz",
        "phone_code": "213",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/dz.png",
        "min_phone_number_length": 9,
        "max_phone_number_length": 10
    },
    {
        "country_name": "American Samoa",
        "ISO2": "AS",
        "ISO3": "ASM",
        "top_level_domain": "as",
        "phone_code": "1-684",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/as.png",
        "min_phone_number_length": 7,
        "max_phone_number_length": 10
    },
    {
        "country_name": "Andorra",
        "ISO2": "AD",
        "ISO3": "AND",
        "top_level_domain": "ad",
        "phone_code": "376",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/ad.png",
        "min_phone_number_length": 6,
        "max_phone_number_length": 9
    },
    {
        "country_name": "Angola",
        "ISO2": "AO",
        "ISO3": "AGO",
        "top_level_domain": "ao",
        "phone_code": "244",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/ao.png",
        "min_phone_number_length": 9,
        "max_phone_number_length": 9
    },
    {
        "country_name": "Anguilla",
        "ISO2": "AI",
        "ISO3": "AIA",
        "top_level_domain": "ai",
        "phone_code": "1-264",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/ai.png",
        "min_phone_number_length": 7,
        "max_phone_number_length": 10
    },
    {
        "country_name": "Antarctica",
        "ISO2": "AQ",
        "ISO3": "ATA",
        "top_level_domain": "aq",
        "phone_code": "672",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/aq.png",
        "min_phone_number_length": 6,
        "max_phone_number_length": 7
    },
    {
        "country_name": "Antigua and Barbuda",
        "ISO2": "AG",
        "ISO3": "ATG",
        "top_level_domain": "ag",
        "phone_code": "1-268",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/ag.png",
        "min_phone_number_length": 7,
        "max_phone_number_length": 10
    },
    {
        "country_name": "Argentina",
        "ISO2": "AR",
        "ISO3": "ARG",
        "top_level_domain": "ar",
        "phone_code": "54",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/ar.png",
        "min_phone_number_length": 10,
        "max_phone_number_length": 10
    },
    {
        "country_name": "Armenia",
        "ISO2": "AM",
        "ISO3": "ARM",
        "top_level_domain": "am",
        "phone_code": "374",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/am.png",
        "min_phone_number_length": 8,
        "max_phone_number_length": 8
    },
    {
        "country_name": "Aruba",
        "ISO2": "AW",
        "ISO3": "ABW",
        "top_level_domain": "aw",
        "phone_code": "297",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/aw.png",
        "min_phone_number_length": 7,
        "max_phone_number_length": 7
    },
    {
        "country_name": "Australia",
        "ISO2": "AU",
        "ISO3": "AUS",
        "top_level_domain": "au",
        "phone_code": "61",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/au.png",
        "min_phone_number_length": 9,
        "max_phone_number_length": 10
    },
    {
        "country_name": "Austria",
        "ISO2": "AT",
        "ISO3": "AUT",
        "top_level_domain": "at",
        "phone_code": "43",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/at.png",
        "min_phone_number_length": 4,
        "max_phone_number_length": 13
    },
    {
        "country_name": "Azerbaijan",
        "ISO2": "AZ",
        "ISO3": "AZE",
        "top_level_domain": "az",
        "phone_code": "994",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/az.png",
        "min_phone_number_length": 9,
        "max_phone_number_length": 9
    },
    {
        "country_name": "Bahamas",
        "ISO2": "BS",
        "ISO3": "BHS",
        "top_level_domain": "bs",
        "phone_code": "1-242",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/bs.png",
        "min_phone_number_length": 10,
        "max_phone_number_length": 10
    },
    {
        "country_name": "Bahrain",
        "ISO2": "BH",
        "ISO3": "BHR",
        "top_level_domain": "bh",
        "phone_code": "973",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/bh.png",
        "min_phone_number_length": 8,
        "max_phone_number_length": 8
    },
    {
        "country_name": "Bangladesh",
        "ISO2": "BD",
        "ISO3": "BGD",
        "top_level_domain": "bd",
        "phone_code": "880",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/bd.png",
        "min_phone_number_length": 8,
        "max_phone_number_length": 10
    },
    {
        "country_name": "Barbados",
        "ISO2": "BB",
        "ISO3": "BRB",
        "top_level_domain": "bb",
        "phone_code": "1-246",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/bb.png",
        "min_phone_number_length": 7,
        "max_phone_number_length": 7
    },
    {
        "country_name": "Belarus",
        "ISO2": "BY",
        "ISO3": "BLR",
        "top_level_domain": "by",
        "phone_code": "375",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/by.png",
        "min_phone_number_length": 9,
        "max_phone_number_length": 9
    },
    {
        "country_name": "Belgium",
        "ISO2": "BE",
        "ISO3": "BEL",
        "top_level_domain": "be",
        "phone_code": "32",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/be.png",
        "min_phone_number_length": 8,
        "max_phone_number_length": 9
    },
    {
        "country_name": "Belize",
        "ISO2": "BZ",
        "ISO3": "BLZ",
        "top_level_domain": "bz",
        "phone_code": "501",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/bz.png",
        "min_phone_number_length": 7,
        "max_phone_number_length": 7
    },
    {
        "country_name": "Benin",
        "ISO2": "BJ",
        "ISO3": "BEN",
        "top_level_domain": "bj",
        "phone_code": "229",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/bj.png",
        "min_phone_number_length": 8,
        "max_phone_number_length": 8
    },
    {
        "country_name": "Bermuda",
        "ISO2": "BM",
        "ISO3": "BMU",
        "top_level_domain": "bm",
        "phone_code": "1-441",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/bm.png",
        "min_phone_number_length": 10,
        "max_phone_number_length": 10
    },
    {
        "country_name": "Bhutan",
        "ISO2": "BT",
        "ISO3": "BTN",
        "top_level_domain": "bt",
        "phone_code": "975",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/bt.png",
        "min_phone_number_length": 8,
        "max_phone_number_length": 8
    },
    {
        "country_name": "Bolivia",
        "ISO2": "BO",
        "ISO3": "BOL",
        "top_level_domain": "bo",
        "phone_code": "591",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/bo.png",
        "min_phone_number_length": 8,
        "max_phone_number_length": 8
    },
    {
        "country_name": "Bosnia and Herzegovina",
        "ISO2": "BA",
        "ISO3": "BIH",
        "top_level_domain": "ba",
        "phone_code": "387",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/ba.png",
        "min_phone_number_length": 8,
        "max_phone_number_length": 9
    },
    {
        "country_name": "Botswana",
        "ISO2": "BW",
        "ISO3": "BWA",
        "top_level_domain": "bw",
        "phone_code": "267",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/bw.png",
        "min_phone_number_length": 7,
        "max_phone_number_length": 7
    },
    {
        "country_name": "Brazil",
        "ISO2": "BR",
        "ISO3": "BRA",
        "top_level_domain": "br",
        "phone_code": "55",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/br.png",
        "min_phone_number_length": 10,
        "max_phone_number_length": 11
    },
    {
        "country_name": "British Indian Ocean Territory",
        "ISO2": "IO",
        "ISO3": "IOT",
        "top_level_domain": "io",
        "phone_code": "246",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/io.png",
        "min_phone_number_length": 7,
        "max_phone_number_length": 7
    },
    {
        "country_name": "British Virgin Islands",
        "ISO2": "VG",
        "ISO3": "VGB",
        "top_level_domain": "vg",
        "phone_code": "1-284",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/vg.png",
        "min_phone_number_length": 10,
        "max_phone_number_length": 10
    },
    {
        "country_name": "Brunei",
        "ISO2": "BN",
        "ISO3": "BRN",
        "top_level_domain": "bn",
        "phone_code": "673",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/bn.png",
        "min_phone_number_length": 7,
        "max_phone_number_length": 7
    },
    {
        "country_name": "Bulgaria",
        "ISO2": "BG",
        "ISO3": "BGR",
        "top_level_domain": "bg",
        "phone_code": "359",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/bg.png",
        "min_phone_number_length": 8,
        "max_phone_number_length": 9
    },
    {
        "country_name": "Burkina Faso",
        "ISO2": "BF",
        "ISO3": "BFA",
        "top_level_domain": "bf",
        "phone_code": "226",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/bf.png",
        "min_phone_number_length": 8,
        "max_phone_number_length": 8
    },
    {
        "country_name": "Burundi",
        "ISO2": "BI",
        "ISO3": "BDI",
        "top_level_domain": "bi",
        "phone_code": "257",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/bi.png",
        "min_phone_number_length": 8,
        "max_phone_number_length": 8
    },
    {
        "country_name": "Cambodia",
        "ISO2": "KH",
        "ISO3": "KHM",
        "top_level_domain": "kh",
        "phone_code": "855",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/kh.png",
        "min_phone_number_length": 8,
        "max_phone_number_length": 9
    },
    {
        "country_name": "Cameroon",
        "ISO2": "CM",
        "ISO3": "CMR",
        "top_level_domain": "cm",
        "phone_code": "237",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/cm.png",
        "min_phone_number_length": 8,
        "max_phone_number_length": 9
    },
    {
        "country_name": "Canada",
        "ISO2": "CA",
        "ISO3": "CAN",
        "top_level_domain": "ca",
        "phone_code": "1",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/ca.png",
        "min_phone_number_length": 10,
        "max_phone_number_length": 10
    },
    {
        "country_name": "Cape Verde",
        "ISO2": "CV",
        "ISO3": "CPV",
        "top_level_domain": "cv",
        "phone_code": "238",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/cv.png",
        "min_phone_number_length": 7,
        "max_phone_number_length": 7
    },
    {
        "country_name": "Cayman Islands",
        "ISO2": "KY",
        "ISO3": "CYM",
        "top_level_domain": "ky",
        "phone_code": "1-345",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/ky.png",
        "min_phone_number_length": 10,
        "max_phone_number_length": 10
    },
    {
        "country_name": "Central African Republic",
        "ISO2": "CF",
        "ISO3": "CAF",
        "top_level_domain": "cf",
        "phone_code": "236",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/cf.png",
        "min_phone_number_length": 8,
        "max_phone_number_length": 8
    },
    {
        "country_name": "Chad",
        "ISO2": "TD",
        "ISO3": "TCD",
        "top_level_domain": "td",
        "phone_code": "235",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/td.png",
        "min_phone_number_length": 8,
        "max_phone_number_length": 8
    },
    {
        "country_name": "Chile",
        "ISO2": "CL",
        "ISO3": "CHL",
        "top_level_domain": "cl",
        "phone_code": "56",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/cl.png",
        "min_phone_number_length": 9,
        "max_phone_number_length": 9
    },
    {
        "country_name": "China",
        "ISO2": "CN",
        "ISO3": "CHN",
        "top_level_domain": "cn",
        "phone_code": "86",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/cn.png",
        "min_phone_number_length": 11,
        "max_phone_number_length": 11
    },
    {
        "country_name": "Christmas Island",
        "ISO2": "CX",
        "ISO3": "CXR",
        "top_level_domain": "cx",
        "phone_code": "61",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/cx.png",
        "min_phone_number_length": 9,
        "max_phone_number_length": 9
    },
    {
        "country_name": "Cocos Islands",
        "ISO2": "CC",
        "ISO3": "CCK",
        "top_level_domain": "cc",
        "phone_code": "61",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/cc.png",
        "min_phone_number_length": 9,
        "max_phone_number_length": 9
    },
    {
        "country_name": "Colombia",
        "ISO2": "CO",
        "ISO3": "COL",
        "top_level_domain": "co",
        "phone_code": "57",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/co.png",
        "min_phone_length": 10,
        "max_phone_length": 10
    },
    {
        "country_name": "Comoros",
        "ISO2": "KM",
        "ISO3": "COM",
        "top_level_domain": "km",
        "phone_code": "269",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/km.png",
        "min_phone_length": 7,
        "max_phone_length": 7
    },
    {
        "country_name": "Cook Islands",
        "ISO2": "CK",
        "ISO3": "COK",
        "top_level_domain": "ck",
        "phone_code": "682",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/ck.png",
        "min_phone_length": 5,
        "max_phone_length": 7
    },
    {
        "country_name": "Costa Rica",
        "ISO2": "CR",
        "ISO3": "CRI",
        "top_level_domain": "cr",
        "phone_code": "506",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/cr.png",
        "min_phone_length": 8,
        "max_phone_length": 8
    },
    {
        "country_name": "Croatia",
        "ISO2": "HR",
        "ISO3": "HRV",
        "top_level_domain": "hr",
        "phone_code": "385",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/hr.png",
        "min_phone_length": 8,
        "max_phone_length": 12
    },
    {
        "country_name": "Cuba",
        "ISO2": "CU",
        "ISO3": "CUB",
        "top_level_domain": "cu",
        "phone_code": "53",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/cu.png",
        "min_phone_length": 8,
        "max_phone_length": 8
    },
    {
        "country_name": "Curacao",
        "ISO2": "CW",
        "ISO3": "CUW",
        "top_level_domain": "cw",
        "phone_code": "599",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/cw.png",
        "min_phone_length": 7,
        "max_phone_length": 7
    },
    {
        "country_name": "Cyprus",
        "ISO2": "CY",
        "ISO3": "CYP",
        "top_level_domain": "cy",
        "phone_code": "357",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/cy.png",
        "min_phone_length": 8,
        "max_phone_length": 8
    },
    {
        "country_name": "Czech Republic",
        "ISO2": "CZ",
        "ISO3": "CZE",
        "top_level_domain": "cz",
        "phone_code": "420",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/cz.png",
        "min_phone_length": 9,
        "max_phone_length": 9
    },
    {
        "country_name": "Democratic Republic of the Congo",
        "ISO2": "CD",
        "ISO3": "COD",
        "top_level_domain": "cd",
        "phone_code": "243",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/cd.png",
        "min_phone_length": 9,
        "max_phone_length": 9
    },
    {
        "country_name": "Denmark",
        "ISO2": "DK",
        "ISO3": "DNK",
        "top_level_domain": "dk",
        "phone_code": "45",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/dk.png",
        "min_phone_length": 8,
        "max_phone_length": 8
    },
    {
        "country_name": "Djibouti",
        "ISO2": "DJ",
        "ISO3": "DJI",
        "top_level_domain": "dj",
        "phone_code": "253",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/dj.png",
        "min_phone_length": 6,
        "max_phone_length": 6
    },
    {
        "country_name": "Dominica",
        "ISO2": "DM",
        "ISO3": "DMA",
        "top_level_domain": "dm",
        "phone_code": "1-767",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/dm.png",
        "min_phone_length": 10,
        "max_phone_length": 10
    },
    {
        "country_name": "Dominican Republic",
        "ISO2": "DO",
        "ISO3": "DOM",
        "top_level_domain": "do",
        "phone_code": "1-809, 1-829, 1-849",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/do.png",
        "min_phone_length": 10,
        "max_phone_length": 10
    },
    {
        "country_name": "East Timor",
        "ISO2": "TL",
        "ISO3": "TLS",
        "top_level_domain": "tl",
        "phone_code": "670",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/tl.png",
        "min_phone_length": 7,
        "max_phone_length": 8
    },
    {
        "country_name": "Ecuador",
        "ISO2": "EC",
        "ISO3": "ECU",
        "top_level_domain": "ec",
        "phone_code": "593",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/ec.png",
        "min_phone_length": 7,
        "max_phone_length": 9
    },
    {
        "country_name": "Egypt",
        "ISO2": "EG",
        "ISO3": "EGY",
        "top_level_domain": "eg",
        "phone_code": "20",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/eg.png",
        "min_phone_length": 10,
        "max_phone_length": 10
    },
    {
        "country_name": "El Salvador",
        "ISO2": "SV",
        "ISO3": "SLV",
        "top_level_domain": "sv",
        "phone_code": "503",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/sv.png",
        "min_phone_length": 8,
        "max_phone_length": 8
    },
    {
        "country_name": "Equatorial Guinea",
        "ISO2": "GQ",
        "ISO3": "GNQ",
        "top_level_domain": "gq",
        "phone_code": "240",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/gq.png",
        "min_phone_length": 9,
        "max_phone_length": 9
    },
    {
        "country_name": "Eritrea",
        "ISO2": "ER",
        "ISO3": "ERI",
        "top_level_domain": "er",
        "phone_code": "291",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/er.png",
        "min_phone_length": 7,
        "max_phone_length": 7
    },
    {
        "country_name": "Estonia",
        "ISO2": "EE",
        "ISO3": "EST",
        "top_level_domain": "ee",
        "phone_code": "372",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/ee.png",
        "min_phone_length": 7,
        "max_phone_length": 8
    },
    {
        "country_name": "Ethiopia",
        "ISO2": "ET",
        "ISO3": "ETH",
        "top_level_domain": "et",
        "phone_code": "251",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/et.png",
        "min_phone_length": 9,
        "max_phone_length": 9
    },
    {
        "country_name": "Falkland Islands",
        "ISO2": "FK",
        "ISO3": "FLK",
        "top_level_domain": "fk",
        "phone_code": "500",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/fk.png",
        "min_phone_length": 5,
        "max_phone_length": 5
    },
    {
        "country_name": "Faroe Islands",
        "ISO2": "FO",
        "ISO3": "FRO",
        "top_level_domain": "fo",
        "phone_code": "298",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/fo.png",
        "min_phone_length": 6,
        "max_phone_length": 6
    },
    {
        "country_name": "Fiji",
        "ISO2": "FJ",
        "ISO3": "FJI",
        "top_level_domain": "fj",
        "phone_code": "679",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/fj.png",
        "min_phone_length": 7,
        "max_phone_length": 7
    },
    {
        "country_name": "Finland",
        "ISO2": "FI",
        "ISO3": "FIN",
        "top_level_domain": "fi",
        "phone_code": "358",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/fi.png",
        "min_phone_length": 9,
        "max_phone_length": 12
    },
    {
        "country_name": "France",
        "ISO2": "FR",
        "ISO3": "FRA",
        "top_level_domain": "fr",
        "phone_code": "33",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/fr.png",
        "min_phone_length": 9,
        "max_phone_length": 9
    },
    {
        "country_name": "French Polynesia",
        "ISO2": "PF",
        "ISO3": "PYF",
        "top_level_domain": "pf",
        "phone_code": "689",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/pf.png",
        "min_phone_length": 6,
        "max_phone_length": 6
    },
    {
        "country_name": "Gabon",
        "ISO2": "GA",
        "ISO3": "GAB",
        "top_level_domain": "ga",
        "phone_code": "241",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/ga.png",
        "min_phone_length": 7,
        "max_phone_length": 9
    },
    {
        "country_name": "Gambia",
        "ISO2": "GM",
        "ISO3": "GMB",
        "top_level_domain": "gm",
        "phone_code": "220",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/gm.png",
        "min_phone_length": 7,
        "max_phone_length": 7
    },
    {
        "country_name": "Georgia",
        "ISO2": "GE",
        "ISO3": "GEO",
        "top_level_domain": "ge",
        "phone_code": "995",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/ge.png",
        "min_phone_length": 9,
        "max_phone_length": 9
    },
    {
        "country_name": "Germany",
        "ISO2": "DE",
        "ISO3": "DEU",
        "top_level_domain": "de",
        "phone_code": "49",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/de.png",
        "min_phone_length": 10,
        "max_phone_length": 11
    },
    {
        "country_name": "Ghana",
        "ISO2": "GH",
        "ISO3": "GHA",
        "top_level_domain": "gh",
        "phone_code": "233",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/gh.png",
        "min_phone_length": 9,
        "max_phone_length": 10
    },
    {
        "country_name": "Gibraltar",
        "ISO2": "GI",
        "ISO3": "GIB",
        "top_level_domain": "gi",
        "phone_code": "350",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/gi.png",
        "min_phone_length": 8,
        "max_phone_length": 8
    },
    {
        "country_name": "Greece",
        "ISO2": "GR",
        "ISO3": "GRC",
        "top_level_domain": "gr",
        "phone_code": "30",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/gr.png",
        "min_phone_length": 10,
        "max_phone_length": 10
    },
    {
        "country_name": "Greenland",
        "ISO2": "GL",
        "ISO3": "GRL",
        "top_level_domain": "gl",
        "phone_code": "299",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/gl.png",
        "min_phone_length": 6,
        "max_phone_length": 6
    },
    {
        "country_name": "Grenada",
        "ISO2": "GD",
        "ISO3": "GRD",
        "top_level_domain": "gd",
        "phone_code": "1-473",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/gd.png",
        "min_phone_length": 10,
        "max_phone_length": 10
    },
    {
        "country_name": "Guam",
        "ISO2": "GU",
        "ISO3": "GUM",
        "top_level_domain": "gu",
        "phone_code": "1-671",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/gu.png",
        "min_phone_length": 10,
        "max_phone_length": 10
    },
    {
        "country_name": "Guatemala",
        "ISO2": "GT",
        "ISO3": "GTM",
        "top_level_domain": "gt",
        "phone_code": "502",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/gt.png",
        "min_phone_length": 8,
        "max_phone_length": 8
    },
    {
        "country_name": "Guernsey",
        "ISO2": "GG",
        "ISO3": "GGY",
        "top_level_domain": "gg",
        "phone_code": "44-1481",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/gg.png",
        "min_phone_length": 6,
        "max_phone_length": 10
    },
    {
        "country_name": "Guinea",
        "ISO2": "GN",
        "ISO3": "GIN",
        "top_level_domain": "gn",
        "phone_code": "224",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/gn.png",
        "min_phone_length": 8,
        "max_phone_length": 9
    },
    {
        "country_name": "Guinea-Bissau",
        "ISO2": "GW",
        "ISO3": "GNB",
        "top_level_domain": "gw",
        "phone_code": "245",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/gw.png",
        "min_phone_length": 7,
        "max_phone_length": 9
    },
    {
        "country_name": "Guyana",
        "ISO2": "GY",
        "ISO3": "GUY",
        "top_level_domain": "gy",
        "phone_code": "592",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/gy.png",
        "min_phone_length": 7,
        "max_phone_length": 10
    },
    {
        "country_name": "Haiti",
        "ISO2": "HT",
        "ISO3": "HTI",
        "top_level_domain": "ht",
        "phone_code": "509",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/ht.png",
        "min_phone_length": 8,
        "max_phone_length": 8
    },
    {
        "country_name": "Honduras",
        "ISO2": "HN",
        "ISO3": "HND",
        "top_level_domain": "hn",
        "phone_code": "504",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/hn.png",
        "min_phone_length": 7,
        "max_phone_length": 8
    },
    {
        "country_name": "Hong Kong",
        "ISO2": "HK",
        "ISO3": "HKG",
        "top_level_domain": "hk",
        "phone_code": "852",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/hk.png",
        "min_phone_length": 8,
        "max_phone_length": 8
    },
    {
        "country_name": "Hungary",
        "ISO2": "HU",
        "ISO3": "HUN",
        "top_level_domain": "hu",
        "phone_code": "36",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/hu.png",
        "min_phone_length": 8,
        "max_phone_length": 9
    },
    {
        "country_name": "Iceland",
        "ISO2": "IS",
        "ISO3": "ISL",
        "top_level_domain": "is",
        "phone_code": "354",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/is.png",
        "min_phone_length": 7,
        "max_phone_length": 9
    },
    {
        "country_name": "India",
        "ISO2": "IN",
        "ISO3": "IND",
        "top_level_domain": "in",
        "phone_code": "91",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/in.png",
        "min_phone_length": 10,
        "max_phone_length": 10
    },
    {
        "country_name": "Indonesia",
        "ISO2": "ID",
        "ISO3": "IDN",
        "top_level_domain": "id",
        "phone_code": "62",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/id.png",
        "min_phone_length": 9,
        "max_phone_length": 12
    },
    {
        "country_name": "Iran",
        "ISO2": "IR",
        "ISO3": "IRN",
        "top_level_domain": "ir",
        "phone_code": "98",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/ir.png",
        "min_phone_length": 10,
        "max_phone_length": 10
    },
    {
        "country_name": "Iraq",
        "ISO2": "IQ",
        "ISO3": "IRQ",
        "top_level_domain": "iq",
        "phone_code": "964",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/iq.png",
        "min_phone_length": 10,
        "max_phone_length": 10
    },
    {
        "country_name": "Ireland",
        "ISO2": "IE",
        "ISO3": "IRL",
        "top_level_domain": "ie",
        "phone_code": "353",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/ie.png",
        "min_phone_length": 9,
        "max_phone_length": 10
    },
    {
        "country_name": "Isle of Man",
        "ISO2": "IM",
        "ISO3": "IMN",
        "top_level_domain": "im",
        "phone_code": "44-1624",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/im.png",
        "min_phone_length": 6,
        "max_phone_length": 10
    },
    {
        "country_name": "Israel",
        "ISO2": "IL",
        "ISO3": "ISR",
        "top_level_domain": "il",
        "phone_code": "972",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/il.png",
        "min_phone_length": 9,
        "max_phone_length": 10
    },
    {
        "country_name": "Italy",
        "ISO2": "IT",
        "ISO3": "ITA",
        "top_level_domain": "it",
        "phone_code": "39",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/it.png",
        "min_phone_length": 9,
        "max_phone_length": 10
    },
    {
        "country_name": "Ivory Coast",
        "ISO2": "CI",
        "ISO3": "CIV",
        "top_level_domain": "ci",
        "phone_code": "225",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/ci.png",
        "min_phone_length": 8,
        "max_phone_length": 10
    },
    {
        "country_name": "Jamaica",
        "ISO2": "JM",
        "ISO3": "JAM",
        "top_level_domain": "jm",
        "phone_code": "1-876",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/jm.png",
        "min_phone_length": 7,
        "max_phone_length": 10
    },
    {
        "country_name": "Japan",
        "ISO2": "JP",
        "ISO3": "JPN",
        "top_level_domain": "jp",
        "phone_code": "81",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/jp.png",
        "min_phone_length": 10,
        "max_phone_length": 11
    },
    {
        "country_name": "Jersey",
        "ISO2": "JE",
        "ISO3": "JEY",
        "top_level_domain": "je",
        "phone_code": "44-1534",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/je.png",
        "min_phone_length": 6,
        "max_phone_length": 10
    },
    {
        "country_name": "Jordan",
        "ISO2": "JO",
        "ISO3": "JOR",
        "top_level_domain": "jo",
        "phone_code": "962",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/jo.png",
        "min_phone_length": 9,
        "max_phone_length": 9
    },
    {
        "country_name": "Kazakhstan",
        "ISO2": "KZ",
        "ISO3": "KAZ",
        "top_level_domain": "kz",
        "phone_code": "7",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/kz.png",
        "min_phone_length": 10,
        "max_phone_length": 10
    },
    {
        "country_name": "Kenya",
        "ISO2": "KE",
        "ISO3": "KEN",
        "top_level_domain": "ke",
        "phone_code": "254",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/ke.png",
        "min_phone_length": 9,
        "max_phone_length": 10
    },
    {
        "country_name": "Kiribati",
        "ISO2": "KI",
        "ISO3": "KIR",
        "top_level_domain": "ki",
        "phone_code": "686",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/ki.png",
        "min_phone_length": 5,
        "max_phone_length": 8
    },
    {
        "country_name": "Kosovo",
        "ISO2": "XK",
        "ISO3": "XKX",
        "top_level_domain": "kv",
        "phone_code": "383",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/kv.png",
        "min_phone_length": 8,
        "max_phone_length": 9
    },
    {
        "country_name": "Kuwait",
        "ISO2": "KW",
        "ISO3": "KWT",
        "top_level_domain": "kw",
        "phone_code": "965",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/kw.png",
        "min_phone_length": 8,
        "max_phone_length": 8
    },
    {
        "country_name": "Kyrgyzstan",
        "ISO2": "KG",
        "ISO3": "KGZ",
        "top_level_domain": "kg",
        "phone_code": "996",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/kg.png",
        "min_phone_length": 9,
        "max_phone_length": 9
    },
    {
        "country_name": "Laos",
        "ISO2": "LA",
        "ISO3": "LAO",
        "top_level_domain": "la",
        "phone_code": "856",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/la.png",
        "min_phone_length": 9,
        "max_phone_length": 10
    },
    {
        "country_name": "Latvia",
        "ISO2": "LV",
        "ISO3": "LVA",
        "top_level_domain": "lv",
        "phone_code": "371",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/lv.png",
        "min_phone_length": 8,
        "max_phone_length": 8
    },
    {
        "country_name": "Lebanon",
        "ISO2": "LB",
        "ISO3": "LBN",
        "top_level_domain": "lb",
        "phone_code": "961",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/lb.png",
        "min_phone_length": 7,
        "max_phone_length": 8
    },
    {
        "country_name": "Lesotho",
        "ISO2": "LS",
        "ISO3": "LSO",
        "top_level_domain": "ls",
        "phone_code": "266",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/ls.png",
        "min_phone_length": 8,
        "max_phone_length": 8
    },
    {
        "country_name": "Liberia",
        "ISO2": "LR",
        "ISO3": "LBR",
        "top_level_domain": "lr",
        "phone_code": "231",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/lr.png",
        "min_phone_length": 7,
        "max_phone_length": 9
    },
    {
        "country_name": "Libya",
        "ISO2": "LY",
        "ISO3": "LBY",
        "top_level_domain": "ly",
        "phone_code": "218",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/ly.png",
        "min_phone_length": 8,
        "max_phone_length": 9
    },
    {
        "country_name": "Liechtenstein",
        "ISO2": "LI",
        "ISO3": "LIE",
        "top_level_domain": "li",
        "phone_code": "423",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/li.png",
        "min_phone_length": 7,
        "max_phone_length": 9
    },
    {
        "country_name": "Lithuania",
        "ISO2": "LT",
        "ISO3": "LTU",
        "top_level_domain": "lt",
        "phone_code": "370",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/lt.png",
        "min_phone_length": 8,
        "max_phone_length": 8
    },
    {
        "country_name": "Luxembourg",
        "ISO2": "LU",
        "ISO3": "LUX",
        "top_level_domain": "lu",
        "phone_code": "352",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/lu.png",
        "min_phone_length": 8,
        "max_phone_length": 9
    },
    {
        "country_name": "Macau",
        "ISO2": "MO",
        "ISO3": "MAC",
        "top_level_domain": "mo",
        "phone_code": "853",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/mo.png",
        "min_phone_length": 8,
        "max_phone_length": 8
    },
    {
        "country_name": "Macedonia",
        "ISO2": "MK",
        "ISO3": "MKD",
        "top_level_domain": "mk",
        "phone_code": "389",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/mk.png",
        "min_phone_length": 8,
        "max_phone_length": 9
    },
    {
        "country_name": "Madagascar",
        "ISO2": "MG",
        "ISO3": "MDG",
        "top_level_domain": "mg",
        "phone_code": "261",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/mg.png",
        "min_phone_length": 9,
        "max_phone_length": 10
    },
    {
        "country_name": "Malawi",
        "ISO2": "MW",
        "ISO3": "MWI",
        "top_level_domain": "mw",
        "phone_code": "265",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/mw.png",
        "min_phone_length": 7,
        "max_phone_length": 9
    },
    {
        "country_name": "Malaysia",
        "ISO2": "MY",
        "ISO3": "MYS",
        "top_level_domain": "my",
        "phone_code": "60",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/my.png",
        "min_phone_length": 9,
        "max_phone_length": 10
    },
    {
        "country_name": "Maldives",
        "ISO2": "MV",
        "ISO3": "MDV",
        "top_level_domain": "mv",
        "phone_code": "960",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/mv.png",
        "min_phone_length": 7,
        "max_phone_length": 7
    },
    {
        "country_name": "Mali",
        "ISO2": "ML",
        "ISO3": "MLI",
        "top_level_domain": "ml",
        "phone_code": "223",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/ml.png",
        "min_phone_length": 8,
        "max_phone_length": 8
    },
    {
        "country_name": "Malta",
        "ISO2": "MT",
        "ISO3": "MLT",
        "top_level_domain": "mt",
        "phone_code": "356",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/mt.png",
        "min_phone_length": 8,
        "max_phone_length": 8
    },
    {
        "country_name": "Marshall Islands",
        "ISO2": "MH",
        "ISO3": "MHL",
        "top_level_domain": "mh",
        "phone_code": "692",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/mh.png",
        "min_phone_length": 7,
        "max_phone_length": 7
    },
    {
        "country_name": "Mauritania",
        "ISO2": "MR",
        "ISO3": "MRT",
        "top_level_domain": "mr",
        "phone_code": "222",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/mr.png",
        "min_phone_length": 8,
        "max_phone_length": 8
    },
    {
        "country_name": "Mauritius",
        "ISO2": "MU",
        "ISO3": "MUS",
        "top_level_domain": "mu",
        "phone_code": "230",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/mu.png",
        "min_phone_length": 7,
        "max_phone_length": 8
    },
    {
        "country_name": "Mayotte",
        "ISO2": "YT",
        "ISO3": "MYT",
        "top_level_domain": "yt",
        "phone_code": "262",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/yt.png",
        "min_phone_length": 9,
        "max_phone_length": 9
    },
    {
        "country_name": "Mexico",
        "ISO2": "MX",
        "ISO3": "MEX",
        "top_level_domain": "mx",
        "phone_code": "52",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/mx.png",
        "min_phone_length": 10,
        "max_phone_length": 10
    },
    {
        "country_name": "Micronesia",
        "ISO2": "FM",
        "ISO3": "FSM",
        "top_level_domain": "fm",
        "phone_code": "691",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/fm.png",
        "min_phone_length": 7,
        "max_phone_length": 7
    },
    {
        "country_name": "Moldova",
        "ISO2": "MD",
        "ISO3": "MDA",
        "top_level_domain": "md",
        "phone_code": "373",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/md.png",
        "min_phone_length": 8,
        "max_phone_length": 8
    },
    {
        "country_name": "Monaco",
        "ISO2": "MC",
        "ISO3": "MCO",
        "top_level_domain": "mc",
        "phone_code": "377",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/mc.png",
        "min_phone_length": 8,
        "max_phone_length": 9
    },
    {
        "country_name": "Mongolia",
        "ISO2": "MN",
        "ISO3": "MNG",
        "top_level_domain": "mn",
        "phone_code": "976",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/mn.png",
        "min_phone_length": 8,
        "max_phone_length": 8
    },
    {
        "country_name": "Montenegro",
        "ISO2": "ME",
        "ISO3": "MNE",
        "top_level_domain": "me",
        "phone_code": "382",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/me.png",
        "min_phone_length": 8,
        "max_phone_length": 9
    },
    {
        "country_name": "Montserrat",
        "ISO2": "MS",
        "ISO3": "MSR",
        "top_level_domain": "ms",
        "phone_code": "1-664",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/ms.png",
        "min_phone_length": 10,
        "max_phone_length": 10
    },
    {
        "country_name": "Morocco",
        "ISO2": "MA",
        "ISO3": "MAR",
        "top_level_domain": "ma",
        "phone_code": "212",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/ma.png",
        "min_phone_length": 9,
        "max_phone_length": 9
    },
    {
        "country_name": "Mozambique",
        "ISO2": "MZ",
        "ISO3": "MOZ",
        "top_level_domain": "mz",
        "phone_code": "258",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/mz.png",
        "min_phone_length": 9,
        "max_phone_length": 9
    },
    {
        "country_name": "Myanmar",
        "ISO2": "MM",
        "ISO3": "MMR",
        "top_level_domain": "mm",
        "phone_code": "95",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/mm.png",
        "min_phone_length": 7,
        "max_phone_length": 9
    },
    {
        "country_name": "Namibia",
        "ISO2": "NA",
        "ISO3": "NAM",
        "top_level_domain": "na",
        "phone_code": "264",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/na.png",
        "min_phone_length": 6,
        "max_phone_length": 7
    },
    {
        "country_name": "Nauru",
        "ISO2": "NR",
        "ISO3": "NRU",
        "top_level_domain": "nr",
        "phone_code": "674",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/nr.png",
        "min_phone_length": 7,
        "max_phone_length": 7
    },
    {
        "country_name": "Nepal",
        "ISO2": "NP",
        "ISO3": "NPL",
        "top_level_domain": "np",
        "phone_code": "977",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/np.png",
        "min_phone_length": 8,
        "max_phone_length": 10
    },
    {
        "country_name": "Netherlands",
        "ISO2": "NL",
        "ISO3": "NLD",
        "top_level_domain": "nl",
        "phone_code": "31",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/nl.png",
        "min_phone_length": 9,
        "max_phone_length": 9
    },
    {
        "country_name": "Netherlands Antilles",
        "ISO2": "AN",
        "ISO3": "ANT",
        "top_level_domain": "an",
        "phone_code": "599",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/an.png",
        "min_phone_length": 7,
        "max_phone_length": 7
    },
    {
        "country_name": "New Caledonia",
        "ISO2": "NC",
        "ISO3": "NCL",
        "top_level_domain": "nc",
        "phone_code": "687",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/nc.png",
        "min_phone_length": 6,
        "max_phone_length": 6
    },
    {
        "country_name": "New Zealand",
        "ISO2": "NZ",
        "ISO3": "NZL",
        "top_level_domain": "nz",
        "phone_code": "64",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/nz.png",
        "min_phone_length": 8,
        "max_phone_length": 10
    },
    {
        "country_name": "Nicaragua",
        "ISO2": "NI",
        "ISO3": "NIC",
        "top_level_domain": "ni",
        "phone_code": "505",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/ni.png",
        "min_phone_length": 8,
        "max_phone_length": 8
    },
    {
        "country_name": "Niger",
        "ISO2": "NE",
        "ISO3": "NER",
        "top_level_domain": "ne",
        "phone_code": "227",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/ne.png",
        "min_phone_length": 8,
        "max_phone_length": 8
    },
    {
        "country_name": "Nigeria",
        "ISO2": "NG",
        "ISO3": "NGA",
        "top_level_domain": "ng",
        "phone_code": "234",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/ng.png",
        "min_phone_length": 7,
        "max_phone_length": 10
    },
    {
        "country_name": "Niue",
        "ISO2": "NU",
        "ISO3": "NIU",
        "top_level_domain": "nu",
        "phone_code": "683",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/nu.png",
        "min_phone_length": 4,
        "max_phone_length": 4
    },
    {
        "country_name": "North Korea",
        "ISO2": "KP",
        "ISO3": "PRK",
        "top_level_domain": "kp",
        "phone_code": "850",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/kp.png",
        "min_phone_length": 6,
        "max_phone_length": 12
    },
    {
        "country_name": "Northern Mariana Islands",
        "ISO2": "MP",
        "ISO3": "MNP",
        "top_level_domain": "mp",
        "phone_code": "1-670",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/mp.png",
        "min_phone_length": 7,
        "max_phone_length": 10
    },
    {
        "country_name": "Norway",
        "ISO2": "NO",
        "ISO3": "NOR",
        "top_level_domain": "no",
        "phone_code": "47",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/no.png",
        "min_phone_length": 8,
        "max_phone_length": 8
    },
    {
        "country_name": "Oman",
        "ISO2": "OM",
        "ISO3": "OMN",
        "top_level_domain": "om",
        "phone_code": "968",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/om.png",
        "min_phone_length": 8,
        "max_phone_length": 8
    },
    {
        "country_name": "Pakistan",
        "ISO2": "PK",
        "ISO3": "PAK",
        "top_level_domain": "pk",
        "phone_code": "92",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/pk.png",
        "min_phone_length": 10,
        "max_phone_length": 11
    },
    {
        "country_name": "Palau",
        "ISO2": "PW",
        "ISO3": "PLW",
        "top_level_domain": "pw",
        "phone_code": "680",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/pw.png",
        "min_phone_length": 7,
        "max_phone_length": 7
    },
    {
        "country_name": "Palestine",
        "ISO2": "PS",
        "ISO3": "PSE",
        "top_level_domain": "ps",
        "phone_code": "970",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/ps.png",
        "min_phone_length": 8,
        "max_phone_length": 9
    },
    {
        "country_name": "Panama",
        "ISO2": "PA",
        "ISO3": "PAN",
        "top_level_domain": "pa",
        "phone_code": "507",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/pa.png",
        "min_phone_length": 7,
        "max_phone_length": 8
    },
    {
        "country_name": "Papua New Guinea",
        "ISO2": "PG",
        "ISO3": "PNG",
        "top_level_domain": "pg",
        "phone_code": "675",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/pg.png",
        "min_phone_length": 7,
        "max_phone_length": 8
    },
    {
        "country_name": "Paraguay",
        "ISO2": "PY",
        "ISO3": "PRY",
        "top_level_domain": "py",
        "phone_code": "595",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/py.png",
        "min_phone_length": 6,
        "max_phone_length": 9
    },
    {
        "country_name": "Peru",
        "ISO2": "PE",
        "ISO3": "PER",
        "top_level_domain": "pe",
        "phone_code": "51",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/pe.png",
        "min_phone_length": 8,
        "max_phone_length": 9
    },
    {
        "country_name": "Philippines",
        "ISO2": "PH",
        "ISO3": "PHL",
        "top_level_domain": "ph",
        "phone_code": "63",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/ph.png",
        "min_phone_length": 10,
        "max_phone_length": 10
    },
    {
        "country_name": "Pitcairn",
        "ISO2": "PN",
        "ISO3": "PCN",
        "top_level_domain": "pn",
        "phone_code": "64",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/pn.png",
        "min_phone_length": 6,
        "max_phone_length": 8
    },
    {
        "country_name": "Poland",
        "ISO2": "PL",
        "ISO3": "POL",
        "top_level_domain": "pl",
        "phone_code": "48",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/pl.png",
        "min_phone_length": 9,
        "max_phone_length": 9
    },
    {
        "country_name": "Portugal",
        "ISO2": "PT",
        "ISO3": "PRT",
        "top_level_domain": "pt",
        "phone_code": "351",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/pt.png",
        "min_phone_length": 9,
        "max_phone_length": 9
    },
    {
        "country_name": "Puerto Rico",
        "ISO2": "PR",
        "ISO3": "PRI",
        "top_level_domain": "pr",
        "phone_code": "1-787, 1-939",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/pr.png",
        "min_phone_length": 10,
        "max_phone_length": 10
    },
    {
        "country_name": "Qatar",
        "ISO2": "QA",
        "ISO3": "QAT",
        "top_level_domain": "qa",
        "phone_code": "974",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/qa.png",
        "min_phone_length": 8,
        "max_phone_length": 8
    },
    {
        "country_name": "Republic of the Congo",
        "ISO2": "CG",
        "ISO3": "COG",
        "top_level_domain": "cg",
        "phone_code": "242",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/cg.png",
        "min_phone_length": 6,
        "max_phone_length": 9
    },
    {
        "country_name": "Reunion",
        "ISO2": "RE",
        "ISO3": "REU",
        "top_level_domain": "re",
        "phone_code": "262",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/re.png",
        "min_phone_length": 9,
        "max_phone_length": 9
    },
    {
        "country_name": "Romania",
        "ISO2": "RO",
        "ISO3": "ROU",
        "top_level_domain": "ro",
        "phone_code": "40",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/ro.png",
        "min_phone_length": 9,
        "max_phone_length": 10
    },
    {
        "country_name": "Russia",
        "ISO2": "RU",
        "ISO3": "RUS",
        "top_level_domain": "ru",
        "phone_code": "7",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/ru.png",
        "min_phone_length": 10,
        "max_phone_length": 10
    },
    {
        "country_name": "Rwanda",
        "ISO2": "RW",
        "ISO3": "RWA",
        "top_level_domain": "rw",
        "phone_code": "250",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/rw.png",
        "min_phone_length": 9,
        "max_phone_length": 9
    },
    {
        "country_name": "Saint Barthelemy",
        "ISO2": "BL",
        "ISO3": "BLM",
        "top_level_domain": "gp",
        "phone_code": "590",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/gp.png",
        "min_phone_length": 9,
        "max_phone_length": 9
    },
    {
        "country_name": "Saint Helena",
        "ISO2": "SH",
        "ISO3": "SHN",
        "top_level_domain": "sh",
        "phone_code": "290",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/sh.png",
        "min_phone_length": 4,
        "max_phone_length": 4
    },
    {
        "country_name": "Saint Kitts and Nevis",
        "ISO2": "KN",
        "ISO3": "KNA",
        "top_level_domain": "kn",
        "phone_code": "1-869",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/kn.png",
        "min_phone_length": 10,
        "max_phone_length": 10
    },
    {
        "country_name": "Saint Lucia",
        "ISO2": "LC",
        "ISO3": "LCA",
        "top_level_domain": "lc",
        "phone_code": "1-758",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/lc.png",
        "min_phone_length": 10,
        "max_phone_length": 10
    },
    {
        "country_name": "Saint Martin",
        "ISO2": "MF",
        "ISO3": "MAF",
        "top_level_domain": "gp",
        "phone_code": "590",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/gp.png",
        "min_phone_length": 9,
        "max_phone_length": 9
    },
    {
        "country_name": "Saint Pierre and Miquelon",
        "ISO2": "PM",
        "ISO3": "SPM",
        "top_level_domain": "pm",
        "phone_code": "508",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/pm.png",
        "min_phone_length": 6,
        "max_phone_length": 6
    },
    {
        "country_name": "Saint Vincent and the Grenadines",
        "ISO2": "VC",
        "ISO3": "VCT",
        "top_level_domain": "vc",
        "phone_code": "1-784",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/vc.png",
        "min_phone_length": 10,
        "max_phone_length": 10
    },
    {
        "country_name": "Samoa",
        "ISO2": "WS",
        "ISO3": "WSM",
        "top_level_domain": "ws",
        "phone_code": "685",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/ws.png",
        "min_phone_length": 5,
        "max_phone_length": 7
    },
    {
        "country_name": "San Marino",
        "ISO2": "SM",
        "ISO3": "SMR",
        "top_level_domain": "sm",
        "phone_code": "378",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/sm.png",
        "min_phone_length": 8,
        "max_phone_length": 10
    },
    {
        "country_name": "Sao Tome and Principe",
        "ISO2": "ST",
        "ISO3": "STP",
        "top_level_domain": "st",
        "phone_code": "239",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/st.png",
        "min_phone_length": 7,
        "max_phone_length": 7
    },
    {
        "country_name": "Saudi Arabia",
        "ISO2": "SA",
        "ISO3": "SAU",
        "top_level_domain": "sa",
        "phone_code": "966",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/sa.png",
        "min_phone_length": 9,
        "max_phone_length": 9
    },
    {
        "country_name": "Senegal",
        "ISO2": "SN",
        "ISO3": "SEN",
        "top_level_domain": "sn",
        "phone_code": "221",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/sn.png",
        "min_phone_length": 9,
        "max_phone_length": 9
    },
    {
        "country_name": "Serbia",
        "ISO2": "RS",
        "ISO3": "SRB",
        "top_level_domain": "rs",
        "phone_code": "381",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/rs.png",
        "min_phone_length": 8,
        "max_phone_length": 9
    },
    {
        "country_name": "Seychelles",
        "ISO2": "SC",
        "ISO3": "SYC",
        "top_level_domain": "sc",
        "phone_code": "248",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/sc.png",
        "min_phone_length": 7,
        "max_phone_length": 7
    },
    {
        "country_name": "Sierra Leone",
        "ISO2": "SL",
        "ISO3": "SLE",
        "top_level_domain": "sl",
        "phone_code": "232",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/sl.png",
        "min_phone_length": 8,
        "max_phone_length": 8
    },
    {
        "country_name": "Singapore",
        "ISO2": "SG",
        "ISO3": "SGP",
        "top_level_domain": "sg",
        "phone_code": "65",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/sg.png",
        "min_phone_length": 8,
        "max_phone_length": 8
    },
    {
        "country_name": "Sint Maarten",
        "ISO2": "SX",
        "ISO3": "SXM",
        "top_level_domain": "sx",
        "phone_code": "1-721",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/sx.png",
        "min_phone_length": 10,
        "max_phone_length": 10
    },
    {
        "country_name": "Slovakia",
        "ISO2": "SK",
        "ISO3": "SVK",
        "top_level_domain": "sk",
        "phone_code": "421",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/sk.png",
        "min_phone_length": 9,
        "max_phone_length": 9
    },
    {
        "country_name": "Slovenia",
        "ISO2": "SI",
        "ISO3": "SVN",
        "top_level_domain": "si",
        "phone_code": "386",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/si.png",
        "min_phone_length": 8,
        "max_phone_length": 9
    },
    {
        "country_name": "Solomon Islands",
        "ISO2": "SB",
        "ISO3": "SLB",
        "top_level_domain": "sb",
        "phone_code": "677",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/sb.png",
        "min_phone_length": 5,
        "max_phone_length": 7
    },
    {
        "country_name": "Somalia",
        "ISO2": "SO",
        "ISO3": "SOM",
        "top_level_domain": "so",
        "phone_code": "252",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/so.png",
        "min_phone_length": 7,
        "max_phone_length": 8
    },
    {
        "country_name": "South Africa",
        "ISO2": "ZA",
        "ISO3": "ZAF",
        "top_level_domain": "za",
        "phone_code": "27",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/za.png",
        "min_phone_length": 9,
        "max_phone_length": 9
    },
    {
        "country_name": "South Korea",
        "ISO2": "KR",
        "ISO3": "KOR",
        "top_level_domain": "kr",
        "phone_code": "82",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/kr.png",
        "min_phone_length": 9,
        "max_phone_length": 11
    },
    {
        "country_name": "South Sudan",
        "ISO2": "SS",
        "ISO3": "SSD",
        "top_level_domain": "ss",
        "phone_code": "211",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/ss.png",
        "min_phone_length": 7,
        "max_phone_length": 9
    },
    {
        "country_name": "Spain",
        "ISO2": "ES",
        "ISO3": "ESP",
        "top_level_domain": "es",
        "phone_code": "34",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/es.png",
        "min_phone_length": 9,
        "max_phone_length": 9
    },
    {
        "country_name": "Sri Lanka",
        "ISO2": "LK",
        "ISO3": "LKA",
        "top_level_domain": "lk",
        "phone_code": "94",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/lk.png",
        "min_phone_length": 9,
        "max_phone_length": 10
    },
    {
        "country_name": "Sudan",
        "ISO2": "SD",
        "ISO3": "SDN",
        "top_level_domain": "sd",
        "phone_code": "249",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/sd.png",
        "min_phone_length": 9,
        "max_phone_length": 10
    },
    {
        "country_name": "Suriname",
        "ISO2": "SR",
        "ISO3": "SUR",
        "top_level_domain": "sr",
        "phone_code": "597",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/sr.png",
        "min_phone_length": 6,
        "max_phone_length": 7
    },
    {
        "country_name": "Svalbard and Jan Mayen",
        "ISO2": "SJ",
        "ISO3": "SJM",
        "top_level_domain": "sj",
        "phone_code": "47",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/sj.png",
        "min_phone_length": 8,
        "max_phone_length": 8
    },
    {
        "country_name": "Swaziland",
        "ISO2": "SZ",
        "ISO3": "SWZ",
        "top_level_domain": "sz",
        "phone_code": "268",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/sz.png",
        "min_phone_length": 8,
        "max_phone_length": 8
    },
    {
        "country_name": "Sweden",
        "ISO2": "SE",
        "ISO3": "SWE",
        "top_level_domain": "se",
        "phone_code": "46",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/se.png",
        "min_phone_length": 7,
        "max_phone_length": 13
    },
    {
        "country_name": "Switzerland",
        "ISO2": "CH",
        "ISO3": "CHE",
        "top_level_domain": "ch",
        "phone_code": "41",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/ch.png",
        "min_phone_length": 9,
        "max_phone_length": 12
    },
    {
        "country_name": "Syria",
        "ISO2": "SY",
        "ISO3": "SYR",
        "top_level_domain": "sy",
        "phone_code": "963",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/sy.png",
        "min_phone_length": 7,
        "max_phone_length": 10
    },
    {
        "country_name": "Taiwan",
        "ISO2": "TW",
        "ISO3": "TWN",
        "top_level_domain": "tw",
        "phone_code": "886",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/tw.png",
        "min_phone_length": 8,
        "max_phone_length": 9
    },
    {
        "country_name": "Tajikistan",
        "ISO2": "TJ",
        "ISO3": "TJK",
        "top_level_domain": "tj",
        "phone_code": "992",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/tj.png",
        "min_phone_length": 9,
        "max_phone_length": 9
    },
    {
        "country_name": "Tanzania",
        "ISO2": "TZ",
        "ISO3": "TZA",
        "top_level_domain": "tz",
        "phone_code": "255",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/tz.png",
        "min_phone_length": 9,
        "max_phone_length": 10
    },
    {
        "country_name": "Thailand",
        "ISO2": "TH",
        "ISO3": "THA",
        "top_level_domain": "th",
        "phone_code": "66",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/th.png",
        "min_phone_length": 9,
        "max_phone_length": 10
    },
    {
        "country_name": "Togo",
        "ISO2": "TG",
        "ISO3": "TGO",
        "top_level_domain": "tg",
        "phone_code": "228",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/tg.png",
        "min_phone_length": 8,
        "max_phone_length": 8
    },
    {
        "country_name": "Tokelau",
        "ISO2": "TK",
        "ISO3": "TKL",
        "top_level_domain": "tk",
        "phone_code": "690",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/tk.png",
        "min_phone_length": 6,
        "max_phone_length": 6
    },
    {
        "country_name": "Tonga",
        "ISO2": "TO",
        "ISO3": "TON",
        "top_level_domain": "to",
        "phone_code": "676",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/to.png",
        "min_phone_length": 5,
        "max_phone_length": 7
    },
    {
        "country_name": "Trinidad and Tobago",
        "ISO2": "TT",
        "ISO3": "TTO",
        "top_level_domain": "tt",
        "phone_code": "1-868",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/tt.png",
        "min_phone_length": 7,
        "max_phone_length": 7
    },
    {
        "country_name": "Tunisia",
        "ISO2": "TN",
        "ISO3": "TUN",
        "top_level_domain": "tn",
        "phone_code": "216",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/tn.png",
        "min_phone_length": 8,
        "max_phone_length": 8
    },
    {
        "country_name": "Turkey",
        "ISO2": "TR",
        "ISO3": "TUR",
        "top_level_domain": "tr",
        "phone_code": "90",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/tr.png",
        "min_phone_length": 10,
        "max_phone_length": 10
    },
    {
        "country_name": "Turkmenistan",
        "ISO2": "TM",
        "ISO3": "TKM",
        "top_level_domain": "tm",
        "phone_code": "993",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/tm.png",
        "min_phone_length": 8,
        "max_phone_length": 8
    },
    {
        "country_name": "Turks and Caicos Islands",
        "ISO2": "TC",
        "ISO3": "TCA",
        "top_level_domain": "tc",
        "phone_code": "1-649",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/tc.png",
        "min_phone_length": 10,
        "max_phone_length": 10
    },
    {
        "country_name": "Tuvalu",
        "ISO2": "TV",
        "ISO3": "TUV",
        "top_level_domain": "tv",
        "phone_code": "688",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/tv.png",
        "min_phone_length": 5,
        "max_phone_length": 6
    },
    {
        "country_name": "U.S. Virgin Islands",
        "ISO2": "VI",
        "ISO3": "VIR",
        "top_level_domain": "vi",
        "phone_code": "1-340",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/vi.png",
        "min_phone_length": 10,
        "max_phone_length": 10
    },
    {
        "country_name": "Uganda",
        "ISO2": "UG",
        "ISO3": "UGA",
        "top_level_domain": "ug",
        "phone_code": "256",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/ug.png",
        "min_phone_length": 9,
        "max_phone_length": 9
    },
    {
        "country_name": "Ukraine",
        "ISO2": "UA",
        "ISO3": "UKR",
        "top_level_domain": "ua",
        "phone_code": "380",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/ua.png",
        "min_phone_length": 9,
        "max_phone_length": 9
    },
    {
        "country_name": "United Arab Emirates",
        "ISO2": "AE",
        "ISO3": "ARE",
        "top_level_domain": "ae",
        "phone_code": "971",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/ae.png",
        "min_phone_length": 9,
        "max_phone_length": 9
    },
    {
        "country_name": "United Kingdom",
        "ISO2": "GB",
        "ISO3": "GBR",
        "top_level_domain": "uk",
        "phone_code": "44",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/uk.png",
        "min_phone_length": 10,
        "max_phone_length": 11
    },
    {
        "country_name": "United States",
        "ISO2": "US",
        "ISO3": "USA",
        "top_level_domain": "us",
        "phone_code": "1",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/us.png",
        "min_phone_length": 10,
        "max_phone_length": 10
    },
    {
        "country_name": "Uruguay",
        "ISO2": "UY",
        "ISO3": "URY",
        "top_level_domain": "uy",
        "phone_code": "598",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/uy.png",
        "min_phone_length": 8,
        "max_phone_length": 9
    },
    {
        "country_name": "Uzbekistan",
        "ISO2": "UZ",
        "ISO3": "UZB",
        "top_level_domain": "uz",
        "phone_code": "998",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/uz.png",
        "min_phone_length": 9,
        "max_phone_length": 9
    },
    {
        "country_name": "Vanuatu",
        "ISO2": "VU",
        "ISO3": "VUT",
        "top_level_domain": "vu",
        "phone_code": "678",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/vu.png",
        "min_phone_length": 5,
        "max_phone_length": 7
    },
    {
        "country_name": "Vatican",
        "ISO2": "VA",
        "ISO3": "VAT",
        "top_level_domain": "va",
        "phone_code": "379",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/va.png",
        "min_phone_length": 6,
        "max_phone_length": 10
    },
    {
        "country_name": "Venezuela",
        "ISO2": "VE",
        "ISO3": "VEN",
        "top_level_domain": "ve",
        "phone_code": "58",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/ve.png",
        "min_phone_length": 10,
        "max_phone_length": 11
    },
    {
        "country_name": "Vietnam",
        "ISO2": "VN",
        "ISO3": "VNM",
        "top_level_domain": "vn",
        "phone_code": "84",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/vn.png",
        "min_phone_length": 9,
        "max_phone_length": 11
    },
    {
        "country_name": "Wallis and Futuna",
        "ISO2": "WF",
        "ISO3": "WLF",
        "top_level_domain": "wf",
        "phone_code": "681",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/wf.png",
        "min_phone_length": 6,
        "max_phone_length": 6
    },
    {
        "country_name": "Western Sahara",
        "ISO2": "EH",
        "ISO3": "ESH",
        "top_level_domain": "eh",
        "phone_code": "212",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/eh.png",
        "min_phone_length": 9,
        "max_phone_length": 9
    },
    {
        "country_name": "Yemen",
        "ISO2": "YE",
        "ISO3": "YEM",
        "top_level_domain": "ye",
        "phone_code": "967",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/ye.png",
        "min_phone_length": 9,
        "max_phone_length": 9
    },
    {
        "country_name": "Zambia",
        "ISO2": "ZM",
        "ISO3": "ZMB",
        "top_level_domain": "zm",
        "phone_code": "260",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/zm.png",
        "min_phone_length": 9,
        "max_phone_length": 9
    },
    {
        "country_name": "Zimbabwe",
        "ISO2": "ZW",
        "ISO3": "ZWE",
        "top_level_domain": "zw",
        "phone_code": "263",
        "flag_url": "https://analytics.cheddyproducts.com/cheddy_php_v3/images/flags/zw.png",
        "min_phone_length": 9,
        "max_phone_length": 9
    }
]