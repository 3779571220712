import React, { useState, useEffect } from "react";
import { Modal, Button, Switch, Row, Col } from "antd";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { color } from "highcharts";

const ColumnConfigModal1 = ({ visible, onClose, columns, onColumnChange, userData }) => {
    const [visibleColumns, setVisibleColumns] = useState([]);
    const [hiddenColumns, setHiddenColumns] = useState([]);
    const [stickyColumns, setStickyColumns] = useState({});

    // Retrieve column sort order from userData or fallback
    const DataColumns = JSON.parse(userData?.user?.user_data?.columns_sort || "{}");
    const columns_sort = DataColumns?.Item || [];

    useEffect(() => {
        const savedColumns = JSON.parse(localStorage.getItem("visibleColumns")) || null;
        const savedSticky = JSON.parse(localStorage.getItem("stickyColumns")) || {};

        // Convert columns_sort array into an object for sticky column tracking
        const stickyMap = columns_sort.reduce((acc, col) => {
            acc[col.content] = col.Sticky; // Use `content` as key and `Sticky` value
            return acc;
        }, {});

        // Extract column names only when necessary
        const defaultColumns = columns_sort?.length
            ? columns_sort.map(col => col.content)
            : columns.map(col => col.title);

        const initialVisible = savedColumns !== null ? savedColumns : defaultColumns;
        const initialHidden = columns.map(col => col.title).filter(title => !initialVisible.includes(title));

        setVisibleColumns(initialVisible);
        setHiddenColumns(initialHidden);
        setStickyColumns((prev) =>
            JSON.stringify(prev) === JSON.stringify(savedSticky) ? prev : { ...stickyMap, ...savedSticky }
        );
    }, [columns, columns_sort?.length]);

    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const sourceList = result.source.droppableId === "visible" ? visibleColumns : hiddenColumns;
        const destinationList = result.destination.droppableId === "visible" ? visibleColumns : hiddenColumns;
        const item = sourceList[result.source.index];

        if (result.source.droppableId !== result.destination.droppableId) {
            setVisibleColumns(
                result.destination.droppableId === "visible"
                    ? [...visibleColumns, item]
                    : visibleColumns.filter(col => col !== item)
            );
            setHiddenColumns(
                result.destination.droppableId === "hide"
                    ? [...hiddenColumns, item]
                    : hiddenColumns.filter(col => col !== item)
            );
        } else {
            // Reorder within the same list
            const reorderedList = Array.from(destinationList);
            reorderedList.splice(result.source.index, 1);
            reorderedList.splice(result.destination.index, 0, item);
            if (result.destination.droppableId === "visible") {
                setVisibleColumns(reorderedList);
            } else {
                setHiddenColumns(reorderedList);
            }
        }
    };

    const handleStickyChange = (columnName) => {
        setStickyColumns((prev) => {
            const updatedSticky = { ...prev, [columnName]: !prev[columnName] };
            localStorage.setItem("stickyColumns", JSON.stringify(updatedSticky));
            return updatedSticky;
        });
    };

    const handleApply = () => {
        localStorage.setItem("visibleColumns", JSON.stringify(visibleColumns));
        localStorage.setItem("columns_sort", JSON.stringify({ Item: visibleColumns }));
        onColumnChange(visibleColumns);
        onClose();
    };

    return (
        <Modal
            title="Personalize Grid Configuration"
            open={visible}
            onCancel={onClose}
            width={700}
            bodyStyle={{ maxHeight: "600px", overflowY: "auto", overflowX: "hidden" }}
            footer={[
                <Button key="cancel" onClick={onClose}>Cancel</Button>,
                <Button key="apply" type="primary" onClick={handleApply}>Apply</Button>,
            ]}
        >
            <DragDropContext onDragEnd={handleDragEnd}>
                <Row gutter={[20, 16]}>
                    <Col lg={12}>
                        <h4 className="mt-3" style={styles?.title}>Show Columns</h4>
                        <Droppable droppableId="visible">
                            {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps} style={styles.columnBox}>
                                    <Row>
                                        <Col lg={12}><h5> Columns </h5></Col>
                                        <Col lg={12} className="text-end"> <h5>  Sticky</h5></Col>
                                    </Row>

                                    {visibleColumns.map((col, index) => (
                                        <Draggable key={col} draggableId={String(col)} index={index}>
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{ ...styles.draggableItem, ...provided.draggableProps.style }}
                                                >
                                                    <div style={{ color: "#6e6b7b", fontWeight: "500" }}>
                                                        {index + 1}&nbsp;&nbsp;<span style={{ color: "rgb(145 144 144)", fontWeight: "500" }}>{col} </span>

                                                    </div>
                                                    <Switch
                                                        checked={!!stickyColumns[col]}
                                                        onChange={() => handleStickyChange(col)}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}

                                </div>
                            )}
                        </Droppable>
                    </Col>
                    <Col lg={12}>
                        <h4 className="mt-3" style={styles?.title}>Hide Columns</h4>
                        <Droppable droppableId="hide">
                            {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps} style={styles.columnBox}>
                                    <Row>
                                        <Col lg={12}><h5> Columns </h5></Col>
                                        <Col lg={12} className="text-end"> <h5>  Sticky</h5></Col>
                                    </Row>
                                    {hiddenColumns.map((col, index) => (
                                        <Draggable key={col} draggableId={String(col)} index={index}>
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{ ...styles.draggableItem, ...provided.draggableProps.style }}
                                                >
                                                    <div style={{ color: "#6e6b7b", fontWeight: "500" }}>
                                                        {index + 1}&nbsp;&nbsp;<span style={{ color: "rgb(145 144 144)", fontWeight: "500" }}>{col} </span>

                                                    </div>
                                                    <Switch
                                                    disabled={true}
                                                        checked={!!stickyColumns[col]}
                                                        onChange={() => handleStickyChange(col)}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </Col>
                </Row>



            </DragDropContext>
        </Modal>
    );
};

const styles = {
    title : {
        color : "#333",
    },
    columnBox: {
        width: "100%",
        minHeight: "540px",
        border: "1px solid rgb(221, 221, 221)",
        padding: "10px",
    },
    draggableItem: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "8px",
        margin: "4px 0px 8px 0px",
        background: "#fff",
        border: "1px solid rgb(221, 221, 221)",
        borderRadius: "8px",
    },
};

export default ColumnConfigModal1;
