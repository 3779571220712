import React, { useEffect } from "react";
import { CompoenntWrapper } from "../style";
import {
  Button,
  Flex,
  Form,
  Input,
  message,
  Select,
  Space,
  Tag,
  Typography,
} from "antd";
import { Icon } from "@iconify/react/dist/iconify.js";
import Table2 from "../../../../Components/tables/table2";
import { MakeApiCall } from "../../../../apis";
import { useState } from "react";
import {
  ConvertParams,
  FormatNumber,
  PaginationConfig,
} from "../../../../config";
import { render } from "less";
// import AddHistoryModal from "./components-lib/add-history";
import AddComponentsModal from "./add-components";
import GetUnitCostHistory from "./components-lib/add-history";
import AddHistoryModal from "./components-lib/add-history-plus";
import AdvancedFilter from "./components-lib/advanced-filter";
import { TableLoading } from "../../../../Components/table-animation";

const ComponentList = (props) => {
  const { userData } = props;
  const [Filterform] = Form.useForm(); // Initialize useForm hook
  const [brandList, setBrandList] = useState([]);
  const [brandLoading, setBrandLoading] = useState(true);
  const [vendorList, setVendorList] = useState([]);
  const [vendorLoading, setVendorLoading] = useState(true);
  const [advanceList, setAdvanceList] = useState([]);
  const [advanceLoading, setAdvanceLoading] = useState(true);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [moreFilterView, setMoreFilterView] = useState(false);
  const [selectedAdvanceFilter, setSelectedAdvanceFilter] = useState(null);
  const [filters, setFilters] = useState({
    page: 1,
    "page-size": 20,
    totalPage: 0,
    page_count: 0,
    filter: "enabled",
    filter_value: null,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [formHistory] = Form.useForm();
  const [selectedRow, setSelectedRow] = useState({});
  const [visible, setVisible] = useState(false);
  const [unitCostHistory, setUnitCostHistory] = useState(false);
  const [historyType, setHistoryType] = useState("view");

  const filterBy = [
    { label: "All", value: "all" },
    { label: "Enabled", value: "enabled" },
    { label: "Disabled", value: "disabled" },
  ];
  const showModal = () => {
    setIsModalVisible(true);
  };

  const showModalAdd = () => {
    setVisible(true);
    setSelectedRow({});
  };

  const handleClose = () => {
    setVisible(false);
    setSelectedRow({});
  };

  const GetBrandListAction = async () => {
    setBrandLoading(true);
    setBrandList([]);
    try {
      const response = await MakeApiCall(
        `brands?page=all`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setBrandList(response?.data?.records || []);

        setBrandLoading(false);
      } else {
        setBrandList([]);
        message.destroy();
        setBrandLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setBrandList([]);
      setBrandLoading(false);
      message.destroy();
      message.error(error?.message);
    }
  };
  const GetVendorListAction = async () => {
    setVendorLoading(true);
    setVendorList([]);
    try {
      const response = await MakeApiCall(
        `vendors?page=all`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setVendorList(response?.data?.records || []);
        setVendorLoading(false);
      } else {
        message.destroy();
        setVendorList([]);
        setVendorLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setVendorLoading(false);
      setVendorList([]);
      message.destroy();
      message.error(error?.message);
    }
  };
  const GetAdvanceFilterListAction = async () => {
    setAdvanceLoading(true);
    setAdvanceList([]);
    try {
      const response = await MakeApiCall(
        `advance-filter-component`,
        "post",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setAdvanceList(response?.data?.getAllFilter || []);
        setAdvanceLoading(false);
      } else {
        message.destroy();
        setVendorList([]);
        setAdvanceLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setVendorList([]);
      setAdvanceLoading(false);
      message.destroy();
      message.error(error?.message);
    }
  };

  const ComponentListAction = async (data) => {
    const { totalPage, page_count, ...rest } = data;
    setLoading(true);
    try {
      const response = await MakeApiCall(
        `components${ConvertParams(rest)}`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        const { pagination, records } = response?.data;
        const { totalCount, page_count, page, page_size } = pagination;
        if (records?.length > 0) {
          setList(records || []);
          setFilters({
            ...data,
            page: page,
            "per-page": page_size,
            page_count: page_count,
            totalPage: totalCount,
          });
        } else {
          setList([]);
          setLoading(false);
        }

        setLoading(false);
      } else {
        message.destroy();
        setList([]);
        setLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setLoading(false);
      setList([]);
      message.destroy();
      message.error(error?.message);
    }
  };

  console.log("filters" ,filters)

  useEffect(() => {
    ComponentListAction({
      ...filters,
    });
    GetAdvanceFilterListAction();
    GetVendorListAction();
    GetBrandListAction();
    return () => { };
  }, []);

  const columns = [
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Button
          size="small"
          color="primary"
          className="p-2 py-4"
          variant="filled"
          onClick={() => handleAction(record)}
        >
          <Icon width={24} icon="flowbite:edit-solid" />
        </Button>
      ),
    },
    {
      title: "Component ID",
      dataIndex: "component_id",
      key: "component_id",
      render: (e) => {
        return <Tag color="blue">{e || "-"}</Tag>;
      },
    },
    {
      title: "Item Description",
      dataIndex: "item_description",
      key: "item_description",

      render: (_, row) => {
        return (
          <div
            className="d-flex box-view-columns"
            style={{
              padding: "10px",
              borderRadius: "10px",
              border: filters?.filter === "disabled"  ? "1px solid rgb(176 1 1 / 66%)" : "1px solid #0179b0a8",
              background: filters?.filter === "disabled"  ?   "rgb(244 236 236)" : "#ecf1f4",
            }}
          >
            <div className="d-grid">
              <div
                style={{ marginLeft: "10px" }}
                className="text-truncate_"
                title={row.item_description}
              >
                {row.item_description}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Unit Cost",
      dataIndex: "unit_cost",
      key: "unit_cost",
      render: (e) => {
        return (
          <Flex gap={5}>
            <Typography.Text>${FormatNumber(e || 0)}</Typography.Text>
          </Flex>
        );
      },
    },
    {
      title: "Brand",
      dataIndex: ["brands", "b_name"],
      key: ["brands", "b_name"],
      render: (e) => {
        return e || "-";
      },
    },
    {
      title: "Vendor",
      dataIndex: ["vendors", "name"],
      key: ["vendors", "name"],
      render: (e) => {
        return e || "-";
      },
    },
    {
      title: "Unit Cost History",
      dataIndex: "unitCostHistory",
      key: "unitCostHistory",
      render: (_, e) => {
        return (
          <Flex gap={5}>
            <Button
              color="default"
              className="p-2 py-4"
              variant="filled"
              size="small"
              onClick={() => {
                setUnitCostHistory(true);
                setSelectedRow(e);
                setHistoryType("view");
              }}
            >
              <Icon width={20} icon="material-symbols:history" />
            </Button>
            <Button
              size="small"
              color="primary"
              className="p-2 py-4"
              variant="filled"
              onClick={() => {
                setUnitCostHistory(true);
                setSelectedRow(e);
                setHistoryType("edit");
              }}
            >
              <Icon width={20} icon="flowbite:edit-solid" />
            </Button>
            <Button
              size="small"
              color="primary"
              className="p-2 py-4"
              variant="solid"
              onClick={() => {
                showModal();
                setSelectedRow(e);
                setHistoryType("edit");
              }}
            >
              <Icon width={20} icon="ic:baseline-plus" />
            </Button>
          </Flex>
        );
      },
    },
    {
      title: "Item Note",
      dataIndex: "note",
      render: (e) => {
        return e || "-";
      },
      key: "note",
    },
    {
      title: "UPC",
      dataIndex: "upc",
      key: "upc",
      render: (e) => {
        return e || "-";
      },
    },
    {
      title: "Case Quantity",
      dataIndex: "case_quantity",
      key: "case_quantity",
      render: (e) => {
        return (
          <Flex gap={5}>
            <Typography.Text>{FormatNumber(e || 0)}</Typography.Text>
          </Flex>
        );
      },
    },
    {
      title: "Component MOQ",
      dataIndex: "moq",
      key: "moq",
    },
    {
      title: "Vendor SKU",
      dataIndex: "vendor_sku",
      key: "vendor_sku",
    },
    {
      title: "Date Active",
      dataIndex: "date_active",
      key: "date_active",
    },
  ];

  // Action handler (Customize it for your use case)
  const handleAction = (record) => {
    console.log("record", record)
    setSelectedRow(record);
    setVisible(true);

  };
  return (
    <CompoenntWrapper>
      <Flex justify="space-between">
        <Flex wrap gap={10}>
          <Select
            size="large"
            options={filterBy}
            value={filters.filter}
            onChange={(e) => {
              setFilters({
                ...filters,
                filter: e,
              });
              ComponentListAction({
                ...filters,
                filter: e,
              });
            }}
            className="w-125px"
            placeholder="Status"
          />
          <Input
            onChange={(e) => {
              setFilters({
                ...filters,
                filter_value: e.target.value,
              });
            }}
            value={filters?.filter_value}
            onPressEnter={(e) => {
              ComponentListAction({
                ...filters,
              });
            }}
            className="w-250px"
            placeholder="Search By Title etc..."
          />

          <Select
            size="large"
            allowClear
            className="w-175px"
            loading={advanceLoading}
            options={advanceList?.map((d) => {
              return { label: d?.user_filter_name, value: d?.usf_id };
            })}
            value={(filters && filters?.["filters[user_filter_id]"]) || null}
            onChange={(_, obj) => {

              if (!_) {
                setSelectedAdvanceFilter({});
                setFilters({
                  filter: filters?.filter || "",
                  filter_value: filters?.filter_value || "",
                  page: filters?.page || "",
                  page_count: filters?.page_count || "",
                  "per-page": filters?.["per-page"] || "",
                  totalPage: filters?.["totalPage"] || "",
                });
                ComponentListAction({
                  filter: filters?.filter || "",
                  filter_value: filters?.filter_value || "",
                  page: filters?.page || "",
                  page_count: filters?.page_count || "",
                  "per-page": filters?.["per-page"] || "",
                  totalPage: filters?.["totalPage"] || "",
                });
                Filterform.resetFields();
                return;
              }
              let filters_ = { ...filters };
              const index = advanceList?.findIndex(
                (d) => d.usf_id === obj.value
              );

              if (index !== -1) {
                filters_["filter_value"] = advanceList[index].search || "";
                filters_["filters[user_filter_id]"] = obj.value || "";
                filters_["filters[comp_desc]"] =
                  advanceList[index].comp_desc || "";
                filters_["filters[unit_cost]"] =
                  advanceList[index].unit_cost || "";
                filters_["filters[vendor_id]"] =
                  advanceList[index].vendor_id || "";
                filters_["filters[brand_id]"] =
                  advanceList[index].brand_id || "";
                filters_["filters[upc]"] = advanceList[index].upc || "";
                filters_["filters[case_qty]"] =
                  advanceList[index].case_qty || "";
                filters_["filters[moq]"] = advanceList[index].moq || "";
                filters_["filters[vendor_sku]"] =
                  advanceList[index].vendor_sku || "";
              }
              Filterform.setFieldsValue({
                user_filter_name: advanceList[index]?.user_filter_name,
                user_filter_value: advanceList[index]?.user_filter_value,
                brand_id: advanceList[index]?.brand_id,
                case_qty: advanceList[index]?.case_qty,
                comp_desc: advanceList[index]?.comp_desc,
                comp_note: advanceList[index]?.comp_note,
                moq: advanceList[index]?.moq,
                unit_cost: advanceList[index]?.unit_cost,
                upc: advanceList[index]?.upc,
                vendor_id: advanceList[index]?.vendor_id,
                vendor_sku: advanceList[index]?.vendor_sku,
              });

              setSelectedAdvanceFilter(filters_);
              setFilters(filters_);
              ComponentListAction({
                ...filters_,
              });
            }}
            placeholder="Saved Filters"
          />
          <Button
            color="primary"
            variant="dashed"
            onClick={() => setMoreFilterView(true)}
          >
            <Icon width={22} icon="solar:filter-bold-duotone" />
          </Button>
        </Flex>
        <Button onClick={showModalAdd} type="primary">
          + Add Component
        </Button>
      </Flex>
      {
        loading ?  <TableLoading
                      id="test-table"
                      row={10}
                      columns={[1, 2, 3, 4, 5]}
                      checkBoxVal={false}
                      actions={[]}
                      style={{ height: "calc(100vh - 300px)", overflow: "hidden" }}
                    /> : 
     
      <Table2
        dataSource={list}
        className="mt-3"
        loading={loading}
        columns={columns}
        scroll={{ x: "max-content" }}
        // pagination={PaginationConfig({
        //   pageSize: filters["per-page"],
        //   currentPage: filters.page,
        //   totalPage: filters.totalPage,
        //   onChange: (e, f) => {
        //     const obj = {
        //       ...filters,
        //       page: e,
        //       "per-page": f,
        //     };
        //     setFilters(obj);
        //     ComponentListAction({
        //       ...obj,
        //     });
        //   },
        // })}

        pagination={PaginationConfig({
          pageSize: filters["page-size"],
          currentPage: filters.page,
          totalPage: filters.totalPage,
          onChange: (page, pageSize) => {
            const obj = {
              ...filters,
              page: page,
              "page-size": pageSize,
            };
            setFilters(obj);
            setLoading(true);
            ComponentListAction({
              ...obj,
            });
          },
        })}


      /> }
      <AddHistoryModal
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
        form={form}
        vendorList={vendorList}
        userData={userData}
        vendorLoading={vendorLoading}
        setSelectedRow={setSelectedRow}
        selectedRow={selectedRow}
      />
      <AddComponentsModal
        visible={visible}
        selectedRow={selectedRow}
        getList={() =>
          ComponentListAction({
            ...filters,
          })
        }
        brandList={brandList}
        brandLoading={brandLoading}
        userData={userData}
        vendorList={vendorList}
        vendorLoading={vendorLoading}
        onClose={handleClose}
      />

      <GetUnitCostHistory
        setIsModalVisible={setUnitCostHistory}
        isModalVisible={unitCostHistory}
        historyType={historyType}
        form={formHistory}
        vendorList={vendorList}
        userData={userData}
        vendorLoading={vendorLoading}
        setSelectedRow={setSelectedRow}
        selectedRow={selectedRow}
      />

      <AdvancedFilter
        setMoreFilterView={setMoreFilterView}
        moreFilterView={moreFilterView}
        brandList={brandList}
        brandLoading={brandLoading}
        vendorList={vendorList}
        vendorLoading={vendorLoading}
        userData={userData}
        advanceFilterLoading={advanceLoading}
        advanceFilterList={advanceList}
        setSelectedAdvanceFilter={setSelectedAdvanceFilter}
        selectedAdvanceFilter={selectedAdvanceFilter}
        getList={GetAdvanceFilterListAction}
        form={Filterform}
        applyFilter={(e) => {
          setMoreFilterView(false);
          setFilters({
            ...filters,
            ...e,
            ["filters[user_filter_id]"]: e?.usf_id,
          });
          ComponentListAction({
            ...filters,
            ...e,
            ["filters[user_filter_id]"]: e?.usf_id,
          });
        }}
      />
    </CompoenntWrapper>
  );
};

export default ComponentList;
