import {
  Modal,
  Button,
  Input,
  Table,
  Image,
  Divider,
  Form,
  Select,
  Avatar,
  Tag,
  InputNumber,
  Col,
  Row,
  List,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { FormatNumber } from "../../../config";
import { Icon } from "@iconify/react/dist/iconify.js";
import { MakeApiCall } from "../../../apis";

const { Option } = Select;
const ComponentList = ({
  show,
  close,
  componentList,
  userData,
  setComponentList,
  record,
  selectedComponents,
}) => {

  console.log(componentList, 'componentList');
  // console.log(setComponentList, 'setComponentList');
  console.log("selectedComponents", selectedComponents);


  const [form] = Form.useForm();
  const [selectedComponentsList, setSelectedComponentsList] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);

  console.log('selectedComponentsList', selectedComponentsList);


  const handleOk = (value) => {
    console.log(value)
    close(false);
  };

  const handleCancel = () => {
    close(false);
  };

  const columns = [
    {
      title: "Component ID",
      dataIndex: "component_id",
      key: "component_id",
    },
    {
      title: "Item Description",
      dataIndex: "item_description",
      key: "item_description",
    },
    {
      title: "Brand",
      dataIndex: "brand_id",
      key: "brand_id",
    },
    {
      title: "Vendor SKU",
      dataIndex: "vendor_sku",
      key: "vendor_sku",
    },
    {
      title: "Unit Cost",
      dataIndex: "unit_cost",
      key: "unit_cost",
      render: (text) => `$${FormatNumber(parseFloat(text).toFixed(2))}`, // Adding dollar sign formatting for unit cost
    },
    {
      title: "UPC",
      dataIndex: "upc",
      key: "upc",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: 160,
      render: (e, row) => {
        return (
          <Input
            type="number"
            value={e}
            variant={row?.isLinked ? "filled" : "outlined"}
            onChange={(e) => {
              const val = parseInt(e.target.value);
              setComponentList((prev) => {
                return prev?.map((d) => {
                  if (d?.id === row?.id) {
                    return {
                      ...d,
                      quantity: val,
                    };
                  } else {
                    return { ...d };
                  }
                });
              });
            }}
          />
        );
      },
    },
    {
      title: "Action",
      render: (e) => {
        console.log(e, "eee");
        if (e?.isLinked) {
          return (
            <Button size="small" variant="filled" color="danger">
              <Icon icon="carbon:close-filled" width={16} />
            </Button>
          );
        }
        return (
          <Button size="small" variant="filled" color="primary">
            <Icon icon="gridicons:add" width={16} />
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    console.log(selectedComponents, 'select32edComponents');
    
    setSelectedComponentsList(
      selectedComponents?.map((d) => ({
        ...d,
        // quantity: d?.quantity,
        item_description :  d?.item_description
      })) || []
    );
  }, [selectedComponents]);


  const LinkComponents = async (data) => {
    setSubmitLoading(true);

    try {
      const response = await MakeApiCall(
        `link-components`,
        "post",
        data,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setSubmitLoading(false);
        close();
        message.destroy();
        message.success(response?.message);
      } else {
        setSubmitLoading(false);

        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      setSubmitLoading(false);

      message.destroy();
      message.error(error?.message);
    }
  };

  return (
    <Modal
      title="Component List"
      visible={show}
      onOk={handleOk}
      width={"700px"}
      onCancel={handleCancel}
      footer={[
        // <Button className="" onClick={handleCancel} color="primary" variant="filled">
        //   Close
        // </Button>,
        <Button
          loading={submitLoading}
          onClick={() =>
            LinkComponents({
              item_id: record?.id,
              link_components: selectedComponentsList?.map((d) => ({
                component_id: d?.id,
                quantity: d?.quantity,
                item_description : d?.item_description
              })),
            })
          }
          type="primary"
        >
          Save
        </Button>,
      ]}
    >
      <div className="d-flex align-items-center justify-content-start">
        <Image
          src={record?.image_url}
          style={{ objectFit: "contain", width: "140px" }}
          // height={140}
          preview={false}
        />
        <div
          style={{ marginBottom: "16px", marginLeft: "16px" }}
          className="mt-5"
        >
          <h3 className="truncate-text">{record?.item_description || "-"}</h3>
          <span
            style={{
              padding: "4px 8px",
              backgroundColor: "#f0f0f0",
              borderRadius: "4px",
            }}
          >
            {record?.asin1 || "-"}
          </span>
        </div>
      </div>
      <Divider className="my-3" />
      <Form form={form} layout="vertical">
        <Row gutter={16} align={"bottom"}>
          <Col span={12}>
            <Form.Item
              name="component"
              label="Search Component"
              rules={[{ required: true, message: "Please select a Component" }]}
            >
              <Select
                virtual
                showSearch
                optionLabelProp="label"
                placeholder="Select a Component"
                filterOption={(input, option) =>
                  option?.label?.toLowerCase()?.includes(input?.toLowerCase())
                }
                rules={[{ required: true }]}
              >
                {componentList
                  .filter((product) =>
                    !selectedComponentsList.some(
                      (linked) => linked.component_id === product.component_id
                    ) // Ensure this condition is correct
                  )
                  .map((product) => (
                    <Option
                      key={product.component_id}
                      value={product.component_id}
                      label={product.component_id} // Ensure correct field
                      {...product}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ marginLeft: "10px" }}>
                          <div
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "280px",
                            }}
                          >
                            {product.item_description || "-"}
                          </div>
                          <div>
                            <Tag style={{ fontSize: "12px" }} bordered={false} color="blue">
                              {product.component_id}
                            </Tag>
                            <Tag style={{ fontSize: "12px" }} bordered={false} color="geekblue">
                              {product.upc}
                            </Tag>
                          </div>
                        </div>
                      </div>
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="quantity"
              label="Quantity"
              rules={[
                { required: true, message: "Please input quantity" },
                {
                  type: "number",
                  min: 1,
                  message: "Quantity must be at least 1",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item>
              <Button
                style={{ height: "33px" }}
                type="primary"
                // htmlType="submit"
                onClick={() => {
                  form
                    .validateFields()
                    .then(async (values) => {
                      await setSelectedComponentsList((prev) => {
                        const index = prev?.findIndex(
                          (d) => d?.component_id === values?.component
                        );
                        if (index === -1) {
                          const findComponent = componentList?.find(
                            (e) => e?.component_id === values?.component
                          );
                          return [...prev, { ...findComponent, quantity: values?.quantity }];
                        } else {
                          return prev;
                        }
                      });
                      await form.resetFields()
                      // Ensure resetFields is NOT called
                    })
                    .catch((err) => { });
                }}
              >
                +
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <h3>Selected Items</h3>

      <List
        itemLayout="horizontal"
        className="compoent-list-view "
        dataSource={selectedComponentsList}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button
                type="link"
                icon={
                  <Icon
                    icon="carbon:close-filled"
                    style={{ color: "#ff5757" }}
                    width={20}
                  />
                }
                onClick={() => {
                  setSelectedComponentsList((prev) =>
                    prev?.filter((d) => d?.component_id !== item?.component_id)
                  );
                }}
              />,
            ]}
          >
            <List.Item.Meta
              style={{ display: "flex" }}
              title={
                <div className="w-350px">
                  Component ID&nbsp;:&nbsp;{item.component_id}
                  {/* {console.log('item', item) } */}
                  <div className="w-350px truncate-text">
                  Description&nbsp;:&nbsp;{item.item_description || ""}
                </div>
                </div>
              }
              description={
                <div className="d-flex ms-3 ">
                  <span style={{ display: "block" }}>
                    <b className="me-4">
                      {/* Quantity&nbsp;:&nbsp;{item?.quantity || 0} */}
                      Quantity
                      <InputNumber
                        value={item?.quantity}
                        className="ms-2"
                        min={1}
                        onChange={(value) => {
                          setSelectedComponentsList((prev) =>
                            prev?.map((d) =>
                              d?.component_id === item?.component_id
                                ? { ...d, quantity: value }
                                : d
                            )
                          );
                        }}
                        style={{ width: "80px" }}
                      />
                    </b>
                  </span>
                </div>
              }
            />
          </List.Item>
        )}
      />
      {/* <Table
        columns={columns}
        dataSource={componentList?.map((r) => {
          return {
            ...r,
            isLinked: selectedComponents?.map((d) => d?.id)?.includes(r?.id),
          };
        })}
        pagination={true}
        rowKey={"id"}
        scroll={{ x: "max-content" }}
      /> */}
    </Modal>
  );
};

export default ComponentList;
