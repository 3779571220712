import Dashboard from "../modules/admin/dashboard";
import FinancialSummary from "../modules/admin/financial-summary";
import ManageUser from "../modules/admin/manage-user";

export const Admins = [
  {
    path: "dashboard",
    element: <Dashboard />,
  },
  {
    path: "manage-user",
    element: <ManageUser />,
  },
  {
    path: "financial-summary",
    element: <FinancialSummary />,
  },
];
