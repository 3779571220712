import { Button, Card, Flex, Input, message, Popconfirm, Space } from "antd";
import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";
import { Icon } from "@iconify/react/dist/iconify.js";
import Table2 from "../../../../../Components/tables/table2";
import { ConvertParams } from "../../../../../config";
import { MakeApiCall } from "../../../../../apis";
import BrandModalForm from "./add";
import ImportFIle from "../../../../../Components/import-file";

const { Search } = Input;

const BrandSetup = ({ userData,  marketplace_id, selectedMarketplace}) => {

  const [visible, setVisible] = useState(false);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    page: 1,
    "per-page": 20,
    totalPage: 0,
    name: null
  });
  const [selectedRow, setSelectedRow] = useState({});

  const columns = [
    {
      title: "#",
      align: "left",
      render: (_, __, i) => {
        return (
          <span>{(filters?.page - 1) * filters?.["per-page"] + 1 + i}</span>
        );
      },
    },
    {
      title: "Brand Name",
      dataIndex: "b_name",
      key: "b_name",
      render: (e) => {
        return <b>{e}</b>;
      },
    },
    {
      title: "Contact Name",
      dataIndex: "contact_name",
      key: "contact_name",
      render: (e) => {
        return <div className="min-w-100px">{e}</div>;
      },
    },
    {
      title: "Contact Email",
      dataIndex: "contact_email",
      key: "contact_email",
    },
    {
      title: "Contact Note",
      dataIndex: "contact_note",
      key: "contact_note",
      render: (e) => {
        return <div className="min-w-100px">{e}</div>;
      },
    },
    {
      title: "Phone",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Action",
      key: "action",
      width: 150,
      render: (text, record) => (
        <Space size="middle">
          <Button
            color="primary"
            variant="solid"
            onClick={() => handleEdit(record)}
            icon={<Icon width={22} icon="iconamoon:edit-fill" />}
          ></Button>
          <Popconfirm
            title="Are you sure to delete this brand?"
            onConfirm={() => handleDelete(record)}
            okText="Yes"
            placement="left"
            cancelText="No"
          >
            <Button
              color="danger"
              variant="filled"
              icon={<Icon width={22} icon="ic:round-delete" />}
            ></Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const ListBrand = async (data) => {
    const { totalPage, ...rest } = data;
    setLoading(true);
    try {
      const response = await MakeApiCall(
        `brands${ConvertParams(rest)}`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        const { totalCount, page, page_size } = response?.data?.pagination;

        setFilters({
          ...data,
          page: page,
          "per-page": page_size,
          totalPage: totalCount,
        });

        setList(response?.data?.records || []);

        setLoading(false);
      } else {
        message.destroy();
        setLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setLoading(false);
      message.destroy();
      message.error(error?.message);
    }
  };
  const DeleteBrand = async (id) => {
    try {
      const response = await MakeApiCall(
        `brand/${id}`,
        "delete",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        ListBrand({
          ...filters,
        });
        setLoading(true);
        message.destroy();
        message.success(response?.message);
      } else {
        message.destroy();

        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      message.error(error?.message);
    }
  };

  useEffect(() => {
    ListBrand({
      ...filters,
    });
    setLoading(true);
    return () => {};
  }, []);

  // Edit handler
  const handleEdit = (record) => {
    setSelectedRow(record);
    setVisible(true);
  };

  // Delete handler
  const handleDelete = (record) => {
    DeleteBrand(record.b_id);

    // Add your delete logic here
  };

  const handleSearch = (value) => {
    // setLoading(true);
    ListBrand(filters);
    console.log(value)
  };

  return (
    <Wrapper>
      <Card className="mb-5" title={
        <>
        {/* <Search
      placeholder="Search by Brand Name"
      allowClear
      onSearch={onSearch}
      size="large"
      style={{
        width: 250,
      }}
    /> */}
    <Search
                placeholder="Search by brand name"
                allowClear
                size="large"
                className=""
                onSearch={handleSearch}
                value={filters?.name || ""}
                style={{
                  width: 250,
                }}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    name: e.target.value,
                  });
                }}
                onClear={() => {
                  setFilters({
                    ...filters,
                    name: null,
                  });
                  ListBrand({ ...filters, name: null }); 
                }}
              />
        </>
      } 
      extra={
      <Flex wrap>
      <Button
          type="primary"
          className="me-3"
          onClick={() => setVisible(true)}
          icon={<Icon width={20} icon="material-symbols:add" />}
        >
          Add Brand
        </Button>
        {/* <ImportFIle
                    fileType=".xlsx"
                    multiple={false}
                    documentType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    downloadSamplePath={`${URL}Product Line Template.xlsx`}
                    trigger={["click"]}
                    endpoint="import-product-lines"
                    methodKey="POST"
                    bodyKey={{
                      marketplace_id: selectedMarketplace,
                    }}
                    paramKey="UploadedDataFile[import_file]"
                    onResponse={(e) => {
                      if (e?.status === true) {
                        message.destroy();
                        message.success(e?.message);
                      } else {
                        message.destroy();
                        message.error(e?.message);
                      }
                    }}
                    tokenKey={userData?.token}
                  >
                    <Button
                      type="primary"
                      style={{ marginRight: "0px", padding: "5px 10px" }}
                    >
                      <Icon icon="bi:filetype-xlsx" width={18} /> Import
                    </Button>
                  </ImportFIle> */}
        </Flex>
      }
      >
         <Table2
        dataSource={list}
        columns={columns}
        loading={loading}
        pagination={{
          pageSize: filters?.["per-page"],
          current: filters?.page,
          total: filters?.totalPage,
          onChange: (e, f) => {
            const obj = {
              ...filters,
              page: e,
              "per-page": f,
            };
            setFilters(obj);
            ListBrand({
              ...obj,
            });
          },
          showSizeChanger: true, // To show page size options
          pageSizeOptions: [10, 20, 50, 100], // Page size options
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
        scroll={{ x: "max-content", y: 500 }}
      />
      </Card>
      <Flex justify="end" className="mb-0">
        
        <>
                  {/* <div className="position-relative me-3">
                    <MarketplaceSelect
                      showSearch
                      callAfterMarketPlace={(e) => {
                        setSelectedMarketplace(e);
                      }}
                      MarketplaceData={(e) => {}}
                      onChange={(e) => {
                        setSelectedMarketplace(e);
                      }}
                      userData={userData}
                    />
                  </div> */}
                 
                </>
      </Flex>
     
      <BrandModalForm
        selectedRow={selectedRow}
        onSubmit={() => {
          setSelectedRow({});
          setVisible(false);
          ListBrand({
            ...filters,
          });
          setLoading(true);
        }}
        userData={userData}
        visible={visible}
        setVisible={() => {
          setVisible(false);
          setSelectedRow({});
        }}
      />
    </Wrapper>
  );
};

export default BrandSetup;
