// src/components/AccountModal.js
import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const AccountModal = ({
  visible,
  onClose_,
  onSubmit,
  modalLoading,
  initialValues,
  setEditingAccount,
}) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    onSubmit({ ...values }); // , avatar: fileList
  };

  const onClose = () => {
    form.resetFields();
    setEditingAccount({});
    onClose_();
  };

  return (
    <Modal
      title={initialValues ? "Edit Account" : "Add a Account"}
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Close
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={modalLoading}
          onClick={() => form.submit()}
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        name="userForm"
        autoComplete="new-password"
        initialValues={{ ...initialValues }}
        onFinish={onFinish}
      >
        <div className="row">
          <div className="col-12">
            <Form.Item
              name="u_name"
              label="Name"
              rules={[{ required: true, message: "Please input the name!" }]}
            >
              <Input autocomplete="new-password" />
            </Form.Item>
          </div>
          <div className="col-12">
            <Form.Item
              name="u_email"
              label="Email"
              rules={[
                { required: true, message: "Please input the email!" },
                { type: "email", message: "Please enter a valid email!" },
              ]}
            >
              <Input autocomplete="new-password" />
            </Form.Item>
          </div>
        </div>
        <div className="col-12">
          <Form.Item
            name="u_contact_no"
            label="Contact Number"
            rules={[
              { required: true, message: "Please input the contact number!" },
              {
                pattern: new RegExp(/^(\+\d{1,3}[- ]?)?\d{10}$/, "i"),
                message: "Please enter valid phone number!",
              },
            ]}
          >
            <Input autocomplete="new-password" />
          </Form.Item>
        </div>
        {Object.keys(initialValues || {})?.length === 0 && (
          <div className="row">
            <div className="col-6">
              <Form.Item
                name="u_password"
                label="Password"
                rules={[
                  { required: true, message: "Please input the password!" },
                ]}
              >
                <Input.Password autocomplete="new-password" />
              </Form.Item>
            </div>
            <div className="col-6">
              <Form.Item
                name="u_confirm_password"
                label="Confirm Password"
                dependencies={["u_password"]}
                rules={[
                  { required: true, message: "Please confirm the password!" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("u_password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Passwords do not match!")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password autocomplete="new-password" />
              </Form.Item>
            </div>
          </div>
        )}
      </Form>
    </Modal>
  );
};

export default AccountModal;
